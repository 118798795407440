<template>
  <div class="entry-content">
    <div class="error-container"></div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Admin Access</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" v-if="isApprovable">
        <p class="listing-label">Admin Approved</p>
        <div class="form-group font-80P">
          <label
            ><input
              class="form-check-input"
              id="admin_approved"
              name="admin_approved"
              type="checkbox"
              value="admin_approved"
              v-model="values.permissions.admin_access.admin_approved"
              :checked="values.permissions.admin_access.admin_approved"
            />&nbsp;&nbsp; Approved by Admin</label
          >
        </div>
      </div>
      <div class="col-md-9">
        <p class="listing-label">Site Access</p>
        <div class="form-group font-80P">
          <label
            ><input
              class="form-check-input"
              id="site_access"
              name="site_access"
              type="checkbox"
              value="site_access"
              v-model="values.permissions.admin_access.site_access"
              :checked="values.permissions.admin_access.site_access"
            />&nbsp;&nbsp; Show on Site</label
          >
        </div>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <SubmitButton
            :isProgress="submitting"
            :caption="
              update
                ? submitting
                  ? 'Updating'
                  : 'Update IM'
                : submitting
                ? 'Submitting'
                : 'Add IM'
            "
            @submit="handleAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton";

export default {
  props: {
    formData: Object,
    submitting: Boolean,
    update: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    wholePermissions() {
      return this.$route.wholePermissions ? this.$route.wholePermissions : {};
    },
    isApprovable() {
      return this.wholePermissions.admin &&
        this.wholePermissions.admin.permissions
        ? this.wholePermissions.admin.permissions.includes(0)
        : false;
    }
  },
  components: {
    SubmitButton
  },
  data() {
    return {
      values: {
        ...this.formData,
        permissions: {
          ...this.formData.permissions,
          admin_access: {
            ...(this.formData.permissions &&
            this.formData.permissions.hasOwnProperty("admin_access")
              ? this.formData.permissions.admin_access
              : {})
          }
        }
      }
    };
  },
  methods: {
    handleAdd() {
      this.$emit("submit", this.values);
    }
  }
};
</script>

<style scoped>
.mt-22 {
  margin-top: 22px !important;
}
</style>
