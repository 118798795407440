<template>
  <div>
    <ModalComponent v-if="visible" @close="$emit('close')">
      <template v-slot:title>
        <p>
          <strong class="font-120P">Create New Password</strong>
        </p>
      </template>
      <div class="row">
        <div class="col-md-12">
          <form action="">
            <div class="row">
              <div class="col-md-12">
                <p>
                  Create a new password if a customer has difficulty accessing
                  their account.<br />An email will be sent with their new
                  credentials.
                </p>
              </div>
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-12">
                <p class="listing-label">Business Email / Username</p>
                <p>
                  <a :href="`mailto:${account?.contact?.email}`">{{
                    account?.contact?.email
                  }}</a>
                </p>
                <br />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="password">New Password *</label>
                  <div class="input-group" id="show_hide_password">
                    <input
                      class="form-control"
                      id="password"
                      name="password"
                      placeholder="New password"
                      v-bind:type="passwordType"
                      v-model="values.password"
                    />
                    <div class="input-group-addon">
                      <a v-on:click="showPassword"
                        ><i
                          :class="[
                            passwordType === 'password'
                              ? eyeSlashClass
                              : eyeClass
                          ]"
                          aria-hidden="true"
                        ></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="confirm_password">Confirm Password *</label>
                  <div class="input-group" id="show_hide_confirm_password">
                    <input
                      class="form-control"
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm new password"
                      v-bind:type="passwordType"
                      v-model="values.confirm_password"
                    />
                    <div class="input-group-addon">
                      <a v-on:click="showPassword"
                        ><i
                          v-bind:class="[
                            passwordType === 'password'
                              ? eyeSlashClass
                              : eyeClass
                          ]"
                          aria-hidden="true"
                        ></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row" v-if="errors.password">
        <div class="col-md-12">
          <div class="form-group">
            <span class="help-block text-danger">{{ errors.password }}</span>
          </div>
        </div>
      </div>
      <template v-slot:submitBtn>
        <SubmitButton
          :isProgress="submitting"
          :caption="submitting ? 'Submitting' : 'Create New Password'"
          @submit="handleSubmit"
        />
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import ModalComponent from "../../../components/ModalComponent";
import SubmitButton from "../../../components/SubmitButton";
export default {
  components: {
    ModalComponent,
    SubmitButton
  },
  props: {
    visible: Boolean,
    account: Object,
    submitting: Boolean
  },
  data() {
    return {
      values: {
        password: "",
        confirm_password: ""
      },
      passwordType: "password",
      eyeSlashClass: "fa fa-eye-slash",
      eyeClass: "fa fa-eye",
      errors: {
        password: ""
      }
    };
  },
  methods: {
    showPassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    handleSubmit() {
      console.log("Checking new password whether it works or not")
      if (!this.values.password || !this.values.confirm_password) {
        this.errors.password = "A password is required.";
        return;
      }
      if (this.values.password !== this.values.confirm_password) {
        this.errors.password = "The password do not match.";
        return;
      }
      this.$emit("password", this.values);
      console.log(this.values);
    }
  }
};
</script>
