<template>
  <div
    class="modal right fade"
    :id="id"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel2"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <p>
            <strong class="font-120P"
              ><i class="fa fa-calendar-check-o font-orange"></i
              >&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-uppercase">{{
                title
              }}</span></strong
            >
          </p>
          <!--<h4 class="modal-title" id="myModalLabel2">Right Sidebar</h4>-->
        </div>

        <div class="modal-body">
          <div class="col-md-12">
            <!-- Start  contact -->
            <div class="row">
              <form
                id="modal_form"
                class=""
                @submit.prevent.stop="$emit('submit', values)"
                method="post"
                action=""
              >
                <div class="gap-20"></div>
                <slot></slot>
              </form>
            </div>
            <!-- End Right contact -->
          </div>
        </div>
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- modal -->
</template>

<script>
export default {
  props: {
    id: {
      type: String
    },
    title: {
      type: String
    },
    values: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style scoped>
/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 40%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}
</style>
