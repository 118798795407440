<template>
  <span>{{ timestamp }}</span>
</template>
<script>
import moment from "moment-timezone";
import store from "../store";

export default {
  props: {
    value: String,
    format: String
  },
  computed: {
    timestamp() {
      let time = moment.utc(this.value);
      if (store.getters.timezone) {
        time = time.tz(store.getters.timezone);
      } else {
        time = time.local();
      }
      return time.format(this.format ? this.format : "YYYY-MM-DD hh:mm:ss");
    }
  }
};
</script>
