<template>
  <section id="main-container" class="main-container">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <SideBar />
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div class="post-admin">
            <div class="post-body">
              <div class="entry-header-admin">
                <div class="col-md-12 entry-title-admin">
                  <div class="row">
                    <p class="font-180P">
                      INFORMATION MEMORANDUM
                    </p>
                    <p class="font-90P">
                      //
                      <router-link :to="{ name: 'home' }"
                        >dashboard
                      </router-link>
                      /
                      <span class="font-orange">I M Details</span>
                      /
                      <span class="font-orange">{{
                        im && im.listing ? im.listing.reference : "IM"
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="entry-content">
                <!--Tabs start-->
                <div class="col-md-12">
                  <div class="row">
                    <!--tabs border start-->
                    <section class="normal-tabs line-tab">
                      <ul class="nav nav-tabs">
                        <li
                          id="tab-label-01"
                          @click="activeTap = 'detail'"
                          :class="{ active: activeTap === 'detail' }"
                        >
                          <a
                            id="view_im_tab_link"
                            data-toggle="tab"
                            href="#tab-01"
                            ><h4 class="panel-title">View IM</h4></a
                          >
                        </li>
                      </ul>
                      <div class="panel-body">
                        <div class="tab-content">
                          <div
                            id="tab-01"
                            class="tab-pane"
                            v-if="activeTap === 'detail'"
                            :class="{ active: activeTap === 'detail' }"
                          >
                            <!-- im details start -->
                            <div class="col-md-12">
                              <Details
                                v-if="!isLoading && im"
                                :im="im"
                                :updated="updated"
                                :permission-update="permissionUpdate"
                              />
                              <div v-if="isLoading" class="text-center">
                                <div class="loading"></div>
                              </div>
                            </div>
                            <!-- im details end -->
                          </div>
                          <!-- -->
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "@/components/SideBar";
import ResourceTable from "@/components/ResourceTable";
import Details from "./Show/Index";
import Form from "./Form";
import Permission from "./Permission";
import ActivityLog from "@/components/ActivityLog";
import get from "lodash-es/get";
import { mapActions } from "vuex";

export default {
  components: {
    SideBar,
    ResourceTable,
    Details,
    Form,
    Permission,
    ActivityLog
  },
  data() {
    return {
      im: null,
      isLoading: false,
      updateAvailable: false,
      isSubmitting: false,
      activeTap: "detail",
      updated: false,
      permissionUpdate: false,
      url: `${process.env.VUE_APP_LISTINGS_API_URL}/admin/information-memorandums`,
      interest: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/customers`,
      formData: {},
      errors: {},
      interestColumns: [
        {
          name: "date",
          text: "Date Enquired",
          timestamp: item => item.created
        },
        {
          name: "customer",
          text: "Customer",
          render: item => `${item.first_name} ${item.last_name}`,
          link: item => `${process.env.VUE_APP_ADMIN_URL}/customers/${item.id}`,
          sort_disable: true
        },
        {
          name: "ca",
          text: "CA",
          render: item =>
            item.status === "Completed" ? "Download" : item.status,
          download: true,
          downloadable: item => item.status === "Completed",
          className: item =>
            item.status === "Completed" ? "cursor-pointer font-link" : "",
          downloadUrl: item =>
            `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-ca`,
          sort_disable: true
        },
        {
          name: "eoi",
          text: "EOI",
          render: item => item.expressionstatus === null ? 'None' : item.expressionstatus,
          link: item => (item.expressionstatus === null ? ""
                        : (item.expressionstatus === "Submitted"
                          ? `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-eoi`
                          : `${process.env.VUE_APP_ADMIN_URL}/customer/${item.uuid}/information-memorandums?tab=eoi`)),
          downloadable: item => item.expressionstatus === "Submitted",
          className: item => item.expressionstatus === "Submitted"
                                ? "cursor-pointer font-link"
                                : "",
          sort_disable: true
        }
      ]
    };
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async loadUser() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(
          `${this.url}/${atob(this.$route.params.id)}`
        );
        this.im = data;
        this.formData = {
          ...data
        };
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
      }
    },
    async handleSubmit(values, permission) {
      this.isSubmitting = true;
      try {
        const { data } = await this.$http.post(
          `${this.url}/${this.$route.params.id}`,
          {
            ...this.formData,
            ...values
          }
        );
        await this.loadUser();
        await this.loadStats();
        if (permission) {
          this.permissionUpdate = true;
        } else {
          this.updated = true;
        }
        this.activeTap = "detail";
        this.isSubmitting = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isSubmitting = false;
      }
    },
    handlePermissionSubmit(values) {
      this.handleSubmit(values, true);
    }
  },
  mounted() {
    this.loadUser();
    this.updateAvailable = this.$route.permissions
      ? this.$route.permissions.permissions.includes(1)
      : true;
    if (this.$route.query.action) {
      this.activeTap = this.$route.query.action;
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
