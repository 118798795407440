import { createStore } from "vuex";
import app from "./modules/app";
import auth from "./modules/auth";
import table from "./modules/table";
import task from "./modules/task";
import stats from "./modules/stats";
import im from "./modules/im";
import masterData from "./modules/masterData";
import createAxiosPlugin from "./plugins/axios";
import { admin as authAxios } from "@/lib/axios";

window.$http = authAxios;
import VuexPersistence from "vuex-persist";
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["app", "auth"]
});

export default createStore({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    app: app,
    auth: auth,
    table: table,
    stats: stats,
    task: task,
    im: im,
    masterData: masterData
  },
  plugins: [vuexLocal.plugin, createAxiosPlugin(authAxios)]
});
