<template>
  <div class="row">
    <!--<div class="gap-40">-->
    <div class="col-md-12">
      <div class="entry-content">
        <div class="col-md-12">
          <!-- <div class="gap-40"></div>-->
          <div class="row">
            <div class="row border-lt">
              <img
                v-show="isPreview"
                src=""
                :id="`chartImage_${id}`"
                alt="Chart image"
              />
              <canvas v-show="!isPreview" :id="`chart_${id}`"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div>-->
  </div>
</template>

<script>
import map from "lodash-es/map";
import uniq from "lodash-es/uniq";
import filter from "lodash-es/filter";
import first from "lodash-es/first";
export default {
  props: {
    heading: String,
    title: String,
    data: Array,
    id: Number,
    isPreview: Boolean
  },
  data() {
    return {
      chartData: []
    };
  },
  methods: {
    getStructuredChartData() {
      this.chartData = [];
      const legends = uniq(map(this.data, "legend"));
      const labels = uniq(map(this.data, "label"));

      legends.forEach(legend => {
        labels.forEach(label => {
          const citem = this.data.find(
            item => item.legend === legend && item.label === label
          );
          const litem = this.data.find(item => item.legend === legend);
          if (citem !== null && citem !== undefined) {
            this.chartData.push(citem);
          } else {
            this.chartData.push({
              color: litem.color,
              label: label,
              value: 0,
              legend: legend
            });
          }
        });
      });
    },
    getChartData() {
      this.getStructuredChartData();
      return uniq(map(this.chartData, "legend")).map(legend => {
        const items = filter(
          this.chartData,
          itemData => itemData.legend === legend
        );
        const color = window.chartColors[first(uniq(map(items, "color")))];
        return {
          label: legend,
          backgroundColor: color ? color : window.chartColors.grey,
          stack: "Stack 0",
          data: map(items, "value")
        };
      });
    },
    getTitle() {
      return this.title;
    },
    getId() {
      return this.id;
    }
  },
  async mounted() {
    var _this = this;
    var barChartData = {
      type: "bar",
      data: {
        labels: uniq(map(this.data, "label")),
        datasets: this.getChartData()
      },
      options: {
        title: {
          display: true,
          text: this.getTitle()
        },
        tooltips: {
          mode: "index",
          intersect: false
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        },
        animation: {
          onComplete: function() {
            document
              .getElementById(`chartImage_${_this.id}`)
              .setAttribute("src", chart.toBase64Image());
          }
        }
      }
    };
    var chart = new Chart(
      document.getElementById(`chart_${this.id}`).getContext("2d"),
      barChartData
    );
  }
};
</script>
