<template>
  <div class="entry-content" v-if="im">
    <div class="row">
      <div class="alert alert-success fade in" v-if="created">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The IM has been created
      </div>
    </div>
    <div class="row" v-if="updated">
      <div class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The IM's details have been updated
      </div>
      <!-- Alert success end -->
    </div>
    <div class="row" v-if="permissionUpdate">
      <div class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The IM's permissions have been updated
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 ">
        <div class="d-flex justify-content-between p-15">
          <div>
            <p class="listing-label">IM Summary</p>
          </div>
          <div>
            <button
                class="btn btn-small"
                type="button"
                value=""
                v-on:click="
                              downloadIM(
                                'blog-content',
                                 im?.listing?.reference ??''
                              )
                            "
            >
              <i class="fa fa-cloud-download"></i
              >&nbsp;&nbsp;Download
            </button>
          </div>
        </div>


      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row" v-if="im && im.listing">
      <div class="col-md-3">
        <div class="">
          <p class="listing-label">Reference Number</p>
          <p>
            <span class="listing-highlight-3">{{ im.listing.reference }}</span
            >&nbsp;&nbsp;<span
              class="badge badge-active"
              v-if="im.active_status === 'Active'"
              >{{ im.active_status }}</span
            >
          </p>
          <span
            v-if="im.active_status === 'Inactive'"
            class="badge badge-inactive"
            >Inactive</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="">
          <p class="listing-label">Asking price ($)</p>
          <p>{{ im.listing.price }} {{ im.listing.currency }}</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="">
          <p class="listing-label">Active Date</p>
          <p>{{ im.listing.active_date }}</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="">
          <p class="listing-label">Listing Status</p>
          <p>{{ im.listing.status }}</p>
        </div>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">IM As Seen by Customers</strong></p>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="entry-content">
      <div class="gap-20"></div>
      <div id="blog-content" class="blog-content-max-width" ref="blogContent">
        <Blog
            :key="index"
            v-for="(blog, index) in im.sections"
            :listing="im.listing"
            :blog="blog"
        />
      </div>
    </div>
    <div class="gap-40"></div>
    <ModifiedSection
      :created="im.created_at"
      :updated="im.updated_at"
      model="InformationMemorandum"
      :model-id="`${im.id}`"
    />
  </div>
</template>

<script>
import ModifiedSection from "@/components/ModifiedSection";
import Blog from "../components/View/Blog/Index";
import {jsPDF} from "jspdf";
export default {
  props: {
    created: Boolean,
    updated: Boolean,
    im: Object,
    permissionUpdate: Boolean
  },
  components: {
    ModifiedSection,
    Blog
  },
  data() {
    return {};
  },
  methods: {
    downloadIM(divName, reference) {
      this.isPreview = true;
      let _this = this;

      var pdf = new jsPDF("p", "pt", "a2", true, true, 2, 1.0);
      let fileName = "INFORMATION_MEMORANDUM".concat("_", reference, ".pdf");
      pdf.html(document.getElementById(divName), {
        margin: [50, 50, 50, 50],
        callback: function(pdf) {
          pdf.save(fileName);
          _this.isPreview = false;
        },

        x: 175,
        y: 50,
        useCORS: true
      });
    }
  },
  mounted() {}
};
</script>

<style></style>
