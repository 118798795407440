<template>
  <div>
    <div class="entry-content" v-if="customer">
      <div class="row" v-if="updated">
        <div class="alert alert-success fade in">
          <a
            href="#"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            title="close"
            >×</a
          >
          <span class="icon"><i class="fa fa-check"></i></span
          ><strong>Success!</strong><br />{{
            customer?.contact?.first_name
          }}&nbsp;{{ customer?.contact?.last_name }}'s details have been updated
        </div>
        <!-- Alert success end -->
      </div>

      <div class="row">
        <div class="col-md-12">
          <p><strong class="font-120P">Account Definition</strong></p>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p class="listing-label">Registered as an</p>
          <p>{{ customer?.company?.name }}</p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Category</p>
          <p>{{ customer?.category }}</p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Buyer</p>
          <p>{{ customer?.registered_buyer }}</p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Seller</p>
          <p>{{ customer?.registered_seller }}</p>
          <br />
        </div>
      </div>
      <Single
        title="Primary Contact"
        :business="customer.business"
        :contact="customer.contact"
        v-if="customer.contact"
        customer
      />
      <div class="row">
        <Accordion header="Additional Contacts" icon="fa fa-user-plus" closed>
          <div class="gap-40"></div>
          <div v-for="contact in customer.contacts" :key="contact.id">
            <ContactDetail :contact="contact" />
            <div class="gap-40"></div>
          </div>
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Business Details" icon="fa fa-address-card" closed>
          <div class="gap-40"></div>
          <BusinessDetail :business="customer?.primaryBusiness" />
          <div class="row">
            <div class="col-md-12">
              <div class="div-border-bottom"></div>
            </div>
          </div>
          <div class="gap-40"></div>
          <div
            v-for="address in customer?.primaryBusiness?.addresses"
            :key="address.id"
          >
            <BusinessAddressDetail
              :address="address"
              :primary="customer?.primaryBusiness?.primary_address_id"
            />
            <div class="gap-40"></div>
          </div>
        </Accordion>
      </div>

      <div class="gap-40"></div>
      <div class="row" v-if="customer.registered_buyer === 'Yes'">
        <Accordion
          header="Rent Roll / Agency Preferences (for buyers)"
          icon="fa fa-building"
          closed
        >
          <AgencyPreference :account="customer" />
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row" v-if="customer.registered_seller === 'Yes'">
        <Accordion header="Rent Rolls For Sale" icon="fa fa-gavel" closed>
          <ListingReference :account="customer" />
        </Accordion>
      </div>
    </div>
    <div v-if="!customer">
      Loading...
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModifiedSection from "@/components/ModifiedSection";
import Single from "../../../components/Contact/Single";
import ContactDetail from "../../../components/Contact/View";
import Accordion from "../../../components/Accordion";
import BusinessDetail from "../../../components/Business/Detail";
import BusinessAddressDetail from "../../../components/Business/Address/Detail";
import AgencyPreference from "../../CustomerNew/components/Details/AgencyPreference";
import ListingReference from "../../CustomerNew/components/Details/ListingReference";

export default {
  props: {
    created: Boolean,
    updated: Boolean,
    permissionUpdate: Boolean
  },
  components: {
    Single,
    ContactDetail,
    Accordion,
    BusinessDetail,
    BusinessAddressDetail,
    AgencyPreference,
    ListingReference
  },
  computed: {
    ...mapGetters("auth", ["customer"])
  }
};
</script>

<style></style>
