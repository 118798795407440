export default function(axios) {
  return store => {
    axios.interceptors.request.use(
      config => {
        const { useAuth, ...headers } = config.headers;
        if (useAuth !== false) {
          headers[
            "Authorization"
          ] = `Bearer ${store.getters["auth/authorizationToken"]}`;
        }
        return { ...config, headers };
      },
      error => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => response.data,
      error => {
        if (!error.response) {
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          store.commit("resetState");
        }

        return Promise.reject(error.response);
      }
    );

    store.$http = axios;
  };
}
