<template>
  <div class="entry-content">
    <!--Tabs start-->
    <div class="col-md-12">
      <div class="row">
        <!--tabs border start-->
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li class="active">
              <a data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Affiliates</h4></a
              >
            </li>
          </ul>
          <div class="panel-body">
            <div class="tab-content">
              <div id="tab-01" class="tab-pane active">
                <div class="col-md-12">
                  <div class="entry-header-admin">
                    <div class="gap-40"></div>
                    <div class="col-md-8 entry-title-admin text-left pull-left">
                      <div class="row">
                        <p class="font-120P">All Affiliates</p>
                      </div>
                    </div>
                    <div
                      class="col-md-4 entry-title-admin text-right pull-right"
                    >
                      <div class="row" v-if="isCreatable">
                        <router-link
                          class="btn btn-primary"
                          :to="{ name: 'affiliates_create' }"
                        >
                          <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Add
                          Affiliate
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- Heading end -->

                  <!-- Task details start -->
                  <div class="entry-content">
                    <div class="gap-20"></div>
                    <ResourceTable
                      show-path="affiliates_show"
                      :columns="columns"
                      :update-available="updateAvailable"
                      :url="url"
                      :extra-params="{
                        sort_column: 'created_at',
                        sort_dir: 'desc'
                      }"
                    />
                  </div>
                  <!-- Task details end -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceTable from "@/components/ResourceTable";
import columns from "./columns";

export default {
  components: {
    ResourceTable
  },
  computed: {
    columns() {
      return columns;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : true;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  methods: {},
  data() {
    return {
      isLoading: false,
      url: `${process.env.VUE_APP_AFFILIATE_API_URL}/admin/affiliates`
    };
  },
  mounted() {
    this.$ui.setPageHeader({
      title: "Affiliate Administration",
      parentTitle: "Affiliate Administration"
    });

    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Affiliate Administration",
        url: "affiliates",
        active: true
      }
    ]);
  }
};
</script>

<style></style>
