import { configs } from "@/lib/axios";
import map from "lodash-es/map";

export const SET_IM = "SET_IM";
export const SET_EXPRESSION = "SET_EXPRESSION";
export const SET_IM_IS_LOADING = "SET_IM_IS_LOADING";

const state = {
  data: null,
  isLoading: false,
  expression: {}
};

// getters
const getters = {
  data: state => {
    return state.data;
  },
  isLoading: state => {
    return state.isLoading;
  },
  expression: state => {
    return state.expression;
  }
};

// actions
const actions = {
  async getIm({ commit }, uuid) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await this.$http.get(
        `${process.env.VUE_APP_INTEREST_API_URL}/admin/shares/${uuid}`
      );
      commit(SET_IM, data);
    } catch (error) {
      throw error;
    }
  },
  async loadExpression({ commit }, id) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await this.$http.get(
        `${process.env.VUE_APP_INTEREST_API_URL}/admin/expressions/${id}`
      );
      commit(SET_EXPRESSION, data);
    } catch (error) {
      throw error;
    }
  }
};

// mutations
const mutations = {
  [SET_IM]: (state, data) => {
    state.data = data;
  },
  [SET_IM_IS_LOADING]: (state, loading) => {
    state.isLoading = loading;
  },
  [SET_EXPRESSION]: (state, data) => {
    state.expression = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
