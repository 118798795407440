<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import AppDefaultLayout from "./AppDefaultLayout";
import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "AppLayout",
  setup() {
    const layout = shallowRef(AppDefaultLayout);
    const route = useRoute();
    watch(
      () => route.meta,
      async meta => {
        try {
          const component = await require(`@/layouts/${meta.layout}.vue`);
          layout.value = component?.default || AppDefaultLayout;
        } catch (e) {
          layout.value = AppDefaultLayout;
        }
      }
    );
    return { layout };
  }
};
</script>
