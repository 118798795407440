<template>
  <div class="col-md-12">
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row" v-if="singleUser">
          <p class="font-120P">Two-Factor Authentication</p>
          <span v-if="!singleUser.email_otp" class="d-flex m-bot-2 text-gray-600 text-none font-normal">To factor authentication is activated to your account</span>
          <button class="btn btn-small" type="button" @click="getQR" v-if="!singleUser.otp_enable && singleUser.email_otp">
            Enable
          </button>
          <button class="btn btn-small-red" type="button" @click="otpDisable" v-else-if="!(singleUser?.otp_enable && singleUser?.email_otp)">
            Disable
          </button>
        </div>
      </div><!--End label-->
    </div>

    <div class="gap-20"></div>
    <div v-show="singleUser?.otp_enable && singleUser?.email_otp">
      <div>
        <div class="display-flex">
          <div>
            <p class="circle-boarder">1</p>
          </div>
          <div class="m-left-10">
            <p>
              To active Two-Factor Authentication,  you will need <strong>Microsoft Authenticator</strong>
              or <strong>Google Authenticator</strong> on your mobile phone.
            </p>
          </div>
        </div>

        <div class="display-flex">
          <div>
            <p class="circle-boarder">2</p>
          </div>
          <div class="m-left-10">
            <p>
              If either of these are not installed:
            </p>
            <p>
              If you are on an iPhone, Open the App Store, and search for and install either
              <strong>Microsoft Authenticator</strong> or <strong>Google Authenticator.</strong>
            </p>
            <p>
              If you are on an Android Phone, Open the Google Play Store, and search for and install either
              <strong>Microsoft Authenticator</strong> or <strong>Google Authenticator.</strong>
            </p>
          </div>
        </div>

        <div class="display-flex">
          <div>
            <p class="circle-boarder">3</p>
          </div>
          <div class="m-left-10">
            <p>
              Once installed (or if already installed):
            </p>
            <p>
              If using Microsoft Authenticator, within the bottom menu, select Verified IDs, then scan the QR Code below
            </p>
            <p>
              If using Google Authenticator, press the '+' on the bottom-right hand corner of the app screen, then scan the QR Code below
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <img
            :src="qr_code_url"
            alt="">
      </div>
      <div class="gap-20"></div>
      <div>
        <p>
          <strong>The Rent Roll Broker provided code from Microsoft or Google Authenticator.</strong>
        </p>
      </div>
      {{}}
      <div class="form-group">
        <input class="form-control" type="text" v-model="code">
        <span v-if="error">{{error[0]}}</span>
        <span v-if="message">{{message}}</span>
      </div>
      <div class="gap-10"></div>
      <div>
        <button class="btn btn-small" @click="qrActivate">Activate</button>
        <button class="btn m-left-10" @click="otpDisable">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import {get} from "lodash-es";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    })
  },
  data() {
    return {
      checked: false,
      qr_code_url: null,
      isLoading: false,
      error: {},
      code: null,
      message: null,
      singleUser: null
    }
  },
  methods: {
    async getQR() {
      this.isLoading = true;
      try {
        let data = await this.$http.post(
            `${process.env.VUE_APP_IDENTITY_API_URL}/api/authenticator/enable`
        );
        this.qr_code_url = data.qr_code_url;
        await this.getUser();
      } catch (e) {
        this.error = get(e, "data.error");
      }
      this.isLoading = false;
    },
    async qrActivate() {
      this.isLoading = true;
      let otpCode = {
        'code': this.code
      }
      try {
        let data = await this.$http.post(
            `${process.env.VUE_APP_IDENTITY_API_URL}/api/authenticator/activate`, otpCode
        );
        this.error = data.errors ? data.errors.code : null;
        this.message = data.message
        if (data.success) {
          await this.getUser();
        }
        return data;
      } catch (e) {
        this.error = get(e, "data.error");
      }
      this.isLoading = false;
    },
    async otpDisable() {
      this.isLoading = true;
      try {
        let data = await this.$http.post(
            `${process.env.VUE_APP_IDENTITY_API_URL}/api/authenticator/disable`,
        );
        await this.getUser();
        return data;
      } catch (e) {
        this.error = get(e, "data.error");
      }
      this.isLoading = false;
    },
    async getUser() {
      try {
        let data = await this.$http.get(
            `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/${this.$route.params.id}/single-user`
        );
        this.singleUser = data.data;
      }catch (e){
        console.log(e);

      }
    }
  },
  async mounted() {
    await this.getUser();

    if (this.singleUser.otp_enable && this.singleUser.email_otp) {
      await this.getQR();
    }
  }
}
</script>
