<template>
  <div class="row">
    <div class="col-md-12" v-if="opportunity">
      <EmailListTable :queries="queries"/>
    </div>
  </div>
</template>
<script>
import {toRefs} from "vue";
import EmailListTable from "../../../components/O365/EmailListTable.vue";

export default {
  components: {
    EmailListTable,
  },
  props: {
    opportunity: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {opportunity} = toRefs(props);

    const queries = [];
    queries.push(opportunity.value?.customer?.contact.email);
    queries.push(...opportunity.value?.customer?.contacts.map(contact => contact.email));

    queries.push(opportunity.value?.listing?.seller?.contact.email);
    queries.push(...opportunity.value?.listing?.seller?.contacts.map(contact => contact.email));

    queries.push(opportunity.value?.listing?.reference);

    return {
      queries
    };
  }
}
</script>
