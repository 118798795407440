<template>
  <div class="form-group">
    <label :for="name">{{ label }}</label>
    <DatePicker
      :name="name"
      :model-value="values.date"
      input-class="form-control"
      @update="e => handleChange(e.target.value)"
      placeholder="Date"
    />
  </div>
</template>
<script>
import DatePicker from "../../Form/DatePicker";
import moment from "moment";
export default {
  components: {
    DatePicker
  },
  props: {
    name: String,
    label: String,
    key: String,
    value: String
  },
  data() {
    return {
      values: {
       // date: this.value ? this.value : null //moment().format("YYYY-MM-DD")
      }
    };
  },
  methods: {
    handleChange(value) {
      this.values.date = value;
      this.$emit("change", {
        value: moment(value).format("YYYY-MM-DD"),
        key: this.name
      });
    },
    reset() {
      this.values = {}
    }
  }
};
</script>
