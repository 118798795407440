<template>
  <div class="row">
    <Accordion
      header="Account Definition"
      icon="fa fa-thermometer-three-quarters"
      closed
    >
      <div class="row" ref="refAccountDefinitionRow">
        <div class="col-md-12 text-right">
          <button
            title="Save Account"
            class="btn btn-xsmall"
            @click="handleSave(false)"
          >
            <span class="font-120P"><i class="fa fa-save"></i></span>
          </button>
        </div>
      </div>
      <div class="gap-20"></div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="owner_id">Account Owner *</label>
          <Select
            skip
            name="owner_id"
            :value="values.owner_id"
            placeholder="Account Owner"
            :url="taskOwnerUrl"
            extra-query="&admin=true"
            :axios="$http"
            @selected="
              value => {
                values.owner_id = value?.id;
              }
            "
          />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="stage_id">Account Stage *</label>
          <Select
            skip
            name="stage_id"
            :value="values.stage_id"
            placeholder="Account Stage"
            :url="stageUrl"
            extra-query="&admin=true"
            :axios="$http"
            @selected="
              value => {
                values.stage_id = value?.id;
              }
            "
          />
          </div>
        </div>
      </div>
      <div class="gap-20"></div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="lead_source">Lead Source *</label>
            <select
              class="form-control"
              name="lead_source"
              id="lead_source"
              required
              v-model="values.lead_source"
            >
              <option value="" disabled>Please Select</option>
              <option
                :value="value.name"
                v-for="(value, index) in leadSources"
                :key="index"
                >{{ value.name }}</option
              >
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="category">Category *</label>
            <select
              class="form-control"
              name="category"
              id="category"
              required
              v-model="values.category"
            >
              <option disabled value="">Please Select</option>
              <option
                :value="value.name"
                v-for="(value, index) in categories"
                :key="index"
                >{{ value.name }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <div class="gap-20"></div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <Select
              name="company_id"
              label="Registering as an *"
              :value="values.company_id"
              required
              placeholder="Please Select"
              :url="companyUrl"
              :axios="$http"
              @selected="
                value => {
                  values.company_id = value?.id;
                }
              "
              showother
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="registered_buyer">Buyer </label>
            <select
              class="form-control"
              id="registered_buyer"
              name="registered_buyer"
              v-model="values.registered_buyer"
            >
              <option selected disabled value="">Please Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="registered_seller">Seller </label>
            <select
              class="form-control"
              id="registered_seller"
              name="registered_seller"
              v-model="values.registered_seller"
            >
              <option selected disabled value="">Please Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
      </div>
    </Accordion>
    <div class="gap-40"></div>
    <div v-if="values.registered_buyer === 'Yes'">
      <Accordion
        header="Rent Roll / Agency Preferences"
        icon="fa fa-building"
        closed
      >
        <AgencyPreference
            ref="refAgencyPreference"
          :form-data="values"
          @save="values => $emit('save', values)"
        />
      </Accordion>
    </div>
    <div class="gap-40"></div>
    <div v-if="values.registered_seller === 'Yes'">
      <Accordion header="Rent Rolls For Sale" icon="fa fa-gavel" closed>
        <ListingReference
            ref="refListingReference"
          :form-data="values"
          @save="values => $emit('save', values)"
        />
      </Accordion>
    </div>
  </div>
</template>
<script>
import Accordion from "../../../components/Accordion";
import AgencyPreference from "./AgencyPreference";
import ListingReference from "./ListingReference";
import Select from "../../../components/Form/Select";
import { mapGetters } from "vuex";
export default {
  components: {
    Accordion,
    Select,
    AgencyPreference,
    ListingReference
  },
  props: {
    formData: {
      type: Object,
      default: () => ({
        owner_id: "",
        stage_id: "",
        category: "",
        lead_source: "",
        company_id: "",
        registered_buyer: "",
        registered_seller: ""
      })
    }
  },
  computed: {
    ...mapGetters({
      leadSources: "masterData/leadSources",
      categories: "masterData/categories"
    })
  },
  data() {
    return {
      isSubmitting: false,
      taskOwnerUrl: `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/select`,
      values: {
        ...this.formData,
        category: this.formData.category ? this.formData.category : "",
        lead_source: this.formData.lead_source ? this.formData.lead_source : ""
      },
      stageUrl: `${process.env.VUE_APP_CONFIG_API_URL}/accountstages`,
      companyUrl: `${process.env.VUE_APP_CONFIG_API_URL}/accountcompanies`
    };
  },
  methods: {
    async submitAll(){
    if(this.values.registered_buyer === 'Yes') await  this.$refs.refAgencyPreference?.handleSave(true)
    if(this.values.registered_seller === 'Yes') await this.$refs.refListingReference?.handleSave(true)
    await this.handleSave(true)

    },
    async handleSave(isMassUpdate = false) {
      this.isSubmitting = true;
      try {
        let accountData = {
          owner_id: this.values.owner_id,
          stage_id: this.values.stage_id,
          lead_source: this.values.lead_source,
          category: this.values.category,
          company_id: this.values.company_id,
          registered_buyer: this.values.registered_buyer,
          registered_seller: this.values.registered_seller
        }
        await this.$http.patch(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${this.values.id}`,
          accountData
          //this.values
        );
        if(!isMassUpdate) this.$emit("save", "account");
      } catch (e) {
        console.log(e);
      }
      this.isSubmitting = false;
    }
  }
};
</script>
