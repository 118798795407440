<template>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <Select
          skip
          name="owner_id"
          required
          :value="owner"
          placeholder="Filter Owner"
          :url="taskOwnerUrl"
          extra-query="&admin=true"
          :axios="$http"
          @selected="value => handleOwnerChange(value ? value.id : null)"
        />
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group" style="margin-top: 8px;">
        <button
          title="Filter Calendar"
          class="btn btn-xsmall"
          type="submit"
          value="Submit"
        >
          <span class="font-120P"><i class="fa  fa-filter"></i></span>
        </button>
      </div>
    </div>
    <div class="col-md-12">
      <FullCalendar ref="fullCalendar" :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import Select from "../../components/Form/Select";
import moment from "moment";
import map from "lodash-es/map";
import filter from "lodash-es/filter";
import { mapActions } from "vuex";

export default {
  components: {
    FullCalendar,
    Select
  },
  data: () => {
    return {
      tasks: [],
      owner: "",
      start_date: null,
      end_date: null
    };
  },
  methods: {
    ...mapActions({
      setInitialValues: "task/setInitialValues"
    }),
    handleOwnerChange(owner) {
      this.owner = owner;
      this.fullCalendar.refetchEvents();
    }
  },
  computed: {
    taskOwnerUrl() {
      return `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/search`;
    },
    calendarOptions() {
      return {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        events: async (start, callback, failureCallback) => {
          const start_date = moment(start.startStr).format("YYYY-MM-DD");
          const end_date = moment(start.endStr).format("YYYY-MM-DD");
          if (start_date && end_date) {
            try {
              const { data } = await this.$http.get(
                `${process.env.VUE_APP_TASKS_API_URL}/admin/tasks/calendar`,
                {
                  params: {
                    start: start_date,
                    end: end_date,
                    owner: this.owner
                  }
                }
              );
              callback(
                filter(
                  map(data, item => {
                    return {
                      title: item.title,
                      date: item.due_date,
                      allDay: true,
                      id: item.identifier
                    };
                  })
                )
              );
            } catch (e) {
              failureCallback(e);
            }
            return;
          }
          callback([]);
        },
        datesSet: element => {
          this.start_date = moment(element.startStr).format("YYYY-MM-DD");
          this.end_date = moment(element.endStr).format("YYYY-MM-DD");
        },
        eventDidMount: info => {
          info.el.title = info.event._def.title;
        },
        eventClick: info => {
          console.log(info.event.id, "info");
          this.$router.push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              task: info.event.id
            }
          });
          this.$store.commit("task/RESET_FORM");

          this.setInitialValues({
            related: this.$route.meta.model,
            id: this.$route.meta.id,
            task: info.event.id
          });
          this.$store.commit("task/SET_IS_OPEN", true);
        }
      };
    },
    fullCalendar() {
      return this.$refs.fullCalendar.getApi();
    }
  },
  mounted() {
    const fullCalendar = this.fullCalendar;
    this.$emitter.on("task_created", () => {
      fullCalendar.refetchEvents();
    });
  }
};
</script>
