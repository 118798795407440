<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              :class="{ active: activeTap === 'listings' }"
              @click="activeTap = 'listings'"
            >
              <a id="user_details_tab_link" data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Add Listing</h4></a
              >
            </li>
          </ul>
          <form id="form_1">
            <div class="error-container"></div>
            <div class="panel-body">
              <div class="tab-content">
                <div
                  id="tab-01"
                  class="tab-pane"
                  :class="{ active: activeTap === 'listings' }"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <Form
                        :form-data="formData"
                        :errors="errors"
                        @submit="handleSubmit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./Form";
import get from "lodash-es/get";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  components: {
    Form,
  },
  data() {
    return {
      formData: {
        permissions: {
          admin_access: {
            site_access: true,
            admin_approved: true
          }
        },
        agency_detail_title: "Agency Details"
      },
      activeTap: "listings",
      isLoading: false,
      errors: {}
    };
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async handleSubmit(values) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.post(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings`,
          {
            ...this.formData,
            ...values,
            active_date: values.active_date
              ? moment(values.active_date).format("YYYY-MM-DD")
              : null,
            price:
                typeof values.price === "string"
              ? parseFloat(values.price.replaceAll(",", ""))
              : values.price,
            country_id: values.country ? values.country.id : null,
            listing_owner_id: values.owner_id ? values.owner_id.id : null,
            seller_id: values.seller ? values.seller.id : null,
            state_id: values.state ? values.state.id : null,
            commission_amount:
              typeof values.commission_amount === "string"
                ? parseFloat(values.commission_amount.replaceAll(",", ""))
                : values.commission_amount,
            management_fee_income: typeof values.management_fee_income === "string"
              ? parseFloat(values.management_fee_income.replaceAll(",", ""))
              : values.management_fee_income,
            gross_income: typeof values.gross_income === "string"
              ? parseFloat(values.gross_income.replaceAll(",", ""))
              : values.gross_income,
            average_rent: typeof values.average_rent === "string"
              ? parseFloat(values.average_rent.replaceAll(",", ""))
              : values.average_rent,
            average_management_fee: typeof values.average_management_fee === "string"
              ? parseFloat(values.average_management_fee.replaceAll(",", ""))
              : values.average_management_fee
          }
        );
        await this.loadStats();
        this.$router.push({
          name: "listings_show",
          params: { id: data.id }
        });
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
        this.activeTap = "listings";
      }
    },
    handleChangeTap(values) {
      this.activeTap = "permission";
      this.formData = { ...values };
    }
  },
  mounted() {
    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Listing Administration",
        url: "listings"
      },
      {
        name: "Listing Details",
        url: "listings_create",
        active: true
      }
    ]);
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
