<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">Trading Name</p>
        <p>{{ business?.trading_name }}</p>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">Legal Business Name</p>
        <p>{{ business?.name }}</p>
        <br />
      </div>

      <div class="col-md-6">
        <p class="listing-label">Business ABN / ACN</p>
        <p>{{ business?.abn }}</p>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Business Website</p>
        <p>
          <a :href="business?.website">{{ business?.website }}</a>
        </p>
        <br />
      </div>
    </div>
    <div class="row" v-if="seller">
      <div class="col-md-3">
        <p class="listing-label">Member Since</p>
        <p>
          {{
            business.creation_date ? formatDate(business.creation_date) : "-"
          }}
        </p>
      </div>
      <div class="col-md-3">
        <p class="listing-label">Owned By</p>
        <p>{{seller?.owner?.first_name}} {{seller?.owner?.last_name}}</p>
      </div>
      <div class="col-md-3">
        <p class="listing-label">Category</p>
        <p>{{ seller.category }}</p>
      </div>
      <div class="col-md-3">
        <p class="listing-label">Lead Source</p>
        <p>{{ seller.lead_source }}</p>
      </div>
      <div class="gap-20"></div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Business Note</p>
        <p>
          {{ business?.note }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: {
    business: Object,
    seller: Object,
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    }
  }
};
</script>
