<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <Tab :tabs="tabs" :key="key"/>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "../../components/Tab";
import { shallowRef } from "vue";

export default {
  components: {
    Tab
  },
  data() {
    return {
      key : 0,
      tabs: [
        {
          name: "Dropdown Lists",
          component: shallowRef(require("./DropdownList/index.vue").default),
          key: "dropdown"
        },
        {
          name: "Project Stage Templates",
          component: shallowRef(
            require("./ProjectTemplates/index.vue").default
          ),
          key: "project"
        },
        {
          name: "Information Memorandum Template",
          component: shallowRef(
            require("./InformationMemorandum/index.vue").default
          ),
          key: "im"
        }
      ],
    };
  },
  mounted() {
    if(this.$route.name === "im-template"){
      this.tabs = this.tabs.splice(2,1);
      this.key ++;
    }
    this.$ui.setPageHeader({
      title: "Master Data Administration",
      showTask: false
    });

    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Master Data Administration",
        url: "master_data",
        active: true
      }
    ]);
  },
  methods: {}
};
</script>
