<template>
  <div v-if="modelValue">
    <div class="modal-backdrop fade in"></div>
    <div class="modal-dialog-scrollable modal fade in modal-overlay">
      <div id="Delete_Tasks_Modal_3" class="modal-lg modal-dialog" >
        <!-- Modal content -->
        <div class="modal-content model-content-gab">
          <!-- Start  contact -->
          <div class="row">

            <div class="row">
              <div class="col-md-12 text-center">
                <p><strong class="font-200P font-orange"><i class="fa fa-warning font-orange"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                  Are You Sure?</strong></p>
              </div>
            </div>

            <div class="gap-20"></div>

            <form id="form_1" role="form">
              <div class="error-container"></div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <p><strong class="font-120P">{{ message }}<br/><br/>This function cannot be undone.<br/><br/></strong>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="form-group">
                    <button class="btn btn-primary" @click="toggleModal(false)" type="button" form="form_1">
                      CANCEL
                    </button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        class="btn btn-primary" type="button" @click="$emit('clear-all-tasks')" >{{ title }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- End Right contact -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ConfirmationTaskModal',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'CLEAR ALL',
    },
    message: {
      type: String,
      default: 'Are you sure you want to clear all Approval Tasks?',
    },

  },
  data () {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggleModal (isOpen) {
      this.$emit('update:modelValue', isOpen)
    },
  },
}
</script>
<style scoped>
.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center
}
.model-content-gab {
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%
}
</style>
