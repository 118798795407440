export const status = {
  OPEN: "Active",
  CLOSED: "Completed",
  NO_ACTION: "No Action Taken"
};

export const columns = [
  {
    name: "due_date",
    text: "Date Due",
    timestamp: item => item.due_date
  },
  {
    name: "task",
    text: "Task",
    render: item => item.title
  },
  {
    name: "initiator",
    text: "Initiator",
    render: item =>
      `${item?.created_by?.first_name} ${item?.created_by?.last_name}`,
    sort_disable: true
  },
  {
    name: "owner",
    text: "Owner",
    render: item => `${item?.owner?.first_name} ${item?.owner?.last_name}`,
    sort_disable: true
  },
  {
    name: "status",
    text: "Status",
    render: item => {
      return status[item?.status];
    },
    sort_disable: true
  }
];
