<template>
  <div>
    <label v-if="label" :for="name">{{ label }}</label>
    <v-select
      :name="name"
      :full="full"
      :contentClass="contentClass"
      :options="items"
      :placeholder="placeholder"
      :disabled="disabled"
      @filter="onSearch"
      :loading="loading"
      @selected="handleSelected"
      :value="valueData"
      :class-name="className"
      :default="defaultValue"
      :external-link="externalLink"
      @externalLinkClicked="$emit('externalLinkClicked')"
      @open="loadData"
      ref="select"
    />
    <span v-if="error" class="help-block text-danger">{{ error }}</span>
  </div>
</template>

<script>
import SearchDropdown from "../SearchDropdown";
import debounce from "lodash-es/debounce";
import filter from "lodash-es/filter";

export default {
  props: {
    label: String,
    name: String,
    contentClass: String,
    url: String,
    identifier: String,
    externalLink: Boolean,
    full: Boolean,
    loadOnOpen: Boolean,
    selectedName: String,
    error: String,
    extraQuery: String,
    required: {
      type: Boolean,
      default: false
    },
    selected: String,
    placeholder: String,
    disabled: Boolean,
    className: String,
    axios: Function,
    value: Number,
    skip: {
      type: Boolean,
      default: false
    },
    showother: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "v-select": SearchDropdown
  },
  data() {
    return {
      items: [],
      loading: true,
      valueData: null,
      defaultValue: null
    };
  },
  methods: {
    onSearch(value) {
      this.loading = true;
      this.onFilter(value);
    },
    onFilter: debounce(function(value) {
      this.loadData(value);
    }, 500),
    async loadData(value = "", selected) {
      try {
        const { data } = await this.axios.get(
          `${this.url}?q=${value}&selected=${selected ? selected : ""}${
            this.skip ? "&skip=true" : ""
          }${this.showother ? "&showother=true" : ""}&identifier=${
            this.identifier ? this.identifier : ""
          }${this.extraQuery ? this.extraQuery : ""}`
        );
        this.items = data;
        const selectedValue = filter(data, {
          [this.identifier ? this.identifier : "id"]: selected
        })[0];
        if (selected && selectedValue) {
          this.$emit("selected", selectedValue);
          this.defaultValue = selectedValue;
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    handleSelected(selected) {
      this.$emit("selected", selected);
      this.defaultValue = null;
    },
    resetSelection() {
      this.$refs.select.resetSearch();
    }
  },
  mounted() {
    if (!this.loadOnOpen) {
      this.loadData("", this.value);
    } else {
      if (this.selectedName && this.value) {
        this.defaultValue = {
          id: this.value,
          name: this.selectedName
        };
      }
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    value: {
      immediate: true,
      handler(current) {
        if (!current) {
          this.valueData = null;
        }
      }
    }
  }
};
</script>
