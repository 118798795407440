<template>
  <div class="entry-content">
    <div class="row">
      <div class="col-md-12">
        <Tab ref="tabs" :tabs="tabs" :defaultTab="activeTap" />
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "../../components/Tab";
import columns from "./columns";
import {mapGetters} from "vuex";
export default {
  components: {
    Tab
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    },
    tabs() {
      const tabs = [
        {
          name: "All Accounts",
          component: require("./components/Accounts.vue").default,
          key: "accounts",
          props: {
            url: `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers`,
            showPath: "accounts_show",
            updateAvailable: this.updateAvailable,
            isCreatable: this.isCreatable,
            columns: columns,
            title: "All Accounts",
            create: "Add Account",
            user :this.user,
            extraParams: {
              sort_column: 'created_at',
              sort_dir: 'desc',
              exclude_inactive_secondary_contacts : true
            }
          }
        },
        {
          name: "Leads",
          component: require("./components/Accounts.vue").default,
          key: "leads",
          props: {
            url: `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers`,
            showPath: "accounts_show",
            updateAvailable: this.updateAvailable,
            isCreatable: this.isCreatable,
            columns: columns,
            title: "Leads",
            create: "Add Account",
            extraParams: {
              account_stage: 1001,
              sort_column: 'created_at',
              sort_dir: 'desc'
            }
          }
        },
        {
          name: "Opportunities",
          component: require("./components/Accounts.vue").default,
          key: "opportunities",
          props: {
            url: `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers`,
            showPath: "accounts_show",
            updateAvailable: this.updateAvailable,
            isCreatable: this.isCreatable,
            columns: columns,
            title: "Opportunities",
            create: "Add Account",
            extraParams: {
              account_stage: 1003,
              sort_column: 'created_at',
              sort_dir: 'desc'
            }
          }
        },
        {
          name: "Customers",
          component: require("./components/Accounts.vue").default,
          key: "customers",
          props: {
            url: `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers`,
            showPath: "accounts_show",
            updateAvailable: this.updateAvailable,
            isCreatable: this.isCreatable,
            columns: columns,
            title: "Customers",
            create: "Add Account",
            extraParams: {
              account_stage: 1004,
              sort_column: 'created_at',
              sort_dir: 'desc'
            }
          }
        },
        {
          name: "Export CSV",
          component: require("./components/CsvForm.vue").default,
          key: "customerscsv",
          props: {
            title: "Select CSV Export Fliters"
          }
        }
      ];

      return [...tabs];
    }
  },
  methods: {},
  data() {
    return {
      isLoading: false,
      activeTap: "accounts"
    };
  },
  mounted() {
    this.$ui.setPageHeader({
      title: "Account Administration",
      parentTitle: "Account Administration"
    });
    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Account Administration",
        url: "accounts",
        active: true
      }
    ]);
  }
};
</script>

<style></style>
