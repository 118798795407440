<template>
  <div class="entry-content">
    <!--Tabs start-->
    <div class="col-md-12">
      <div class="row">
        <!--tabs border start-->
        <section class="normal-tabs line-tab">
          <Tab
              ref="tabs"
              :tabs="tabs"
              :defaultTab="activeTap"
              @submit="handleSubmit"
              @unlink="() => (listing.memorandum = null)"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "../../components/Tab";
import get from "lodash-es/get";
import moment from "moment";
import {mapGetters} from "vuex";
import {mapActions} from "vuex";



export default {
  components: {
    Tab
  },
  data() {
    return {
      listing: null,
      id: this.$route.params.id,
      isLoading: false,
      updateAvailable: false,
      isSubmitting: false,
      activeTap: "details",
      updated: false,
      permissionUpdate: false,
      interest: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/customers`,
      formData: {},
      errors: {},
      interestColumns: [
        {
          name: "date",
          text: "Date Enquired",
          timestamp: item => item.created
        },
        {
          name: "customer",
          text: "Customer",
          render: item => `${item.first_name} ${item.last_name}`,
          link: item => `${process.env.VUE_APP_ADMIN_URL}/customers/${item.id}`,
          sort_disable: true
        },
        {
          name: "ca",
          text: "CA",
          render: item =>
              item.status === "Completed" ? "Download" : item.status,
          download: true,
          downloadable: item => item.status === "Completed",
          className: item =>
              item.status === "Completed" ? "cursor-pointer font-link" : "",
          downloadUrl: item =>
              `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-ca`,
          sort_disable: true
        },
        {
          name: "eoi",
          text: "EOI",
          render: item =>
              item.expressionstatus === null ? "None" : item.expressionstatus,
          // link: item =>
          //     item.expressionstatus === null
          //         ? ""
          //         : item.expressionstatus === "Submitted"
          //             ? `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-eoi`
          //             : `${process.env.VUE_APP_ADMIN_URL}/customer/${item.uuid}/information-memorandums?tab=eoi`,
          downloadable: item => item.expressionstatus === "Submitted",
          className: item =>
              item.expressionstatus === "Submitted"
                  ? "cursor-pointer font-link"
                  : "",
          sort_disable: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters("table", ["items"]),
    tabs() {
      return [
        {
          name: "",
          icon: "fa fa-eye",
          component: require("./Show/Index.vue").default,
          key: "details",
          props: {
            listing: this.listing,
            updated: this.updated,
            permissionUpdate: this.permissionUpdate,
            isLoading: this.isLoading
          }
        },
        {
          name: "",
          icon: "fa fa-edit",
          component: require("./Form.vue").default,
          key: "update",
          props: {
            update: true,
            errors: this.errors,
            key: this.listing?.id,
            formData: this.listing,
            isLoading: this.isLoading
          }
        },
        {
          name: "",
          icon: "fa fa-cloud-upload",
          component: require("./components/Document").default,
          key: "documents",
          props: {
            listing: this.listing
          }
        },
        {
          name: "",
          icon: "fa fa-handshake-o",
          component: require("./components/Opportunities").default,
          key: "opportunities",
          props: {
            listingId: this.listing?.id
          }
        },
        {
          name: "",
          icon: "fa fa-calendar-check-o",
          component: require("../../components/Task/Items").default,
          key: "tasks",
          props: {
            relatedType: "List",
            relatedId: this.listing?.id
          }
        },
        {
          name: "",
          icon: "fa fa-sticky-note",
          component: require("../../components/Note/Items").default,
          key: "notes",
          props: {
            relatedType: "List",
            relatedId: this.listing?.id
          }
        },
        {
          name: "",
          icon: "fa fa-envelope",
          component: require("./components/Emails").default,
          key: "emails",
          props: {
            listing: this.listing
          }
        },
        {
          name: "",
          icon: "fa fa-list",
          component: require("../../components/ActivityLog").default,
          key: "activity-log",
          props: {
            subject: "Listing",
            subjectId: this.listing?.id,
            item: this.listing,
            userAvailable: true
          }
        }
      ];
    }
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async loadListings() {
      this.isLoading = true;
      try {
        const {data} = await this.$http.get(
            `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings/${this.$route.params.id}`
        );
        this.listing = data;

        this.formData = {
          ...data
        };
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
      }
    },
    async handleSubmit(values, permission) {
      this.isSubmitting = true;
      try {
        let formData = {
          ...this.formData,
          ...values
        };
        const {data} = await this.$http.patch(
            `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings/${this.$route.params.id}`,
            {
              ...formData,
              active_date: formData.active_date
                  ? moment(formData.active_date).format("YYYY-MM-DD")
                  : null,
              price: (formData.price ? parseFloat(formData.price.toString().replaceAll(",", "")) : ""),
              commission_amount:
                  typeof formData.commission_amount === "string"
                      ? parseFloat(formData.commission_amount.toString().replaceAll(",", ""))
                      : formData.commission_amount,
              management_fee_income: parseFloat(
                  formData.management_fee_income.toString().replaceAll(",", "")
              ),
              gross_income: parseFloat(formData.gross_income.toString().replaceAll(",", "")),
              average_rent: parseFloat(formData.average_rent.toString().replaceAll(",", "")),
              average_management_fee: parseFloat(
                  formData.average_management_fee.toString().replaceAll(",", "")
              ),
              country_id:
                  formData.country && formData.country.id
                      ? formData.country.id
                      : formData.country_id,
              state_id:
                  formData.state && formData.state.id
                      ? formData.state.id
                      : formData.state_id,
              listing_owner_id:
                  formData.owner_id && formData.owner_id.id
                      ? formData.owner_id.id
                      : formData.owner_id,
              seller_id:
                  formData.seller && formData.seller.id
                      ? formData.seller.id
                      : formData.seller_id
            }
        );
        await this.loadListings();
        await this.loadStats();
        if (permission) {
          this.permissionUpdate = true;
        } else {
          this.updated = true;
        }
        this.$refs.tabs.handleActiveTab("details");
        window.scrollTo(0,0);
        this.isSubmitting = false;
      } catch (e) {
        console.log(e);
        this.errors = get(e, "data.errors");
        this.isSubmitting = false;
      }
    },
    handlePermissionSubmit(values) {
      this.handleSubmit(values, true);
    }
  },
  async mounted() {
    const breadcrumbs = [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Listing Administration",
        url: "listings"
      },
      {
        name: "Listing Details",
        url: "listings_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ];

    this.$store.commit("app/SET_BREADCRUMBS", [...breadcrumbs]);

    await this.loadListings();

    this.$store.commit("app/SET_BREADCRUMBS", [
      ...breadcrumbs,
      {
        name: `${this.listing?.reference}`,
        url: "listings_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ]);

    this.updateAvailable = this.$route.permissions
        ? this.$route.permissions.permissions.includes(1)
        : true;
    if (this.$route.query.action) {
      this.activeTap = this.$route.query.action;
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
