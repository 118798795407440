<template>
  <div class="col-md-12">
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-8 entry-title-admin text-left pull-left">
        <div class="row">
          <div class="col-md-3">
            <p class="font-120P">{{title}}</p>
          </div>
          <!-- Start - Available to Super Admin users only -->
          <div class="col-md-2" v-if="isSuperAdmin || isAdmin">
            <!-- Square switch -->
            <label class="switch">
              <input type="checkbox" v-model="showArchived">
              <span class="slider"></span>
            </label>
          </div>
          <div class="col-md-7" v-if="isSuperAdmin || isAdmin">
            Show Archived Accounts &nbsp;&nbsp;
          </div>
          <!-- End - Available to Super Admin users only -->
        </div>
      </div>
      <div class="col-md-4 entry-title-admin text-right pull-right">
        <div class="row" v-if="isCreatable">
          <router-link
            class="btn btn-primary"
            :to="{ name: 'accounts_create' }"
          >
            <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;{{ create }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="entry-content">
      <div class="gap-20"></div>
      <ResourceTable
          :showArchived="showArchived"
        v-if="apiUrl"
        ref="table"
        :show-path="showPath"
        :columns="columns"
        :update-available="updateAvailable"
        :url="apiUrl"
        :extra-params="extraParams"
      />
    </div>
  </div>
</template>
<script>
import ResourceTable from "@/components/ResourceTable";
import {mapGetters} from "vuex";
export default {
  data() {
    return{
      showArchived : false,
      apiUrl : '',
    }
  },
  computed:{
    ...mapGetters({
      isSuperAdmin: "auth/isSuperAdmin",
      isAdmin: "auth/isAdmin"
    })
  },
  props: {
    updateAvailable: Boolean,
    isCreatable: Boolean,
    columns: Object,
    extraParams: Object,
    url: String,
    title: String,
    create: String,
    showPath: String,
    user : Object
  },
  mounted() {
    this.apiUrl = this.url
  },
  components: {
    ResourceTable
  },
  watch : {
    showArchived() {
      setTimeout(() =>{
        this.$refs.table.pageChange(1)
      },500)
    }
  }
};
</script>
