<template>
  <div>
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row">
          <p class="font-120P">Site Statistics</p>
        </div>
      </div>
    </div>
    <div class="entry-content">
      <div class="gap-20"></div>
      <div class="col-md-12">
        <!-- Chart 1-->
        <div class="row">
          <div class="row border-lt">
            <canvas id="canvas1"></canvas>
          </div>
        </div>

        <!-- Chart 2-->
        <div class="gap-40"></div>
        <div class="row">
          <div class="row border-lt">
            <canvas id="canvas2"></canvas>
          </div>
        </div>

        <!-- Chart 3-->
        <div class="gap-40"></div>
        <div class="row">
          <div class="row border-lt">
            <canvas id="canvas3"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import map from "lodash-es/map";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      activeListingChart: {
        labels: [],
        datasets: []
      },
      priceListingChart: {
        labels: [],
        datasets: []
      },
      diversityListingChart: {
        labels: [],
        datasets: []
      }
    };
  },
  computed: {
    ...mapGetters("stats", ["items"])
  },
  methods: {
    ...mapActions("stats", ["getData"]),
    createChart() {
      window.myBar = new Chart(
        document.getElementById("canvas1").getContext("2d"),
        {
          type: "bar",
          data: {
            labels: this.activeListingChart.labels,
            datasets: this.activeListingChart.datasets
          },
          options: {
            responsive: true,
            legend: {
              position: "top"
            },
            title: {
              display: true,
              text: "Number of Active Rent Roll Listings by State"
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
            }
          }
        }
      );
      window.myBar = new Chart(
        document.getElementById("canvas2").getContext("2d"),
        {
          type: "bar",
          data: {
            labels: this.priceListingChart.labels,
            datasets: this.priceListingChart.datasets
          },
          options: {
            responsive: true,
            legend: {
              position: "top"
            },
            title: {
              display: true,
              text: "Dollar Value of Active Rent Roles Listed by State"
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
            }
          }
        }
      );

      window.myBar = new Chart(
        document.getElementById("canvas3").getContext("2d"),
        {
          type: "bar",
          data: {
            labels: this.diversityListingChart.labels,
            datasets: this.diversityListingChart.datasets
          },
          options: {
            title: {
              display: true,
              text: "Active Rent Roll Listings Diversity by State"
            },
            tooltips: {
              mode: "index",
              intersect: false
            },
            responsive: true,
            scales: {
              xAxes: [
                {
                  stacked: true
                }
              ],
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
            }
          }
        }
      );
    },
    mapData() {
      const color = Chart.helpers.color;
      const activeListingChart = this.items.listing_by_state;
      const priceListingChart = this.items.listing_by_state_price;
      const diversityListingChart = this.items.listing_by_diversity;

      this.activeListingChart = {
        labels: map(activeListingChart, "label"),
        datasets: [
          {
            label: "Number of Listings",
            backgroundColor: color(window.chartColors.orange)
              .alpha(0.5)
              .rgbString(),
            borderColor: window.chartColors.orange,
            borderWidth: 1,
            data: map(activeListingChart, "value")
          }
        ]
      };
      this.priceListingChart = {
        labels: map(priceListingChart, "label"),
        datasets: [
          {
            label: "Listings Value $",
            backgroundColor: color(window.chartColors.purple)
              .alpha(0.5)
              .rgbString(),
            borderColor: window.chartColors.purple,
            borderWidth: 1,
            data: map(priceListingChart, "value")
          }
        ]
      };
      this.diversityListingChart = {
        labels: map(diversityListingChart, "label"),
        datasets: [
          {
            label: "Residential",
            backgroundColor: window.chartColors.red,
            stack: "Stack 0",
            data: map(map(diversityListingChart, "value"), "residential")
          },
          {
            label: "Commercial",
            backgroundColor: window.chartColors.blue,
            stack: "Stack 0",
            data: map(map(diversityListingChart, "value"), "commercial")
          },
          {
            label: "Holiday",
            backgroundColor: window.chartColors.green,
            stack: "Stack 0",
            data: map(map(diversityListingChart, "value"), "holiday")
          },
          {
            label: "Strata",
            backgroundColor: window.chartColors.brown,
            stack: "Stack 0",
            data: map(map(diversityListingChart, "value"), "strata")
          }
        ]
      };
      this.createChart();
    }
  },
  async mounted() {
    if (!Object.keys(this.items).length) {
      await this.getData();
    }
    this.mapData();
  }
};
</script>

<style></style>
