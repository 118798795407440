<template>
  <div class="entry-content">
    <div class="error-container"></div>
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-md-12">
          <p>
            <strong>{{ permissionMeta.admin_access.display }}</strong>
          </p>
        </div>
      </div>
      <div class="row">
        <div
          :key="item.key"
          v-for="item in permissionMeta.admin_access.permissions"
          class="col-md-3"
        >
          <p class="listing-label">{{ item.caption }}</p>
          <div class="form-group font-80P">
            <label
              ><input
                class="form-check-input"
                :id="item.key"
                name="admin_access"
                type="checkbox"
                :value="item.key"
                v-model="values.admin.permissions"
                :checked="values.admin.permissions.indexOf(item.key) > -1"
                @change="handleAdminPermission($event)"
              />&nbsp;&nbsp;{{ item.display }}</label
            >
          </div>
        </div>

        <div
            v-if="archiveLabel"
            class="col-md-3"
        >
          <p class="listing-label">{{archiveLabel}}</p>
          <div class="form-group font-80P" :key="key">
            <label
                v-for="(status, index) in statuses"
                :key="index"
                class="pr-2"
            ><input
                class="form-check-input"
                type="radio"
                :disabled="status !== 'Archived'"
                :checked="formData.status === status"
                :value="status"
                @click="updateStatus(status)"

            />&nbsp;{{status}}</label
            >
          </div>
        </div>
      </div>
      <div class="row" v-if="archiveLabel && showDelegate">
        <div class="col-md-12">
          <p class="listing-label">Delegate all artefact ownership to: *</p>
          <div class="form-group">
            <select class="form-control" id="admin_user" v-model="delegateTo" name="admin_user" required @change="$emit('delegateTo',delegateTo)">
              <option selected disabled :value="null">Please Select</option>
              <option v-for="(admin,index) in dropDownUsers" :key="index" :value="admin.identifier">{{admin.name}} - {{admin.business_name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="gap-20"></div>

      <div>
        <div class="row">
          <div class="col-md-12">
            <p>
              <strong>{{ permissionMeta.state_access.display }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="listing-label">
              {{ permissionMeta.state_access.description }}
            </p>
            <div class="form-group font-80P">
              <span
                :key="item"
                v-for="item in permissionMeta.state_access.states"
              >
                <label>
                  <input
                    class="form-check-input"
                    :id="item"
                    :name="item"
                    type="checkbox"
                    :value="item"
                    v-model="values.state.access"
                    :checked="values.state.access.indexOf(item) > -1"
                    @change="handleStateAccessPermission($event)"
                  />&nbsp;&nbsp; {{ item }} </label
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        :key="item.name"
        v-for="item in permissionMeta.module_access.modules"
      >
        <div class="gap-20"></div>

        <div class="row">
          <div class="col-md-12">
            <p>
              <strong>{{ item.display }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="listing-label">{{ item.description }}</p>
          </div>
        </div>
        <div class="row">
          <div
            :key="data_isolation.key"
            v-for="data_isolation in item.data_isolation"
            class="col-md-3"
          >
            <div class="form-group font-80P">
              <label
                ><input
                  class="form-check-input"
                  :id="`${item.name}_${data_isolation.name}`"
                  :name="`${item.name}_${data_isolation.name}`"
                  type="radio"
                  :value="data_isolation.key"
                  v-model="values[item.name].data_isolation"
                />&nbsp;&nbsp;{{ data_isolation.display }}</label
              >
            </div>
          </div>
        </div>
        <div v-if="item.action_permissions">
          <div class="row">
            <div class="col-md-12">
              <p class="listing-label">Permissions</p>
            </div>
          </div>
          <div class="row">
            <div
              :key="action_permission.name"
              v-for="action_permission in item.action_permissions"
              class="col-md-3"
            >
              <div class="form-group font-80P">
                <label
                  ><input
                    class="form-check-input"
                    :id="`${item.name}_${action_permission.name}`"
                    :name="`${item.name}_${action_permission.name}`"
                    type="checkbox"
                    :value="action_permission.key"
                    v-model="values[item.name].permissions"
                    :checked="values[item.name].permissions.indexOf(item) > -1"
                    @change="handleModuleActionPermission($event, item.name)"
                  />&nbsp;&nbsp;{{ action_permission.display }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <SubmitButton
              :isProgress="submitting"
              :caption="
                update
                  ? submitting
                    ? 'Updating'
                    : 'Update User'
                  : submitting
                  ? 'Submitting'
                  : 'Add User'
              "
              @submit="handleAddUser"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitButton from "@/components/SubmitButton";
import {statuses} from "@/constants/status.js";

export default {
  props: {
    permissionMeta: Object,
    formData: Object,
    update: {
      type: Boolean,
      default: () => false
    },
    submitting: {
      type: Boolean,
      default: () => false
    },
    archiveLabel: {
      type: [String,null],
      default : null
    },
  },
  computed: {
    ...mapGetters({
      userPermissions: "auth/permissions",
      isSuperAdmin: "auth/isSuperAdmin"
    })
  },
  components: {
    SubmitButton
  },
  data() {
    return {
      values: {
        ...this.formData.permissions
      },
      isLoading: false,
      statuses : statuses,
      showDelegate : false,
      dropDownUsers : [],
      delegateTo : null,
      key : 0,
    };
  },
  methods: {
    updateStatusAreaKey(){
      this.key++
    },
    handleAddUser() {
      if(this.showDelegate && this.formData.status === "Archived" && !this.delegateTo) return
      this.$emit("submit", this.values);
    },
    async updateStatus(status) {
      this.$emit('updateStatus',status)
      this.showDelegate = status === "Archived"

      if(this.showDelegate) {
        await this.getAdminForDelegate()
      }
    },
    async getAdminForDelegate (){
      const { data } = await this.$http.get(
          `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/dropdown?except_admin_identifier=${this.$route?.params.id}`
      );
      this.dropDownUsers = data
    },
    async getWholePermissions() {
      this.isLoading = true;

      let values = {};
      const permissions = this.formData.permissions;
      this.values = values;
      this.isLoading = false;
    },
    updatePermissionItems(items, checked, value) {
      let index = items.indexOf(value);
      if (!checked && index > -1) {
        items.splice(index, 1);
      }
      return items;
    },
    handleAdminPermission(event) {
      this.values.admin.permissions = this.updatePermissionItems(
        this.values.admin.permissions,
        event.target.checked,
        event.target.value
      );
    },
    handleStateAccessPermission(event) {
      this.values.state.access = this.updatePermissionItems(
        this.values.state.access,
        event.target.checked,
        event.target.value
      );
    },
    handleModuleActionPermission(event, group) {
      this.values[group].permissions = this.updatePermissionItems(
        this.values[group].permissions,
        event.target.checked,
        event.target.value
      );
    }
  }
};
</script>

<style scoped>
.mt-22 {
  margin-top: 22px !important;
}
</style>
