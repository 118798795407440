<template>
  <button
    :disabled="isProgress"
    class="btn btn-primary"
    type="button"
    @click="$emit('submit')"
  >
    <div class="loading-btn" :class="{ 'loading-btn--loading': isProgress }">
      <i :class="icon" v-if="icon"></i> {{ caption }}
      <span v-if="isProgress" class="loading-btn--loading">
        <b></b>
        <b></b>
        <b></b>
      </span>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      default: ""
    },
    isProgress: {
      type: Boolean,
      default: () => false
    },
    icon: String
  }
};
</script>

<style scoped>
/*----------------------------------------*/
/*  Start Loading Button css
/*----------------------------------------*/
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    transform: scale(0.5);
    background-color: #b5ddf0;
  }
  50% {
    background-color: #cfe9f6;
  }
  100% {
    transform: scale(1);
    background-color: #ffffff;
  }
}

.loading-btn {
  align-items: center;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
}
.loading-btn.loading-btn--loading {
  /*font-size: 0;*/
  overflow: hidden;
  white-space: nowrap;
}
.loading-btn.loading-btn--loading span {
  padding-left: 5px;
  display: flex;
}
.loading-btn.loading-btn--loading span b {
  animation-direction: alternate;
  animation-duration: 0.5s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-name: stretch;
  animation-play-state: running;
  animation-timing-function: ease-out;
  border-radius: 100%;
  display: block;
  height: 10px;
  margin: 0 1px;
  width: 10px;
  animation-delay: 0.1s;
  margin: 0 5px;
}
.loading-btn.loading-btn--loading span b:first-child {
  animation-delay: 0s;
  margin: 0;
}
.loading-btn.loading-btn--loading span b:last-child {
  animation-delay: 0.2s;
  margin: 0;
}
.loading-btn b {
  font-weight: normal;
}
.loading-btn span {
  display: none;
}

.input-group-addon {
  border-radius: 0 !important;
}
/*----------------------------------------*/
/*  End Loading Button css
/*----------------------------------------*/
</style>
