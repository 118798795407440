<template>
  <div class="row" id="the-purpose">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-9">
          <h2 class="section-title">{{ headingSmall }}</h2>
          <h3 class="section-sub-title">{{ headingLarge }}</h3>
          <!--/ Title row end -->
        </div>
        <div class="col-md-3 text-right" v-if="listing">
          <p class="listing-label">Reference</p>
          <p>
            <span class="listing-highlight-3">{{ listing.reference }}</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <p v-html="body.text_1"></p>
        </div>
        <div class="col-md-6">
          <div class="item">
            <img class="img-responsive" :src="new_img_url ?? image_url" alt="Purpose" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toDataURL} from "@/utils/image";

export default {
  props: {
    headingSmall: String,
    headingLarge: String,
    image: String,
    image_url: String,
    body: Object,
    listing: Object
  },
  data() {
    return {
      new_img_url : null,
      imageData: null
    };
  },
  async mounted() {
    if (!this.image) {
      return;
    }
    try {
      this.new_img_url = await this.$http.get (
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/document-url/${this.image}`,
          {
            params: {
              path: "information-memorandums/"
            }
          }
      );
      let _this = this;
      toDataURL(this.new_img_url, function(img) {

        _this.new_img_url = img;
      });
    } catch (e) {
      console.log(e);
    }
   /* let _this = this;
    toDataURL(this.image_url, function(img) {
      _this.imageData = img;
    });*/
  },
  /*created() {
    toDataURL(this.image_url, function (img) {
      console.log(img);
    })
  }*/
};
</script>
