<template>
  <div class="col-md-12">
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row">
          <p class="font-120P">Tasks</p>
        </div>
      </div>
    </div>

    <div class="entry-content">
      <div class="gap-20"></div>
      <ResourceTable
        :edit="false"
        :columns="columns"
        :url="url"
        :extra-params="extraParams"
        remove-actions
        @clicked="handleClicked"
      />
    </div>
  </div>
</template>
<script>
import ResourceTable from "../ResourceTable";
import { mapActions } from "vuex";
import { columns } from "../../utils/tasks";

export default {
  props: {
    relatedType: String,
    relatedId: String,
    queryParams: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    ResourceTable
  },
  computed: {
    extraParams() {
      return {
        related_type: this.relatedType,
        related_id: this.relatedId,
        ...this.queryParams
      };
    },
    url() {
      return `${process.env.VUE_APP_TASKS_API_URL}/admin/tasks`;
    },
    columns() {
      return columns;
    }
  },
  methods: {
    ...mapActions({
      setInitialValues: "task/setInitialValues"
    }),
    handleClicked(task) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          task: task.identifier
        }
      });
      this.$store.commit("task/RESET_FORM");

      this.setInitialValues({
        task: task.identifier
      });
      this.$store.commit("task/SET_IS_OPEN", true);
    }
  }
};
</script>
