<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Body Text</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <Editor
            class="form-control form-control-message"
            name="text_line_1"
            :plugins="plugins"
            :toolbar="toolbar"
            placeholder="Text line 1 *"
            rows="10"
            v-model="values.text_line_1"
          ></Editor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    Editor
  },
  props: {
    text_line_1: String
  },
  data() {
    return {
      values: {
        text_line_1: this.text_line_1
      },
      plugins: "lists advlist code link table",
      toolbar: "numlist bullist"
    };
  },
  created() {},
  mounted() {},
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          return;
        }
        this.$emit("valueChange", this.values);
      }
    }
  }
};
</script>
