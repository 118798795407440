<template>
  <div class="side-menu-padding">
    <p class="side-panel-header">MODULES</p>
    <div class="side-panel-group side-panel-default" id="accordionA">
      <div
        :key="menu.name"
        v-for="menu in menus"
        class="panel side-panel-default side-panel"
      >
        <div class="side-panel-heading">
          <h4 class="side-panel-title">
            <a
              data-toggle="collapse"
              v-bind:class="[
                selectedMenu && selectedMenu.name === menu.name
                  ? ''
                  : 'collapsed'
              ]"
              data-parent="#accordionA"
              :href="'#' + menu.href"
              >{{ menu.name }}</a
            >
          </h4>
        </div>
        <div
          :id="menu.href"
          v-bind:class="[
            selectedMenu && selectedMenu.name === menu.name
              ? 'panel-collapse collapse in'
              : 'panel-collapse collapse'
          ]"
        >
          <div class="side-panel-body">
            <router-link
              :key="sub.name"
              v-for="sub in menu.subs"
              :to="{ name: sub.path }"
            >
              <p
                v-bind:class="[
                  currentRoute === sub.path || parentRoute === sub.path
                    ? 'font-admin-menu-active'
                    : 'font-admin-menu'
                ]"
              >
                <i class="fa" :class="sub.icon" />
                {{ sub.name }}
              </p>

              <p v-if="sub.count" class="font-95P">
                <span class="font-95P"
                  >{{
                    stats[sub.stats] ? stats[sub.stats].active : 0
                  }}&nbsp;<span class="font-green font-95P">{{
                    sub.show.active.name
                  }}</span></span
                >
                <span class="font-95P"
                  >&nbsp;{{
                    stats[sub.stats] ? stats[sub.stats].inactive : 0
                  }}&nbsp;<span class="font-orange font-95P">{{
                    sub.show.inactive.name
                  }}</span></span
                >
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import superAdmin from "./Menu/superAdmin";
import adminMenu from "./Menu/admin";
import customerMenu from "./Menu/customer";
import get from "lodash-es/get";
import cloneDeep from "lodash-es/cloneDeep";

export default {
  data() {
    return {
      //menus: this.isCustomer ? customerMenu : adminMenu
    };
  },
  computed: {
    ...mapGetters({
      permissions: "auth/permissions",
      user: "auth/user",
      customer: "auth/customer"
    }),
    ...mapGetters("auth", ["stats", "statsLoaded"]),

    currentRoute() {
      return this.$route.name;
    },
    parentRoute() {
      return this.$route.meta.parentRoute;
    },

    menus() {
      let menus =  this.user ? (this.user.isSuperAdmin ? superAdmin : (this.user.isCustomer ? customerMenu : adminMenu)) : adminMenu;

      let filteredMenus = [];
      menus.forEach((menu) => {
        let menusThatHasPermissions = menu.subs.filter(sub => sub.permissions && sub.permissions.length > 0)

        //If subs have permissions check does that permission available for auth user
        if (menusThatHasPermissions.length) {
          let tempMenu = cloneDeep(menu)
          tempMenu.subs = []

          menu.subs.forEach((sub) => {

            if (sub.permissions) {
              sub.permissions.forEach((per) => {
                let permissionValues = get(this.permissions, per.path)
                let hasPermissions = permissionValues?.includes(per.values)
                if (hasPermissions) {
                  tempMenu.subs.push(sub)
                }
              })
            } else {
              tempMenu.subs.push(sub)
            }
          })

          filteredMenus.push(tempMenu);
        } else {
          filteredMenus.push(menu);
        }
      })
      return filteredMenus;
    },
    selectedMenu() {
      let menus = this.user ? (this.user.isSuperAdmin ? superAdmin : (this.user.isCustomer ? customerMenu : adminMenu)) : adminMenu;
      let _this = this;
      let smenu = menus.find(function(item) {
        return item.subs.find(function(sub) {
          return (
            sub.path === _this.$route.name ||
            sub.path === _this.$route.meta.parentRoute
          );
        });
      });
      return smenu;
    }
  },
  methods: {
    ...mapActions({
      loadStats: "auth/loadStats"
    })
  },
  mounted() {
    this.loadStats();
  }
};
</script>

<style scoped></style>
