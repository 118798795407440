<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              :class="{ active: activeTap === 'user' }"
              @click="activeTap = 'user'"
            >
              <a id="user_details_tab_link" data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Add</h4></a
              >
            </li>
            <li
              id="tab-label-02"
              :class="{ active: activeTap === 'permission' }"
              @click="activeTap = 'permission'"
            >
              <a id="permissions_tab_link" data-toggle="tab" href="#tab-02"
                ><h4 class="panel-title">
                  <i class="fa fa-lock font-120P"></i></h4
              ></a>
            </li>
          </ul>
          <form id="form_1">
            <div class="error-container"></div>
            <div class="panel-body">
              <div class="tab-content">
                <div
                  id="tab-01"
                  class="tab-pane"
                  v-if="activeTap === 'user'"
                  :class="{ active: activeTap === 'user' }"
                >
                  <div class="col-md-12">
                    <Form
                      :business-data="businessData"
                      :form-data="formData"
                      @submit="handleChangeTap"
                      :errors="errors"
                    />
                  </div>
                </div>
                <div
                  id="tab-02"
                  class="tab-pane"
                  v-if="activeTap === 'permission'"
                  :class="{ active: activeTap === 'permission' }"
                >
                  <div class="col-md-12">
                    <Permission
                      :permission-meta="permissions"
                      :form-data="formData"
                      @submit="handleSubmit"
                      :submitting="isLoading"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./Form";
import Permission from "./Permission";
import get from "lodash-es/get";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Form,
    Permission
  },
  data() {
    return {
      formData: {
        permissions: {},
        attributes: {
          is_agent: false
        },
        business: {
          identifier: "",
          address_identifier: ""
        }
      },
      activeTap: "user",
      isLoading: false,
      errors: {},
      businessData: {},
      businessesUrl: `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses/getwithaddresses`
    };
  },
  computed: {
    ...mapGetters({
      permissions: "app/permissions"
    })
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async getPermissionsMeta() {
      try {
        this.formData.permissions = this.permissions?.default_admin_permissions;
      } catch (error) {
        console.log(error);
      }
    },
    async loadBusinesses() {
      try {
        const { data } = await this.$http.get(this.businessesUrl);
        this.businessData = data;
      } catch (e) {
        //this.errors = get(e, "data.errors");
      }
    },
    async handleSubmit(values) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.post("/api/admin/users", {
          ...this.formData,
          permissions: {
            ...values
          }
        });
        await this.loadStats();
        this.$router.push({
          name: "admin_users_show",
          params: { id: data.id }
        });
        this.isLoading = false;
      } catch (e) {
        this.formData.permissions = values;
        this.errors = get(e, "data.errors");
        this.activeTap = "user";
        this.isLoading = false;
      }
    },
    handleChangeTap(values) {
      this.activeTap = "permission";
      this.formData = { ...values };
      this.formData.permissions = this.permissions.default_admin_permissions;
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  },
  async created() {
    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "User Administration",
        url: "admin_users"
      },
      {
        name: "Add User",
        url: "admin_users_create",
        active: true
      }
    ]);

    await this.getPermissionsMeta();
    await this.loadBusinesses();
  }
};
</script>

<style></style>
