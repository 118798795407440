<template>
  <div class="col-md-12">
    <div v-if="!isLoading && listing" class="entry-content">
      <div class="row">
        <div class="alert alert-success fade in" v-if="created">
          <a
              href="#"
              class="close"
              data-dismiss="alert"
              aria-label="close"
              title="close"
          >×</a
          >
          <span class="icon"><i class="fa fa-check"></i></span
          ><strong>Success!</strong><br/>The listing has been created
        </div>
      </div>

      <div class="row" v-if="updated">
        <div class="alert alert-success fade in">
          <a
              href="#"
              class="close"
              data-dismiss="alert"
              aria-label="close"
              title="close"
          >×</a
          >
          <span class="icon"><i class="fa fa-check"></i></span
          ><strong>Success!</strong><br/>The listing's details have been
          updated
        </div>
        <!-- Alert success end -->
      </div>
      <div class="row" v-if="permissionUpdate">
        <div class="alert alert-success fade in">
          <a
              href="#"
              class="close"
              data-dismiss="alert"
              aria-label="close"
              title="close"
          >×</a
          >
          <span class="icon"><i class="fa fa-check"></i></span
          ><strong>Success!</strong><br/>The listing's permissions have been
          updated
        </div>
      </div>
      <ListingDetails :listing="listing" @unlink="unlinkIm"/>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Seller Primary Contact" icon="fa fa-user" closed>
          <div class="gap-40"></div>
          <ContactDetail :contact="listing?.seller?.primary_contact"/>
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Seller Additional Contacts" icon="fa fa-user" closed>
          <div class="gap-40"></div>
          <div
              v-for="contact in listing?.seller?.additional_contacts"
              :key="contact.id"
          >
            <ContactDetail :contact="contact"/>
            <div class="gap-40"></div>
          </div>
        </Accordion>
      </div>
      <!--Start Business details-->
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Business Details" icon="fa fa-address-card" closed>
          <div class="gap-40"></div>
          <BusinessDetail :business="listing?.primaryBusiness" />
          <div class="row">
            <div class="col-md-12">
              <div class="div-border-bottom"></div>
            </div>
          </div>
          <div class="gap-40"></div>
          <div
              v-for="address in listing?.primaryBusiness?.addresses"
              :key="address.id"
          >
            <BusinessAddressDetail
                :address="address"
                :primary="listing?.primaryBusiness?.primary_address_id"
            />
            <div class="gap-40"></div>
          </div>
        </Accordion>
      </div>
    <!--Start linked project-->
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Linked Projects" icon="fa fa-modx" closed>
          <ProjectsListing :listing="listing" />
        </Accordion>
      </div>

      <ModifiedSection
          :created="listing.created_at"
          :updated="listing.updated_at"
          model="Listing"
          :model-id="`${listing.id}`"
      />
    </div>
    <div v-if="isLoading" class="text-center">
      <div class="loading"></div>
    </div>
  </div>
</template>

<script>
import ListingDetails from "../../../components/Listing";
import Accordion from "../../../components/Accordion";
import ContactDetail from "../../../components/Contact/View";
import get from "lodash-es/get";
import ModifiedSection from "@/components/ModifiedSection";
import BusinessDetail from "../../../components/Business/Detail";
import BusinessAddressDetail from "../../../components/Business/Address/Detail";
import ProjectsListing from "../components/Details/ProjectListing";

export default {
  props: {
    created: Boolean,
    updated: Boolean,
    isLoading: Boolean,
    listing: Object,
    projects: Object,
    permissionUpdate: Boolean
  },
  components: {
    ListingDetails,
    Accordion,
    ContactDetail,
    ModifiedSection,
    BusinessDetail,
    BusinessAddressDetail,
    ProjectsListing,
  },
  methods: {
    async unlinkIm() {
      try {
        const {data} = await this.$http.post(
            `${process.env.VUE_APP_LISTINGS_API_URL}/admin/information-memorandums/${this.listing?.id}/unlink`,
            null
        );
        this.$emit("unlink");
      } catch (e) {
        this.errors = get(e, "data.errors");
      }
    }
  },
  mounted() {
  },
  computed: {
    memorandumUrl() {
      return `${process.env.VUE_APP_ADMIN_URL}/information-memorandums/${this.listing?.memorandum.id}`;
    },
    memorandunCreateUrl() {
      return `${process.env.VUE_APP_ADMIN_URL}/information-memorandums/create/${this.listing?.id}`;
    },
    websiteLink() {
      return `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings/${this.listing?.identifier}`;
    }
  },
};
</script>

<style></style>
