<template>
  <div>
    <div>
      <div class="row">
        <div class="col-md-10" v-if="!editName">
          <p>
            <span class="listing-label"
              >ACTIVITY {{ activity.ordering }}:&nbsp;&nbsp;</span
            ><strong>{{ activity.title }}</strong>
          </p>
        </div>
        <div class="col-md-10" v-if="editName">
          <div class="form-group">
            <input
              class="form-control"
              name="activity"
              id="activity"
              placeholder="Activity description"
              v-model="title"
            />
          </div>
        </div>
        <div class="col-md-2 text-right">
          <button
            class="btn btn-xxsmall"
            type="button"
            v-if="editName"
            title="Save Event"
            @click="handleSaveName"
          >
            <i class="fa fa-save"></i>
          </button>
          <button
            class="btn btn-xxsmall"
            type="button"
            v-else
            @click="editName = true"
            title="Edit Activity"
          >
            <i class="fa fa-edit"></i>
          </button>
        </div>
      </div>
      <div class="gap-10"></div>
      <Event
        :key="event.id"
        :event="event"
        v-for="(event, index) in activity.events"
        @updated="values => $emit('eventUpdated', { index, values })"
      />
      <div class="row">
        <div class="gap-10"></div>
        <div class="col-md-6" style="padding-left: 35px;">
          <div class="form-group">
            <textarea
              class="form-control form-control-message"
              name="add_event_text"
              id="add_event_text"
              placeholder="New event"
              v-model="event.title"
              rows="1"
            ></textarea>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <Select
              name="owner"
              :value="event.owner_id"
              required
              load-on-open
              placeholder="Select a owner"
              :url="userSearch"
              :axios="$http"
              @selected="value => (event.owner_id = value ? value.id : null)"
              showother
              class-name="form-control-sm"
            />
          </div>
        </div>
        <div class="col-md-2 text-right">
          <button
            class="btn btn-xxsmall"
            type="button"
            title="Save"
            :class="{ disabled: !(event.title && event.owner_id) }"
            :disabled="!(event.title && event.owner_id)"
            @click="handleSubmitEvent"
          >
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
  </div>
</template>
<script>
import Event from "./Event";
import Select from "../../../components/Form/Select";
export default {
  props: {
    activity: Object
  },
  components: {
    Event,
    Select
  },
  data() {
    return {
      editName: false,
      nameSubmitting: false,
      title: this.activity.title,
      event: {
        title: "",
        owner_id: null
      }
    };
  },
  computed: {
    userSearch() {
      return `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/search`;
    }
  },
  methods: {
    async handleSaveName() {
      this.nameSubmitting = true;
      try {
        await this.$http.patch(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/activities/${this.activity?.id}`,
          {
            title: this.title
          }
        );
        this.$emit("updated", this.title);
      } catch {
        //
      }
      this.editName = false;
      this.nameSubmitting = false;
    },
    async handleSubmitEvent() {
      this.eventSubmitting = true;
      try {
        await this.$http.post(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/events`,
          {
            ...this.event,
            project_stage_activity_id: this.activity.id
          }
        );
        this.event = {
          title: "",
          owner_id: null
        };
        this.$emit("eventCreated");
      } catch {
        //
      }
      this.eventSubmitting = false;
    }
  }
};
</script>
