<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Bar Charts</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="gap-10"></div>
        <button type="button" class="help-accordion">
          &nbsp;&nbsp;Graph Data Instructions
        </button>
        <div class="help-panel">
          <br />
          <p class="font-90P">
            <em>Format</em><br />[bottom (x) label], [bar label], [bar label
            value], [bar colour]
          </p>
          <p class="font-90P">
            <em
              >Bar label must have matching colours (eg all Residential is red
              or all Commercial is blue)</em
            >
          </p>
          <p class="font-90P">
            <em>Available Colours</em><br /><a
              href="https://www.w3schools.com/colors/colors_hex.asp"
              target="_blank"
              >Click here to see available colours by name</a
            >
          </p>
          <p class="font-90P">
            <em>Example</em><br />
            SA, Residential, 12850, red<br />
            SA, Commercial, 1850, blue<br />
            TAS, Residential, 356, red<br />
            TAS, Commercial, 985, blue<br /><br />
            <img class="img-responsive" :src="image" alt="" />
            <br />
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="heading">Chart Heading</label>
          <input
            class="form-control"
            id="heading"
            name="heading"
            placeholder="Chart Heading"
            type="text"
            v-model="values.heading"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="title">Chart Title *</label>
          <input
            class="form-control"
            id="title"
            name="title"
            placeholder="Chart Title"
            type="text"
            v-model="values.title"
            required
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Labels</strong></p>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <table class="table table-bordered ">
        <thead>
          <tr>
            <th>Label</th>
            <th>Legend</th>
            <th>Value</th>
            <th>Color</th>
            <th v-if="values.data.length > 1"></th>
          </tr>
        </thead>
        <tbody>
          <tr :key="index" v-for="(item, index) in values.data">
            <td>
              <input
                class="form-control"
                name="label"
                placeholder="Label"
                type="text"
                v-model="item.label"
              />
            </td>
            <td>
              <input
                class="form-control"
                name="legend"
                placeholder="Legend"
                type="text"
                v-model="item.legend"
              />
            </td>
            <td>
              <input
                class="form-control"
                name="value"
                placeholder="Value"
                type="text"
                v-model="item.value"
              />
            </td>
            <td>
              <input
                class="form-control"
                name="color"
                placeholder="Color"
                type="text"
                v-model="item.color"
              />
            </td>
            <td class="vertical-middle" v-if="values.data.length > 1">
              <button
                title="Remove Column"
                class="btn btn-xsmall btn-danger"
                type="button"
                @click="handleRemoveRow(index)"
              >
                <span class="font-120P"
                  ><i class="fa fa-minus-circle"></i
                ></span>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-right">
              <button
                type="button"
                class="btn btn-small btn-primary ml-4 font-80P"
                @click="handleAddData"
              >
                Add More Data
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="gap-20"></div>
  </div>
</template>

<script>
import filter from "lodash-es/filter";
import HelpImage from "@/assets/images/help/bar_graph_multi.jpg";

export default {
  props: {
    heading: String,
    title: String,
    data: Array
  },
  data() {
    return {
      values: {
        heading: this.heading ? this.heading : null,
        title: this.title ? this.title : null,
        data: this.data
          ? this.data
          : [{ label: "", legend: "", value: "", color: "" }]
      }
    };
  },
  computed: {
    image() {
      return HelpImage;
    }
  },
  methods: {
    handleAddData() {
      this.values.data.push({ label: "", legend: "", value: "", color: "" });
    },
    handleRemoveRow(index) {
      this.values.data = filter(this.values.data, (row, key) => index !== key);
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          return;
        }
        this.$emit("valueChange", this.values);
      }
    }
  }
};
</script>
