<template>
  <div class="col-md-12">
    <div class="breadcrumb-container">
      <div class="progress-breadcrumb">
        <a
          :class="{ 'active-customer': status.key === selected }"
          href="#"
          v-for="(status, index) in statuses"
          :key="status.key"
          @click="$emit('clicked', status.key)"
        >
          <span class="progress-breadcrumb__inner">
            <span class="progress-breadcrumb__title">{{ index + 1 }}</span>
            <span class="progress-breadcrumb__desc">{{ status.text }}</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    statuses: Array,
    selected: String
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.breadcrumb-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 480px;
  padding: 0 0;
}

.progress-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* border-radius: 5px; */
  overflow: hidden;
  margin: auto;
  text-align: center;
  top: 50%;
  width: 100%;
  height: 57px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-shadow: 0 2px 2px #ccc, 0 3px 7px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 1px solid #ccc;
  z-index: 1;
  background-color: #eaeaea;
  font-size: 14px;
}

.progress-breadcrumb a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-decoration: none;
  margin: auto;
  height: 100%;
  padding-left: 38px;
  padding-right: 0;
  color: #959a8e;
}

.progress-breadcrumb a:first-child {
  padding-left: 15.2px;
}

.progress-breadcrumb a:last-child {
  padding-right: 15.2px;
}

.progress-breadcrumb a:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 57px;
  height: 57px;
  top: 0;
  right: -28.14815px;
  background-color: #eaeaea;
  border-top-right-radius: 5px;
  -webkit-transform: scale(0.707) rotate(45deg);
  transform: scale(0.707) rotate(45deg);
  box-shadow: 1px -1px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.progress-breadcrumb a:last-child:after {
  content: none;
}

.progress-breadcrumb__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: auto;
  z-index: 2;
}

.progress-breadcrumb__title {
  font-size: 10px;
}

.progress-breadcrumb__desc {
  font-size: 12px;
  font-weight: bold;
}

.progress-breadcrumb .active-customer,
.progress-breadcrumb .active-customer::after {
  background: #6dbf65;
  color: white;
}

@media all and (max-width: 1000px) {
  .progress-breadcrumb {
    font-size: 12px;
  }
}
@media all and (max-width: 710px) {
  .progress-breadcrumb__desc {
    display: none;
  }

  .progress-breadcrumb {
    height: 38px;
  }

  .progress-breadcrumb a {
    padding-left: 25.33333px;
  }

  .progress-breadcrumb a:after {
    content: "";
    width: 38px;
    height: 38px;
    right: -19px;
    -webkit-transform: scale(0.707) rotate(45deg);
    transform: scale(0.707) rotate(45deg);
  }
}
</style>
