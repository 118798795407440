<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <Tab
          ref="tabs"
          :tabs="tabs"
          :defaultTab="activeTap"
          @submit="handleSubmit"
          @save="handleSave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "../../components/Tab";
import upperFirst from "lodash-es/upperFirst";
import get from "lodash-es/get";
export default {
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      permissionChanged: false,
      activeTap: "details",
      updated: false,
      project: null,
      errors: {}
    };
  },
  components: {
    Tab
  },
  computed: {
    tabs() {
      const tabs = [
        {
          name: "",
          icon: "fa fa-eye",
          component: require("./components/Details.vue").default,
          key: "details",
          props: {
            project: this.project,
            updated: this.updated
          }
        },
        {
          name: "",
          icon: "fa fa-edit",
          component: require("./Form.vue").default,
          key: "update",
          props: {
            project: this.project,
            errors: this.errors,
            key: this.project?.id,
            formData: this.project,
            isLoading: this.isLoading,
            update: true
          }
        },
        {
          name: "",
          icon: "fa fa-cloud-upload",
          component: require("./components/Document").default,
          key: "documents",
          props: {
            project: this.project
          }
        }
      ];
      const stages = this.project?.stages
        ? this.project?.stages.map(stage => {
            return {
              name: upperFirst(stage.key).replace("_", " "),
              component: require("./components/Stage.vue").default,
              key: stage.key,
              props: {
                project: this.project,
                stage: stage
              }
            };
          })
        : [];

      const contract = [
        {
          name: "",
          icon: "fa fa-address-card",
          component: require("./components/Contact").default,
          key: "contract"
        }
      ];

      const afterStages = [
        {
          name: "",
          icon: "fa fa-sticky-note",
          component: require("../../components/Note/Items").default,
          key: "notes",
          props: {
            relatedType: "Project",
            relatedId: this.project?.id
          }
        }
      ];
      //return [...tabs, ...stages, ...contract, ...afterStages];
      return [...tabs, ...stages, ...afterStages];
    }
  },
  methods: {
    async loadProject() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/projects/${this.$route.params.id}`
        );
        this.project = data;
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    async handleSubmit(values) {
      this.isSubmitting = true;
      try {
        const { data } = await this.$http.post(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/projects/${this.project.id}`,
          values
        );
        await this.loadProject();
        // await this.loadStats();
        this.$refs.tabs.handleActiveTab("details");
        this.activeTap = "details";
        window.scrollTo(0,0);
        this.updated = true;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.activeTap = "update";
      }
      this.isSubmitting = false;
    },
    handleSave(value) {
      switch (value) {
        case "project":
        case "permission":
          this.updated = true;
          break;
      }
      this.$refs.tabs.handleActiveTab("details");
      this.activeTap = "details";
    }
  },
  async mounted() {
    this.$ui.setPageHeader({
      title: "Project Administration",
      showTask: true
    });

    const breadcrumbs = [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Project Administration",
        url: "projects"
      },
      {
        name: "Project Details",
        url: "projects_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ];
    this.$store.commit("app/SET_BREADCRUMBS", [...breadcrumbs]);
    await this.loadProject();
    this.$store.commit("app/SET_BREADCRUMBS", [
      ...breadcrumbs,
      {
        name: this.project?.name,
        url: "projects_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ]);
  }
};
</script>
