<template>
  <div class="col-md-12" v-if="project">
    <div class="entry-content">
      <div class="row" v-if="updated">
        <div id="alert-mail-success" class="alert alert-success fade in">
          <a
            href="#"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            title="close"
            >×</a
          >
          <span class="icon"><i class="fa fa-check"></i></span
          ><strong>Success!</strong><br />Project has been updated
        </div>
      </div>
      <div class="gap-20"></div>

      <div class="row">
        <div class="col-md-6">
          <p class="listing-label">Project Name</p>
          <p class="font-120P">
            <strong>{{ project.name }}</strong>
          </p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Date Created</p>
          <p>{{ project.created_at }}</p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Owner</p>
          <p>{{ project.owner?.first_name }} {{ project.owner?.last_name }}</p>
          <br />
        </div>
      </div>

      <div class="gap-40"></div>

      <div class="row">
        <Status
          :statuses="projectStatuses"
          :selected="project.status"
          @clicked="handleClicked"
        />
      </div>

      <div class="gap-40"></div>

      <div class="row">
        <div class="col-md-12">
          <p class="listing-label">Project Description</p>
          <p>
            {{ project.description }}
          </p>
          <br />
        </div>
      </div>

      <div class="gap-40"></div>

      <div class="row">
        <Accordion header="Listing Reference" closed>
          <Listing v-if="project.listing" :listing="project.listing" />
        </Accordion>
      </div>

      <div class="gap-40"></div>

      <Opportunities :project="project" />

      <div class="gap-40"></div>
      <Contact
        :customer="project.listing?.seller?.primary_contact"
        :seller="project.listing?.seller"
        header="Seller Primary Contact"
        icon="fa fa-user"
      />

      <div class="gap-40"></div>

      <div class="row">
        <Accordion header="Seller Additional Contacts" icon="fa fa-user" closed>
          <div class="gap-40"></div>
          <div
            v-for="contact in project?.listing?.seller?.additional_contacts"
            :key="contact.id"
          >
            <ContactDetail :contact="contact" />
            <div class="gap-40"></div>
          </div>
        </Accordion>
      </div>

      <div class="gap-40"></div>

      <div class="row">
        <Accordion header="Business Details" icon="fa fa-address-card" closed>
          <div class="gap-40"></div>
          <div v-if="project?.listing?.seller?.business">
            <BusinessDetail
              :business="project?.listing?.seller?.business"
              :seller="project?.listing?.seller"
            />
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="div-border-bottom"></div>
            </div>
          </div>
          <div class="gap-40"></div>
          <div v-if="project?.listing?.seller?.business">
            <div
              v-for="address in project?.listing?.seller?.business.addresses"
              :key="address.id"
            >
              <BusinessAddressDetail
                :address="address"
                :primary="project?.listing?.seller?.business.primary_address_id"
              />
              <div class="gap-40"></div>
            </div>
          </div>
        </Accordion>
      </div>

      <div class="gap-40"></div>
      <ModifiedSection
        :created="project.created_at"
        :updated="project.updated_at"
        model="Project"
        :model-id="project.id"
      />
    </div>
  </div>
</template>
<script>
import Status from "../../../components/Status";
import Accordion from "../../../components/Accordion";
import Contact from "../../../components/Contact";
import Listing from "../../Listing/Show/Index";
import Opportunities from "./Opportunities";
import ContactDetail from "../../../components/Contact/View";
import BusinessDetail from "../../../components/Business/Detail";
import BusinessAddressDetail from "../../../components/Business/Address/Detail";
import ModifiedSection from "@/components/ModifiedSection";

export default {
  props: {
    project: Object,
    updated: Boolean,
  },
  components: {
    Status,
    Accordion,
    Listing,
    Contact,
    Opportunities,
    ContactDetail,
    BusinessDetail,
    BusinessAddressDetail,
    ModifiedSection
  },
  data() {
    return {
      projectStatuses: [
        {
          key: "NEW",
          text: "New Project"
        },
        {
          key: "IN_PROGRESS",
          text: "In Progress"
        },
        {
          key: "COMPLETE",
          text: "Complete"
        },
        {
          key: "ARCHIVED",
          text: "Archived"
        }
      ]
    };
  },
  methods: {
    handleClicked(selected) {
      this.$emit(
        "submit",
        {
          ...this.project,
          status: selected
        },
        true
      );
    }
  }
};
</script>
