<template>
  <div></div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          return;
        }
        this.$emit("valueChange", this.values);
      }
    }
  }
};
</script>
