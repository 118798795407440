<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <p>
          <span
            class="badge"
            :class="{
              'badge-active': address.is_active,
              'badge-inactive': !address.is_active
            }"
          >
            {{ address.is_active ? "Active" : "Inactive" }}
          </span>
        </p>
      </div>
      <div class="col-md-9" v-if="primary === address.id">
        <p><span class="badge badge-qualification">Primary Address</span></p>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-3">
        <p class="listing-label">Description</p>
        <p>{{ address.description }}</p>
      </div>

      <div class="col-md-6">
        <p class="listing-label">Business Address</p>
        {{ address?.street_address }} <br />{{ address?.suburb }},
        {{ address?.state?.code }}, {{ address?.postcode }} <br />{{
          address?.country?.name
        }}
      </div>
      <div class="col-md-3">
        <p class="listing-label">Business Phone (land-line)</p>
        <p>
          <a :href="`tel:${address.phone}`">{{ address.phone }}</a>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Address Note</p>
        <p>
          {{ address.note }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="div-border-bottom"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    address: Object,
    primary: Number
  }
};
</script>
