<template>
  <div id="main-container">
  <section id="document-body" class="main-container m-top-40">
    <div id="main-document-container" class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center">
            <table width="100%" height="100%" border="0">
              <tr>
                <td align="center" valign="middle">
                  <table border="0" width="100%">
                    <thead v-if="user.email_otp">
                      <tr>
                        <td align="center"><h2>Email Verification</h2></td>
                      </tr>
                      <tr>
                        <td align="center">
                          <br/>
                          <span class="font-120P font-line-height-220P" v-show="success && otpResend">
                            A new <strong>Email Verification Code</strong> has been sent.
                            <br/><br/>
                          </span>
                          <span class="font-120P font-line-height-220P">Please check your <strong>Inbox</strong> or <strong>Junk Mail</strong> folder for an email titled<br/>'<strong>TRRB Email Verification Code</strong>' and enter the enclosed code below</span>
                        </td>
                      </tr>
                    </thead>
                    <thead v-else>
                      <tr>
                        <td align="center"><h2>OTP Verification</h2></td>
                      </tr>
                      <tr>
                        <td align="center">
                          <br/>
                          <span class="font-120P font-line-height-220P">Generated code from Google/Microsoft Authenticator and enter the enclosed code below</span>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td align="center">
                          <div class="verification-wrapper">
                            <div id="alert-mail-error" class="alert alert-danger fade in" v-show="!success && message">
                              {{message}}
                            </div>
                            <form onsubmit="onSubmit(event)" class="content-area">
                              <fieldset class='number-code'>
                                <div class="validation">
                                  <input name='code' v-model="otp_code_one" class='code-input' maxlength="1" required/>
                                  <input name='code' v-model="otp_code_two" class='code-input' maxlength="1" required/>
                                  <input name='code' v-model="otp_code_three" class='code-input' maxlength="1" required/>
                                  <input name='code' v-model="otp_code_four" class='code-input' maxlength="1" required/>
                                  <input name='code' v-model="otp_code_five" class='code-input' maxlength="1" required/>
                                  <input name='code' v-model="otp_code_six" class='code-input' maxlength="1" required/>
                                </div>
                              </fieldset>
                              <p v-if="user.email_otp"><br/><a href="#" @click="resendCode" >Resend Code</a></p>
                              <button class="btn btn-primary" type="button" @click="submitHandle">
                                Submit Code
                              </button>
                            </form>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </div><!-- Col end -->
      </div><!-- Content row end -->
    </div><!-- Document Container end -->
  </section><!-- Document body end print -->
  </div>
</template>

<script>
import { identities } from "@/lib/axios";
import {mapActions, mapGetters} from "vuex";
import { setCookie } from '@/utils/helpers'

export default {
  data() {
    return {
      otp_code_one: null,
      otp_code_two: null,
      otp_code_three: null,
      otp_code_four: null,
      otp_code_five: null,
      otp_code_six: null,
      isSubmitting: false,
      message: false,
      errors: {},
      success: false,
      otpResend: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
      user: "auth/user",
      token: "auth/token",
    })
  },
  mounted() {
    let container = document.getElementsByClassName("validation")[0];
    container.onkeyup = function(e) {
      let target = e.srcElement;
      let maxLength = parseInt(target.attributes["maxlength"].value, 10);
      let myLength = target.value.length;
      if (myLength >= maxLength) {
        let next = target;
        while (next = next.nextElementSibling) {
          if (next == null)
            break;
          if (next.tagName.toLowerCase() == "input") {
            next.focus();
            break;
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getOTPVerification: "auth/getOTPVerification",
      getEmailOTPVerification: "auth/getEmailOTPVerification",
      getToken: "auth/login",
      getCustomer: "auth/getCustomer",
    }),
    async submitHandle(values) {
      this.isSubmitting = true;
      this.errors = {}
      try {
        let otp_code = this.otp_code_one + this.otp_code_two + this.otp_code_three + this.otp_code_four + this.otp_code_five + this.otp_code_six;
        let formData = {
          'otp_code' : otp_code,
          'user_uuid' : this.user.id
        }

        if (this.user.email_otp) {
          const data = await this.getEmailOTPVerification(formData);
          this.success = data.success;
          this.message = data.message;
        } else {
          const data = await this.getOTPVerification(formData);
          this.success = data.success;
          this.message = data.message;
        }
        if (this.success) {
          let formData = {
            'user_uuid' : this.user.id
          }
          await this.getToken({login_token: this.user.login_token});

          if(this.user && this.user.customer){
            await this.getCustomer(this.user.customer.id);
          }

          if(this.loggedIn){
            setCookie('getLoggedIn', this.loggedIn, 1)
            if (this.$route.query && this.$route.query.redirect) {
              window.location.replace(this.$route.query.redirect);
            } else {
              await this.$router.push({name: "home"});
            }
          }

          window.scrollTo(0,0);
          this.isSubmitting = false;
          this.isLoading = false;
        }
      } catch (e) {
        this.success = false;
        this.isSubmitting = false;
      }
    },
    async resendCode() {
      this.isSubmitting = true;
      this.otpResend = false;
      this.errors = {}
      let formData = {
        'user_uuid' : this.user.id
      }
      const data = await identities.post("/api/email-verification/generate", formData);
      this.success = data.data.success;
      this.message = data.data.message;
      this.otpResend = true;
      this.isSubmitting = false;
      this.otp_code_one = null;
      this.otp_code_two = null;
      this.otp_code_three = null;
      this.otp_code_four = null;
      this.otp_code_five = null;
      this.otp_code_six = null;
    }
  }
}
</script>

<style>
.content-area {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing1) * 2);
  padding-left: var(--spacing2);
  border-radius: var(--spacing1);
  max-width: min(100%, 50rem);
}

:root {
  --spacing1: 8px;
  --spacing2: 16px;
  --hue: 400;
  --background1: hsl(261, 35%, 53%);
  --background2: hsl(214, 14%, 13%);
  --background3: hsl(214, 14%, 5%);
  --brand1: hsl(var(--hue) 80% 60%);
  --text1: hsl(328, 5%, 14%);
  --text2: hsl(0, 0%, 90%);
}

.number-code > div {
  display: flex;
}

.number-code > div > input:not(:last-child) {
  margin-right: calc(var(--spacing1) * 2);
}

form input.code-input {
  font-size: 2.5em;
  width: 1em;
  text-align: center;
  flex: 1 0 1em;
}

form input {
  padding: var(--spacing1);
  border-radius: calc(var(--spacing1) / 2);
  color: var(--text1);
  border: 0;
  border: 4px solid var(--background1);
}

form input:invalid {
  box-shadow: none;
}

form input:focus {
  outline: none;
  border: 4px solid var(--brand1);
}
</style>
