export const SET_DATA = "SET_DATA";
export const SET_META = "SET_META";
export const SET_LOADING = "SET_LOADING";
export const CLEAR_STATE = "CLEAR_STATE";

const state = {
  items: {},
  meta: {},
  isLoading: {}
};

// getters
const getters = {
  items: state => name => {
    return state.items && state.items[name] ? state.items[name] : [];
  },
  meta: state => name => {
    return state.meta && state.meta[name] ? state.meta[name] : [];
  },
  isLoading: state => name => {
    return state.isLoading && state.isLoading[name]
      ? state.isLoading[name]
      : false;
  }
};

// actions
const actions = {
  async getData({ commit }, { url, params, name }) {
    commit(SET_LOADING, {
      name: name,
      isLoading: true
    });
    try {
      const data = await this.$http.get(url, {
        params: params
      });
      commit(SET_DATA, { name, data: data.data });
      commit(SET_META, { name, meta: data.meta });
      commit(SET_LOADING, {
        name: name,
        isLoading: false
      });
    } catch (e) {
      console.log(e, "e");
      commit(SET_LOADING, {
        name: name,
        isLoading: false
      });
    }
  },
  async clearState({ commit }) {
    commit(CLEAR_STATE);
  }
};

// mutations
const mutations = {
  [SET_DATA]: (state, { name, data }) => {
    state.items = {
      ...state.items,
      [name]: data
    };
  },
  [SET_LOADING]: (state, { name, isLoading }) => {
    state.isLoading = {
      ...state.isLoading,
      [name]: isLoading
    };
  },
  [SET_META]: (state, { name, meta }) => {
    state.meta = {
      ...state.meta,
      [name]: meta
    };
  },
  [CLEAR_STATE]: state => {
    state.items = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
