<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <Tab
          ref="tabs"
          :tabs="tabs"
          :defaultTab="activeTap"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "../../components/Tab";
import get from "lodash-es/get";

export default {
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      activeTap: "details",
      updated: false,
      opportunity: null,
      errors: {}
    };
  },
  components: {
    Tab
  },
  computed: {
    tabs() {
      return [
        {
          name: "",
          icon: "fa fa-eye",
          component: require("./components/Details.vue").default,
          key: "details",
          props: {
            opportunity: this.opportunity,
            updated: this.updated
          }
        },
        {
          name: "",
          icon: "fa fa-edit",
          component: require("./Form.vue").default,
          key: "update",
          props: {
            opportunity: this.opportunity,
            errors: this.errors,
            key: this.opportunity?.id,
            formData: this.opportunity,
            isLoading: this.isLoading,
            update: true
          }
        },
        {
          name: "",
          icon: "fa fa-cloud-upload",
          component: require("./components/Document").default,
          key: "documents",
          props: {
            opportunity: this.opportunity
          }
        },
        {
          name: "",
          icon: "fa fa-sticky-note",
          component: require("../../components/Note/Items").default,
          key: "notes",
          props: {
            relatedType: "CustomerList",
            relatedId: this.opportunity?.id
          }
        },
        {
          name: "",
          icon: "fa fa-envelope",
          component: require("./components/Emails").default,
          key: "emails",
          props: {
            opportunity: this.opportunity
          }
        },
        {
          name: "",
          icon: "fa fa-list",
          component: require("../../components/ActivityLog").default,
          key: "activity-log",
          props: {
            subject: "CustomerListings",
            subjectId: this.opportunity?.id,
            item: this.opportunity?.listing,
            userAvailable: true
          }
        }
      ];
    }
  },
  methods: {
    async loadOpportunity() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${this.$route.params.id}`
        );
        this.opportunity = data;
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    async handleSubmit(values) {
      this.isSubmitting = true;
      try {
        await this.$http.patch(
          `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${this.opportunity.id}`,
          {
            ...values,
            opportunity_amount: values.opportunity_amount
              ? values.opportunity_amount
              : 0
          }
        );
        await this.loadOpportunity();
        // await this.loadStats();
        this.$refs.tabs.handleActiveTab("details");
        window.scrollTo(0,0);
        this.activeTap = "details";
        this.updated = true;
      } catch (e) {
        console.log(12);
        this.errors = get(e, "data.errors");
        this.activeTap = "update";
      }
      this.isSubmitting = false;
    }
  },
  async mounted() {
    this.$ui.setPageHeader({
      title: "Opportunity Administration",
      showTask: true,
      parentTitle: "Opportunity Administration",
      pageItemTitle: "Opportunity Details",
      pageItem: `${this.opportunity ? this.opportunity.name : ""}`
    });

    const breadcrumbs = [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Opportunity Administration",
        url: "opportunities"
      },
      {
        name: "Opportunity Details",
        url: "opportunities_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ];
    this.$store.commit("app/SET_BREADCRUMBS", [...breadcrumbs]);
    await this.loadOpportunity();
    this.$store.commit("app/SET_BREADCRUMBS", [
      ...breadcrumbs,
      {
        name: `${this.opportunity?.listing?.reference} - ${this.opportunity?.opportunity_name}`,
        url: "opportunities_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ]);
  }
};
</script>
