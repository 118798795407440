<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div>
          <p><strong>Current Top Image</strong></p>
          <p class="font-orange font-90P">
            Replacement image size must be <strong>W 1024px x H 350px</strong>
          </p>
        </div>
        <div class="gap-20"></div>
        <div class="form-group">
          <FileUpload
            @uploaded="handleFileUploaded"
            name="profile_image"
            :image="values.image_url"
            preview
            :path="path"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/components/FileUpload/Index";
export default {
  components: {
    FileUpload
  },
  props: {
    image: String,
    image_url: String,
    path: String
  },
  data() {
    return {
      values: {
        image: this.image,
        image_url: this.image_url
      }
    };
  },
  created() {},
  async mounted() {
    if (!this.image) {
      return;
    }
    try {
      const url = await this.$http.get(
        `${process.env.VUE_APP_LISTINGS_API_URL}/admin/document-url/${this.image}`,
        {
          params: {
            path: this.path ? this.path : "information-memorandums/"
          }
        }
      );
      this.values.image_url = url;
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    handleFileUploaded({ file, fileUrl }) {
      this.values.image = file;
      this.values.image_url = fileUrl;
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          return;
        }
        this.$emit("valueChange", this.values);
      }
    }
  }
};
</script>
