<template>
  <div>
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row">
          <p class="font-120P">Recent Site Activity</p>
        </div>
      </div>
    </div>
    <!-- Heading end -->

    <!-- Task details start -->
    <div class="entry-content">
      <div class="gap-20"></div>
      <ResourceTable :columns="columns" :url="url" remove-actions />
    </div>
    <!-- Task details end -->
  </div>
</template>

<script>
import ResourceTable from "@/components/ResourceTable";

export default {
  components: {
    ResourceTable
  },
  data() {
    return {
      columns: [
        {
          name: "created_date",
          text: "Date",
          timestamp: item => item.created_date
        },
        {
          name: "user",
          text: "User",
          render: item => item.user
        },
        {
          name: "state",
          text: "State",
          render: item => item.state
        },
        {
          name: "role",
          text: "Role",
          render: item => item.role,
          sort_disable: true
        },
        {
          name: "activity",
          text: "Activity",
          render: item => item.activity,
          sort_disable: true
        }
      ],
      url: `${process.env.VUE_APP_ACTIVITY_API_URL}/admin/sitelogs`
    };
  }
};
</script>

<style></style>
