<template>
  <section id="main-container" class="main-container">
    <div class="text-center"><div class="loading"></div></div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    async handleRedirectCognito() {
      if (this.$route.query.code) {
        const data = await this.$http.post(
          `${process.env.VUE_APP_GS_AUTH_URL}/oauth2/token`,
          {},
          {
            useAuth: false,
            headers: {
              "Content-Type": "application/json"
            },
            params: {
              grant_type: "authorization_code",
              code: this.$route.query.code,
              redirect_uri: process.env.VUE_APP_GS_REDIRECT_URL,
              client_id: process.env.VUE_APP_GS_CLIENT_ID
            }
          }
        );
      }
    }
  },
  mounted() {
    this.handleRedirectCognito();
  }
};
</script>

<style></style>
