<template>
  <section id="main-container" class="main-container">
    <div class="container">
      <div class="container">
        <form action="" method="post" role="form" @submit="handleFormSubmit">
          <div class="error-container"></div>
          <div class="row">
            <div class="col-md-3">
              <div>&nbsp;</div>
            </div>
            <div class="col-md-6 listing-container">
              <div class="row p-15">
                <h2 class="font-purple">Password Reset</h2>
                <div
                  id="alert-password-reset-alert"
                  class="alert alert-success fade in"
                  v-if="forgotSuccess"
                >
                  <a
                    href="#"
                    class="close"
                    data-dismiss="alert"
                    aria-label="close"
                    title="close"
                    >×</a
                  >
                  <span class="icon"><i class="fa fa-check"></i></span
                  ><strong>Success!</strong><br />Your password reset request
                  has been sent to your Inbox. Please check and provide the code
                  supplied. If you have not recieved the request, please check
                  your Junk Mail.
                </div>
                <div class="alert alert-danger fade in" v-if="error">
                  <a
                    href="#"
                    class="close"
                    data-dismiss="alert"
                    aria-label="close"
                    title="close"
                    >×</a
                  >
                  <span class="icon"><i class="fa fa-check"></i></span
                  ><strong>Error!</strong><br />
                  {{ error }}
                </div>
              </div>
              <div class="row p-15">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="password">Password *</label>
                    <input
                      class="form-control"
                      id="password"
                      :class="{
                        'has-error': errors.hasOwnProperty('password')
                      }"
                      v-model="formData.password"
                      name="password"
                      data-minlength="6"
                      data-minlength-error="Password need to be min 6 characters."
                      placeholder="Your unique password"
                      type="password"
                      required
                    />
                    <div class="help-block with-errors text-danger">
                      {{
                        errors.hasOwnProperty("password")
                          ? errors.password[0]
                          : ""
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="password_confirmation">Repeat password *</label>
                    <input
                      class="form-control"
                      id="password_confirmation"
                      v-model="formData.password_confirmation"
                      name="password_confirmation"
                      :class="{
                        'has-error': errors.hasOwnProperty('password')
                      }"
                      data-minlength="6"
                      data-minlength-error="Passwords need to be min 6 characters."
                      data-match-error="Passwords do not match."
                      placeholder="Confirm password"
                      type="password"
                      required
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
              </div>
              <div class="row p-15">
                <div class="col-md-12">
                  <div class="form-group">
                    <p class="pull-left">
                      <button
                        :disabled="isLoading"
                        class="btn btn-primary"
                        type="submit"
                        value="Submit"
                      >
                        Reset Password
                      </button>
                    </p>
                    <p class="pull-right">
                      <router-link
                        :to="{ name: 'login' }"
                        class="btn btn-primary"
                        >Login</router-link
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div>&nbsp;</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import get from "lodash-es/get";

export default {
  data() {
    return {
      formData: {
        password: "",
        password_confirmation: "",
        token: "",
        email: ""
      },
      success: false,
      errors: {},
      error: null,
      isLoading: false,
      forgotSuccess: false
    };
  },
  methods: {
    ...mapActions({
      resetPassword: "auth/resetPassword"
    }),
    async handleFormSubmit($event) {
      this.isLoading = true;
      this.errors = {};
      $event.preventDefault();
      try {
        const data = await this.resetPassword(this.formData);
        this.isLoading = false;
        if (!data) {
          this.error = "Token Mismatch!";
        } else {
          await this.$router.push({ name: "login", query: { reset: "true" } });
        }
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.formData.token = this.$route.query.token;
    this.formData.email = this.$route.query.email;
  }
};
</script>

<style></style>
