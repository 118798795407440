<template>
  <div>
    <header id="header" class="header-two">
      <div class="container">
        <div class="row">
          <div class="navbar-header">
            <div class="logo">
              <a
                  :href="webUrl">
                <img
                  src="@/assets/images/the_rent_roll_broker_logo.png"
                  alt="The Rent Roll Broker"
                />
              </a>
            </div>
            <!-- logo end -->
          </div>
          <!-- Navbar header end -->

          <nav class="site-navigation navigation pull-right">
            <div class="site-nav-inner">
              <button
                type="button"
                class="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>

              <div class="collapse navbar-collapse navbar-responsive-collapse">
                <ul class="nav navbar-nav">
                  <li
                    v-for="menuItem in menus"
                    :key="menuItem.path"
                    :class="{
                      dropdown:
                        menuItem.hasOwnProperty('child') ||
                        getLoggedIn(menuItem)
                    }"
                  >
                    <a
                        :href="listingUrl"
                        v-if="menuItem.hasOwnProperty('route') && menuItem.name === 'Rent Roll Listings'"
                    >
                      <span :class="menuItem.spanClass">
                      <i v-show="menuItem.icon" :class="menuItem.icon"></i>
                      {{ menuItem.name }}</span>
                    </a>
                    <a
                      :href="menuItem.route"
                      v-if="
                        menuItem.hasOwnProperty('route') &&
                          menuItem.name !== 'Login' && menuItem.name !== 'Rent Roll Listings'
                      "
                    >
                      <span :class="menuItem.spanClass">
                        <i v-show="menuItem.icon" :class="menuItem.icon"></i>
                        {{ menuItem.name }}</span
                      >
                    </a>
                    <a
                        :href="menuItem.route"
                        v-if="
                        menuItem.hasOwnProperty('route') &&
                          menuItem.name === 'Login' && !getLoggedIn(menuItem) "
                    >
                      <span :class="menuItem.spanClass">
                        <i v-show="menuItem.icon" :class="menuItem.icon"></i>
                        {{ menuItem.name }}</span
                      >
                    </a>
                    <a
                      v-if="
                        menuItem.hasOwnProperty('child') ||
                          getLoggedIn(menuItem)
                      "
                      href="#"
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <span
                        v-if="getLoggedIn(menuItem)"
                        class="font-white font-70P"
                      >
                        <i class="fa fa-user-circle-o"></i>
                        <span> logged in</span>
                      </span>
                      <span v-if="menuItem.name !== 'Login'">{{
                        menuItem.name
                      }}</span
                      >&nbsp;&nbsp;<i class="fa fa-angle-down"></i>
                    </a>
                    <ul
                      v-if="menuItem.hasOwnProperty('child')"
                      class="dropdown-menu"
                      role="menu"
                    >
                      <li v-for="child in menuItem.child" :key="child.route">
                        <a
                          :href="child.route"
                          v-if="child.hasOwnProperty('route')"
                          >{{ child.name }}</a
                        >
                      </li>
                    </ul>
                    <ul
                      v-if="getLoggedIn(menuItem)"
                      class="dropdown-menu"
                      role="menu"
                    >
                      <li>
                        <a disabled>
                          <span class="font-purple">{{
                            `${user.first_name} ${user.last_name}`
                          }}</span>
                        </a>
                      </li>
                      <li v-if="isSuperAdmin || this.$route.name === 'rent_roll_listings' || this.$route.name === 'rent_roll_listings_show' || this.$route.name === 'confidentiality-agreement-prepare'">
                        <a :href="accountUrl">Account</a>
                      </li>
                      <li>
                        <a class="pointer" @click="handleLogout">Log out</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import findIndex from "lodash-es/findIndex";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      menuItems: []
    };
  },
  computed: {
    ...mapGetters({
      menus: "app/menus",
      user: "auth/user",
      loggedIn: "auth/loggedIn",
      customer: "auth/customer",
      isSuperAdmin: "auth/isSuperAdmin"
    }),
    accountUrl() {
      return this.customer === null
        ? `${process.env.VUE_APP_ADMIN_URL}/users/${this.user.id}`
        : `${process.env.VUE_APP_ADMIN_URL}`;
    },
    listingUrl(){
      return `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings`;
    },
    webUrl(){
      return `${process.env.VUE_APP_WEB_URL}`;
    }
  },
  methods: {
    ...mapActions({
      logout: "auth/logOut",
      clearState: "table/clearState"
    }),
    getActiveRoute(path) {
      if (path.child && path.child.length) {
        return findIndex(path.child, { path: this.$route.path }) >= 0;
      }
      return path.name === "login";
    },
    async handleLogout() {
      await this.logout({ values: { id: this.user.id } });
      await this.clearState();
      await this.$router.push({ name: "login" });
    },
    getLoggedIn(menuItem) {
      return this.loggedIn && menuItem.name === "Login";
    }
  }
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
