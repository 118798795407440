<template>
  <div class="col-md-12" v-if="opportunity">
    <div class="entry-content">
      <div class="row" v-if="updated">
        <div id="alert-mail-success" class="alert alert-success fade in">
          <a
            href="#"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            title="close"
            >×</a
          >
          <span class="icon"><i class="fa fa-check"></i></span
          ><strong>Success!</strong><br />Opportunity has been updated
        </div>
      </div>

      <div class="gap-20"></div>

      <div class="row">
        <div class="col-md-6">
          <p class="listing-label">Opportunity name</p>
          <p class="font-120P">
            <strong
              >{{ opportunity?.listing?.reference }} -
              {{ opportunity.opportunity_name }}</strong
            >
          </p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Closing date</p>
          <p>{{ opportunity.closing_date }}</p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Probibility (%)</p>
          <p>
            {{
              opportunity.opportunity_probability
                ? opportunity.opportunity_probability
                : 0
            }}
          </p>
          <br />
        </div>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Status
          :statuses="stages"
          :selected="opportunity.process_stage"
          @clicked="handleClicked"
        />
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <div class="col-md-3">
          <p class="listing-label">Opportunity amount ($)</p>
          <p>
            <strong class="font-green font-120P">{{
              formatPrice(opportunity.opportunity_amount)
            }}</strong>
          </p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Asking price ($)</p>
          <p>
            {{ formatPrice(opportunity?.listing?.price) }}
            {{ opportunity?.listing?.currency }}
          </p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Offer amount ($)</p>
          <Amount
            v-if="opportunity?.current_offer"
            :amount-string="formatPrice(opportunity?.current_offer?.offer_amount)"
            :listing-amount-string="opportunity?.listing?.price"
            :amount="opportunity?.current_offer?.offer_amount"
            :listing-amount="opportunity?.listing?.price"
          />
          <p v-else>0</p>
          <br />
        </div>
      </div>
      <div class="row">
        <div
          v-if="opportunity?.listing?.commission_type === 'FixedAmount'"
          class="col-md-3"
        >
          <p class="listing-label">Commission Fixed</p>
          <p>${{ opportunity?.listing?.commission_amount }}</p>
        </div>
        <div
          v-if="opportunity?.listing?.commission_type === 'Percentage'"
          class="col-md-3"
        >
          <p class="listing-label">Commission Percentage</p>
          <p>{{ opportunity?.listing?.commission_amount }}%</p>
        </div>
        <div class="col-md-3">
          <p class="listing-label">Business Type</p>
          <p>{{ opportunity.business_type }}</p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Lead Source</p>
          <p>{{ opportunity.lead_source }}</p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Owner</p>
          <p>
            {{ opportunity.owner?.first_name }}
            {{ opportunity.owner?.last_name }}
          </p>
          <br />
        </div>
      </div>
      <div class="row">
        <Offer :offers="opportunity.offers" />
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Buyer Primary Contact" icon="fa fa-user" closed>
          <div class="gap-40"></div>
          <ContactDetail :contact="opportunity?.customer?.contact" />
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Buyer Additional Contacts" icon="fa fa-user" closed>
          <div class="gap-40"></div>
          <div
            v-for="contact in opportunity?.customer?.contacts"
            :key="contact.id"
          >
            <ContactDetail :contact="contact" />
            <div class="gap-40"></div>
          </div>
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Buyer Business Details" icon="fa fa-address-card" closed>
          <div class="gap-40"></div>
          <div v-if="opportunity?.customer?.primary_business">
            <BusinessDetail
              :business="opportunity?.customer?.primary_business"
            />
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="div-border-bottom"></div>
            </div>
          </div>
          <div class="gap-40"></div>
          <div v-if="opportunity?.customer?.primary_business">
            <div
              v-for="address in opportunity?.customer?.primary_business
                .addresses"
              :key="address.id"
            >
              <BusinessAddressDetail
                :address="address"
                :primary="
                  opportunity?.customer?.primary_business.primary_address_id
                "
              />
              <div class="gap-40"></div>
            </div>
          </div>
        </Accordion>
      </div>

      <div class="gap-40"></div>
      <div class="row">
        <Accordion
          header="Buyer Rent Roll / Agency Preferences"
          icon="fa fa-building"
          closed
        >
          <div class="gap-40"></div>
          <AgencyPreferences :customer="opportunity.customer" />
        </Accordion>
      </div>

      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Listing Reference" icon="fa fa-list-alt" closed>
          <div class="gap-40"></div>
          <ListingDetails :listing="opportunity.listing" />
        </Accordion>
      </div>

      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Seller Primary Contact" icon="fa fa-user" closed>
          <div class="gap-40"></div>
          <ContactDetail :contact="opportunity?.listing?.seller?.contact" />
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Seller Additional Contacts" icon="fa fa-user" closed>
          <div class="gap-40"></div>
          <div
            v-for="contact in opportunity?.listing?.seller?.contacts"
            :key="contact.id"
          >
            <ContactDetail :contact="contact" />
            <div class="gap-40"></div>
          </div>
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Seller Business Details" icon="fa fa-address-card" closed>
          <div class="gap-40"></div>
          <div v-if="opportunity?.listing?.seller?.primary_business">
            <BusinessDetail
              :business="opportunity?.listing?.seller?.primary_business"
            />
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="div-border-bottom"></div>
            </div>
          </div>
          <div class="gap-40"></div>
          <div v-if="opportunity?.listing?.seller?.primary_business">
            <div
              v-for="address in opportunity?.listing?.seller?.primary_business
                .addresses"
              :key="address.id"
            >
              <BusinessAddressDetail
                :address="address"
                :primary="
                  opportunity?.listing?.seller?.primary_business
                    .primary_address_id
                "
              />
              <div class="gap-40"></div>
            </div>
          </div>
        </Accordion>
      </div>



      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Linked Projects" icon="fa fa-modx" closed>
          <div class="gap-40"></div>
          <Projects
            :account="opportunity.customer"
            :listing="opportunity.listing"
          />
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion
          header="Opportunity Progression History"
          icon="fa fa-thermometer-three-quarters"
          closed
        >
          <div class="gap-40"></div>
          <StageHistory :stages="opportunity.stage_histories" />
        </Accordion>
      </div>

      <div class="gap-40"></div>
      <div class="row">
        <div class="col-md-12">
          <p class="listing-label">Next Step</p>
          <p>
            {{ opportunity.next_step }}
          </p>
          <br />
        </div>
        <div class="col-md-12">
          <p class="listing-label">Opportunity Description</p>
          <p>
            {{ opportunity.description }}
          </p>
          <br />
        </div>
        <div class="col-md-12">
          <p class="listing-label">How did you uncover this opportunity?</p>
          <p>
            {{ opportunity.uncover }}
          </p>
          <br />
        </div>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <ModifiedSection
            :created="opportunity.created_at"
            :updated="
        opportunity.updated_at > opportunity.business?.updated_at
          ? opportunity.updated_at
          : opportunity.business?.updated_at
      "
            model="CustomerListings"
            :model-id="opportunity.id"
        />
        <ConfirmationModal ref="refConfirmationModal"
                           message="Are you sure you want to archive this artifact ?"
                           @declined="$refs.refConfirmationModal.toggleModal(false)"
                           @confirmed="handleClicked('Archived',false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Status from "../../../components/Status";
import Amount from "../partials/Amount";
import Accordion from "../../../components/Accordion";
import ContactDetail from "../../../components/Contact/View";
import BusinessDetail from "../../../components/Business/Detail";
import BusinessAddressDetail from "../../../components/Business/Address/Detail";
import ListingDetails from "../../../components/Listing";
import AgencyPreferences from "../components/AgencyPreferences";
import Offer from "./Offer";
import Projects from "./Projects";
import StageHistory from "./StageHistory";
import ModifiedSection from "@/components/ModifiedSection";
import ConfirmationModal from "../../../components/ConfirmationModal";

export default {
  props: {
    opportunity: Object,
    updated: Boolean
  },
  components: {
    Amount,
    Status,
    Offer,
    Accordion,
    ContactDetail,
    BusinessDetail,
    BusinessAddressDetail,
    ListingDetails,
    AgencyPreferences,
    Projects,
    StageHistory,
    ModifiedSection,
    ConfirmationModal
  },
  data() {
    return {
      stages: [
        { key: "Draft", text: "Draft" },
        { key: "Qualification", text: "Qualify" },
        { key: "Value Proposition", text: "Value Prop" },
        { key: "Expression of Interest", text: "EOI" },
        { key: "Negotiation / Review", text: "Negotiation" },
        { key: "Closed (WON)", text: "Won" },
        { key: "Closed (LOST)", text: "LOST" },
        { key: "Archived", text: "Archived" }
      ]
    };
  },
  methods: {
    handleClicked(selected,byPass = true) {
      if(selected === "Archived" && byPass){
        this.$refs.refConfirmationModal.toggleModal(true)
        return
      }

      if(!byPass){
        this.$refs.refConfirmationModal.toggleModal(false)
      }
      this.$emit(
        "submit",
        {
          ...this.opportunity,
          process_stage: selected
        },
        true
      );
    },
    formatPrice(value) {
      return value?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
  }
};
</script>
