<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <FileViewer class="img-responsive" alt="History" :image="image" />
      </div>
    </div>
    <div class="gap-40"></div>
  </div>
</template>

<script>
import FileViewer from "@/components/FileViewer/Index";

export default {
  components: {
    FileViewer
  },
  props: {
    image: String,
    image_url: String
  }
};
</script>
