<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">State</p>
        <p>{{ customer?.state?.name }}</p>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <p class="listing-label">Price Range From</p>
        <p>{{ customer.price_range_from }}</p>
        <br />
      </div>

      <div class="col-md-3">
        <p class="listing-label">Price Range To</p>
        <p>{{ customer.price_range_to }}</p>
        <br />
      </div>

      <div class="col-md-3">
        <p class="listing-label">Roll Size From</p>
        <p>{{ customer.roll_size_from }}</p>
        <br />
      </div>

      <div class="col-md-3">
        <p class="listing-label">Roll Size To</p>
        <p>{{ customer.roll_size_to }}</p>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Listing preferences</p>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <div class="col-md-2" style="display:inline-block">
          <label
            ><input
              class="form-check-input"
              id="listing_preference_agency"
              name="listing_preferences_agency"
              type="checkbox"
              value="agency"
              :checked="customer.agency === 1"
              disabled
            />&nbsp;&nbsp; Agency
          </label>
        </div>
        <div class="col-md-4" style="display:inline-block">
          <label
            ><input
              class="form-check-input"
              id="listing_preference_commercial"
              name="listing_preferences_commercial"
              type="checkbox"
              value="commercialIndustrial"
              disabled
              :checked="customer.commercial === 1"
            />&nbsp;&nbsp; Commercial/Industrial
          </label>
        </div>
        <div class="col-md-2" style="display:inline-block">
          <label
            ><input
              class="form-check-input"
              id="listing_preference_holiday"
              name="listing_preferences_holiday"
              type="checkbox"
              value="holiday"
              disabled
              :checked="customer.holiday === 1"
            />&nbsp;&nbsp; Holiday
          </label>
        </div>
        <div class="col-md-2" style="display:inline-block">
          <label
            ><input
              class="form-check-input"
              id="listing_preference_residential"
              name="listing_preferences_residential"
              type="checkbox"
              value="residential"
              disabled
              :checked="customer.residential === 1"
            />&nbsp;&nbsp; Residential
          </label>
        </div>
        <div class="col-md-2" style="display:inline-block">
          <label
            ><input
              class="form-check-input"
              id="listing_preference_strata"
              name="listing_preferences_strata"
              type="checkbox"
              value="strata"
              :checked="customer.strata === 1"
              disabled
            />&nbsp;&nbsp; Strata
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    customer: Object
  }
};
</script>
