export const columns = [
  {
    name: "full_name",
    text: "Name",
    render: item => `${item.first_name} ${item.last_name}`
  },
  {
    name: "business_id",
    text: "Company",
    render: item => item.business_name,
    link: item =>
      `${process.env.VUE_APP_ADMIN_URL}/businesses/${item.business_id}`,
    sort_disable: true
  },
  {
    name: "state_code",
    text: "State",
    render: item => item.state_code
  },
  {
    name: "status",
    text: "Status",
    render: item => item.status,
    className: item =>
      item.status === "Active" ? "font-green" : "font-orange",
    sort_disable: true
  },
  {
    name: "last_login",
    text: "Last Login",
    timestamp: item => (item.last_login ? item.last_login : ""),
    sort_disable: true
  }
];

export const business_columns = [
  {
    name: "name",
    text: "Company",
    render: item => item.name
  },
  {
    name: "state",
    text: "State",
    render: item => item.state_code
  },
  {
    name: "status",
    text: "Status",
    render: item => item.status,
    className: item => (item.status === "Active" ? "font-green" : "font-orange")
  },
  {
    name: "number_of_users",
    text: "Number of Users",
    render: item => (item.number_of_users ? item.number_of_users : 0),
    sort_disable: true
  }
];
