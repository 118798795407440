<template>
  <div class="form-group">
    <label :for="name">{{ label }}</label>
    <select
      class="form-control"
      :name="name"
      @change="e => handleChange(e.target.value)"
    >
      <option :value="value.value" v-for="(value, index) in values" :key="index"
        >{{ value.name }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    name: String,
    values: Array,
    key: String
  },
  data() {
    return {
      formData: {
        selected: ""
      }
    };
  },
  methods: {
    handleChange(value) {
      this.formData.selected = value;
      this.$emit("change", {
        value,
        key: this.name
      });
    },
    reset() {
      $('select').prop('selectedIndex', 0);
    }
  }
};
</script>
