<template>
  <div class="col-md-12">
    <div class="row">
      <Permission
        @update="handleChangePermission"
        :default-permissions="defaultPermissions"
        header="Permissions"
        :values="values"
        :create="!update"
        @save="handleUpdatePermission"
      />
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Article Details</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="active_date">Article Title *</label>
          <input
            class="form-control"
            id="title"
            v-model="values.title"
            name="title"
            :class="
              `form-control ${
                errors.hasOwnProperty('title') ? 'has-error' : ''
              }`
            "
            placeholder="The article title"
            type="text"
            required
          />
          <span
            v-if="errors.hasOwnProperty('title')"
            class="help-block text-danger"
            >{{ errors.title[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="active_date">Active Date *</label>
          <DatePicker
            name="active_date"
            id="active_date"
            :model-value="values.active_date"
            @update="
              e => {
                values.active_date = e.target.value;
              }
            "
            :input-class="
              `form-control ${
                errors.hasOwnProperty('active_date') ? 'has-error' : ''
              }`
            "
            placeholder="The date the listing will be active from"
          />
          <span
            v-if="errors.hasOwnProperty('active_date')"
            class="help-block text-danger"
            >{{ errors.active_date[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Home Page Image</strong></p>
      </div>
      <div class="col-md-6">
        <p class="font-admin-help-text">Dimensions: 750px x 450px</p>
        <p class="font-admin-help-text">Image type: .jpg, .png</p>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="upload_file_1">
            {{
              isUploading.home_page_image === true
                ? "Uploading..."
                : `Select image to upload *`
            }}
          </label>
          <input
            class=""
            @change="e => handleFileUpload(e, 'home_page_image')"
            name="upload_file_1"
            id="upload_file_1"
            placeholder="Browse image (.jpg, .png)"
            type="file"
            accept="image/*"
            required
          />
          <span
              v-if="errors.hasOwnProperty('home_page_image')"
              class="help-block text-danger"
          >{{ errors.home_page_image[0] }}</span
          >
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p>
          <strong class="font-120P">Industry News & Advice Page Image</strong>
        </p>
      </div>
      <div class="col-md-6">
        <p class="font-admin-help-text">Dimensions: 1200px x 400px</p>
        <p class="font-admin-help-text">Image type: .jpg, .png</p>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="upload_file_2">{{
            isUploading.article_page_image === true
              ? "Uploading..."
              : `Select image to upload *`
          }}</label>
          <input
            class=""
            name="upload_file_2"
            @change="e => handleFileUpload(e, 'article_page_image')"
            id="upload_file_2"
            placeholder="Browse image (.jpg, .png)"
            type="file"
            accept="image/*"
            required
          />
          <span
              v-if="errors.hasOwnProperty('article_page_image')"
              class="help-block text-danger"
          >{{ errors.article_page_image[0] }}</span
          >
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Full Article Page</strong></p>
      </div>
      <div class="gap-20"></div>
      <div class="col-md-12">
        <p>
          <strong>Page Image</strong> -
          <span class="font-admin-help-text"
            >Add an image <strong>OR</strong> a YouTube video</span
          >
        </p>
      </div>
      <div class="col-md-6">
        <p class="font-admin-help-text">Dimensions: 1200px x up to 800px</p>
        <p class="font-admin-help-text">Image type: .jpg, .png</p>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="upload_file_3">{{
            isUploading.full_article_page_image === true
              ? "Uploading..."
              : `Select image to upload *`
          }}</label>
          <input
            class=""
            name="upload_file_3"
            id="upload_file_3"
            @change="e => handleFileUpload(e, 'full_article_page_image')"
            placeholder="Browse image (.jpg, .png)"
            type="file"
            accept="image/*"
            required
          />
        </div>
      </div>

      <div class="gap-20"></div>
      <div class="col-md-12">
        <p>
          <strong>YouTube Video Link</strong> -
          <span class="font-admin-help-text"
            >Add a YouTube video <strong>OR</strong> an image</span
          >
        </p>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <input
            class="form-control"
            id="youtube_link"
            v-model="values.youtube_link"
            name="youtube_link"
            placeholder="YouTube video link URL"
            type="text"
            required
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="youtube_link_description"
            >Video title / description
          </label>
          <input
            class="form-control"
            id="youtube_link_description"
            v-model="values.youtube_link_description"
            name="youtube_link_description"
            placeholder="YouTube video title / descrition"
            type="text"
            required
          />
        </div>
      </div>

      <div class="gap-20"></div>
      <div class="col-md-12">
        <p><strong>Article Body</strong></p>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <Editor
            class="form-control form-control-message"
            name="article_body"
            placeholder="Article body text"
            rows="20"
            v-model="values.article_body"
            required
            :plugins="plugins"
            :toolbar="toolbar"
          ></Editor>
          <span
              v-if="errors.hasOwnProperty('article_body')"
              class="help-block text-danger"
          >{{ errors.article_body[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <SubmitButton v-if="isCreatable || updateAvailable"
            :isProgress="submitting || isDisabled"
            :caption="buttonName"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/Form/DatePicker";
import SubmitButton from "@/components/SubmitButton";
import { fileUpload } from "@/lib/axios";
import Editor from "@tinymce/tinymce-vue";
import Permission from "../../components/Permission";
import map from "lodash-es/map";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions"
    }),
    buttonName() {
      if (this.update) {
        if (this.submitting) {
          return "Updating";
        } else {
          return "Update News";
        }
      } else {
        if (this.submitting) {
          return "Creating";
        } else {
          return "Create News";
        }
      }
    },
    getGeoLocations() {
      return this.$http;
    },
    isDisabled() {
      return (
        this.isUploading.home_page_image === true ||
        this.isUploading.article_page_image === true ||
        this.isUploading.full_article_page_image === true
      );
    },
    defaultPermissions() {
      let permissions = [];
      map(this.permissionMeta.module_permission.article.permissions, group => {
        map(group.permissions, permission => {
          permissions.push({
            group: group.group,
            name: permission.caption,
            permission: permission.title,
            value: permission.name
          });
        });
      });
      return permissions;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Object,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: () => false
    },
    submitting: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    DatePicker,
    Editor,
    SubmitButton,
    Permission
  },
  data() {
    return {
      isUploading: {},
      values: {
        ...this.formData
      },
      countrySearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/countries/search`,
      stateSearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`,
      plugins: "lists advlist code link table",
      toolbar: "numlist bullist"
    };
  },
  methods: {
    handleSetPermission() {
      this.$emit("submit", this.values);
    },
    handleSubmit() {
      this.$emit("submit", {
        ...this.values,
        permissions: this.values.permissions
          ? this.values.permissions
          : {
              admin_access: {
                admin_approved: false,
                site_access: false
              }
            }
      });
    },
    handleDropdownChange(value, param) {
      this.values[param] = value;
    },
    async handleFileUpload(e, name) {
      const file = e.target.files[0];

      this.isUploading = {
        ...this.isUploading,
        [name]: true
      };
      try {
        const data = await this.$http.post(
          `${process.env.VUE_APP_NEWS_API_URL}/admin/signed-storage-url`,
          {
            path: this.handleGetPath(name),
            content_type: file.type,
            visibility: "private"
          }
        );
        if (data.url) {
          await fileUpload.put(data.url, file, {
            headers: {
              useAuth: false,
              "Content-Type": file.type,
              "x-amz-acl": "private"
            }
          });
        }
        this.isUploading = {
          ...this.isUploading,
          [name]: false,
        };
        this.values[name] = data.uuid;
      } catch (e) {
        console.log(e);
      }
    },
    handleGetPath(path) {
      switch (path) {
        case "home_page_image":
          return "articles/home-page-images/";
        case "article_page_image":
          return "articles/article-page-images/";
        case "full_article_page_image":
          return "articles/full-article-page-images/";
      }
      return "tmp/";
    },
    handleChangePermission(values) {
      this.values.permissions = values;
    },
    handleUpdatePermission(values) {
      this.$emit(
        "submit",
        {
          ...this.values,
          permissions: values
            ? values
            : {
                admin_access: {
                  admin_approved: false,
                  site_access: false
                }
              }
        },
        true
      );
    }
  }
};
</script>

<style>
.v3dp__divider {
  display: none !important;
}
</style>
