<template>
  <div class="col-md-12">
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin text-left pull-left">
        <div class="row">
          <p class="font-120P">Accounts</p>
        </div>
      </div>
    </div>
    <div class="entry-content">
      <div class="row">
        <Filter :filters="filters" @submit="handleSubmit" closed />
      </div>
      <div class="gap-20"></div>
    </div>
    <div class="entry-content">
      <div class="gap-20"></div>
      <ResourceTable
        ref="resourceTable"
        show-path="opportunities_show"
        :columns="columns"
        :update-available="false"
        :url="url"
      />
    </div>
    <div class="gap-20"></div>
    <div class="entry-content">
      <div class="col-md-12" v-for="chart in charts" :key="chart.key">
        <div class="row">
          <div class="gap-40"></div>
          <div class="col-md-12">
            <div class="row">
              <div class="row border-lt">
                <canvas :id="chart.key"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Filter from "../../../components/Filter";
import ResourceTable from "../../../components/ResourceTable";
import { insightsColumns } from "../../CustomerNew/columns";
import moment from "moment";
import map from "lodash-es/map";

export default {
  components: {
    Filter,
    ResourceTable
  },
  computed: {
    filters() {
      return [
        {
          key: "group_1",
          filters: [
            {
              key: "created_from",
              name: "created_from",
              component: "DateFilter",
              label: "Created Date From",
              grid: "col-md-3"
            },
            {
              key: "created_to",
              name: "created_to",
              component: "DateFilter",
              label: "Created Date To",
              grid: "col-md-3"
            },
            {
              key: "owner",
              name: "owner",
              component: "SearchDropdown",
              label: "Account Owner",
              grid: "col-md-3",
              url: `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/search`
            },
            {
              key: "stage",
              name: "stage",
              component: "SelectDropdown",
              label: "Account Stage",
              grid: "col-md-3",
              values: [
                { name: "All", value: "" },
                { name: "Lead", value: 1001 },
                { name: "Contact", value: 1002 },
                { name: "Opportunity", value: 1003 },
                { name: "Customer", value: 1004 },
                { name: "Archived", value: 1005 }
              ]
            }
          ]
        }
      ];
    },
    columns() {
      return insightsColumns;
    },
    url() {
      return `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers`;
    }
  },
  data() {
    return {
      total: 0,
      isTotalLoading: false,
      isChartLoading: false,
      values: {},
      charts: [
        {
          name: "Accounts by State",
          url: `admin/charts/by-state`,
          key: "accounts_by_state",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Accounts by State"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        },
        {
          name: "Accounts by Agent",
          url: `admin/charts/by-agent`,
          key: "accounts_by_agent",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Accounts by Agent"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        },
        {
          name: "Accounts by Stage by Agent",
          url: `admin/charts/by-stage-by-agent`,
          key: "accounts_by_stage_by_agent",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Accounts by Stage by Agent"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        },
        {
          name: "Accounts by Category",
          url: `admin/charts/by-category`,
          key: "accounts_by_category",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Accounts by Category"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        },
        {
          name: "Accounts by Stage",
          url: `admin/charts/by-stage`,
          key: "accounts_by_stage",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Accounts by Stage"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        },
        {
          name: "Accounts by Lead Source by Buyers & Sellers",
          url: `admin/charts/by-lead-source`,
          key: "accounts_by_lead_source",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Accounts by Lead Source by Buyers & Sellers"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        }
      ]
    };
  },
  methods: {
    handleSubmit(values) {
      this.$refs.resourceTable.customGetDataCall(values);
      this.getAccountChartsData(values);
    },
    async getAccountChartsData(values = {}) {
      this.isChartLoading = true;
      try {
        const data = await Promise.all(
          map(this.charts, chart => {
            return this.$http.get(
              `${process.env.VUE_APP_CUSTOMER_API_URL}/${chart.url}`,
              {
                params: values
              }
            );
          })
        );
        map(this.charts, (chart, index) => {
          chart.options.data = data[index].data;
          this.charts[index].options.data = data[index].data;
          this.initiateChart(chart);
        });
      } catch (e) {
        console.log(e, "error");
      }
      this.isChartLoading = false;
    },
    initiateChart(chart) {
      var ctx = document.getElementById(chart.key).getContext("2d");
      window.myBar = new Chart(ctx, chart.options);
    }
  },
  created() {
    this.getAccountChartsData();
  }
};
</script>
