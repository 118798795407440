<template>
  <div class="col-md-12">
    <form role="form">
      <div v-for="(data, index) in values" :key="index">
        <div class="row">
          <div class="col-md-12">
            <Accordion :header="data.name" icon="fa fa-database" closed>
              <div v-for="(item, key) in data.values" :key="key">
                <div class="row">
                  <div class="col-md-9">
                    <div class="form-group">
                      <label :for="`${data.key}_${key}_name`"
                        >{{ data.name }} description *</label
                      >
                      <input
                        class="form-control"
                        :id="`${data.key}_${key}_name`"
                        :name="`${data.key}_${key}_name`"
                        :placeholder="`${data.name} description`"
                        type="text"
                        :value="item.name"
                        v-on:input="
                          $event =>
                            handleChange(
                              $event.target.value,
                              index,
                              `${key}.name`,
                              `updateValues`
                            )
                        "
                        v-on:blur="
                          $event =>
                            handleChange(
                              $event.target.value,
                              index,
                              `${key}.name`,
                              `updateValues`
                            )
                        "
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label :for="`${data.key}_${key}_active`">Active</label
                    ><br />
                    <label class="switch">
                      <input
                        :id="`${data.key}_${key}_active`"
                        :name="`${data.key}_${key}_active`"
                        type="checkbox"
                        :checked="item.is_active"
                        @change="
                          $event =>
                            handleChange(
                              $event.target.checked,
                              index,
                              `${key}.is_active`,
                              `updateValues`
                            )
                        "
                      />
                      <span class="slider"></span> </label
                    >&nbsp;&nbsp;
                  </div>
                  <div class="col-md-1">
                    <div class="text-right">
                      <br />
                      <button
                        title="Save"
                        class="btn btn-xsmall"
                        type="button"
                        @click="handleUpdate(index, key)"
                      >
                        <span class="font-120P"
                          ><i class="fa fa-save"></i
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!data.values.length" class="text-center">
                No data found.
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="div-border-bottom"></div>
                </div>
              </div>
              <div class="gap-40"></div>
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group">
                    <label :for="`new_${data.key}_name`"
                      >New {{ data.name }} description *</label
                    >
                    <input
                      class="form-control"
                      :id="`new_${data.key}_name`"
                      :name="`new_${data.key}_name`"
                      :placeholder="`New ${data.name} description`"
                      type="text"
                      :value="newValues[index]?.name"
                      required
                      v-on:input="
                        $event =>
                          handleChange(
                            $event.target.value,
                            index,
                            'name',
                            'newValues'
                          )
                      "
                      v-on:blur="
                        $event =>
                          handleChange(
                            $event.target.value,
                            index,
                            'name',
                            'newValues'
                          )
                      "
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="text-right">
                    <br />
                    <button
                      title="Save"
                      class="btn btn-xsmall"
                      type="button"
                      @click="handleSave(index)"
                      :disabled="isSubmitting[index]"
                    >
                      <span class="font-120P"><i class="fa fa-save"></i></span>
                    </button>
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
        <div class="gap-40"></div>
      </div>
    </form>
  </div>
</template>

<script>
import Accordion from "../../../components/Accordion";
import { mapActions, mapGetters } from "vuex";
import set from "lodash-es/set";
import get from "lodash-es/get";
import cloneDeep from "lodash-es/cloneDeep";

export default {
  components: {
    Accordion
  },
  computed: {
    ...mapGetters({
      values: "masterData/dropdownValues"
    })
  },
  data() {
    return {
      isSubmitting: [],
      newValues: [],
      updateValues: []
    };
  },
  methods: {
    ...mapActions({
      loadDropDownListData: "masterData/loadDropDownListData"
    }),
    async handleSave(index) {
      const values = {
        ...this.values,
        [index]: {
          ...(this.values[index] ? this.values[index] : []),
          values: [
            ...(this.values[index].values ? this.values[index].values : []),
            {
              name: this.newValues[index].name,
              is_active: true
            }
          ]
        }
      };
      await this.handleRequest(values, index);
    },
    async handleUpdate(index, key) {
      const oldValues = cloneDeep(this.values[index].values);
      oldValues[key] = {
        ...oldValues[key],
        ...get(this.updateValues, `${index}.${key}`)
      };
      const values = {
        ...this.values,
        [index]: {
          ...(this.values[index] ? this.values[index] : []),
          values: oldValues
        }
      };
      await this.handleRequest(values, index, key);
    },
    async handleRequest(values, index, key = null) {
      this.isSubmitting[index] = true;
      try {
        this.$http.post(
          `${process.env.VUE_APP_CONFIG_API_URL}/admin/master-data`,
          {
            key: "DROPDOWN",
            values: values
          }
        );
        if (!key) {
          this.newValues[index] = {};
        }
        this.$store.commit("masterData/UPDATE_DROPDOWN_VALUES", values);
      } catch (error) {
        console.log(error, "error");
      }
      this.isSubmitting[index] = false;
    },
    handleChange(value, index, name, object) {
      let values = this[object];
      set(values, `${index}.${name}`, value);
      this[object] = values;
    }
  },
  mounted() {
    this.loadDropDownListData();
  }
};
</script>
