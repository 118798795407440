<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              :class="{ active: activeTap === 'account' }"
              @click="activeTap = 'account'"
            >
              <a id="account_details_tab_link" data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Add</h4></a
              >
            </li>
          </ul>
          <form id="form_1">
            <div class="error-container"></div>
            <div class="panel-body">
              <div class="tab-content">
                <div
                  id="tab-01"
                  class="tab-pane"
                  v-if="activeTap === 'account'"
                  :class="{ active: activeTap === 'account' }"
                >
                  <div class="col-md-12">
                    <Form
                      :form-data="formData"
                      @submit="handleSubmit"
                      :errors="errors"
                      :isLoading="isLoading"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./Form";
import get from "lodash-es/get";
import { mapActions } from "vuex";

export default {
  components: {
    Form
  },
  data() {
    return {
      formData: {
        permissions: {
          admin_access: {
            site_access: false,
            admin_approved: false
          },
          print_access: {
            im_access: false
          }
        },
        contact: {},
        business: {
          permissions: {
            admin_access: {
              site_access: true,
              admin_approved: true
            }
          },
          address: {
            state_id: "",
            country_id: 14
          }
        },
        stage_id: null,
        category_id: null,
        company_id: null,
        registered_seller: "",
        registered_buyer: "",
        price_range_from: "any",
        price_range_to: "any",
        roll_size_from: "any",
        roll_size_to: "any"
      },
      activeTap: "account",
      isLoading: false,
      errors: {}
    };
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async handleSubmit(values) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.post(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers`,
          values
        );
        await this.loadStats();
        await this.$router.push({
          name: "accounts_show",
          params: { id: data.id }
        });
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors", {});
        this.activeTap = "account";
        this.isLoading = false;
      }
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  },
  mounted() {
    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Account Administration",
        url: "accounts"
      },
      {
        name: "Account Details",
        url: "accounts_create",
        active: true
      }
    ]);
  }
};
</script>

<style></style>
