<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table">
        <table
          id=""
          class="table table-striped table-hover table-sm table-bordered"
          cellspacing="0"
          width="100%"
        >
          <thead class="thead-light">
            <tr>
              <th class="th-sm">Date Date</th>
              <th class="th-sm">Opportunity Stage</th>
              <th class="th-sm">Set By</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="stage in stages" :key="stage.id">
              <td width="20%">{{ stage.created_at }}</td>
              <td width="50%">{{ stage.stage }}</td>
              <td width="30%">
                {{
                  stage.user
                    ? `${stage.user.first_name} ${stage.user.last_name}`
                    : "System"
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    stages: Array
  }
};
</script>
