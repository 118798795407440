<template>
  <div class="row" id="about-us">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-9">
          <h2 class="section-title">{{ headingSmall }}</h2>
          <h3 class="section-sub-title">{{ headingLarge }}</h3>
          <!--/ Title row end -->
        </div>
        <div class="col-md-3 text-right" v-if="listing">
          <p class="listing-label">Reference</p>
          <p>
            <span class="listing-highlight-3">{{ listing.reference }}</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <FileViewer
            v-if="image"
            :image="image"
            alt="About The Rent Roll Broker"
          />
          <img
            v-else
            class="img-responsive"
            :src="imageData"
            alt="About The Rent Roll Broker"
          />
        </div>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <div class="col-md-12">
          <p v-html="body.text_1"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileViewer from "@/components/FileViewer/Index";
import { toDataURL } from "@/utils/image";

export default {
  data() {
    return {
      imageData: null
    };
  },
  components: {
    FileViewer
  },
  props: {
    headingSmall: String,
    headingLarge: String,
    image: String,
    image_url: String,
    body: Object,
    listing: Object
  },
  async mounted() {
    let _this = this;
    toDataURL(this.image_url, function(img) {
      _this.imageData = img;
    });
  }
};
</script>
