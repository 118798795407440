<template>
  <form id="form_1" class="p-15" action="" @submit="handleSubmit" method="post" ref="clear">
    <div class="error-container"></div>
    <input
        type="hidden"
        name="type_of_interest"
        :value="interest"
        id="type_of_interest"
    />
    <div class="row" v-if="enquiry">
      <p><strong class="font-120P">Enquiry Details</strong>&nbsp;
        <a v-if="helpLink" class="btn btn-small-orangetxt-whitebg popup" :href="helpLink">
          <span class="post-meta-date"><i class="fa fa-question-circle"></i> </span>
        </a></p>
      <div class="col-md-3">
        <div class="form-group">
          <Select
              name="company_id"
              label="Are you enquiring as an *"
              :class-name="
              `${errors.hasOwnProperty('company_id') ? 'has-error' : null}`
            "
              :error="errors.hasOwnProperty('company_id') ? 'The Business Type field is required' : ''"
              :value="values.company_id"
              placeholder="Please Select"
              url="/companies/search"
              :axios="getCompany"
              @selected="
              value =>
                handleDropdownChange(value ? value.id : null, 'company_id')
            "
          />
        </div>
      </div>
    </div>

    <div class="row">
      <h2 class="font-purple">{{ title }}</h2>
      <div
          id="alert-mail-success"
          class="alert alert-success fade in"
          v-if="success"
      >
        <a
            href="#"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            title="close"
        >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />Your registration has been received.
        Someone will be in touch within the next 48 business hours.
      </div>
      <!-- Alert success end -->

      <div
          id="alert-mail-error"
          class="alert alert-danger fade in"
          v-else-if="message"
      >

        <a
            href="#"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            title="close"
        >×</a
        >
        <span class="icon"><i class="fa fa-exclamation"></i></span
        >
        <strong>Error!</strong>
        <br />{{message}}
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <p><strong class="font-120P">Primary Contact</strong>&nbsp;
        <a v-if="helpLink" class="btn btn-small-orangetxt-whitebg popup" :href="helpLink">
          <span class="post-meta-date"><i class="fa fa-question-circle"></i> </span>
        </a>
      </p>
      <div class="col-md-3">
        <div class="form-group">
          <label for="first_name">First name *</label>
          <input
              class="form-control"
              id="first_name"
              v-model="formData.first_name"
              name="first_name"
              :class="{ 'has-error': errors.hasOwnProperty('first_name') }"
              placeholder="Your legal first name"
              type="text"
          />
          <span
              v-if="errors.hasOwnProperty('first_name')"
              class="help-block text-danger"
          >{{ errors.first_name[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="surname">Surname *</label>
          <input
              class="form-control"
              id="surname"
              v-model="formData.last_name"
              :class="{ 'has-error': errors.hasOwnProperty('last_name') }"
              name="surname"
              placeholder="Your legal last name / surname"
              type="text"
          />
          <span
              v-if="errors.hasOwnProperty('last_name')"
              class="help-block text-danger"
          >{{ errors.last_name[0] }}</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="role">Your role within the business </label>
          <input
              class="form-control"
              id="role"
              name="role"
              :class="{ 'has-error': errors.hasOwnProperty('business_role') }"
              v-model="formData.business_role"
              placeholder="Leave blank if individual"
              type="text"
          />
          <span
              v-if="errors.hasOwnProperty('business_role')"
              class="help-block text-danger"
          >{{ errors.business_role[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="email">Best contact number *</label>
          <input
              class="form-control"
              id="contact_phone"
              v-model="formData.phone_number"
              :class="{ 'has-error': errors.hasOwnProperty('phone_number') }"
              name="contact_phone"
              placeholder="Best correspondence phone"
              type="text"
          />
          <span
              v-if="errors.hasOwnProperty('phone_number')"
              class="help-block text-danger"
          >{{ errors.phone_number[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="email">Email address *</label>
          <input
              class="form-control"
              id="email"
              v-model="formData.email"
              :class="{ 'has-error': errors.hasOwnProperty('email') }"
              name="email"
              placeholder="Best correspondence email"
              type="email"
          />
          <span
              v-if="errors.hasOwnProperty('email')"
              class="help-block text-danger"
          >{{ errors.email[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-3" v-show="!loggedIn && email_verified_at">
        <div class="form-group">
          <label for="password">Password *</label>
          <input
              class="form-control"
              id="password"
              v-model="formData.password"
              :class="{ 'has-error': errors.hasOwnProperty('password') }"
              name="password"
              placeholder="password"
              type="password"
          />
          <span
              v-if="errors.hasOwnProperty('password')"
              class="help-block text-danger"
          >{{ errors.password[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-3" v-show="!loggedIn && email_verified_at">
        <div class="form-group">
          <label for="password_confirmation">Confirm Password *</label>
          <input
              class="form-control"
              id="password_confirmation"
              v-model="formData.password_confirmation"
              :class="{ 'has-error': errors.hasOwnProperty('password_confirmation') }"
              name="confirm_password"
              placeholder="confirm password"
              type="password"
          />
          <span
              v-if="errors.hasOwnProperty('confirm_password')"
              class="help-block text-danger"
          >{{ errors.confirm_password[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6" v-show="!loggedIn && email_verified_at">
        <label>
          <input
              class="form-check-input"
              id="newsletter_registration"
              name="newsletter_registration"
              type="checkbox"
              value="yes"
              checked="checked" />
          &nbsp;&nbsp; Register to our Newsletter
        </label>
      </div>
    </div>
    <div class="gap-40"></div>
    <!--    Business Start-->
    <div class="row">
      <p><strong class="font-120P">Business Details</strong>&nbsp;
        <a v-if="helpLink" class="btn btn-small-orangetxt-whitebg popup" :href="helpLink">
          <span class="post-meta-date"><i class="fa fa-question-circle"></i> </span>
        </a>
      </p>
    </div>
    <div class="row" v-if="!enquiry">
      <div class="col-md-3">
        <div class="form-group">
          <Select
              name="company_id"
              label="Business Type *"
              :class-name="
              `${errors.hasOwnProperty('company_id') ? 'has-error' : null}`
            "
              :error="errors.hasOwnProperty('company_id') ? 'The Business Type field is required' : ''"
              :value="values.company_id"
              placeholder="Please Select"
              url="/companies/search"
              :axios="getCompany"
              @selected="
              value =>
                handleDropdownChange(value ? value.id : null, 'company_id')
            "
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="trading_name">Trading name</label>
          <input
              class="form-control"
              id="trading_name"
              :class="{
              'has-error': errors.business.hasOwnProperty('trading_name')
            }"
              v-model="formData.business.trading_name"
              name="trading_name"
              placeholder="Leave blank if individual"
              type="text"
          />
          <span
              v-if="errors.hasOwnProperty('trading_name')"
              class="help-block text-danger"
          >{{ errors.business.trading_name[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="business_name">Legal business name</label>
          <input
              class="form-control"
              id="business_name"
              :class="{
              'has-error': errors.business.hasOwnProperty('name')
            }"
              v-model="formData.business.name"
              name="business_name"
              placeholder="Leave blank if registering as an individual"
              type="text"
          />
          <span
              v-if="errors.hasOwnProperty('name')"
              class="help-block text-danger"
          >{{ errors.business.name[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="business_abn">ABN </label>
          <input
              class="form-control"
              id="business_abn"
              :class="{
              'has-error': errors.business.hasOwnProperty('abn')
            }"
              v-model="formData.business.abn"
              name="business_abn"
              placeholder="Leave blank if individual"
              type="text"
          />
          <span
              v-if="errors.business.hasOwnProperty('abn')"
              class="help-block text-danger"
          >{{ errors.business.abn[0] }}</span
          >
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="street_address">Street address *</label>
          <input
              class="form-control"
              id="street_address"
              v-model="formData.business.street_address"
              :class="{ 'has-error': errors.business ? errors.business.hasOwnProperty('street_address') : null }"
              name="street_address"
              placeholder="Registered company / business / individual street address"
              type="text"
          />
          <span
              v-if="errors.business && errors.business.hasOwnProperty('street_address')"
              class="help-block text-danger"
          >{{ errors.business.street_address[0] }}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="suburb">Suburb *</label>
          <input
              class="form-control"
              id="suburb"
              v-model="formData.business.suburb"
              :class="{ 'has-error': errors.business ? errors.business.hasOwnProperty('suburb') : null }"
              name="suburb"
              placeholder="Registered suburb"
              type="text"
          />
          <span
              v-if="errors.business && errors.business.hasOwnProperty('suburb')"
              class="help-block text-danger"
          >{{ errors.business.suburb[0] }}</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <Select
              name="state"
              label="State *"
              :value="formData.business.state_id"
              :key="formData.business.state_id"
              placeholder="Select a state"
              selected="Australia"
              url="/states/search"
              :axios="getGeoLocations"
              @selected="
              value =>
                handleDropdownChange(
                  value ? value.id : null,
                  'business.state_id'
                )
            "
              :class="{
              'has-error': errors.business.hasOwnProperty('state_id')
            }"
              showother
          />
          <span
              v-if="errors.business.hasOwnProperty('state_id')"
              class="help-block text-danger"
          >The state field is required.</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="postcode">Postcode *</label>
          <input
              class="form-control"
              id="postcode"
              name="postcode"
              v-model="formData.business.postcode"
              :class="{ 'has-error': errors.business ? errors.business.hasOwnProperty('postcode') : null }"
              placeholder="Registered postcode"
              type="text"
          />
          <span
              v-if="errors.business && errors.business.hasOwnProperty('postcode')"
              class="help-block text-danger"
          >{{ errors.business.postcode[0] }}</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <Select
              name="country"
              label="Country *"
              :key="formData.business.country_id"
              placeholder="Select a Country"
              selected="Australia"
              :value="
              formData.business.country_id ? formData.business.country_id : 14
            "
              url="/countries/search"
              :axios="getGeoLocations"
              @selected="
              value =>
                handleDropdownChange(
                  value ? value.id : null,
                  'business.country_id'
                )
            "
          />
        </div>
      </div>
    </div>
    <!--    Business End -->
    <div class="row" v-if="!removeAgency && formData.registered_buyer === 'Yes'">
      <div class="gap-40"></div>
      <div class="row">
        <!--<div class="col-md-12">-->
        <p><strong class="font-120P">Rent Roll / Agency Details</strong>&nbsp;
          <a v-if="helpLink" class="btn btn-small-orangetxt-whitebg popup" :href="helpLink">
            <span class="post-meta-date"><i class="fa fa-question-circle"></i> </span>
          </a>
        </p>
        <!--</div>-->
      </div>
      <!--<div class="row">-->
      <div class="col-md-12">
        <div class="form-group">
          <Select
              name="state_interest"
              label="State *"
              :class="{ 'has-error': errors.hasOwnProperty('state_id') }"
              :key="formData.state_id"
              placeholder="Select a state"
              url="/states/search"
              :value="formData.state_id"
              :axios="getGeoLocations"
              @selected="
                value =>
                  handleDropdownChange(value ? value.id : null, 'state_id')
              "
              showother
          />
          <span
              v-if="errors.hasOwnProperty('state_id')"
              class="help-block text-danger"
          >The state is required.</span
          >
        </div>
      </div>
      <!--</div>-->
      <!--<div class="row">-->
      <div class="col-md-3">
        <div class="form-group">
          <label for="priceRangeFrom">Price Range From *</label>
          <select
              id="priceRangeFrom"
              name="priceRangeFrom"
              class="form-control"
              v-model="formData.price_range_from"
              :class="{ 'has-error': errors.hasOwnProperty('price_range_from') }"
          >
            <option
                :value="priceRange.value"
                v-for="priceRange in priceRanges"
                :key="priceRange.value"
            >{{ priceRange.name }}
            </option>
          </select>
          <span
              v-if="errors.hasOwnProperty('price_range_from')"
              class="help-block text-danger"
          >The price range from is required.</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="priceRangeTo">Price Range To *</label>
          <select
              id="priceRangeTo"
              name="priceRangeTo"
              v-model="formData.price_range_to"
              class="form-control"
              :class="{ 'has-error': errors.hasOwnProperty('price_range_to') }"
          >
            <option
                :value="priceRange.value"
                v-for="priceRange in priceRanges"
                :key="priceRange.value"
            >{{ priceRange.name }}
            </option>
          </select>
          <span
              v-if="errors.hasOwnProperty('price_range_to')"
              class="help-block text-danger"
          >The price range to is required.</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="rollSizeFrom">Roll Size From *</label>
          <select
              id="rollSizeFrom"
              name="rollSizeFrom"
              class="form-control"
              v-model="formData.roll_size_from"
              :class="{ 'has-error': errors.hasOwnProperty('roll_size_from') }"
          >
            <option
                :value="rollSize.value"
                v-for="rollSize in roll_sizes"
                :key="rollSize.value"
            >{{ rollSize.name }}
            </option>
          </select>
          <span
              v-if="errors.hasOwnProperty('roll_size_from')"
              class="help-block text-danger"
          >The roll size from is required.</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="rollSizeTo">Roll Size To *</label>
          <select
              id="rollSizeTo"
              name="rollSizeTo"
              :class="{ 'has-error': errors.hasOwnProperty('roll_size_to') }"
              class="form-control"
              v-model="formData.roll_size_to"
          >
            <option
                :value="rollSize.value"
                v-for="rollSize in roll_sizes"
                :key="rollSize.value"
            >{{ rollSize.name }}
            </option>
          </select>
          <span
              v-if="errors.hasOwnProperty('roll_size_to')"
              class="help-block text-danger"
          >The roll size to is required.</span
          >
        </div>
      </div>
      <!--</div>-->
      <!--<div class="row">-->
      <div class="col-md-12">
        <div class="form-group">
          <label>Listing preferences (select all that apply)</label>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <div class="col-md-2" style="display:inline-block">
            <label
            ><input
                class="form-check-input"
                id="listing_preference_agency"
                name="listing_preferences_agency"
                value="true"
                type="checkbox"
                v-model="formData.agency"
                :checked="values.agency"
            />&nbsp;&nbsp; Agency
            </label>
          </div>
          <div class="col-md-3" style="display:inline-block">
            <label
            ><input
                class="form-check-input"
                id="listing_preference_commercial"
                name="listing_preferences_commercial"
                type="checkbox"
                v-model="formData.commercial"
                :checked="formData.commercial"
                value="true"
            />&nbsp;&nbsp; Commercial/Industrial
            </label>
          </div>
          <div class="col-md-2" style="display:inline-block">
            <label
            ><input
                class="form-check-input"
                id="listing_preference_holiday"
                name="listing_preferences_holiday"
                type="checkbox"
                v-model="formData.holiday"
                :checked="formData.holiday"
                value="true"
            />&nbsp;&nbsp; Holiday
            </label>
          </div>
          <div class="col-md-2" style="display:inline-block">
            <label
            ><input
                class="form-check-input"
                id="listing_preference_residential"
                name="listing_preferences_residential"
                type="checkbox"
                v-model="formData.residential"
                :checked="formData.residential"
                value="true"
            />&nbsp;&nbsp; Residential
            </label>
          </div>
          <div class="col-md-3" style="display:inline-block">
            <label
            ><input
                class="form-check-input"
                id="listing_preference_strata"
                name="listing_preferences_strata"
                type="checkbox"
                v-model="formData.strata"
                :checked="formData.strata"
                value="true"
            />&nbsp;&nbsp; Strata
            </label>
          </div>
        </div>
      </div>
      <!--</div>-->
    </div>
    <div class="gap-40"></div>
    <Terms />
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="agreement"
          ><input
              type="checkbox"
              id="agreement"
              name="agreement"
              v-model="agreement"
          />&nbsp;&nbsp; I have read, understood, and accept the TERMS OF USE
            under which THE RENT ROLL BROKER provides this service *</label
          >
          <br /><br />
          <button
              class="btn btn-primary"
              type="submit"
              form="form_1"
              value="Submit"
              :disabled="isSubmitting || !valid">
            <div class="loading-btn" :class="{'loading-btn--loading': isSubmitting}">
              {{ isSubmitting ? "Submitting" : "Submit Form" }}
              <span v-if="isSubmitting" class="loading-btn--loading">
                    <b></b>
                    <b></b>
                    <b></b>
                </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Terms from "@/components/Terms";
import Select from "@/components/Form/Select/Select.vue";

import {
  geoLocations,
  customers,
  businesses,
  companies,
  interests
} from "@/lib/axios";
import set from "lodash-es/set";
import get from "lodash-es/get";
import { mapGetters } from 'vuex'

export default {
  props: {
    title: {
      type: String
    },
    interest: {
      type: String
    },
    listing: {
      type: String
    },
    values: {
      type: Object
    },
    enquiry: {
      type: Boolean
    },
    removeAgency: {
      type: Boolean
    },
    helpLink: {
      type: String
    },
    isAvailable: {
      type: Boolean
    },
    email_verified_at:{
      type: [Boolean, String]
    }
  },
  components: {
    Terms,
    Select
  },
  data() {
    return {
      errors: {
        business: {}
      },
      success: false,
      message: "",
      isSubmitting: false,
      agreement: false,
      formData: {
        ...this.values,
        business: {
          ...this.values.business
        }
      },
      roll_sizes: [
        { name: "Any (default)", value: "any" },
        { name: "50", value: "50" },
        { name: "100", value: "100" },
        { name: "200", value: "200" },
        { name: "400", value: "400" },
        { name: "600", value: "600" },
        { name: "800", value: "800" },
        { name: "1000", value: "1000" },
        { name: "2000", value: "2000" },
        { name: "3000", value: "3000" }
      ],
      priceRanges: [
        { name: "Any (default)", value: "any" },
        { name: "$200,000", value: "200000" },
        { name: "$400,000", value: "400000" },
        { name: "$600,000", value: "600000" },
        { name: "$800,000", value: "800000" },
        { name: "$1,000,000", value: "1000000" },
        { name: "$3,000,000", value: "3000000" },
        { name: "$4,000,000", value: "4000000" },
        { name: "$5,000,000", value: "5000000" },
        { name: "$10,000,000", value: "10000000" }
      ],
      isLoading: false
    };
  },
  computed: {
    getGeoLocations() {
      return geoLocations;
    },
    valid() {
      return this.agreement;
    },
    getCompany() {
      return companies;
    },
    ...mapGetters('auth', {
      loggedIn: 'loggedIn',
    }),
  },
  methods: {
    handleDropdownChange(value, param) {
      this.formData = set(this.formData, param, value);
    },
    async handleSubmit($event) {
      this.errors = {
        business: {}
      };
      $event.preventDefault();
      this.isSubmitting = true;
      try{
        const validationPassed = (await this.handleValidation(this.formData));
        if(!validationPassed){
          this.isSubmitting = false;
          return
        }
        const { business: businessData, ...formData } = this.formData;
        const business = await this.handleCreateBusiness(businessData);
        if (business && business.id) {
          let customer = await this.handleCreateCustomers({
            ...formData,
            business_id: business.id
          });

          if(!customer.success && customer.redirect){
            //Here customer means customer listing. api send customer listing when it has a opportunity and if the user in not in active (via permissions)
            const interest = customer.data
            await this.$router.push({
              name: "email-verification",
              params: {customerListingUUID: interest.uuid, userUUID: interest.user_uuid}
            });

            return;
          }

          customer = customer.data
          let interest ={};
          if (customer && customer.id && this.listing) {
            interest = await this.handleAttachListing({
              customer_id: customer.id,
              listing_id: this.listing
            });
          }
          this.formData = {
            ...customer,
            business: { ...business }

          }
          this.$refs.clear.reset();
          console.log(customer && customer.user_uuid && interest && interest.uuid,"010101010101");
          if (customer && customer.user_uuid && interest && interest.uuid) {
            console.log("success");
            await this.$router.push({
              name: "confidentiality-agreement-prepare",
              params: {customerListingUUID: interest.uuid}
            });
            // await this.$router.push({
            //   name: "email-verification",
            //   params: {customerListingUUID: interest.uuid, userUUID: customer.user_uuid}
            // });
          }
        }
      }catch (e) {
        // console.log(e)
      }

    },
    async handleCreateBusiness(businessData) {
      try {

        await this.$recaptchaLoaded();
        businessData.re_captcha_token = await this.$recaptcha('createBusiness');
        businessData.email = this.formData.email;

        const { data } = await businesses.post(`/web/businesses`, businessData);
        return data.data;
      } catch (e) {
        this.errors = {
          ...this.errors,
          business: get(e, "response.data.errors")
        };
        this.isSubmitting = false;
        return false;
      }
    },
    async handleCreateCustomers(formData) {
      this.isSubmitting = true;
      try {

        await this.$recaptchaLoaded();
        formData.re_captcha_token = await this.$recaptcha('createCustomer');

        const { data } = await customers.post(`/web/customers`, formData);
        this.success = data.success;
        this.message = data.message;
        if(data?.data?.user_auth_token) {
          this.$store.commit("auth/SET_TOKEN", data?.data?.user_auth_token);
        }
        this.isSubmitting = false;
        this.agreement = false;
        const myElement = document.getElementById("form_1");
        document.body.scrollTop = myElement.offsetTop;
        document.documentElement.scrollTop = myElement.offsetTop;
        return data;
      } catch (e) {
        this.errors = {
          ...this.errors,
          ...get(e, "response.data.errors")
        };
        this.isSubmitting = false;
        return false;
      }
    },
    async handleAttachListing(values) {
      try {
        const { data } = await interests.post(`/web/interests`, values);
        return data.success;
      } catch (e) {
        return false;
      }
    },
    //show validation error from api
    async handleValidation(value){
      try {
        const {data} = await customers.post(`/web/customers/validation`, value);
        return data;
      }catch (e){
        this.errors = {
          ...this.errors,
          business: get(e, "response.data.errors"),
          ...get(e, "response.data.errors")

        };
      }
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      handler(current) {
        this.formData = {
          ...current,
          business: {
            ...current.business
          }
        };
      }
    },
    success: {
      handler(value) {
        if (value === true){
          console.log(value)
        }
      },
      immediate: true,
    },
    formData: {
      handler(newData, oldData) {
        if(Object.keys(this.errors).length > 0){
          // this.formData = {
          //   ...oldData,
          //   business: {
          //     ...oldData.business
          //   }
          // }
        }
      },
      deep: true
    }
  }
};
</script>

<style></style>
