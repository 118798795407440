<template>
  <div class="row" id="proposed-sale">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-9">
          <h2 class="section-title">{{ headingSmall }}</h2>
          <h3 class="section-sub-title">{{ headingLarge }}</h3>
        </div>
        <div class="col-md-3 text-right" v-if="listing">
          <p class="listing-label">Reference</p>
          <p>
            <span class="listing-highlight-3">{{ listing.reference }}</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4 class="font-purple font-160P">Total Price</h4>
          <p class="font-160P" v-if="askingPrice != 0 && askingPrice">
            <strong>{{ getCurrency(askingPrice) }} {{ currency }} </strong>
            {{includeGST ? 'Includes GST' : ''}}
          </p>
          <p class="font-160P" v-else>
            <strong>Expression of Interest</strong>
          </p>
        </div>
      </div>
      <div v-if="!(askingPrice != 0 && askingPrice)">
      <div class="gap-20"></div>
      <div class="row" v-if="consideration">
        <div class="col-md-12">
          <h4 class="font-purple font-160P">CONSIDERATIONS</h4>
          <p class="font-160P"><strong>{{consideration}}</strong></p>
        </div>
      </div>
    </div>
      <div class="gap-20"></div>
      <div class="row">
        <div class="col-md-12">
          <h4 class="font-purple">DUE DILIGENCE</h4>
          <p v-html="body.text_1"></p>
        </div>
      </div>
      <div class="gap-20"></div>
      <div class="row">
        <div class="col-md-12">
          <h4 class="font-purple">RETENTION PERIOD</h4>
          <p v-html="body.text_line_2"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headingSmall: String,
    headingLarge: String,
    askingPrice: String,
    currency: String,
    body: Object,
    listing: Object,
    includeGST: String,
    consideration: String
  },
  methods:{
    getCurrency(amount){
      if(!amount){
        amount = 0;
      }
      return amount.toLocaleString("en-AU", {
        style: "currency",
        currency: "AUD"
      })
    }
  }
};
</script>
