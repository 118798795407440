import {createRouter, createWebHistory} from "vue-router";
import { defineAsyncComponent } from 'vue'
import RentRollListings from "../views/RentRollListings.vue";
import EmailVerificationOtp from "../components/EmailVerification";
import CAPrepare from "../components/Templates/CA/CAPrepare";
import WitnessSign from "../components/Templates/WitnessSign";
import EOIPrepare from "../components/Templates/EOI/EOIPrepare";
import RentRollListingsShow from "../views/RentRollListingsShow.vue";
import Terms from "../views/Terms.vue";
import Privacy from "../views/Privacy.vue";


import Wrong from "../views/Wrong.vue";
import Unauthorized from "../views/Unauthorized.vue";
import GSAuthorize from "../views/GSAuthorize.vue";
import GSLogin from "../views/GSLogin.vue";

import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import PasswordReset from "../views/PasswordReset.vue";
import ForgotPassword from "../views/ForgotPassword.vue";

import Home from "../views/Home.vue";
const CustomLoader = defineAsyncComponent(() => import("@/components/CustomLoader.vue"))

// Project Pages
import ProjectIndex from "../views/Projects/Index.vue";
import ProjectCreate from "../views/Projects/Create.vue";
import ProjectShow from "../views/Projects/Show.vue";

// CRM Pages
import AccountHome from "../views/Customer/Home.vue";
import InsightsIndex from "../views/Insights/Index.vue";
import CustomerIndex from "../views/CustomerNew";
import CustomerCreate from "../views/CustomerNew/Create";
import CustomerShow from "../views/CustomerNew/Show.vue";
import OpportunitiesIndex from "../views/Opportunities/Index.vue";
import OpportunitiesCreate from "../views/Opportunities/Create.vue";
import OpportunitiesShow from "../views/Opportunities/Show.vue";

// Rent Rolls (Listings) Pages
import ListingIndex from "../views/Listing/Index";
import ListingCreate from "../views/Listing/Create.vue";
import ListingShow from "../views/Listing/Show.vue";

import InformationMemorandumIndex from "../views/InformationMemorandum/Index";
import InformationMemorandumCreate from "../views/InformationMemorandum/Create.vue";
import InformationMemorandumShow from "../views/InformationMemorandum/Show.vue";
import InformationMemorandumCustomerShow from "../views/InformationMemorandum/CustomerShow.vue";

//Website CMS Pages
import NewsIndex from "../views/News/Index";
import NewsCreate from "../views/News/Create.vue";
import NewsShow from "../views/News/Show.vue";

import DocumentIndex from "../views/Document/Index";
import DocumentCreate from "../views/Document/Create.vue";
import DocumentShow from "../views/Document/Show.vue";

import AffiliateIndex from "../views/Affiliate/Index";
import AffiliateCreate from "../views/Affiliate/Create.vue";
import AffiliateShow from "../views/Affiliate/Show.vue";

// Admin Pages
import UserHome from "../views/Users/Home.vue";
import UserIndex from "../views/Users/Index";
import UserShow from "../views/Users/Show.vue";
import UserCreate from "../views/Users/Create.vue";

import BusinessHome from "../views/Business/Home.vue";
import BusinessShow from "../views/Business/Show.vue";
import BusinessCreate from "../views/Business/Create.vue";

import MasterDataIndex from "../views/MasterData/Index.vue";
import InformationMemorandumSellerCustomerShow from "../views/InformationMemorandum/SellerCustomerShow.vue";

import get from "lodash-es/get";
import store from "@/store";

// Email Verification
import EmailVerification from "../views/InformationMemorandum/EmailVerify/EmailVerification";
import EOISubmittion from "../views/InformationMemorandum/EOISubmit/EOISubmittion";
import OTPVerification from "@/views/OTPVerification.vue";
import EmailOTPVerification from '@/views/EmailOTPVerification.vue'


const routes = [
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            title: "Login",
            requiresAuth: false,
            disablePermission: true,
            parentRoute: null
        },
        props: (route) => ({ available_email: route.query.available_email })
    },
    {
        path: "/otp-verification",
        name: "otp_verification",
        component: OTPVerification,
        meta: {
            title: "OTP Verification",
            requiresAuth: false,
            disablePermission: true,
            parentRoute: null
        }
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout,
        meta: {
            title: "Logout",
            parentRoute: null
        }
    },
    {
        path: "/forgot-password",
        name: "forgot_password",
        component: ForgotPassword,
        meta: {
            title: "Password Reset: The Rent Roll Broker",
            requiresAuth: false,
            disablePermission: true,
            parentRoute: null
        }
    },
    {
        path: "/password-reset",
        name: "password_reset",
        component: PasswordReset,
        meta: {
            title: "Password Reset",
            requiresAuth: false,
            disablePermission: true,
            parentRoute: null
        }
    },
    {
        path: "/email-otp-verification",
        name: "email_otp_verification",
        component: EmailOTPVerification,
        meta: {
            title: "Email OTP Verification",
            requiresAuth: false,
            disablePermission: true,
            parentRoute: null
        },
        props: (route) => ({non_user: route.query.non_user, listing_ulid: route.query.listing_ulid })
    },
    {
        path: "/wrong",
        name: "wrong",
        component: Wrong,
        meta: {
            title: "404 - Not Found",
            disablePermission: true,
            parentRoute: null
        }
    },
    {
        path: "/unauthorized",
        name: "unauthorized",
        component: Unauthorized,
        meta: {
            title: "403 - Unauthorized",
            disablePermission: true,
            parentRoute: null
        }
    },
    {
        path: "/gs-authorize",
        name: "gs_authorize",
        component: GSAuthorize,
        meta: {
            title: "GSAuthorize",
            disablePermission: true,
            parentRoute: null
        }
    },
    {
        path: "/authorize",
        name: "authorize",
        component: GSLogin,
        meta: {
            title: "GSLogin",
            disablePermission: true,
            parentRoute: null
        }
    },
    // Customer login
    {
        path: "/customer-login",
        name: "customer-login",
        component: CustomLoader,
        meta: {
            title: "Customer Login",
            requiresAuth: false,
            disablePermission: true,
            parentRoute: null
        },
        props: (route) => ({login_token: route.query.login_token, im: route.query.im })
    },
    // Home
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            title: "Home",
            active: "home",
            layout: "AppPortalLayout",
            parentRoute: null,
            model: null,
            newTaskAvailable: true
        }
    },

    //Project
    {
        path: "/projects",
        name: "projects",
        component: ProjectIndex,
        meta: {
            title: "Project",
            active: "projects",
            permission: "project_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        }
    },
    {
        path: "/projects/create",
        name: "projects_create",
        component: ProjectCreate,
        meta: {
            title: "Project",
            active: "projects",
            permission: "project_admin",
            layout: "AppPortalLayout",
            parentRoute: "projects"
        }
    },
    {
        path: "/projects/:id",
        name: "projects_show",
        component: ProjectShow,
        meta: {
            title: "Project",
            active: "projects",
            permission: "project_admin",
            layout: "AppPortalLayout",
            parentRoute: "projects",
            model: "Project",
            newTaskAvailable: true
        }
    },

    {
        path: "/accounts",
        component: AccountHome,
        meta: {
            title: "Accounts",
            active: "accounts",
            permission: "account_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        },
        children: [
            // CRM - Insights
            {
                path: "/insights",
                name: "insights",
                component: InsightsIndex,
                meta: {
                    title: "Insights",
                    active: "insights",
                    permission: "insights",
                    parentRoute: null
                }
            },
            // CRM - Accounts
            {
                path: "",
                name: "accounts",
                component: CustomerIndex,
                meta: {
                    title: "Accounts",
                    active: "accounts",
                    permission: "account_admin",
                    parentRoute: null
                }
            },
            {
                path: "create",
                name: "accounts_create",
                component: CustomerCreate,
                meta: {
                    title: "Accounts",
                    active: "accounts",
                    permission: "account_admin",
                    check: 0,
                    parentRoute: "accounts"
                }
            },
            {
                path: ":id",
                name: "accounts_show",
                component: CustomerShow,
                meta: {
                    title: "Accounts",
                    active: "accounts",
                    permission: "account_admin",
                    parentRoute: "accounts",
                    model: "Customer",
                    newTaskAvailable: true
                }
            },
            // CRM - Opportunities
            {
                path: "/opportunities",
                name: "opportunities",
                component: OpportunitiesIndex,
                meta: {
                    title: "Opportunities",
                    active: "opportunities",
                    permission: "opportunity_admin",
                    layout: "AppPortalLayout",
                    parentRoute: null
                }
            },
            {
                path: "/opportunities/create",
                name: "opportunities_create",
                component: OpportunitiesCreate,
                meta: {
                    title: "Opportunities",
                    active: "opportunities",
                    permission: "opportunity_admin",
                    layout: "AppPortalLayout",
                    parentRoute: "opportunities"
                }
            },
            {
                path: "/opportunities/:id",
                name: "opportunities_show",
                component: OpportunitiesShow,
                meta: {
                    title: "Opportunities",
                    active: "opportunities",
                   // check: 0,
                    permission: "opportunity_admin",
                    layout: "AppPortalLayout",
                    parentRoute: "opportunities"
                }
            }
        ]
    },

    // Rent Rolls - Listings
    {
        path: "/listings",
        name: "listings",
        component: ListingIndex,
        meta: {
            title: "Listings",
            active: "listings",
            permission: "listing_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        },
        beforeEnter(to, from, next) {
            if (store.getters["auth/customer"] !== null) {
                window.location.href = `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings`;
                return;
            }
            next();
        }
    },
    {
        path: "/listings/create",
        name: "listings_create",
        component: ListingCreate,
        meta: {
            title: "Listings",
            active: "listings",
            permission: "listing_admin",
            check: 0,
            layout: "AppPortalLayout",
            parentRoute: "listings"
        }
    },
    {
        path: "/listings/:id",
        name: "listings_show",
        component: ListingShow,
        meta: {
            title: "Listings",
            active: "listings",
            permission: "listing_admin",
            layout: "AppPortalLayout",
            parentRoute: "listings",
            model: "List",
            newTaskAvailable: true
        }
    },
    // Rent Rolls - Information Memorandums
    {
        path: "/information-memorandums",
        name: "im",
        component: InformationMemorandumIndex,
        meta: {
            title: "Information Memorandums",
            active: "im",
            permission: "im_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        }
    },
    {
        path: "/information-memorandum-template",
        name: "im-template",
        component: MasterDataIndex,
        meta: {
            title: "Information Memorandum template",
            active: "im",
            permission: "im_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        }
    },
    {
        path: "/information-memorandums/create",
        name: "im_create",
        component: InformationMemorandumCreate,
        meta: {
            title: "Information Memorandums",
            active: "im",
            permission: "im_admin",
            check: 0,
            layout: "AppPortalLayout",
            parentRoute: "im"
        }
    },
    {
        path: "/information-memorandums/create/:id",
        name: "im_create_listing",
        component: InformationMemorandumCreate,
        meta: {
            title: "Information Memorandums",
            active: "im",
            permission: "im_admin",
            check: 0,
            layout: "AppPortalLayout",
            parentRoute: "im"
        }
    },
    {
        path: "/information-memorandums/:id",
        name: "im_show",
        component: InformationMemorandumShow,
        meta: {
            title: "Information Memorandums",
            active: "im",
            permission: "im_admin",
            layout: "AppPortalLayout",
            parentRoute: "im",
            model: "InformationMemorandum",
            newTaskAvailable: true
        }
    },
    {
        path: "/customer/:uuid/information-memorandums",
        name: "im_customer_show",
        component: InformationMemorandumCustomerShow,
        meta: {
            title: "Information Memorandums",
            active: "im",
            permission: "im_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        }
    },
    // Email Verification
    {
        path: "/customer/:interestID/email-verification/:userUUID",
        name: "email_verification",
        component: EmailVerification,
        meta: {
            title: "Email Verification",
            permission: "im_admin",
            layout: "AppPortalLayout",
        }
    },
    // EOI Submit
    {
        path: "/customer/:interestID/information-memorandums/eoi",
        name: "eoi_submit",
        component: EOISubmittion,
        meta: {
            title: "Email Verification",
            permission: "im_admin",
            layout: "AppPortalLayout",
        }
    },
    {
        path: "/customer/:uuid/seller-information-memorandums",
        name: "im_seller_show",
        component: InformationMemorandumCustomerShow,
        meta: {
            title: "Information Memorandums",
            active: "im",
            permission: "im_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        }
    },
    // Website CMS - News/Articles
    {
        path: "/articles",
        name: "news",
        component: NewsIndex,
        meta: {
            title: "News & Advice",
            active: "news",
            permission: "news_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        }
    },
    {
        path: "/articles/create",
        name: "news_create",
        component: NewsCreate,
        meta: {
            title: "News & Advice",
            active: "news",
            permission: "news_admin",
            check: 0,
            layout: "AppPortalLayout",
            parentRoute: "news"
        }
    },
    {
        path: "/articles/:id",
        name: "news_show",
        component: NewsShow,
        meta: {
            title: "News & Advice",
            active: "news",
            permission: "news_admin",
            layout: "AppPortalLayout",
            parentRoute: "news",
            model: "Article",
            newTaskAvailable: true
        }
    },
    // Website CMS - Documents
    {
        path: "/documents",
        name: "documents",
        component: DocumentIndex,
        meta: {
            title: "Documents",
            active: "documents",
            permission: "document_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        }
    },
    {
        path: "/documents/create",
        name: "documents_create",
        component: DocumentCreate,
        meta: {
            title: "Documents",
            active: "documents",
            permission: "document_admin",
            check: 0,
            layout: "AppPortalLayout",
            parentRoute: "documents"
        }
    },
    {
        path: "/documents/:id",
        name: "documents_show",
        component: DocumentShow,
        meta: {
            title: "Documents",
            active: "documents",
            permission: "document_admin",
            layout: "AppPortalLayout",
            parentRoute: "documents",
            model: "Document",
            newTaskAvailable: true
        }
    },
    // Website CMS - Affiliates
    {
        path: "/affiliates",
        name: "affiliates",
        component: AffiliateIndex,
        meta: {
            title: "Affiliates",
            active: "affiliates",
            permission: "affiliate_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        }
    },
    {
        path: "/affiliates/create",
        name: "affiliates_create",
        component: AffiliateCreate,
        meta: {
            title: "Affiliates",
            active: "affiliates",
            permission: "affiliate_admin",
            check: 0,
            layout: "AppPortalLayout",
            parentRoute: "affiliates"
        }
    },
    {
        path: "/affiliates/:id",
        name: "affiliates_show",
        component: AffiliateShow,
        meta: {
            title: "Affiliates",
            active: "affiliates",
            permission: "affiliate_admin",
            layout: "AppPortalLayout",
            parentRoute: "affiliates",
            model: "Affiliate",
            newTaskAvailable: true
        }
    },
    {
        path: "/rent-roll-listings",
        name: "rent_roll_listings",
        component: RentRollListings,
        meta: {
            requiresAuth: false,
            title: "Rent Roll Listings: The Rent Roll Broker",
            active: "/rent-roll-listings"
        }
    },
    // Email confirmation
    {
        path: "/rent-roll-listings/:customerListingUUID/email-verification/:userUUID",
        name: "email-verification",
        component: EmailVerificationOtp,
        meta: {
            requiresAuth: false,
            title: "Email Verification The Rent Roll Broker",
            active: "/email-verification/email-verification"
        }
    },
    // Confidentiality Agreement
    {
        path: "/rent-roll-listings/confidentiality-agreement/:customerListingUUID",
        name: "confidentiality-agreement-prepare",
        component: CAPrepare,
        meta: {
            requiresAuth: true,
            title: "Confidentiality Agreement The Rent Roll Broker",
            active: "/rent-roll-listings/confidentiality-agreement"
        }
    },
    // {
    //     path: "/rent-roll-listings/confidentiality-agreement/:customerListingUUID/:templateUUID/sign",
    //     name: "confidentiality-agreement-sign",
    //     component: WitnessSign,
    //     meta: {
    //         requiresAuth: false,
    //         title: "Confidentiality Agreement The Rent Roll Broker Sign",
    //         active: "/rent-roll-listings/confidentiality-agreement"
    //     }
    // },
    // {
    //     path: "/expression/eoi-sign/:customerListingUUID/:templateUUID/sign",
    //     name: "eoi-sign",
    //     component: EOIPrepare,
    //     meta: {
    //         requiresAuth: false,
    //         title: "Expression of interest The Rent Roll Broker Sign",
    //         active: "/eoi-sign"
    //     }
    // },
    // {
    //     path: "/rent-roll-listings/expression-of-interest/:customerListingUUID/:templateUUID/sign",
    //     name: "expression-of-interest.witness.sign",
    //     component: WitnessSign,
    //     meta: {
    //         requiresAuth: false,
    //         title: "Expression of interest The Rent Roll Broker Sign",
    //         active: "/eoi-sign"
    //     }
    // },
    {
        path: '/rent-roll-listings/:id',
        name: 'rent_roll_listings_show',
        component: RentRollListingsShow,
        meta: {
            requiresAuth: false,
            title: 'Rent Roll Listings: The Rent Roll Broker',
            active: '/rent-roll-listings',
        },
        props: (route) => ({
            verified_email: route.query.verified_email,
            email_verified_at: route.query.email_verified_at,
            listing_login: route.query.listing_login,
        }),
    },
    {
        path: "/terms",
        name: "terms",
        component: Terms,
        meta: {
            title: "The Rent Roll Broker - Terms of Use",
            active: "/terms"
        }
    },
    {
        path: "/privacy",
        name: "privacy",
        component: Privacy,
        meta: {
            title: "The Rent Roll Broker - Privacy Statement",
            active: "/privacy"
        }
    },




    // Admin - Users
    {
        path: "/users",
        component: UserHome,
        meta: {
            title: "Users",
            active: "admin_users",
            permission: "user_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        },
        children: [
            {
                path: "",
                name: "admin_users",
                component: UserIndex,
                meta: {
                    title: "Users",
                    active: "admin_users",
                    permission: "user_admin",
                    parentRoute: null
                }
            },
            {
                path: "create",
                name: "admin_users_create",
                component: UserCreate,
                meta: {
                    title: "Create - Users",
                    active: "admin_users",
                    permission: "user_admin",
                    check: 0,
                    parentRoute: "admin_users"
                }
            },
            {
                path: ":id",
                name: "admin_users_show",
                component: UserShow,
                meta: {
                    title: "Users",
                    active: "admin_users",
                    permission: "user_admin",
                    parentRoute: "admin_users",
                    model: "User  ",
                    newTaskAvailable: true
                }
            }
        ]
    },

    // Admin - Businesses
    {
        path: "/businesses",
        component: BusinessHome,
        meta: {
            title: "Businesses",
            active: "admin_users",
            permission: "user_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        },
        children: [
            {
                path: "create",
                name: "businesses_create",
                component: BusinessCreate,
                meta: {
                    title: "Create - Business",
                    active: "admin_users",
                    permission: "user_admin",
                    parentRoute: "admin_users"
                }
            },
            {
                path: ":id",
                name: "businesses_show",
                component: BusinessShow,
                meta: {
                    title: "Businesses",
                    active: "admin_users",
                    permission: "user_admin",
                    parentRoute: "admin_users",
                    model: "Business  ",
                    newTaskAvailable: true
                }
            }
        ]
    },

    // Admin - Master Data
    {
        path: "/masterdata",
        name: "master_data",
        component: MasterDataIndex,
        meta: {
            title: "Master Data",
            active: "master_data",
            permission: "user_admin",
            layout: "AppPortalLayout",
            parentRoute: null
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: () => {
        return {x: 0, y: 0};
    }
});

router.beforeEach((to, from, next) => {
    const loggedIn = store.getters["auth/loggedIn"];
    const otpVerified = store.getters["auth/otpVerified"];
    const permissions = store.getters["auth/permissions"];
    const user = store.getters["auth/user"];

    const isAdmin = user && !user.isCustomer;
    to.meta.newTaskAvailable = to.meta.newTaskAvailable && isAdmin;
    const admin = get(permissions, "admin.permissions", []);

    document.title = to.meta.title;
    if (to.name === 'terms' || to.name === 'privacy' ) {
        window.location.href = `${process.env.VUE_APP_WEB_URL}${to.fullPath}`;
        return;
    }
    if (store.getters["auth/customer"] !== null && to.name === 'listings') {
        window.location.href = `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings`;
        return;
    }

    if (to.name === 'im_customer_show') {
        if (user.customer.registered_buyer !== "Yes") {
            return next({name: "unauthorized"});
        }
        //ToDo: Check if user is signed the CA relevant IM
        return next();
    }

    if (to.name === 'im_seller_show') {
        if (user.customer.registered_seller !== "Yes") {
            return next({name: "unauthorized"});
        }
        //ToDo: Check if user is associated with the relevant IM
        return next();
    }

    if (store.getters["auth/customer"] !== null && to.name !== 'home' && !to.meta.hasOwnProperty("customer") && to.name !== "email_verification" && to.name !== "eoi_submit" && to.name !== "rent_roll_listings" && to.name !== "rent_roll_listings_show" && to.name !== "confidentiality-agreement-prepare" && to.name !== "confidentiality-agreement-sign") {
        return next({name: "home"});
    }

    if (
        loggedIn &&
        !admin.includes(1) &&
        !to.meta.hasOwnProperty("disablePermission") &&
        store.getters["auth/customer"] === null

    ) {
        return next({name: "unauthorized"});
    }
    if (
        loggedIn &&
        to.meta.permission &&
        permissions.hasOwnProperty(to.meta.permission)
    ) {
        if (to.meta.check >= 0 && permissions[to.meta.permission].permissions) {
            if (
                !permissions[to.meta.permission].permissions.includes(to.meta.check)
            ) {
                return next({name: "home"});
            }
        }
        to.permissions = permissions[to.meta.permission];
    }
    to.wholePermissions = permissions;
    if (to.matched.some(record => record.meta.requiresAuth === false) && to.name !== "rent_roll_listings" && to.name !== "rent_roll_listings_show" && to.name !== "confidentiality-agreement-prepare" && to.name !== "confidentiality-agreement-sign") {
        if (loggedIn) {
            return next({name: "home"});
        }
    } else if (!loggedIn && to.name !== "rent_roll_listings" && to.name !== "rent_roll_listings_show" && to.name !== "confidentiality-agreement-prepare" && to.name !== "confidentiality-agreement-sign") {
        return next({name: "login", query: {redirect: to.fullPath}});
    }

    if (to.name === 'otp_verification') {

        console.log(user);

        if (!user) {
            return next({name: "login"});
        }
        return next();
    }

    next();
});

export default router;
