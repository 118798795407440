<template>
  <div class="col-md-12">
    <div class="entry-content">
      <div class="gap-40"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="entry-header-admin">
              <div class="col-md-12 entry-title-admin">
                <p class="font-120P">Notes</p>
              </div>
            </div>
            <div class="gap-20"></div>
            <Note :model="relatedType" :model_id="relatedId" ref="note" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Note from "../Note";

export default {
  components: {
    Note
  },
  props: {
    relatedType: String,
    relatedId: String
  }
};
</script>
