<template>
  <div v-if="contact">
    <div class="row">
      <div class="col-md-12">
        <span
          class="badge"
          :class="{
            'badge-active': contact.status === 'Active',
            'badge-inactive': contact.status === 'Inactive'
          }"
        >
          {{ contact.status }}
        </span>
      </div>

      <div class="gap-20"></div>

      <div class="col-md-3">
        <p class="listing-label">Name</p>
        <p>{{ contact.first_name }} {{ contact.last_name }}</p>
        <br />
      </div>

      <div class="col-md-3">
        <p class="listing-label">Role Within Business</p>
        <p>{{ contact.business_role }}</p>
        <br />
      </div>

      <div class="col-md-6">
        <p class="listing-label">Business Email</p>
        <p>
          <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
        </p>
        <br />
      </div>

      <div class="col-md-6">
        <p class="listing-label">Linked Address</p>
        <p>
          {{ contact?.address?.street_address }} <br />{{
            contact?.address?.suburb
          }}, {{ contact?.address?.state?.code }},
          {{ contact?.address?.postcode }} <br />{{
            contact?.address?.country?.name
          }}
        </p>
        <br />
      </div>

      <div class="col-md-6">
        <p class="listing-label">Best Contact Number</p>
        <p>
          <a :href="`tel:${contact.phone_number}`">{{
            contact.phone_number
          }}</a>
        </p>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">LinkedIn Profile</p>
        <p>
          <a :href="contact.linkedin_profile" target="_blank">{{
            contact.linkedin_profile
          }}</a>
        </p>
        <br />
      </div>
      <div class="col-md-6">
        <p class="listing-label">Receive Newsletter</p>
        <p>{{ contact.newsletter ? "Yes" : "No" }}</p>
      </div>

      <div class="col-md-12">
        <p class="listing-label">Contact Note</p>
        <p>
          {{ contact.note }}
        </p>
      </div>

      <div class="col-md-12">
        <div class="div-border-bottom"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contact: Object
  }
};
</script>
