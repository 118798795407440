export default [
  {
    name: "Calendar",
    href: "collapse1",
    isDefault: true,
    subs: [
      {
        name: "Tasks",
        path: "home",
        count: true,
        icon: "fa-calendar-check-o",
        stats: "tasks",
        show: {
          active: {
            name: "Open",
            show: true
          },
          inactive: {
            name: "Overdue",
            show: true
          }
        }
      }
    ]
  },
  {
    name: "Projects",
    href: "collapse2",
    isDefault: false,
    subs: [
      {
        name: "Projects",
        path: "projects",
        count: true,
        icon: "fa-modx",
        stats: "projects",
        show: {
          active: {
            name: "Active",
            show: true
          },
          inactive: {
            name: "Inactive",
            show: true
          }
        }
      }
    ]
  },
  {
    name: "CRM",
    href: "collapse3",
    isDefault: false,
    subs: [
      {
        name: "Accounts",
        path: "accounts",
        count: true,
        icon: "fa-address-card",
        stats: "accounts",
        show: {
          active: {
            name: "Active",
            show: true
          },
          inactive: {
            name: "Inactive",
            show: true
          }
        }
      },
      {
        name: "Opportunities",
        path: "opportunities",
        count: true,
        icon: "fa-handshake-o",
        stats: "opportunities",
        show: {
          active: {
            name: "Active",
            show: true
          },
          inactive: {
            name: "Inactive",
            show: true
          }
        }
      }
    ]
  },
  {
    name: "Rent Rolls",
    href: "collapse4",
    isDefault: false,
    subs: [
      {
        name: "Listings",
        path: "listings",
        count: true,
        icon: "fa-home",
        stats: "listings",
        show: {
          active: {
            name: "Active",
            show: true
          },
          inactive: {
            name: "Inactive",
            show: true
          }
        }
      },
      {
        name: "Info Memorandums",
        path: "im",
        count: true,
        icon: "fa-industry",
        stats: "im",
        show: {
          active: {
            name: "Active",
            show: true
          },
          inactive: {
            name: "Inactive",
            show: true
          }
        }
      }
      ,
      {
        name: "IM Templates",
        path: "im-template",
        count: false,
        icon: "fa-industry",
        stats: "im",
        permissions : [{path :'listing_admin.permissions' ,values : 0}],
        show: {
          active: {
            name: "Active",
            show: true
          },
          inactive: {
            name: "Inactive",
            show: true
          }
        }
      }
    ]
  }
];
