<template>
  <div class="row">
    <div class="col-md-12" v-if="listing">
      <EmailListTable :queries="queries"/>
    </div>
  </div>
</template>
<script>
import {toRefs} from "vue";
import EmailListTable from "../../../components/O365/EmailListTable.vue";

export default {
  components: {
    EmailListTable,
  },
  props: {
    listing: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {listing} = toRefs(props);
    let queries = [];
    queries.push(listing.value?.seller?.email);
    queries.push(...listing.value?.seller?.additional_contacts.map(contact => contact.email));
    queries.push(listing.value?.reference);
    return {
      queries
    };
  }
}
</script>
