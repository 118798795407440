export default [
  {
    name: "active_date",
    text: "Active Date",
    render: item => item.active_date
  },
  {
    name: "reference",
    text: "Reference",
    render: item => item.reference,
    badge: item => item.interests
  },
  {
    name: "region",
    text: "Region",
    render: item => item.region
  },
  {
    name: "link",
    text: "Website",
    render: item =>
      `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings/${item.id}`,
    sort_disable: true
  },
  {
    name: "momerandum",
    text: "IM",
    render: item => (item.memorandum ? "Link" : "N/A"),
    link: item =>
      item.memorandum
        ? `${process.env.VUE_APP_ADMIN_URL}/information-memorandums/${item.memorandum?.id}`
        : "",
    sort_disable: true
  },
  {
    name: "status",
    text: "Status",
    render: item => item.status,
    className: item =>
      item.status === "For Sale" ? "font-green" : "font-orange",
    sort_disable: true
  }
];
