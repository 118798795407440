<template>
  <div class="v3dp__datepicker">
    <div class="input-group date">
      <input
        type="text"
        readonly
        class="bg-white"
        :class="inputClass"
        :disabled="disabled"
        v-model="input"
        :placeholder="placeholder"
        :tabindex="disabled ? -1 : 0"
        @blur="renderView()"
        @focus="renderView(startingView)"
        @click="renderView(startingView)"
      />
      <span class="input-group-addon">
        <span class="glyphicon glyphicon-calendar"></span>
      </span>
    </div>

    <year-picker
      v-show="viewShown === 'year'"
      v-model:pageDate="pageDate"
      :selected="modelValue"
      :lowerLimit="lowerLimit"
      :upperLimit="upperLimit"
      @select="selectYear"
    />
    <month-picker
      v-show="viewShown === 'month'"
      v-model:pageDate="pageDate"
      :selected="modelValue"
      @select="selectMonth"
      :lowerLimit="lowerLimit"
      :upperLimit="upperLimit"
      :format="monthListFormat"
      :headingFormat="monthHeadingFormat"
      :locale="locale"
      @back="viewShown = 'year'"
    />
    <day-picker
      v-show="viewShown === 'day'"
      v-model:pageDate="pageDate"
      :selected="modelValue"
      :weekStartsOn="weekStartsOn"
      :lowerLimit="lowerLimit"
      :upperLimit="upperLimit"
      :locale="locale"
      :weekdayFormat="weekdayFormat"
      @select="selectDay"
      @back="viewShown = 'month'"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watchEffect, PropType } from "vue";
import { parse, isValid, setYear, lightFormat } from "date-fns";
import YearPicker from "./YearPicker.vue";
import MonthPicker from "./MonthPicker.vue";
import DayPicker from "./DayPicker.vue";
import moment from "moment";

export default defineComponent({
  components: {
    YearPicker,
    MonthPicker,
    DayPicker
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    inputClass: {
      type: String,
      default: ""
    },
    /**
     * `v-model` for selected date
     */
    modelValue: {
      type: String,
      required: false
    },
    /**
     * Upper limit for available dates for picking
     */
    upperLimit: {
      type: Date,
      required: false
    },
    /**
     * Lower limit for available dates for picking
     */
    lowerLimit: {
      type: Date,
      required: false
    },
    /**
     * View on which the date picker should open. Can be either `year`, `month`, or `day`
     */
    startingView: {
      type: String,
      required: false,
      default: "day"
    },
    /**
     * `date-fns`-type formatting for a month view heading
     */
    monthHeadingFormat: {
      type: String,
      required: false,
      default: "LLLL yyyy"
    },
    /**
     * `date-fns`-type formatting for the month picker view
     */
    monthListFormat: {
      type: String,
      required: false,
      default: "LLL"
    },
    /**
     * `date-fns`-type formatting for a line of weekdays on day view
     */
    weekdayFormat: {
      type: String,
      required: false,
      default: "EE"
    },
    /**
     * `date-fns`-type format in which the string in the input should be both
     * parsed and displayed
     */
    inputFormat: {
      type: String,
      required: false,
      default: "YYYY-MM-DD"
    },
    /**
     * [`date-fns` locale object](https://date-fns.org/v2.16.1/docs/I18n#usage).
     * Used in string formatting (see default `monthHeadingFormat`)
     */
    locale: {
      type: Object,
      required: false
    },
    /**
     * Day on which the week should start.
     *
     * Number from 0 to 6, where 0 is Sunday and 6 is Saturday.
     * Week starts with a Monday (1) by default
     */
    weekStartsOn: {
      type: Number,
      required: false,
      default: 1
    },
    /**
     * Disables datepicker and prevents it's opening
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const viewShown = ref("none");
    const pageDate = ref(new Date());
    const input = ref(props.modelValue ? props.modelValue : "");
    watchEffect(() => {
      const parsed = new Date(moment(props.modelValue));
      pageDate.value = parsed;
    });

    watchEffect(
      () =>
        (input.value = props.modelValue
          ? moment(props.modelValue).format(props.inputFormat)
          : "")
    );
    const renderView = view => {
      if (!props.disabled) viewShown.value = view;
    };
    watchEffect(() => {
      if (props.disabled) viewShown.value = "none";
    });
    const selectYear = date => {
      pageDate.value = date;
      viewShown.value = "month";
    };
    const selectMonth = date => {
      pageDate.value = date;
      viewShown.value = "day";
    };
    const selectDay = date => {
      emit("update", {
        target: {
          value: date
        }
      });
      viewShown.value = "none";
    };
    return {
      input,
      pageDate,
      renderView,
      selectYear,
      selectMonth,
      selectDay,
      viewShown,
      log: e => console.log(e)
    };
  }
});
</script>

<style scoped>
.v3dp__datepicker {
  --popout-bg-color: var(--vdp-bg-color, #fff);
  --box-shadow: var(
    --vdp-box-shadow,
    0 4px 10px 0 rgba(128, 144, 160, 0.1),
    0 0 1px 0 rgba(128, 144, 160, 0.81)
  );
  --border-radius: var(--vdp-border-radius, 3px);
  --heading-size: var(--vdp-heading-size, 2.5em); /* 40px for 16px font */
  --heading-weight: var(--vdp-heading-weight, bold);
  --heading-hover-color: var(--vpd-heading-hover-color, #eeeeee);
  --arrow-color: var(--vdp-arrow-color, currentColor);
  --elem-color: var(--vpd-elem-color, currentColor);
  --elem-disabled-color: var(--vpd-disabled-color, #d5d9e0);
  --elem-hover-color: var(--vdp-hover-color, #fff);
  --elem-hover-bg-color: #70568b;
  --elem-selected-color: var(--vdp-selected-color, #fff);
  --elem-selected-bg-color: #6c508c;
  --elem-font-size: var(--vpd-elem-font-size, 0.8em);
  --elem-border-radius: var(--vdp-elem-border-radius, 3px);
  --divider-color: var(--vpd-divider-color, var(--elem-disabled-color));
  position: relative;
}
</style>
