<template>
  <div>
    <div
        id="banner-area"
        class="banner-area"
        :style="{
        backgroundImage:
          'url(' +
          require('@/assets/images/rent_roll_broker/rent_roll_listings.jpg') +
          ')'
      }"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="banner-heading">
                <h1 class="banner-title">RENT ROLL LISTINGS</h1>
                <ol class="breadcrumb">
                  <li class="font-white"><a href="/">Home</a></li>
                  <li class="font-white">
                    <router-link :to="{ name: 'rent_roll_listings' }"
                    >Rent Roll Listings</router-link
                    >
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <form
              id="filter-form"
              action=""
              role="form"
              @submit="handleSearchSubmit"
          >
            <div class="col-md-4">
              <p class="">
                <label for="sortby">&nbsp; </label>
                <input
                    class="form-control border-none bg-white"
                    id="business_name"
                    name="business_name"
                    disabled
                    :value="
                    `Showing ${meta.from ? meta.from : 0} to ${
                      meta.to ? meta.to : 0
                    } of ${meta.total ? meta.total : 0} total results`
                  "
                    type="text"
                />
              </p>
            </div>
            <!-- Col end -->
            <div class="col-md-3">
              <Select
                  name="state"
                  :value="parseFloat(params.state_id)"
                  label="Filter by: "
                  required
                  placeholder="Select a state"
                  url="/states/search"
                  :axios="getGeoLocations"
                  @selected="
                  value =>
                    handleDropdownChange(value ? value.id : null, 'state_id')
                "
              />
            </div>
            <!-- Col end -->
            <div class="col-md-3">
              <div class="form-group">
                <label for="sortby">Sort by: </label>
                <select
                    class="form-control"
                    name="sortby"
                    id="sortby"
                    @change="onChange"
                >
                  <option value="soldstatus" selected="selected"
                  >Listing Status</option
                  >
                  <option value="region_asc">Region (A-Z)</option>
                  <option value="region_desc">Region (Z-A)</option>
                  <option value="price_asc">Price (Lowest first)</option>
                  <option value="price_desc">Price (Highest first)</option>
                </select>
              </div>
            </div>
            <!-- Col end -->
            <div class="col-md-2">
              <div class="call-to-action-btn form-group">
                <label for="submit_button">&nbsp; </label>
                <button
                    class="btn btn-primary form-control"
                    name="submit_button"
                    id="submit_button"
                    type="submit"
                    form="filter-form"
                    value="Submit"
                >
                  Search
                </button>
              </div>
            </div>
            <!-- Col end -->
          </form>
        </div>
        <!-- Content row end -->

        <div class="gap-20"></div>

        <div class="gap-40"></div>
        <div v-show="!isLoading">
          <div v-if="listings.length">
            <div class="row" v-for="listing in listings" :key="listing.id">
              <div class="col-md-12 listing-container">
                <div class="row p-15">
                  <div class="col-md-4">
                    <p>
                      <router-link
                          :to="{
                          name: 'rent_roll_listings_show',
                          params: { id: listing.id }
                        }"
                      >
                        <span class="listing-price">{{
                            parseFloat(listing.price)
                                ? `$${listing.price}`
                                : "EOI"
                          }}</span
                        >&nbsp;
                        <span class="listing-price-currency">
                          {{ listing.currency }}</span
                        >
                      </router-link>
                    </p>
                    <p class="listing-label">
                      {{
                        parseFloat(listing.price)
                            ? "ASKING PRICE"
                            : "SEEKING EXPRESSIONS OF INTEREST"
                      }}
                    </p>
                  </div>
                  <div class="col-md-4 m-top-12">
                    <p
                        v-if="
                        listing.status === 'Sold' ||
                          listing.status === 'Under Contract'
                      "
                    >
                      <router-link
                          :to="{
                          name: 'rent_roll_listings_show',
                          params: { id: listing.id }
                        }"
                      >
                        <span class="listing-status">{{ listing.status }}</span>
                      </router-link>
                    </p>
                  </div>
                  <div class="col-md-4 text-right m-top-12">
                    <p>
                      <router-link
                          class="listing-links"
                          :to="{
                          name: 'rent_roll_listings_show',
                          params: { id: listing.id }
                        }"
                      >
                        <i class="fa fa-chevron-right font-purple"></i>
                        Details
                      </router-link>
                    </p>
                  </div>
                </div>

                <div class="row p-15">
                  <div class="m-top-20 col-md-4">
                    <p>
                      <router-link
                          :to="{
                          name: 'rent_roll_listings_show',
                          params: { id: listing.id }
                        }"
                      >
                        <span class="listing-highlight-1">{{
                            listing.region
                          }}</span>
                      </router-link>
                    </p>
                    <p class="listing-label">REGION</p>
                  </div>
                  <div class="m-top-20 col-md-4">
                    <p>
                      <router-link
                          :to="{
                          name: 'rent_roll_listings_show',
                          params: { id: listing.id }
                        }"
                      >
                        <span class="listing-highlight-1">{{
                            listing.total_properties
                          }}</span>
                      </router-link>
                    </p>
                    <p class="listing-label">
                      NUMBER OF PROPERTIES IN RENT ROLL
                    </p>
                  </div>
                  <div class="m-top-20 col-md-4">
                    <p>
                      <router-link
                          :to="{
                          name: 'rent_roll_listings_show',
                          params: { id: listing.id }
                        }"
                      >
                        <span class="listing-highlight-2">{{
                            listing.reference
                          }}</span>
                      </router-link>
                    </p>
                    <p class="listing-label">REFERENCE NUMBER</p>
                  </div>
                </div>

                <div class="gap-20"></div>

                <div class="row">
                  <div id="facts" class="facts-area dark-bg-o">
                    <div class="container">
                      <div class="row">
                        <div class="facts-wrapper-sm">
                          <div class="col-sm-3 ts-facts-sm p-25">
                            <div class="ts-facts-content-sm">
                              <span class="ts-facts-icon-sm"
                              ><i class="fa fa-home"></i
                              ></span>
                              <h3 class="ts-facts-title-sm">Residential</h3>
                              <h2 class="ts-facts-num-sm">
                                <router-link
                                    :to="{
                                    name: 'rent_roll_listings_show',
                                    params: { id: listing.id }
                                  }"
                                >
                                  <span
                                      class="counterUp"
                                      :data-count="listing.residential"
                                  >0</span
                                  >
                                </router-link>
                              </h2>
                            </div>
                          </div>
                          <!-- Col end -->

                          <div class="col-sm-3 ts-facts-sm p-25">
                            <div class="ts-facts-content-sm">
                              <span class="ts-facts-icon-sm"
                              ><i class="fa fa-industry"></i
                              ></span>
                              <h3 class="ts-facts-title-sm">
                                Commercial / Industrial
                              </h3>
                              <h2 class="ts-facts-num-sm">
                                <router-link
                                    :to="{
                                    name: 'rent_roll_listings_show',
                                    params: { id: listing.id }
                                  }"
                                >
                                  <span
                                      class="counterUp"
                                      :data-count="listing.commercial"
                                  >0</span
                                  >
                                </router-link>
                              </h2>
                            </div>
                          </div>
                          <!-- Col end -->

                          <div class="col-sm-3 ts-facts-sm p-25">
                            <div class="ts-facts-content-sm">
                              <span class="ts-facts-icon-sm"
                              ><i class="fa fa-plane"></i
                              ></span>
                              <h3 class="ts-facts-title-sm">Holiday</h3>
                              <h2 class="ts-facts-num-sm">
                                <router-link
                                    :to="{
                                    name: 'rent_roll_listings_show',
                                    params: { id: listing.id }
                                  }"
                                >
                                  <span
                                      class="counterUp"
                                      :data-count="listing.holiday"
                                  >0</span
                                  >
                                </router-link>
                              </h2>
                            </div>
                          </div>
                          <!-- Col end -->

                          <div class="col-sm-3 ts-facts-sm p-25">
                            <div class="ts-facts-content-sm">
                              <span class="ts-facts-icon-sm"
                              ><i class="fa fa-building"></i
                              ></span>
                              <h3 class="ts-facts-title-sm">
                                Strata / Community
                              </h3>
                              <h2 class="ts-facts-num-sm">
                                <router-link
                                    :to="{
                                    name: 'rent_roll_listings_show',
                                    params: { id: listing.id }
                                  }"
                                >
                                  <span
                                      class="counterUp"
                                      :data-count="listing.strata"
                                  >0</span
                                  >
                                </router-link>
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gap-40"></div>
            </div>
          </div>
          <div class="text-center" v-show="!listings.length">
            <p>No data found.</p>
          </div>
          <div class="row" v-if="meta">
            <div class="col-md-12 text-center">
              <div class="paging">
                <Paginate
                    :page-count="meta.last_page"
                    :click-handler="pageChange"
                    :force-page="meta.current_page"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    container-class="pagination"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-show="isLoading" class="text-center">
          <div class="loading"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { geoLocations, listings } from "@/lib/axios";
import Paginate from "@/components/Paginate";
import Select from "@/components/Form/Select/Select.vue";

export default {
  components: {
    Paginate,
    Select
  },
  data() {
    return {
      listings: [],
      meta: [],
      params: {
        page: 1,
        state_id: null,
        per_page: 8,
        sort_column: "active_date",
        sort_dir: "desc"
      },
      isLoading: false
    };
  },
  computed: {
    getGeoLocations() {
      return geoLocations;
    }
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.params.state_id = this.$route.query.state;
    this.loadListings();
  },
  methods: {
    async loadListings() {
      this.isLoading = true;
      try {
        const { data } = await listings.get(`/listings`, {
          params: this.params
        });
        this.listings = data.data;
        this.meta = data.meta;
        this.isLoading = false;
      } catch (e) {
        console.log(e, "e");
        this.isLoading = false;
      }
    },
    pageChange(page) {
      this.params = {
        ...this.params,
        page: page
      };
      this.loadListings();
    },
    handleDropdownChange(value, name) {
      this.params[name] = value;
    },
    onChange(e) {
      let value = e.target.value;
      if (value) {
        let sortArray = value.split("_");
        this.params = {
          ...this.params,
          sort_column: sortArray[0],
          sort_dir: sortArray[1]
        };
      } else {
        this.params = {
          ...this.params,
          sort_column: "active_date",
          sort_dir: null
        };
      }
    },
    handleSearchSubmit(e) {
      e.preventDefault();
      this.loadListings();
    }
  }
};
</script>
