export default [
  {
    name: "active_date",
    text: "Active Date",
    render: item => item.active_date
  },
  {
    name: "user",
    text: "User",
    render: item =>
      item.user ? `${item.user?.first_name} ${item.user?.last_name}` : "",
    sort_disable: true
  },
  {
    name: "description",
    text: "Description",
    render: item => item.description
  },
  {
    name: "link",
    text: "Link",
    render: item => item.file_url,
    sort_disable: true
  },
  {
    name: "status",
    text: "Status",
    render: item => item.status,
    className: item =>
      item.status === "Active" ? "font-green" : "font-orange",
    sort_disable: true
  }
];
