<template>
  <div class="col-md-12">
    <div class="row mb-2">
      <Permission
        header="Permissions"
        :default-permissions="defaultPermissions"
        :create="!update"
        @update="handleUpdatePermission"
        :values="values"
        @save="handleSavePermission"
      />
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <Select
                name="listing_id"
                label="Listing Reference *"
                required
                :class-name="
                  `${errors.hasOwnProperty('listing_id') ? 'has-error' : ''}`
                "
                :error="
                  errors.hasOwnProperty('listing_id')
                    ? errors.listing_id[0]
                    : ''
                "
                :value="values.listing_id"
                placeholder="Select a Listing"
                :url="listingsSearch"
                skip
                :axios="$http"
                identifier="identifier"
                @selected="
                  value =>
                    handleDropdownChange(value ? value : null, 'listing_id')
                "
              />
            </div>
          </div>
          <div class="col-md-6 text-right">
            <button
              title="Save"
              :disabled="submitting || isDisabled"
              class="btn btn-xsmall m-top-40"
              type="button"
              @click="handleSubmit"
            >
              <span class="font-120P"><i class="fa fa-save"></i></span>
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-md-12"
        v-if="!isLoadingData && values.sections && values.sections.length"
      >
        <div
          class="row"
          :class="section.className ? section.className : 'template-area'"
          :key="index"
          v-for="(section, index) in values.sections"
        >
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-9">
                <div class="form-group">
                  <select
                    class="form-control-templates"
                    name="template"
                    v-model="section.component"
                    @change="
                      event => handleBlogChange(index, event.target.value)
                    "
                  >
                    <option disabled>Please Select</option>
                    <option
                      :key="component.value"
                      :value="component.value"
                      v-for="component in components"
                      >{{ component.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="col-md-3 text-right">
                <div class=" m-top-6">
                  <button
                    title="Save section"
                    class="btn btn-xsmall"
                    type="button"
                    :disabled="submitting || isDisabled"
                    @click="handleSubmit"
                  >
                    <span class="font-120P"
                      ><i class="fa fa-save"></i
                    ></span></button
                  >&nbsp;&nbsp;
                  <button
                    title="Add new section below this one"
                    class="btn btn-xsmall"
                    type="button"
                    @click="() => handleAddBlog(section, index)"
                  >
                    <span class="font-120P"
                      ><i class="fa fa-plus-circle"></i
                    ></span></button
                  >&nbsp;&nbsp;<button
                    title="Delete this section"
                    class="btn btn-xsmall"
                    type="button"
                    @click="() => handleRemoveBlog(index)"
                  >
                    <span class="font-120P"
                      ><i class="fa fa-minus-circle"></i
                    ></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="gap-20"></div>
            <component
              v-bind="section.data"
              :is="section.component"
              :default-listing="listing"
              @valueChange="valuesData => handleChangeBlog(index, valuesData)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <SubmitButton v-if="isCreatable || updateAvailable"
            :isProgress="submitting || isDisabled"
            :caption="buttonCaption"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import components from "./components";
import Select from "@/components/Form/Select";
import filter from "lodash-es/filter";
import AboutUs from "./components/Form/AboutUs/Index";
import AcknowledgmentAndUnderstanding from "./components/Form/AcknowledgmentAndUnderstanding/Index";
import BarChart from "./components/Form/BarChart/Index";
import BrokersDisclaimer from "./components/Form/BrokersDisclaimer/Index";
import Confidentiality from "./components/Form/Confidentiality/Index";
import Image from "./components/Form/Image/Index";
import MainHeading from "./components/Form/MainHeading/Index";
import ProposedSale from "./components/Form/ProposedSale/Index";
import Purpose from "./components/Form/Purpose/Index";
import SectionBreak from "./components/Form/SectionBreak/Index";
import SubHeading from "./components/Form/SubHeading/Index";
import Table from "./components/Form/Table/Index";
import TextBox from "./components/Form/TextBox/Index";
import VendorsDisclaimer from "./components/Form/VendorsDisclaimer/Index";
import SubmitButton from "@/components/SubmitButton";
import { mapGetters } from "vuex";
import cloneDeep from "lodash-es/cloneDeep";
import Permission from "../../components/Permission";
import map from "lodash-es/map";

export default {
  components: {
    Select,
    AboutUs,
    AcknowledgmentAndUnderstanding,
    BarChart,
    BrokersDisclaimer,
    Confidentiality,
    Image,
    MainHeading,
    ProposedSale,
    Purpose,
    SectionBreak,
    SubHeading,
    Table,
    TextBox,
    VendorsDisclaimer,
    SubmitButton,
    Permission
  },
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions"
    }),
    getGeoLocations() {
      return this.$http;
    },
    defaultPermissions() {
      let permissions = [];
      map(this.permissionMeta.module_permission.im.permissions, group => {
        map(group.permissions, permission => {
          permissions.push({
            group: group.group,
            name: permission.caption,
            permission: permission.title,
            value: permission.name
          });
        });
      });
      return permissions;
    },
    buttonCaption() {
      return this.update
        ? this.submitting
          ? "Updating"
          : "Update IM"
        : this.submitting
        ? "Submitting"
        : "Add IM";
    },
    components() {
      return components;
    },
    isDisabled() {
      return (
        this.isUploading.home_page_image === true ||
        this.isUploading.article_page_image === true ||
        this.isUploading.full_article_page_image === true
      );
    },
    ...mapGetters({
      blogs: "masterData/imValues"
    }),
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Object,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: () => false
    },
    submitting: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      isUploading: {},
      values: {
        ...this.formData,
        sections:
          this.formData && this.formData.sections
            ? this.formData.sections
            : cloneDeep(this.blogs),
        listing_id:
          this.formData && this.formData.listing
            ? this.formData?.listing?.id
            : null
      },
      listingsSearch: `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings/search`,
      isLoadingData: false,
      listing: null
    };
  },
  methods: {
    handleSetPermission() {
      this.$emit("submit", this.values);
    },
    handleUpdatePermission(permissions) {
      this.values.permissions = permissions;
    },
    handleSubmit() {
      let order = 1;
      const values = {
        ...this.values,
        section: this.values.sections.map(item => {
          item.order = order;
          order++;
          return item;
        })
      };
      this.$emit("submit", values);
    },
    handleChangeBlog(index, values) {
      this.values.sections[index].data = values;
    },
    handleBlogChange(index, value) {
      this.values.sections[index].component = value;
      this.values.sections[index].data = {};
    },
    handleAddBlog(blog, index) {
      this.values.sections = this.handleInsertArray(
        this.values.sections,
        index + 1,
        {
          component: "SectionBreak",
          data: {}
        }
      );
    },
    handleInsertArray(arr, index, newItem) {
      return [...arr.slice(0, index), newItem, ...arr.slice(index)];
    },
    async handleRemoveBlog(index) {
      this.isLoadingData = true;
      this.values.sections = filter(
        this.values.sections,
        (row, key) => index !== key
      );
      this.isLoadingData = false;
    },
    async getListingDetails(id) {
      if (!id) {
        this.listing = null;
        return;
      }
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings/${id}`
        );
        this.listing = data;
      } catch (e) {
        console.log(e);
      }
    },
    handleDropdownChange(value, param) {
      if (param === "listing_id") {
        this.getListingDetails(value.identifier);
      }
      this.values[param] = value.identifier;
    },
    handleSavePermission(values) {
      this.$emit(
        "submit",
        {
          ...this.values,
          permissions: values
            ? values
            : {
                admin_access: {
                  admin_approved: false,
                  site_access: false
                }
              }
        },
        true
      );
    }
  },
  mounted() {
    this.getListingDetails(this.formData.listing?.id);
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    blogs: {
      immediate: true,
      handler() {
        this.values = {
          ...this.formData,
          sections:
            this.formData && this.formData.sections
              ? this.formData.sections
              : cloneDeep(this.blogs),
          listing_id:
            this.formData && this.formData.listing
              ? this.formData?.listing?.id
              : null
        };
      }
    }
  }
};
</script>

<style>
.v3dp__divider {
  display: none !important;
}
</style>
