<template>
  <div>
    <div v-if="!showSuccess">
      <div v-if="template_eoi" v-html="template_eoi"></div>
    </div>
    <div v-else>
      <section id="document-body" class="main-container ">
        <div id="main-document-container" class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="" v-if="this.template?.show_witness">
                <h2><i class="fa fa-check font-green"></i>&nbsp;&nbsp;Witness Sign Request Sent</h2>
                <p><br/><strong class="font-120P font-line-height-220P">What to do next</strong><br/></p>
                <p>
                <ol class="font-line-height-220P" type="1">
                  <li>Contact your witness and ask them to check their Inbox or Junk Mail folder for an email titled
                    'TRRB - Your signature is required as a witness' and click on the enclosed link to sign
                  </li>
                  <li>Once your witness has signed, check your Inbox or Junk Mail folder for an email titled 'The Rent
                    Roll Broker Expression of Interest Confirmation for {{ this.template?.listing_reference }}'.
                  </li>
                  <li>Someone will be in touch within the next 48 business hours.</li>
                </ol>
                </p>
                <p class="font-line-height-220P font-purple"><br/><strong>The Rent Roll Broker</strong></p>
              </div>
              <div class="" v-else>
                <h2><i class="fa fa-check font-green"></i>&nbsp;&nbsp;Sign Request Complete</h2>
                <p><br /><strong class="font-120P font-line-height-220P">We'll take over from here!</strong><br /></p>
                <p class="font-line-height-220P">Thank you, your job is done. An email has been sent to the sign requester advising them you have signed.</p>
                <p class="font-line-height-220P font-purple"><br /><strong>The Rent Roll Broker</strong></p>
              </div>
            </div><!-- Col end -->
          </div><!-- Content row end -->
        </div><!-- Document Container end -->
      </section><!-- Document body end print -->
    </div>
  </div>
</template>

<script>
import has from 'lodash-es/has'
import errorMixin from '@/mixins/errorMixin'

export default {
  mixins: [errorMixin],
  data () {
    return {
      template: null,
      template_eoi: null,
      showSuccess: false,
      templateData: {
        full_name: '',
        witness_full_name: '',
        witness_email: '',
        confirm_witness_email: '',
      }
    }
  },
  async mounted () {
    try {
      this.template_eoi = (await this.$http.get(`${process.env.VUE_APP_INTEREST_API_URL}/admin/expressions/eoi/${this.$route.params.interestID}`))
    } catch (e) {
      if(e?.data?.errors?.show_witness){
        this.template = {}
        this.template.show_witness = e?.data?.errors?.show_witness[0]
      }
      this.showSuccess = true

    }

    document.addEventListener('keyup', this.updateValues)
    document.addEventListener('click', this.clicked)
  },
  beforeUnmount () {
    window.removeEventListener('keyup', this.updateValues, true)
    window.removeEventListener('click', this.clicked, true)
  },
  methods: {
    updateValues () {
      if (has(this.templateData, event.target.id)) {
        this.templateData[event.target.id] = event.target.value
      }
    },
    async clicked () {
      //To prevent auto form fillers
      for (let key in this.templateData) {
        const element = document.getElementById(key)
        if (element) {
          this.templateData[key] = element.value
        }
      }

      let isWitnessElementsAvailable = document.getElementById('witness_full_name')
      let keysCount = isWitnessElementsAvailable ? Object.keys(this.templateData).length : 1
      if (event.target.id === 'submit' && Object.values(this.templateData).filter(n => n).length === keysCount) {
        event.preventDefault()
        let button = document.getElementById('submit')
        if (button.disabled) return
        this.templateData.customer_listing_uuid = this.$route.params.interestID
        try {
          button.disabled = true
          this.template = (await this.$http.post(`${process.env.VUE_APP_INTEREST_API_URL}/admin/expressions/eoi`, this.templateData)).data
          window.location.reload()
          this.showSuccess = true
        } catch (e) {
          button.disabled = false

          let errors = this.getServerValidationErrors(e.response.data.errors)
          await this.setErrorsToHtmlElements(errors, Object.keys(this.templateData))

          if (errors.customer_listing_uuid) {
            const errorSpan = document.createElement('span')
            errorSpan.innerHTML = errors.customer_listing_uuid
            errorSpan.className = 'help-block text-danger'
            button.parentNode.insertBefore(errorSpan, button.nextSibling)
            setTimeout(() => {this.showSuccess = true}, 6000)
          }

        }
        this.showSuccess = true
        button.disabled = false

      }
    }
  }
}
</script>
