<template>
  <form role="form">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <p>
              <strong class="font-purple font-90P"
                >Project Workflow {{ stage }}</strong
              >
            </p>
          </div>
        </div>
        <div class="gap-10"></div>
        <div class="row">
          <div class="col-md-10">
            <div v-if="!editName && values.name">
              <p class="font-120P">
                <span class="listing-label">Process:&nbsp;&nbsp;</span
                ><strong>{{ values.name }}</strong>
              </p>
            </div>
            <div class="form-group" v-else>
              <input
                class="form-control"
                name="edit_process_description_1_text"
                id="edit_process_description_1_text"
                placeholder="Process description"
                :value="values.name"
                v-on:input="$event => handleChangeName($event, 'stageName')"
                v-on:blur="$event => handleChangeName($event, 'stageName')"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="row">
              <div class="col-md-6 text-right">
                <button
                  class="btn btn-xxsmall"
                  type="button"
                  v-if="!editName && values.name"
                  title="Edit Process"
                  @click="editName = true"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  class="btn btn-xxsmall"
                  type="button"
                  title="Save Event"
                  v-else
                  @click="handleSaveName"
                >
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="gap-20"></div>
        <template v-for="(activity, index) in values.activities" :key="index">
          <Activity
            :active="editActivity"
            :current="index"
            :text="activity.name"
            :value="editActivityValue"
            @active="
              editActivity = index;
              editActivityValue = activity.name;
            "
            @update="$event => handleChangeName($event, 'editActivityValue')"
            @save="() => handleSaveActivity(index)"
            @delete="() => handleDeleteActivity(index)"
          />
          <div class="gap-10"></div>
          <template
            v-for="(event, eventIndex) in activity.events"
            :key="eventIndex"
          >
            <div class="row">
              <div
                class="col-md-10"
                v-if="editEvent === `${index}_${eventIndex}`"
              >
                <textarea
                  class="form-control form-control-message"
                  id="edit_event_1_text"
                  placeholder="Event description"
                  rows="2"
                  v-on:input="
                    $event => handleChangeName($event, 'editNameValue')
                  "
                  v-on:blur="
                    $event => handleChangeName($event, 'editNameValue')
                  "
                  :value="editNameValue"
                />
              </div>
              <div class="col-md-10" v-else>
                {{ event.name }}
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div class="col-md-6 text-right">
                    <button
                      class="btn btn-xxsmall"
                      type="button"
                      title="Save Event"
                      @click="handleSaveEvent(index, eventIndex)"
                      v-if="editEvent === `${index}_${eventIndex}`"
                    >
                      <i class="fa fa-save"></i>
                    </button>
                    <button
                      class="btn btn-xxsmall"
                      type="button"
                      title="Edit Event"
                      v-else
                      @click="
                        editEvent = `${index}_${eventIndex}`;
                        editNameValue = event.name;
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                  </div>
                  <div class="col-md-6 text-right">
                    <button
                      class="btn btn-xxsmall"
                      type="button"
                      title="Delete Activity"
                      @click="() => handleDeleteEvent(index, eventIndex)"
                    >
                      DELETE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="gap-10"></div>
          <div class="row">
            <div class="col-md-10">
              <textarea
                class="form-control form-control-message"
                id="edit_event_1_text"
                placeholder="Event description"
                :value="eventName[index]"
                v-on:input="
                  $event => handleChangeName($event, `eventName.${index}`)
                "
                v-on:blur="
                  $event => handleChangeName($event, `eventName.${index}`)
                "
              />
            </div>
            <div class="col-md-2">
              <div class="row">
                <div class="col-md-6 text-right">
                  <button
                    class="btn btn-xxsmall"
                    type="button"
                    title="Save Event"
                    @click="handleSaveEvent(index)"
                  >
                    <i class="fa fa-save"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="gap-20"></div>
        </template>
        <div class="row">
          <div class="col-md-9">
            <div class="form-group">
              <label for="new_activity_description_text">New Activity *</label>
              <input
                class="form-control"
                name="new_activity_description_text"
                id="new_activity_description_text"
                placeholder="New activity description"
                v-on:input="$event => handleChangeName($event, 'activityName')"
                v-on:blur="$event => handleChangeName($event, 'activityName')"
                :value="activityName"
              />
            </div>
          </div>
          <div class="col-md-2 text-right">
            <button
              class="btn btn-xxsmall"
              type="button"
              title="Save"
              @click="() => handleSaveActivity()"
            >
              <i class="fa fa-save"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import Activity from "../../../../components/Activity";
import cloneDeep from "lodash-es/cloneDeep";
export default {
  components: {
    Activity
  },
  props: {
    tab: String,
    isSubmitting: Boolean,
    stage: String,
    values: Object
  },
  data() {
    return {
      editName: false,
      editActivity: null,
      editEvent: null,
      newEvent: "",
      stageName: "",
      activityName: "",
      eventName: [],
      editNameValue: "",
      editActivityValue: ""
    };
  },
  methods: {
    handleSaveName() {
      this.$emit("save", {
        tab: this.tab,
        values: {
          [this.tab]: {
            ...this.values,
            name: this.stageName
          }
        }
      });
      this.editName = false;
    },
    handleSaveActivity(index) {
      if (index || index === 0) {
        let data = cloneDeep(this.values.activities);
        data[index] = {
          ...data[index],
          name: this.editActivityValue
        };
        this.$emit("save", {
          tab: this.tab,
          values: {
            [this.tab]: {
              ...(this.values ? this.values : []),
              activities: data
            }
          }
        });
        this.editActivity = null;
      } else {
        this.$emit("save", {
          tab: this.tab,
          values: {
            [this.tab]: {
              ...(this.values ? this.values : []),
              activities: [
                ...(this.values.activities ? this.values.activities : []),
                {
                  name: this.activityName,
                  events: []
                }
              ]
            }
          }
        });
        this.activityName = "";
      }
    },
    handleSaveEvent(index, event) {
      if (event || event === 0) {
        let data = cloneDeep(this.values.activities);
        let events = data[index].events;
        events[event] = {
          ...events[event],
          name: this.editNameValue
        };
        data[index] = {
          ...data[index],
          events: events
        };
        this.$emit("save", {
          tab: this.tab,
          values: {
            [this.tab]: {
              ...(this.values ? this.values : []),
              activities: data
            }
          }
        });
        this.editNameValue = "";
        this.editEvent = null;
      } else {
        let data = cloneDeep(this.values.activities);
        data[index] = {
          ...data[index],
          events: [
            ...(data[index].events ? data[index].events : []),
            {
              name: this[`eventName.${index}`]
            }
          ]
        };
        this.$emit("save", {
          tab: this.tab,
          values: {
            [this.tab]: {
              ...(this.values ? this.values : []),
              activities: data
            }
          }
        });
        this[`eventName.${index}`] = "";
      }
    },
    handleChangeName($event, key) {
      this[key] = $event.target.value;
    },
    handleDeleteActivity(index) {
      let data = cloneDeep(this.values.activities);
      data.splice(index, 1);
      this.$emit("save", {
        tab: this.tab,
        values: {
          [this.tab]: {
            ...(this.values ? this.values : []),
            activities: data
          }
        }
      });
    },
    handleDeleteEvent(index, event) {
      let data = cloneDeep(this.values.activities);
      let events = data[index].events;
      events.splice(event, 1);
      data[index] = {
        ...data[index],
        events: events
      };
      this.$emit("save", {
        tab: this.tab,
        values: {
          [this.tab]: {
            ...(this.values ? this.values : []),
            activities: data
          }
        }
      });
    }
  }
};
</script>
<style>
.tab-pane.active {
  background-color: transparent;
}

.ml-2 {
  margin-left: 2px;
}
</style>
