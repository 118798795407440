<template>
  <div class="entry-content">
    <!--Tabs start-->
    <div class="col-md-12">
      <div class="row">
        <!--tabs border start-->
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              @click="activeTap = 'users'"
              :class="{ active: activeTap === 'users' }"
            >
              <a data-toggle="tab" href="#tab-01">
                <h4 class="panel-title">Users</h4>
              </a>
            </li>
            <li
              @click="activeTap = 'businesses'"
              :class="{ active: activeTap === 'businesses' }"
            >
              <a data-toggle="tab" href="#tab-02">
                <h4 class="panel-title">Businesses</h4>
              </a>
            </li>
<!--            <li-->
<!--              @click="activeTap = 'activities'"-->
<!--              :class="{ active: activeTap === 'activities' }"-->
<!--            >-->
<!--              <a data-toggle="tab" href="#tab-03" title="Activity Log">-->
<!--                <h4 class="panel-title"><i class="fa fa-list"></i></h4>-->
<!--              </a>-->
<!--            </li>-->
          </ul>
          <div class="panel-body">
            <div class="tab-content">
              <div
                id="tab-01"
                v-if="activeTap === 'users'"
                :class="{ active: activeTap === 'users' }"
                class="tab-pane"
              >
                <div class="col-md-12">
                  <div class="entry-header-admin">
                    <div class="gap-40"></div>
                    <div class="col-md-8 entry-title-admin text-left pull-left">
                      <div class="row">
                        <div class="col-md-4">
                          <p class="font-120P">All Admin Users</p>
                        </div>
                        <!-- Start - Available to Super Admin users only -->
                        <div class="col-md-2" v-if="user.isSuperAdmin || user.isAdmin">
                          <!-- Square switch -->
                          <label class="switch">
                            <input type="checkbox" v-model="showArchived">
                            <span class="slider"></span>
                          </label>
                        </div>
                        <div class="col-md-6" v-if="user.isSuperAdmin || user.isAdmin">
                          Show Archived Users  &nbsp;&nbsp;
                        </div>
                        <!-- End - Available to Super Admin users only -->
                      </div>

                    </div>
                    <div
                      class="col-md-4 entry-title-admin text-right pull-right"
                    >
                      <div class="row" v-if="isCreatable">
                        <router-link
                          class="btn btn-primary"
                          :to="{ name: 'admin_users_create' }"
                        >
                          <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Add User
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- Heading end -->

                  <!-- Task details start -->
                  <div class="entry-content">
                    <div class="gap-20"></div>
                    <ResourceTable
                      :showArchived="showArchived"
                      ref="table"
                      show-path="admin_users_show"
                      :update-available="updateAvailable"
                      :columns="columns"
                      :url="url"
                      :extra-params="{
                        sort_column: 'created_at',
                        sort_dir: 'desc'
                      }"
                    />
                  </div>
                  <!-- Task details end -->
                </div>
              </div>

              <div
                id="tab-02"
                v-if="activeTap === 'businesses'"
                :class="{ active: activeTap === 'businesses' }"
                class="tab-pane"
              >
                <div class="col-md-12">
                  <div class="entry-header-admin">
                    <div class="gap-40"></div>
                    <div class="col-md-8 entry-title-admin text-left pull-left">
                      <div class="row">
                        <div class="col-md-4">
                          <p class="font-120P">All Businesses</p>
                        </div>
                        <!-- Start - Available to Super Admin users only -->
                        <div class="col-md-2" v-if="user.isSuperAdmin || user.isAdmin">
                          <!-- Square switch -->
                          <label class="switch">
                            <input type="checkbox" v-model="showArchivedBusinesses">
                            <span class="slider"></span>
                          </label>
                        </div>
                        <div class="col-md-6" v-if="user.isSuperAdmin || user.isAdmin">
                          Show Archived Businesses  &nbsp;&nbsp;
                        </div>
                        <!-- End - Available to Super Admin users only -->
                      </div>
                    </div>
                    <div
                      class="col-md-4 entry-title-admin text-right pull-right"
                    >
                      <div class="row">
                        <router-link
                          class="btn btn-primary"
                          :to="{ name: 'businesses_create' }"
                        >
                          <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Add
                          Business
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- Heading end -->

                  <!-- Task details start -->
                  <div class="entry-content">
                    <div class="gap-20"></div>
                    <ResourceTable
                      :showArchived="showArchivedBusinesses"
                      ref="businessTable"
                      show-path="businesses_show"
                      :columns="business_columns"
                      :update-available="true"
                      :url="businesses_url"
                      :extra-params="{
                        sort_column: 'created_at',
                        sort_dir: 'desc'
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceTable from "@/components/ResourceTable";
import { columns, business_columns } from "./columns";
import {mapGetters} from "vuex";

export default {
  components: {
    ResourceTable
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    columns() {
      return columns;
    },
    business_columns() {
      return business_columns;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  data() {
    return {
      showArchived : false,
      showArchivedBusinesses : false,
      users: [],
      meta: [],
      params: {
        page: 1
      },
      isLoading: false,
      url: `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users`,
      businesses_url: `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses`,
      activeTap: "users"
    };
  },
  mounted() {
    const tap = this.$route.query.tap;
    this.activeTap = tap ? tap : "users";

    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "User Administration",
        url: "admin_users",
        active: true
      }
    ]);

    this.$ui.setPageHeader({
      title: "User Administration",
      showTask: false,
      parentTitle: "User Administration"
    });
  },
  watch : {
    showArchived() {
      setTimeout(() =>{
        this.$refs.table.pageChange(1)
      },500)
    },
    showArchivedBusinesses() {
      setTimeout(() =>{
        this.$refs.businessTable.pageChange(1)
      },500)
    }
  }
};
</script>

<style></style>
