export default [
  {
    name: "full_name",
    text: "Name",
    render: item => `${item.first_name} ${item.last_name}`,
    badge: item => item.interests
  },
  {
    name: "trading_name",
    text: "Company",
    render: item => (item.business?.trading_name ? item.business?.trading_name : "-"),
    sort_disable: false
  },
  {
    name: "Email",
    text: "Email",
    render: item => (item.contact_type ? item.email : "-"),
    sort_disable: false
  },
  {
    name: "phone_number",
    text: "Phone",
    render: item => (item.contact_type ? item.phone_number : "-"),
    sort_disable: false
  },

  {
    name: "state",
    text: "State",
    render: item =>
      item.business?.state_code ? item.business?.state_code : "-",
    sort_disable: false
  },
  {
    name: "type",
    text: "Type",
    render: item => (item.type ? item.type : "-"),
    sort_disable: false
  },
];
export const insightsColumns = [
  {
    name: "created",
    text: "Created",
    render: item => item.created
  },
  {
    name: "full_name",
    text: "Account",
    render: item => `${item.first_name} ${item.last_name}`,
    badge: item => item.interests
  },
  {
    name: "account_stage",
    text: "Stage",
    render: item => (item.stage?.name ? item.stage?.name : "-"),
    sort_disable: true
  },
  {
    name: "type",
    text: "Type",
    render: item => (item.type ? item.type : "-"),
    sort_disable: false
  },
  {
    name: "status",
    text: "Status",
    render: item => item.status,
    className: item =>
      item.status === "Active" ? "font-green" : "font-orange",
    sort_disable: true
  },
  {
    name: "opps_count",
    text: "Opps",
    render: item => (item.opportunities ? item.opportunities : "-"),
    sort_disable: false
  },
  {
    name: "opps_amount",
    text: "Total Opps Amount",
    render: item =>
      item.opportunities_amount ? item.opportunities_amount : '0.00',
    sort_disable: false
  },
  {
    name: "last_login",
    text: "Last Login",
    render: item => (item.last_login ? item.last_login : "-"),
    sort_disable: true
  }
];
