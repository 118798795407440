<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              v-show="tap.props.show"
              :class="{ active: active === tap.id }"
              @click="handleActiveTap(tap.id)"
              :key="tap.id"
              v-for="tap in taps"
            >
              <a data-toggle="tab" :href="`#${tap.id}`">
                <h4 class="panel-title">
                  {{ tap.name }}&nbsp;
                  <span
                    v-if="
                      tap.count && items(tap.count) && items(tap.count).length
                    "
                    class="badge badge-highlight font-70P"
                  >
                    {{ items(tap.count) ? items(tap.count).length : 0 }}
                  </span>
                </h4>
              </a>
            </li>
          </ul>
          <div class="panel-body">
            <div class="tab-content">
              <div
                :key="tap.id"
                v-for="tap in taps"
                :id="tap.id"
                :class="{ active: this.active === tap.id }"
                class="tab-pane"
              >
                <div class="col-md-12" v-if="active === tap.id">
                  <component
                    v-bind="tap.props ? tap.props : {}"
                    v-bind:is="tap.component"
                  >
                  </component>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "@/views/Dashboard/Calendar";
import OpenTask from "@/views/Dashboard/OpenTask";
import Stats from "@/views/Dashboard/Stats";
import Tasks from "@/views/Dashboard/Tasks";
import TasksResource from "@/views/Dashboard/TasksResource";
import PendingRequests from "@/views/Dashboard/PendingRequests";
import ActivityLog from "@/views/Dashboard/ActivityLog";
import { mapGetters } from "vuex";

export default {
  name: "AdminHome",
  components: {
    Calendar,
    Stats,
    Tasks,
    TasksResource,
    OpenTask,
    PendingRequests,
    ActivityLog
  },
  computed: {
    ...mapGetters("table", ["items"])
  },
  data() {
    return {
      taps: [
        {
          name: "My Calendar",
          id: "mycalendar",
          count: "mycalendar",
          component: "Calendar",
          active: true,
          props: {
            show: true
          }
        },
        {
          name: "Open Tasks",
          id: "opentasks",
          count: "open-tasks",
          component: "OpenTask",
          props: {
            show: true
          }
        },
        {
          name: "Upcoming Tasks",
          id: "upcomingtasks",
          count: "upcoming-tasks",
          component: "TasksResource",
          props: {
            show: true,
            title: "Up Coming Tasks",
            route: "admin/tasks/upcoming"
          }
        },
        {
          name: "Overdue Tasks",
          id: "overduetasks",
          count: "overdue-tasks",
          component: "TasksResource",
          props: {
            show: true,
            title: "Overdue Tasks",
            route: "admin/tasks/due"
          }
        },
        {
          name: "Closed Tasks",
          id: "closedtasks",
          count: "closed-tasks",
          component: "TasksResource",
          props: {
            show: true,
            title: "Closed Tasks",
            route: "admin/tasks/closed"
          }
        }
      ],
      active: ""
    };
  },
  methods: {
    handleActiveTap(tap) {
      this.active = tap;
      this.$router.push({
        name: "home",
        query: {
          tap: tap
        }
      });
    }
  },
  mounted() {
    this.$ui.setPageHeader({
      title: "Calendar & Tasks Dashboard",
      showTask: true
    });

    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Calendar Dashboard",
        url: "home"
      }
    ]);

    const tap = this.$route.query.tap;
    this.active = tap ? tap : "mycalendar";
  }
};
</script>
