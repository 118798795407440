<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
                v-show="tap.props.show && ((tap.id === 'opportunities' && user?.customer.registered_buyer === 'Yes') || tap.id === 'listings' && user?.customer.registered_seller === 'Yes' || (tap.id !== 'opportunities' && tap.id !== 'listings'))"
                :class="{ active: active === tap.id }"
                @click="active = tap.id"
                :key="tap.id"
                v-for="tap in taps"
            >
              <a data-toggle="tab" :href="`#${tap.id}`">
                <h4 class="panel-title">
                  {{ tap.name }}&nbsp;
                  <span
                      v-if="
                      tap.count && items(tap.count) && items(tap.count).length
                    "
                      class="badge badge-highlight font-70P"
                  >
                    {{ items(tap.count) ? items(tap.count).length : 0 }}
                  </span>
                </h4>
              </a>
            </li>
          </ul>
          <div class="panel-body">
            <div class="tab-content">
              <div
                  :key="tap.id"
                  v-for="tap in taps"
                  :id="tap.id"
                  :class="{ active: this.active === tap.id }"
                  class="tab-pane"
              >
                <div class="col-md-12">
                  <component
                      v-bind="tap.props ? tap.props : {}"
                      v-if="active === tap.id"
                      v-bind:is="tap.component"
                      @save="handleSave"
                  >
                  </component>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Detail from "../Dashboard/Customer/Detail";
import Update from "../Dashboard/Customer/Update";
import Interests from "../Dashboard/Customer/Interests";
import Listings from "../Dashboard/Customer/Listings";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CustomerHome",
  components: {
    Detail,
    Update,
    Interests,
    Listings
  },
  computed: {
    ...mapGetters("table", ["items"]),
    ...mapGetters("auth", ["user"]),
    taps() {
      return [
        {
          name: "View Account",
          id: "customer_accounts",
          count: null,
          component: "Detail",
          active: true,
          props: {
            updated: this.updated,
            show: true
          }
        },
        {
          name: "Update Account",
          id: "customer_update",
          count: null,
          component: "Update",
          active: false,
          props: {
            handleSubmit: this.handleCustomerSubmit,
            show: true
          }
        },
        {
          name: "Opportunities",
          id: "opportunities",
          count: "listings",
          component: "Interests",
          active: false,
          props: {
            show: true
          }
        },
        {
          name: "Listings",
          id: "listings",
          count: "seller-listings",
          component: "Listings",
          active: false,
          props: {
            show: true
          }
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      getCustomer: "auth/getCustomer"
    }),
    handleSave() {
      this.updated = true;
      this.active = "customer_accounts";
    }
  },
  data() {
    return {
      active: "customer_accounts",
      updated: false
    };
  },
  mounted() {
    this.$ui.setPageHeader({
      title: "Customer Dashboard",
      parentTitle: "Dashboard"
    });

    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Customer Dashboard",
        url: "home"
      }
    ]);
  }
};
</script>
