<template>
  <div class="" v-if="customer">
    <ResourceTable
      :columns="interestColumns"
      :url="interest"
      remove-actions
      :extra-params="{
        customer_id: customer.id
      }"
    />
  </div>
</template>

<script>
import ResourceTable from "@/components/ResourceTable";
import { mapGetters } from "vuex";
export default {
  components: {
    ResourceTable
  },
  computed: {
    ...mapGetters("auth", ["customer"])
  },
  data() {
    return {
      interest: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/listings`,
      interestColumns: [
        {
          name: "date",
          text: "Date Enquired",
          timestamp: item => item.created
        },
        {
          name: "reference",
          text: "Reference",
          render: item => item.reference,
          link: item =>
            item && item.uuid !== null && item.active_status === "Active"
              ? `${process.env.VUE_APP_ADMIN_URL}/customer/${item.uuid}/information-memorandums`
              : "",
          className: item =>
            item && item.uuid !== null && item.active_status === "Active"
              ? "cursor-pointer font-link"
              : "",
          sort_disable: true
        },
        {
          name: "region",
          text: "Region",
          render: item => item.region,
          link: item =>
            item && item.uuid !== null && item.active_status === "Active"
              ? `${process.env.VUE_APP_ADMIN_URL}/customer/${item.uuid}/information-memorandums`
              : "",
          className: item =>
            item.active_status === "Active" ? "cursor-pointer font-link" : "",
          sort_disable: true
        },
        {
          name: "ca",
          text: "CA",
          render: item =>
            item.interest_status === "Completed"
              ? "Download"
              : item.is_witness_sign_pending ? 'Witness sign pending' : item.interest_status,
          download: true,
          downloadable: item => (item.interest_status === "Completed" || item.interest_status === "Pending") && (!item.is_witness_sign_pending),
          className: item =>
              (item.interest_status === "Completed" || item.interest_status === "Pending") && (!item.is_witness_sign_pending)
              ? "cursor-pointer font-link"
              : "",
          downloadUrl: item =>
              item.interest_status === "Pending" ? this.$router.push({name: 'confidentiality-agreement-prepare', params: {customerListingUUID: item.interest_uuid}}) :
            `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-ca`,
          sort_disable: true
        },
        {
          name: "im",
          text: "IM",
          render: item =>
            item && item.uuid !== null && item.active_status === "Active"
              ? "Link"
              : "N/A",
          link: item =>
            item && item.uuid !== null && item.active_status === "Active"
              ? `${process.env.VUE_APP_ADMIN_URL}/customer/${item.uuid}/information-memorandums`
              : "",
          disableNewTab: true,
          sort_disable: true,
          className: item =>
            item && item.uuid !== null && item.active_status === "Active"
              ? "cursor-pointer font-link"
              : "",
          sort_disable: true
        },
        {
          name: "expression",
          text: "EOI",
          render: item =>
            item.expression === null ? "None" : item.expression.status,
          link: item =>
            item.expression === null
              ? ""
              : item.active_status === "Active"
              ? item.expression.status === "Submitted"
                ? `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-eoi`
                : `${process.env.VUE_APP_ADMIN_URL}/customer/${item.uuid}/information-memorandums?tab=eoi`
              : "",
          downloadable: item =>
            item.expression !== null && item.expression.status === "Submitted",
          className: item =>
            item.expression.status === "Submitted"
              ? "cursor-pointer font-link"
              : "",
          sort_disable: true
        },
        {
          name: "status",
          text: "Status",
          render: item => item.status,
          sort_disable: true
        }
      ]
    };
  }
};
</script>

<style></style>
