<template>
  <div class="row">
    <div class="col-md-12">
      <p><strong>Notes</strong></p>
    </div>
    <div class="gap-10"></div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="row gap-20">
            <div class="col-md-12">
              <div class="post-meta">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea
                      class="form-control form-control-message"
                      name="note"
                      id="note"
                      placeholder="Add note"
                      v-model="values.note"
                      rows="3"
                    ></textarea>
                  </div>
                  <div class="col-md-6">
                    <span class="post-meta-date font-90P"
                      ><i class="fa fa-sticky-note"></i> - </span
                    >&nbsp;<span class="font-90P"
                      >{{ user.first_name }} {{ user.last_name }}</span
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <SubmitButton
                      class="btn-xxsmall"
                      :isProgress="isSubmitting"
                      :caption="isSubmitting ? 'Submitting' : 'Add Note'"
                      v-if="model && model_id"
                      icon="fa fa-sticky-note"
                      @submit="() => handleSubmit(null)"
                    />
                  </div>
                  <div class="gap-10"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="gap-20"></div>

          <div class="row gap-20">
            <div class="col-md-12" v-for="note in notes" :key="note.id">
              <div class="post-meta">
                <p class="font-black font-95P">
                  {{ note.note }}
                </p>
                <span class="post-meta-date font-90P"
                  ><i class="fa fa-sticky-note"></i>
                  {{ note.created_at }} </span
                >&nbsp;<span class="font-90P" v-if="note.user"
                  >{{ note.user.first_name }} {{ note.user.last_name }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitButton from "../SubmitButton";

export default {
  components: {
    SubmitButton
  },
  props: {
    model: String,
    model_id: String
  },
  data() {
    return {
      isSubmitting: false,
      values: {
        note: ""
      },
      notes: []
    };
  },
  methods: {
    async getNotes() {
      if (this.model_id && this.model) {
        try {
          const { data } = await this.$http.get(
            `${process.env.VUE_APP_TASKS_API_URL}/admin/notes/all`,
            {
              params: {
                model: this.model,
                model_id: this.model_id
              }
            }
          );
          this.notes = data;
        } catch (e) {
          console.log(e, "e");
        }
      }
    },
    async handleSubmit(modelId) {
      this.isSubmitting = true;
      try {
        const { data } = await this.$http.post(
          `${process.env.VUE_APP_TASKS_API_URL}/admin/notes/add`,
          {
            note: this.values.note,
            artifact: this.model,
            artifact_identifier: modelId ? modelId : this.model_id
          }
        );
        this.values.note = null;
        this.notes = [...this.notes, data];
      } catch (e) {
        console.log(e, "e");
      }
      this.isSubmitting = false;
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  mounted() {
    this.getNotes();
  }
};
</script>
<style scoped>
span {
  color: rgb(51, 51, 51) !important;
}
</style>
