<template>
  <section id="main-container" class="main-container">
    <div class="container">
      <div class="container">
        <form id="login-form" @submit="handleFormSubmit">
          <div class="error-container"></div>
          <input type="hidden" name="login" value="login" id="login" />
          <div class="row">
            <div class="col-md-3">
              <div>&nbsp;</div>
            </div>
            <div class="col-md-6 listing-container">
              <div class="row p-15">
                <h2 class="font-purple">Log In</h2>
                <div class="col-md-12">
                <br />
                <h4 v-show="available_email">It seems you already have an account with us.</h4>
                <strong v-show="available_email">Please log in with your credentials below or reset your password if it has been forgotten to continue with your application.</strong>
                <br v-show="available_email"/>
                <br v-show="available_email"/>
                <strong
                  >The admin experience is best viewed on a desktop, laptop or
                  iPad</strong
                >
                <br v-show="!available_email"/><br />
                <div
                  id="alert-incorrect-credentials"
                  class="alert alert-danger fade in"
                  v-if="errors"
                >
                  <a
                    href="#"
                    class="close"
                    data-dismiss="alert"
                    aria-label="close"
                    title="close"
                    >×</a
                  >
                  <span class="icon"><i class="fa fa-exclamation"></i></span
                  ><strong>Unsuccessful Login Attempt!</strong><br />
                  <span v-html="errors"></span>
                </div>
                <!-- Alert exclamation end -->
                <div
                  id="alert-password-reset-success"
                  class="alert alert-success fade in"
                  v-if="success"
                >
                  <a
                    href="#"
                    class="close"
                    data-dismiss="alert"
                    aria-label="close"
                    title="close"
                    >×</a
                  >
                  <span class="icon"><i class="fa fa-check"></i></span
                  ><strong>Success!</strong><br />Your password has been reset.
                  Please log in.
                </div>
                  </div>
                <!-- Alert success end -->
              </div>
              <div class="row p-15">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="username">Username *</label>
                    <input
                      class="form-control"
                      id="username"
                      v-model="formData.email"
                      name="username"
                      placeholder="Your username"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row p-15">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="password">Password *</label>
                    <input
                      class="form-control"
                      id="password"
                      name="password"
                      v-model="formData.password"
                      placeholder="Your password"
                      type="password"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row p-15">
                <div class="col-md-5">
                  <div class="form-group">
                    <button
                      :disabled="isLoading"
                      class="btn btn-primary"
                      type="submit"
                    >
                      <div
                        class="loading-btn"
                        :class="{ 'loading-btn--loading': isLoading }"
                      >
                        Log In
                        <span v-if="isLoading" class="loading-btn--loading">
                          <b></b>
                          <b></b>
                          <b></b>
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <router-link
                      class="btn btn-primary"
                      :to="{
                        name: 'forgot_password'
                      }"
                    >
                      Forgot / Reset Password</router-link
                    >&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div>&nbsp;</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import get from "lodash-es/get";
import {identities} from "@/lib/axios";
import { setCookie } from '@/utils/helpers'

export default {
  props: {
    available_email: String
  },
  data() {
    return {
      formData: {
        email: "",
        password: ""
      },
      success: false,
      errors: null,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
      user: "auth/user"
    })
  },
  methods: {
    ...mapActions({
      getUser: "auth/loginOnce",
      getToken: "auth/login",
      getCustomer: "auth/getCustomer",
    }),
    async handleFormSubmit($event) {
      this.isLoading = true;
      $event.preventDefault();
      try {
        await this.getUser(this.formData);
        if(this.user && this.user.email_otp){
          let formData = {
            'user_uuid' : this.user.id
          }
          await identities.post("/api/email-verification/generate", formData);
        }
        let redirect = null;
        if (this.$route.query && this.$route.query.redirect) {
          redirect = `${this.$route.query.redirect}/?listing_login=${true}`;
        }
        await this.$router.push({
          name: "otp_verification",
          params: {redirect:redirect},
          query: {redirect: redirect}
        });
        window.scrollTo(0,0);
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors.email.0");
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.success = this.$route.query.reset === "true";
    this.formData.email = this.available_email
  }
};
</script>

<style></style>
