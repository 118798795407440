<template>
  <div class="col-md-12">
    <div class="row">
      <Permission
        @update="handleChangePermission"
        :default-permissions="defaultPermissions"
        header="Permissions"
        :values="values"
        :create="!update"
        @save="handleUpdatePermission"
      />
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Document Details</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Display Order Number *</label>
          <select
            v-model="values.order_number"
            id="order_number"
            name="order_number"
            class="form-control"
            :disabled="!values.permissions.admin_access.admin_approved"
          > 
        <option v-for="i in dropDownValue" :value="i" :selected="values.order_number">{{ i }}</option>
        </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="">
          <p>&nbsp;</p>
          <p>
            View
            <a target="_blank" :href="industryUrl">
              INDUSTRY NEWS & ADVICE
            </a>
            page live documents
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="document_description">Document short description *</label>
          <textarea
            class="form-control form-control-message"
            :class="{ 'has-error': errors.hasOwnProperty('description') }"
            name="document_description"
            id="document_description"
            v-model="values.description"
            placeholder="Max 80 charactors"
            rows="3"
            maxlength="80"
            required
          ></textarea>
          <span
            v-if="errors.hasOwnProperty('description')"
            class="help-block text-danger"
            >{{ errors.description[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="active_date">Active Date *</label>
          <DatePicker
            :model-value="values.active_date"
            @update="
              e => {
                values.active_date = e.target.value;
              }
            "
            name="active_date"
            id="active_date"
            :input-class="
              `form-control ${
                errors.hasOwnProperty('active_date') ? 'has-error' : ''
              }`
            "
            placeholder="The date the listing will be active from"
          />
          <span
            v-if="errors.hasOwnProperty('active_date')"
            class="help-block text-danger"
            >{{ errors.active_date[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Document File</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="upload_file_1">
            {{
              isUploading.file === true
                ? "Uploading..."
                : `Select PDF to upload *`
            }}
          </label>
          <input
            class=""
            name="upload_file_1"
            id="upload_file_1"
            placeholder="Browse document (.pdf)"
            type="file"
            accept=".pdf"
            @change="e => handleFileUpload(e, 'file')"
            required
          />
        </div>
      </div>
    </div>

    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <SubmitButton v-if="isCreatable || updateAvailable"
            :isProgress="isLoading || isDisabled"
            :caption="buttonName"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/Form/DatePicker";
import { fileUpload } from "@/lib/axios";
import SubmitButton from "@/components/SubmitButton";
import Permission from "../../components/Permission";
import { mapGetters } from "vuex";
import map from "lodash-es/map";

export default {
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions"
    }),
    buttonName() {
      if (this.update) {
        if (this.isLoading) {
          return "Updating";
        } else {
          return "Update Document";
        }
      } else {
        if (this.isLoading) {
          return "Creating";
        } else {
          return "Create Document";
        }
      }
    },
    getGeoLocations() {
      return this.$http;
    },
    industryUrl() {
      return `${process.env.VUE_APP_WEB_URL}/industry-news-advice`;
    },
    isDisabled() {
      return this.isUploading.file === true;
    },
    defaultPermissions() {
      let permissions = [];
      map(this.permissionMeta.module_permission.document.permissions, group => {
        map(group.permissions, permission => {
          permissions.push({
            group: group.group,
            name: permission.caption,
            permission: permission.title,
            value: permission.name
          });
        });
      });
      return permissions;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Object,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: () => false
    },
    isLoading: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    DatePicker,
    SubmitButton,
    Permission
  },
  data() {
    return {
      isUploading: {},
      values: {
        ...this.formData
      },
      countrySearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/countries/search`,
      stateSearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`,
      dropDownValue: 0,
    };
  },
  methods: {
    handleSetPermission() {
      this.$emit("submit", this.values);
    },
    handleSubmit() {
      this.$emit("submit", this.values);
    },
    handleDropdownChange(value, param) {
      this.values[param] = value;
    },
    async handleFileUpload(e, name) {
      const file = e.target.files[0];
      this.isUploading = {
        ...this.isUploading,
        [name]: true
      };
      try {
        const data = await this.$http.post(
          `${process.env.VUE_APP_DOCUMENTS_API_URL}/admin/signed-storage-url`,
          {
            path: this.handleGetPath(name),
            content_type: file.type
          }
        );
        if (data.url) {
          await fileUpload.put(data.url, file, {
            headers: {
              useAuth: false,
              "Content-Type": file.type
            }
          });
        }
        this.isUploading = {
          ...this.isUploading,
          [name]: false
        };
        this.values[name] = data.uuid;
      } catch (e) {
        console.log(e);
      }
    },
    handleGetPath(path) {
      switch (path) {
        case "file":
          return "documents/";
      }
      return "tmp/";
    },
    handleChangePermission(values) {
      this.values.permissions = values;
      this.values.permissions.admin_access.admin_approved ? this.dropDownValue = this.dropDownValue + 1 : this.dropDownValue = this.dropDownValue - 1;
    },
    handleUpdatePermission(values) {
      this.$emit(
        "submit",
        {
          ...this.values,
          permissions: values
            ? values
            : {
                admin_access: {
                  admin_approved: false,
                  site_access: false
                }
              }
        },
        true
      );
    },
    async getLastOrderNumber() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_DOCUMENTS_API_URL}/admin/documents/last-order-number`
        );
        if (data) {
          this.dropDownValue = data;
          console.log(data);
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  mounted() {
    this.getLastOrderNumber();
  }
};
</script>

<style>
.v3dp__divider {
  display: none !important;
}
</style>
