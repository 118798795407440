<template>
  <div class="row">
    <div class="gap-40"></div>

    <div class="col-md-12">
      <div class="entry-header-admin">
        <div class="entry-title-admin text-left pull-left">
          <p class="font-120P">Select CSV Export Fliters</p>
        </div>
      </div>
    </div>

    <div class="gap-20"></div>

    <div class="col-md-6">
      <div class="form-group">
        <label for="accont_owner">Account Owner</label>
        <select
          class="form-control"
          name="accont_owner"
          id="accont_owner"
          v-model="values.account_owner"
        >
          <option value="" selected>All</option>
          <option v-for="owner in owners" :key="owner.id" :value="owner.id">
            {{ owner.last_name }} {{ owner.first_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="listing-reference">Listing Reference</label>
        <select
          class="form-control"
          id="listing_reference"
          name="listing_reference"
          v-model="values.listing_reference"
        >
          <option value="" selected>All</option>
          <option
            v-for="listing in listings"
            :key="listing.id"
            :value="listing.id"
          >
            {{ listing.reference }} - {{ listing.region }},
            {{ listing.state.code }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="opportunity_amount">Opportunity Amount</label>
        <select
          class="form-control"
          name="opportunity_amount"
          id="opportunity_amount"
          v-model="values.opportunity_amount"
        >
          <option value="" selected>All</option>
          <option value="100000">0 - $100,000</option>
          <option value="500000">0 - $500,000</option>
          <option value="1000000">0 - $1,000,000</option>
          <option value="2000000">0 - $2,000,000</option>
          <option value="2500000">0 - $2,500,000</option>
          <option value="5000000">0 - $5,000,000</option>
          <option value="+5">$5,000,000 +</option>
        </select>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="asking_price">Asking Price</label>
        <select
          class="form-control"
          name="asking_price"
          id="asking_price"
          v-model="values.asking_price"
        >
          <option value="" selected>All</option>
          <option value="100000">0 - $100,000</option>
          <option value="500000">0 - $500,000</option>
          <option value="1000000">0 - $1,000,000</option>
          <option value="2000000">0 - $2,000,000</option>
          <option value="2500000">0 - $2,500,000</option>
          <option value="5000000">0 - $5,000,000</option>
          <option value="+5">$5,000,000 +</option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="opportunity_stage">Opportunity Stage</label>
        <select
          class="form-control"
          id="opportunity_stage"
          name="opportunity_stage"
          v-model="values.opportunity_stage"
        >
          <option value="" selected>All</option>
          <option value="Qualification">Qualification</option>
          <option value="Value Proposition">Value Proposition</option>
          <option value="Expression of Interest">Expression of Interest</option>
          <option value="Negotiation / Review">Negotiation / Review</option>
          <option value="Closed (WON)">Closed (WON)</option>
          <option value="Closed (LOST)">Closed (LOST)</option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="contacts">Contacts</label>
        <select
          class="form-control"
          name="contacts"
          id="contacts"
          v-model="values.contact"
        >
          <option value="Primary Contact Only" selected
            >Primary Contact Only</option
          >
          <option value="All Contacts">All Contacts</option>
        </select>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="state_interest">State</label>
        <select
          class="form-control"
          id="state_interest"
          name="state_interest"
          v-model="values.state"
        >
          <option value="" selected>All</option>
          <option v-for="state in states" :key="state.id" :value="state.id">
            {{ state.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="business_type">Business Type</label>
        <select
          class="form-control"
          name="business_type"
          id="business_type"
          v-model="values.business_type"
          required
        >
          <option selected value="">All</option>

          <option
            :value="value.name"
            v-for="(value, index) in businessTypes"
            :key="index"
            >{{ value.name }}</option
          >
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="lead_source">Lead Source</label>
        <select
          class="form-control"
          name="lead_source"
          id="lead_source"
          v-model="values.lead_source"
        >
          <option value="" selected>All</option>
          <option
            :value="value.name"
            v-for="(value, index) in leadSources"
            :key="index"
            >{{ value.name }}</option
          >
        </select>
      </div>
    </div>

    <div class="gap-40"></div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <SubmitButton
          :isProgress="isLoading"
          caption="Generate CSV"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      listings: null,
      owners: null,
      states: null,
      isLoading: false,
      values: {
        account_owner: "",
        listing_reference: "",
        opportunity_amount: "",
        asking_price: "",
        opportunity_stage: "",
        contact: "Primary Contact Only",
        state: "",
        business_type: "Net New Business",
        lead_source: "",
        component: "opportunity"
      }
    };
  },
  components: {
    SubmitButton
  },
  methods: {
    ...mapActions({
      loadDropDownListData: "masterData/loadDropDownListData"
    }),
    async handleSubmit() {
      try {
        const data = await this.$http.post(
          `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/export`,
          this.values
        );
        const blob = new Blob([data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "opportunities.csv";
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        console.log(e);
      }
    },
    async loadListings() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings`
        );
        this.listings = data;
      } catch (e) {
        console.log(e);
      }
    },
    async loadUsers() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users`
        );
        this.owners = data;
      } catch (e) {
        console.log(e);
      }
    },
    async loadStates() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states`
        );
        this.states = data;
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions",
      currencies: "masterData/currencies",
      businessTypes: "masterData/businessTypes",
      leadSources: "masterData/leadSources"
    })
  },
  async mounted() {
    this.loadDropDownListData();
    await this.loadListings();
    await this.loadUsers();
    await this.loadStates();
  }
};
</script>
