<template>
  <div class="row">
    <div class="col-md-12">
      <component
        v-bind="blog.data"
        :is="componentName"
        :listing="listing"
        :id="blog.id"
        :isPreview="isPreview"
      />
    </div>
  </div>
</template>

<script>
import components from "../../../components";

export default {
  props: {
    blog: Object,
    listing: Object,
    isPreview: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    components() {
      return components;
    },
    componentName() {
      return require(`../${this.blog.component}/Index`).default;
    }
  },
  created() {
    // console.log(this.blog)
  }
};
</script>
