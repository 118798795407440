<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="font-purple">{{ headingLarge }}</h4>
      <p>
        <strong>{{ headingSmall }}</strong>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headingSmall: String,
    headingLarge: String
  },
  data() {
    return {
      values: {
        headingSmall: this.headingSmall,
        headingLarge: this.headingLarge
      }
    };
  },
  created() {},
  mounted() {},
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("valueChange", this.values);
      }
    }
  }
};
</script>
