<template>
  <div class="row" id="acknowledgment-understanding">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-9">
          <h2 class="section-title">{{ headingSmall }}</h2>
          <h3 class="section-sub-title">{{ headingLarge }}</h3>
        </div>
        <div class="col-md-3 text-right" v-if="listing">
          <p class="listing-label">Reference</p>
          <p>
            <span class="listing-highlight-3">{{ listing.reference }}</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p v-html="body.text_1"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headingSmall: String,
    headingLarge: String,
    body: Object,
    listing: Object
  }
};
</script>
