<template>
  <div>
    <div class="row">
      <div class="col-md-12" v-if="isSearching">
        Searching...
      </div>
      <div class="col-md-12" v-else>
        <div v-if="emails.length <= 0">No emails found</div>
        <div v-else class="table-responsive">
          <table
              class="table table-striped table-hover table-sm table-bordered"
          >
            <thead class="thead-light">
            <tr>
              <th class="th-sm" style="width: 90px;">
                <div class="d-flex content-between">
                  Date
                </div>
              </th>
              <th class="th-sm" style="width: 270px;">
                <div class="d-flex content-between">
                  Subject
                </div>
              </th>
              <th class="th-sm">
                <div class="d-flex content-between">
                  Body
                </div>
              </th>
              <th class="th-sm">Open</th>
            </tr>
            </thead>
            <tbody>
            <tr :key="email.id" v-for="email in emails">
              <td>
                <Timestamp :value="email.sentDateTime"/>
              </td>
              <td>{{ email.subject }}</td>
              <td>{{ email.bodyPreview }}</td>
              <td class="text-center">
                <a href="#" @click.prevent="selectEmail(email)" title="Open Email"><i
                    class="fa fa-envelope-open font-120P"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="nextDataLink">
          <button class="btn btn-primary mt-3" @click="loadMore">Load More</button>
        </div>
      </div>
    </div>

    <ModalComponent v-if="isOpen" @close="isOpen = false">
      <template v-slot:title>
        <div class="col-md-12">
          <p><strong class="font-120P">{{ selectedEmail.subject }}</strong></p>
        </div>
        <div class="col-md-6">
          <p><strong>Date</strong>:
            <Timestamp :value="selectedEmail.sentDateTime"/>
          </p>
          <p><strong>To</strong>:</p>
          <p>
          <span :key="index" v-for="(to, index) in selectedEmail.toRecipients">
            {{ to.emailAddress.name }} - [{{ to.emailAddress.address }}]
            <br>
          </span>
          </p>
        </div>
        <div class="col-md-6">
          <p><strong>From</strong>: {{ selectedEmail?.from?.emailAddress?.name }} -
            [{{ selectedEmail?.from?.emailAddress?.address }}]</p>
          <p><strong>CC</strong>:</p>
          <p>
          <span :key="index" v-for="(cc, index) in selectedEmail.ccRecipients">
            {{ cc.emailAddress.name }} - [{{ cc.emailAddress.address }}]
            <br>
          </span>
          </p>
        </div>
      </template>
      <div class="col-md-12" style="max-height: 550px; overflow: scroll;">
        <p v-html="selectedEmail.body.content"></p>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import {ref} from "vue";

import ModalComponent from "../../components/ModalComponent.vue";
import Timestamp from "../../components/Timestamp.vue";
import axios from "axios";

export default {
  components: {
    ModalComponent,
    Timestamp,
  },
  props: {
    queries: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const isOpen = ref(false);

    const emails = ref([]);
    const nextDataLink = ref(null);
    const isSearching = ref(false);

    const searchEmails = async (queries) => {
      queries.map(async (query) => {
        isSearching.value = true;
        const url = `${process.env.VUE_APP_O365_API_URL}/emails/?query=${query}`;
        const { data } = await $http.get(url);

        emails.value.push(...data.emails);
        emails.value = emails.value.sort((a, b) => {
          return new Date(b.sentDateTime) - new Date(a.sentDateTime);
        });

        // Set the next link for pagination
        nextDataLink.value = data.nextLink;

        isSearching.value = false;
      });
    };

    // Function to load more emails when "Load More" is clicked
    const loadMore = async () => {
      if (nextDataLink.value) {
        console.log(nextDataLink.value, "next Link value");
        isSearching.value = true;
        const token = await $http.get(`${process.env.VUE_APP_O365_API_URL}/oauth2/365-token`);
        console.log(token,'token');

        const data = await axios.get(nextDataLink.value, { headers: {"Authorization" : `Bearer ${token.data.token}`} });
        console.log(data.data, "new api data");
        console.log(data.data["value"],'email data');
        console.log(data.data["@odata.nextLink"],'nextlink data');

        emails.value.push(...data.data["value"]);

        emails.value = emails.value.sort((a, b) => {
          return new Date(b.sentDateTime) - new Date(a.sentDateTime);
        });

        nextDataLink.value = data.data["@odata.nextLink"] ? data.data["@odata.nextLink"] : null;

        isSearching.value = false;
      }
    };

    const selectedEmail = ref({});
    const selectEmail = (email) => {
      selectedEmail.value = email;
      isOpen.value = true;
    };

    // Filter unique queries and perform initial search
    const uniqueQueries = props.queries.filter((value, index, self) => self.indexOf(value) === index);
    searchEmails(uniqueQueries);

    return {
      selectedEmail,
      selectEmail,
      isOpen,
      emails,
      nextDataLink,
      isSearching,
      loadMore,
    };
  },
};
</script>
