<template>
  <div>
    <Accordion header="Data Filter" icon="fa fa-filter" :closed="closed">
      <div class="col-md-12">
        <div class="row" v-for="filter in filters" :key="filter.key">
          <div
            :class="`${item.grid}`"
            v-for="item in filter.filters"
            :key="item.key"
          >
            <component
              :is="getComponent(item)"
              v-bind="{ ...item }"
              @change="handleChange"
              :value="values[item.key]"
              :ref="item.key"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 text-right">
        <div class="form-group ">
           <button class="btn btn-small" @click="handleReset" type="button">
            <i class="fa fa-undo"></i>
          </button>
          &nbsp;&nbsp;
          <button class="btn btn-small" @click="handleSubmit" type="button">
            <i class="fa fa-filter"></i>
          </button>
        </div>
      </div>
    </Accordion>
  </div>
</template>
<script>
import Accordion from "../Accordion";
import map from "lodash-es/map";
import flatten from "lodash-es/flatten";
export default {
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    closed: {
      type: Boolean,
      default: () => true
    }
  },
  components: {
    Accordion
  },
  data() {
    return {
      values: {}
    };
  },
  methods: {
    getComponent(field) {
      return require(`./fields/${field.component}`).default;
    },
    handleSubmit() {
      this.$emit("submit", this.values);
    },
    handleReset() {
        this.$refs.created_from.reset()
        this.$refs.created_to.reset();
        this.$refs.owner.reset();
        this.$refs.stage.reset();

        this.$refs.closed_from?.reset();
        this.$refs.closed_to?.reset();

        this.values = {};
    },
    handleChange({ value, key }) {
      if (key && value) {
        this.values[key] = value;
      }
    }
  },
  mounted() {
    flatten(map(this.filters, "filters")).map(value => {
      this.values[value.key] = value.defaultValue ? value.defaultValue : null;
    });
  }
};
</script>
