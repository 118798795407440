<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <Tab ref="tabs" :tabs="tabs" defaultTab="opportunities" />
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "../../components/Tab";
export default {
  components: {
    Tab
  },
  computed: {
    tabs() {
      return [
        {
          name: "Opportunities",
          component: require("./components/Opportunity.vue").default,
          key: "opportunities"
        },
        {
          name: "Accounts",
          component: require("./components/Account.vue").default,
          key: "accounts"
        },
        {
          name: "Listings",
          component: require("./components/Listing.vue").default,
          key: "listings"
        },
        {
          name: "Pending Sign Request",
          component: require("./components/Pending.vue").default,
          key: "pending"
        }
      ];
    }
  },
  mounted() {
    this.$ui.setPageHeader({
      title: "CRM INSIGHTS",
      showTask: false,
      parentTitle: "crm insights"
    });

    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Crm insights",
        url: "insights"
      }
    ]);
  }
};
</script>
