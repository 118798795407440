<template>
  <div v-if="seller">
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-6">
        <p>
          <span
              v-if="seller"
              class="badge "
              :class="{
              'badge-customer': seller.stage?.name === 'Customer',
              'badge-Archived': seller.stage?.name === 'Archived',
              'badge-lead': seller.stage?.name === 'Lead',
              'badge-contact': seller.stage?.name === 'Contact',
              'badge-opportunity': seller.stage?.name === 'Opportunity'
            }"
          >{{ seller.stage?.name }}</span
          >
          &nbsp;&nbsp;
          <span
              v-if="seller"
              class="badge "
              :class="{
              'badge-inactive': seller.status === 'Inactive',
              'badge-active': seller.status === 'Active'
            }"
          >{{ seller.status }}</span
          >
        </p>
      </div>
      <div class="col-md-6 text-right">
        <span>
          <router-link
              class="btn btn-xsmall"
              :to="{name: 'accounts_show', params: { id: customer.identifier }, query: {enableAccountEdit: true}}"
          >
            <span class="font-120P">
              <i class="fa fa-edit"></i>
            </span>
          </router-link>
        </span>
      </div>
      <div class="gap-20"></div>
      <div class="col-md-3">
        <p class="listing-label">Name</p>
        <p>{{ customer?.contact.first_name }} {{ customer?.contact.last_name }}</p>
        <br />
      </div>
      <div class="col-md-3">
        <p class="listing-label">Role Within Business</p>
        <p>{{ customer?.contact.business_role ?? "N/A" }}</p>
        <br />
      </div>
      <div class="col-md-6">
        <p class="listing-label">Business Email</p>
        <p>
          <a :href="`mailto:${customer?.contact.email}`">{{ customer?.contact.email }}</a>
        </p>
        <br />
      </div>
      <div class="col-md-6">
        <p class="listing-label">Linked Address</p>
        <!--        <p>-->
        <!--          Level 3, Suite 234, 123 Abcdefg Drive <br />Qwerty, ACT, 2128-->
        <!--          <br />Australia-->
        <!--        </p>-->
        <p>N/A</p>
        <br />
      </div>
      <div class="col-md-6">
        <p class="listing-label">Best Contact Number</p>
        <p>
          <a href="tel:0143123123">{{ customer?.contact.phone_number }}</a>
        </p>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">LinkedIn Profile</p>
        <p v-if="customer.linkedin_profile">
          <a :href="customer.linkedin_profile" target="_blank">{{
              customer.linkedin_profile
            }}</a>
        </p>
        <p v-else>N/A</p>
        <br />
      </div>
      <div class="col-md-6">
        <p class="listing-label">Receive Newsletter</p>
        <p>{{ customer?.contact.newsletter ? "Yes" : "No" }}</p>
      </div>
      <div class="col-md-12">
        <p class="listing-label">Contact Note</p>
        <p>
          {{ customer?.contact.note }}
        </p>
      </div>
      <div class="col-md-12">
<!--        <div class="div-border-bottom"></div>-->
      </div>
    </div>
    <div class="gap-20"></div>
  </div>
</template>
<script>
export default {
  props: {
    customer: Object,
    seller: Object
  }
};
</script>
