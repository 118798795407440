<template>
  <img class="img-responsive" v-if="url" :src="imageData" :alt="alt" />
</template>

<script>
import { toDataURL } from "@/utils/image";

export default {
  props: {
    path: String,
    image: String,
    alt: String
  },
  data() {
    return {
      url: null,
      imageData: null
    };
  },
  async mounted() {
    let _this = this;
    if (!this.image) {
      return;
    }
    try {
      const url = await this.$http.get(
        `${process.env.VUE_APP_LISTINGS_API_URL}/admin/document-url/${this.image}`,
        {
          params: {
            path: this.path ? this.path : "information-memorandums/"
          }
        }
      );
      this.url = url;
    } catch (e) {
      console.log(e);
    }
    toDataURL(this.url, function(img) {
      _this.imageData = img;
    });
  }
};
</script>
