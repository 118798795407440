<template>
  <div class="entry-content">
    <div class="row">
      <div class="alert alert-success fade in" v-if="created">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The document has been created
      </div>
    </div>
    <div class="row" v-if="updated">
      <div class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The document's details have been updated
      </div>
      <!-- Alert success end -->
    </div>
    <div class="row" v-if="permissionUpdate">
      <div class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The document's permissions have been
        updated
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <p class="listing-label">Active Date</p>
        <p>{{ document.active_date }}</p>
      </div>
    </div>
    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Document Before</p>
        <p>
          {{
            document.previous && document.previous.description
              ? document.previous.description
              : "-"
          }}
        </p>
      </div>
    </div>
    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Document Short Description</p>
        <p>
          <strong class="font-120P">{{ document.description }}</strong
          >&nbsp;&nbsp;<span
            class="badge"
            :class="{
              'badge-active': document.active_status === 'Active',
              'badge-inactive': document.active_status === 'Inactive'
            }"
            >{{ document.active_status }}</span
          >
        </p>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Document After</p>
        <p>
          {{
            document.next && document.next.description
              ? document.next.description
              : "-"
          }}
        </p>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-2">
        <p class="listing-label">Document Link</p>
        <p>
          <a :href="document.file_url" target="_blank"
            ><img
              src="@/assets/images/rent_roll_broker_resources/resources_thumbs/pdf2.png"
              alt="Document Description"
          /></a>
        </p>
      </div>
    </div>
    <ModifiedSection
      :created="document.created_at"
      :updated="document.updated_at"
      model="Document"
      :model-id="document.id"
    />
  </div>
</template>

<script>
import ModifiedSection from "@/components/ModifiedSection";

export default {
  props: {
    created: Boolean,
    updated: Boolean,
    document: Object,
    permissionUpdate: Boolean
  },
  components: {
    ModifiedSection
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style></style>
