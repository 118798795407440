import moment from "moment";
import { find } from "lodash-es";
export const SET_IS_OPEN = "SET_IS_OPEN";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const TASK_CREATED = "TASK_CREATED";
export const RESET_FORM = "RESET_FORM";
export const SET_FORM_VALUES = "SET_FORM_VALUES";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_SELECTED_REFERENCE = "SET_SELECTED_REFERENCE";
export const SET_DISABLE_RELATED = "SET_DISABLE_RELATED";

const state = {
  isOpen: false,
  taskCreated: false,
  isLoading: false,
  selectedReference: false,
  disabledResourceSelected: false,
  initialValues: {
    title: "",
    owner_id: null,
    due_date: moment().format("YYYY-MM-DD HH:mm"),
    status: "OPEN",
    related_type: null,
    related_id: null,
    note: "",
    identifier: null,
    identifier_string: null
  },
  values: {
    title: "",
    owner_id: null,
    due_date: moment().format("YYYY-MM-DD HH:mm"),
    status: "OPEN",
    related_type: null,
    related_id: null,
    note: "",
    identifier: null,
    identifier_string: null
  },
  // prettier-ignore
  references: [
    { name: "General", value: "" },
    { name: "Project", value: "Project", url: `${process.env.VUE_APP_LISTINGS_API_URL}/admin/projects/search`, route: 'projects_show', id: true },
    { name: "Event", value: "Event" },
    { name: "Listing", value: "List", url: `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings/search`, route: 'listings_show' },
    { name: "Information Memorandum", value: "InformationMemorandum", url: `${process.env.VUE_APP_LISTINGS_API_URL}/admin/information-memorandums/search`, route: 'im_show' },
    { name: "Account", value: "Customer", url: `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/search`, route: 'accounts_show' },
    { name: "Opportunity", value: "Opportunity", url: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/search`, route: 'opportunities_show' },
    { name: "News & Advice", value: "Article", url: `${process.env.VUE_APP_NEWS_API_URL}/admin/articles/search`, route: 'news_show' },
    { name: "PDF Document", value: "Document", url: `${process.env.VUE_APP_DOCUMENTS_API_URL}/admin/documents/search`, route: 'documents_show' },
    { name: "Affiliate", value: "Affiliate", url: `${process.env.VUE_APP_AFFILIATES_API_URL}/admin/affiliates/search`, route: 'affiliates_show' },
    { name: "Admin User", value: "User", url: `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/search`, route: 'admin_users_show' },
    { name: "Admin Business", value: "Business", url: `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses/search`, route: 'businesses_show' },
    { name: "Master Data", value: "MasterData", route: 'master_data' }
  ]
};

// getters
const getters = {
  isOpen: state => {
    return state.isOpen;
  },
  values: state => {
    const data = { ...state.initialValues, ...state.values };
    return {
      ...data,
      due_time: data.due_date ? moment(data.due_date).format("HH:mm") : null
    };
  },
  selectedReference: state => {
    return state.selectedReference;
  },
  references: state => {
    return state.references;
  },
  disabledResourceSelected: state => {
    return state.disabledResourceSelected;
  },
  isLoading: state => {
    return state.isLoading;
  }
};

// actions
const actions = {
  setIsOpen({ commit }, isOpen) {
    commit(SET_IS_OPEN, isOpen);
  },
  setTaskSuccess({ commit }) {
    commit(TASK_CREATED, true);
    setTimeout(() => {
      commit(TASK_CREATED, false);
    }, 5000);
  },
  async setInitialValues({ commit }, { task }) {
    commit(SET_IS_LOADING, true);
    try {
      const { data } = await this.$http.get(
        `${process.env.VUE_APP_TASKS_API_URL}/admin/tasks/${task}`
      );
      const relatedModel = data.related_type
        ? data.related_type.replace("App\\", "")
        : null;
      commit(
        SET_SELECTED_REFERENCE,
        find(state.references, {
          value: relatedModel
        })
      );
      commit(SET_DISABLE_RELATED, true);

      commit("SET_FORM_VALUES", {
        title: data.title,
        owner_id: data.owner_id,
        due_date: moment(data.due_date).format("YYYY-MM-DD HH:mm"),
        status: data.status,
        related_type: data.related_type
          ? data.related_type.replace("App\\", "")
          : null,
        related_id: data.related_id,
        identifier: data.id,
        identifier_string: data.identifier,
        opportunity_name: data.opportunity?.opportunity_name
      });
    } catch (e) {
      console.log(e, "e");
    }
    commit(SET_IS_LOADING, false);
  }
};

// mutations
const mutations = {
  [SET_IS_OPEN]: (state, isOpen) => {
    state.isOpen = isOpen;
  },
  [SET_IS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  [TASK_CREATED]: (state, isOpen) => {
    state.taskCreated = isOpen;
  },
  [RESET_FORM]: state => {
    state.values = state.initialValues;
    state.selectedReference = null;
    state.disabledResourceSelected = false;
  },
  [SET_FORM_VALUES]: (state, values) => {
    state.values = values;
  },
  [SET_SELECTED_REFERENCE]: (state, reference) => {
    state.selectedReference = reference;
    console.log(reference);
  },
  [SET_DISABLE_RELATED]: (state, disable) => {
    state.disabledResourceSelected = disable;
  },
  [UPDATE_FIELD]: (state, { name, value }) => {
    state.values = {
      ...state.values,
      [name]: value
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
