<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              :class="{ active: activeTap === 'im' }"
              @click="activeTap = 'im'"
            >
              <a id="user_details_tab_link" data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Add</h4></a
              >
            </li>
          </ul>
          <form id="form_1">
            <div class="error-container"></div>
            <div class="panel-body">
              <div class="tab-content">
                <div
                  id="tab-01"
                  class="tab-pane"
                  :class="{ active: activeTap === 'im' }"
                >
                  <div class="col-md-12" v-if="!isLoading">
                    <Form
                      :key="blogs.length"
                      :form-data="formData"
                      :errors="errors"
                      @submit="handleSubmit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./Form";
import Permission from "./Permission";
import { mapActions, mapGetters } from "vuex";
import get from "lodash-es/get";

export default {
  components: {
    Form,
    Permission
  },
  data() {
    return {
      formData: {
        permissions: {
          admin_access: {
            site_access: true,
            admin_approved: true
          }
        },
        listing: {
          id: this.$route.params.id ? this.$route.params.id : null
        },
        sections: this.blogs
      },
      activeTap: "im",
      isLoading: false,
      errors: {}
    };
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    ...mapActions("masterData", ["loadIMData"]),
    async handleSubmit(values) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.post(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/information-memorandums`,
          values
        );
        await this.loadStats();
        this.$router.push({
          name: "im_show",
          params: { id: data.id }
        });
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
        this.activeTap = "im";
      }
    },
    handleChangeTap(values) {
      this.activeTap = "permission";
      this.formData = { ...values };
    }
  },
  async mounted() {
    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "I M Administration",
        url: "im"
      },
      {
        name: "I M Details",
        url: "im_create",
        active: true
      }
    ]);
    this.isLoading = true;
    await this.loadIMData(true);
    this.isLoading = false;
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  },
  computed: {
    ...mapGetters({
      blogs: "masterData/imValues"
    })
  }
};
</script>

<style></style>
