<template>
  <div class="col-md-12">
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row">
          <p class="font-120P">Linked Project Contacts</p>
        </div>
      </div>
    </div>
    <!--End label-->
    <div>

      <div v-for="contact in projectContact" :key="contact.id">
        <Accordion :header="`${contact.full_name}`" icon="fa fa-address-card" closed class="m-top-20">
          <!--Start project contact detail-->
          <ProjectContactDetail :customer="contact" :seller="contact"/>

          <!--Start project contact business detail-->
          <p>
            <strong>Business Details</strong>
          </p>
          <div class="gap-20"></div>
          <ProjectContactBusinessDetail :customer="contact" seller=""/>
          <div class="row">
            <div class="col-md-12">
              <div class="div-border-bottom"></div>
            </div>
          </div>
        </Accordion>
      </div>

    </div>
    <!--End Accordion-->
    <div class="gap-40"></div>
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row">
          <p class="font-120P">Link a Contact</p>
        </div>
      </div>
      <div class="gap-20"></div>
      <div class="col-md-12">
        <div class="row">
          <div class="form-group" style="margin-bottom: 0">
            <Select
                skip
                label="Select Account"
                placeholder="Please select"
                identifier="identifier"
                :url="projectContactUrl"
                :axios="$http"
                @selected="customerDetail = $event"
            />
          </div>

        </div>
      </div>
      <!--End select account label-->
    </div>
    <!--End label-->

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <SubmitButton
              class="white-space-2"
              icon="fa fa-plus-circle"
              :isProgress="isLoading"
              :caption="isLoading ? 'Linking' : 'Link Account'"
              @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "../../../components/Accordion";
import SubmitButton from "@/components/SubmitButton";
import Select from "@/components/Form/Select";
import ProjectContactBusinessDetail from "../../../components/ProjectContact/Business/Detail";
import ProjectContactDetail from "../../../components/ProjectContact/Detail";
import {get} from "lodash-es";

export default {
  props: {},
  components: {
    Accordion,
    SubmitButton,
    Select,
    ProjectContactBusinessDetail,
    ProjectContactDetail
  },
  data() {
    return {
      customerDetail: {},
      projectContact: null,
      isLoading: false,
      error: {}
    }
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true;
      let data;
      try {
        data = await this.$http.post(
            `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/project-contacts`, {
              customer_uuid: this.customerDetail.identifier,
              project_uuid: this.$route.params.id
            }
        )
        await this.getProjectContact();
        this.customerDetail = {};
        this.isLoading = false;
        return data;
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async getProjectContact() {
      this.isLoading = true;
      try {
        let data = await this.$http.get(
            `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/project-contacts/${this.$route.params.id}`
        );
        this.projectContact = data;
      } catch (e) {
        this.error = get(e, "data.error");
      }
      this.isLoading = false;
    }
  },
   async mounted() {
    // this.projectContact = await this.$http.get(`${process.env.VUE_APP_CUSTOMER_API_URL}/admin/project-contacts/${this.$route.params.id}`);
     await this.getProjectContact();
  },
  computed: {
    projectContactUrl() {
      return `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/project-contacts/${this.$route.params.id}/search`
    }
  }
}
</script>
