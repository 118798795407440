<template>
  <div class="col-md-12">
    <button
      class="admin-accordion admin-accordion-open"
      type="button"
      @click="toggleAccordion"
    >
      <i class="fa fa-address-card"></i>&nbsp;&nbsp;Additional Addresses
    </button>
    <div :class="accordionClasses">
      <form
        :key="address.id"
        v-for="address in addresses"
        :id="address.id"
        action=""
      >
        <div class="gap-40"></div>
        <div class="row">
          <Address
            :stateData="stateData"
            :countryData="countryData"
            :addressData="address"
            @set-primary="handleSetPrimary"
            @save-address="handleSave"
          />
          <div v-if="address?.id !== lastAddress?.id" class="col-md-12">
            <div class="div-border-bottom"></div>
          </div>
        </div>
      </form>
      <div class="col-md-12">
        <div class="div-border-bottom"></div>
      </div>
      <form id="new_address" action="">
        <div class="gap-40"></div>

        <Address
          :stateData="stateData"
          :countryData="countryData"
          :addressData="newAddress"
          @save-address="handleAdd"
        />
      </form>
    </div>
  </div>
</template>

<script>
import Address from "@/components/Form/Address";

export default {
  props: {
    stateData: {
      type: Object,
      default: () => {}
    },
    countryData: {
      type: Object,
      default: () => {}
    },
    addressData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      addresses: {
        ...this.addressData
      },
      isOpen: false,
      newAddress: {
        state: {},
        country: {}
      }
    };
  },
  components: {
    Address
  },
  computed: {
    lastAddress() {
      return this.addressData[this.addressData.length - 1];
    },
    accordionClasses() {
      return {
        "admin-panel-closed": !this.isOpen,
        "admin-panel": this.isOpen
      };
    },
    states() {
      return this.stateData;
    },
    countries() {
      return this.countryData;
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    handleSave(address) {
      this.$emit("saveAddress", address);
    },
    handleSetPrimary(address) {
      this.$emit("setPrimary", address);
    },
    handleAdd(address) {
      this.$emit("addAddress", address);
    }
  }
};
</script>

<style scoped></style>
