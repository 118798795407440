<template>
  <div>
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row">
          <p class="font-120P">Pending Sign Requests</p>
        </div>
      </div>
    </div>
    <!-- Heading end -->

    <!-- Task details start -->
    <div class="entry-content">
      <div class="gap-20"></div>
      <ResourceTable :columns="columns" :url="url" remove-actions />
    </div>
    <!-- Task details end -->
  </div>
</template>

<script>
import ResourceTable from "@/components/ResourceTable";

export default {
  components: {
    ResourceTable
  },
  data() {
    return {
      columns: [
        {
          name: "created_date",
          text: "Date",
          timestamp: item => item.created_date
        },
        {
          name: "customer",
          text: "Customer",
          render: item => item.customer
        },
        {
          name: "reference",
          text: "Reference",
          render: item => item.reference
        },
        {
          name: "pending",
          text: "Pending",
          render: item => item.pending,
          sort_disable: true
        }
      ],
      url: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/pending-requests`
    };
  }
};
</script>

<style></style>
