<template>
  <div class="entry-content">
    <div class="row" v-if="created">
      <div class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />{{ user.first_name }}'s account has been
        created
      </div>
      <!-- Alert success end -->
    </div>
    <div class="row" v-if="updated">
      <div id="alert-mail-success" class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />{{ user.first_name }}&nbsp;{{
          user.last_name
        }}'s details have been updated
      </div>
      <!-- Alert success end -->
    </div>
    <div class="row" v-if="profileUpdated">
      <div id="alert-mail-success" class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />{{ user.first_name }}&nbsp;{{
          user.last_name
        }}'s website profile details have been updated
      </div>
      <!-- Alert success end -->
    </div>
    <div class="row" v-if="permissionUpdate">
      <div id="alert-mail-success" class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />{{ user.first_name }}&nbsp;{{
          user.last_name
        }}'s permissions have been updated
      </div>
      <!-- Alert success end -->
    </div>
    <div class="row">
      <div class="col-md-10">
        <p>
          <strong class="font-120P"
            >{{ user.first_name }} {{ user.last_name }}</strong
          >&nbsp;&nbsp;<span
            class="badge badge-active"
            v-if="user.status === 'Active'"
            >{{ user.status }}</span
          >
          <span class="badge badge-inactive" v-if="user.status === 'Inactive'"
            >Inactive</span
          >
        </p>
        <br />
      </div>
      <div class="col-md-2">
        <div class="form-group text-right">
          <span class="badge badge-active" v-if="user.attributes.is_agent"
            >Agent</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <p class="listing-label">Role Within Business</p>
        <p>{{ user.business_role }}</p>
        <br />
      </div>

      <div class="col-md-4">
        <p class="listing-label">Legal Business Name</p>
        <p>{{ user.business?.name }}</p>
        <br />
      </div>

      <div class="col-md-4">
        <p class="listing-label">Business ABN / ACN</p>
        <p>{{ user.business?.abn }}</p>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <p class="listing-label">Business Email / Username</p>
        <p>
          <a :href="`mailto:${user.email}`">{{ user.email }}</a>
        </p>
        <br />
      </div>

      <div class="col-md-4">
        <p class="listing-label">Phone (mobile)</p>
        <p>
          <a :href="`tel:${user.contact_phone}`">{{ user.contact_phone }}</a>
        </p>
        <br />
      </div>

      <div class="col-md-4">
        <p class="listing-label">Business Phone (land-line)</p>
        <p>
          <a :href="`tel:${user.business?.phone}`">{{
            user.business?.phone
          }}</a>
        </p>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <p class="listing-label">Linked Address</p>
        <p>
          {{ user.business?.street_address }} <br />{{ user.business?.suburb }},
          {{ user.business?.state_code }}, {{ user.business?.postcode }}
          <br />{{ user.business?.country }}
        </p>
        <br />
      </div>

      <div class="col-md-4">
        <p class="listing-label">Business Website</p>
        <p>
          <a :href="`https://${user.business?.website}`" target="_blank"
            >https://{{ user.business?.website }}</a
          >
        </p>
        <br />
      </div>
      <div class="col-md-4">
        <p class="listing-label">LinkedIn Profile</p>
        <p>
          <a :href="`https://${user.linkedin_profile}`" target="_blank"
            >https://{{ user.linkedin_profile }}</a
          >
        </p>
        <br />
      </div>
    </div>
    <ModifiedSection
      :created="user.created_at"
      :updated="updatedAt"
      model="User"
      :model-id="user.id"
    />
  </div>
</template>

<script>
import ModifiedSection from "@/components/ModifiedSection";
export default {
  props: {
    created: Boolean,
    updated: Boolean,
    user: Object,
    permissionUpdate: Boolean,
    profileUpdate: Boolean,
    profileUpdated: Boolean
  },
  components: {
    ModifiedSection
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {
    updatedAt: {
      get() {
        if (this.user === null) return null;

        if (this.user.profile.updated_at === null) return this.user.updated_at;

        return this.user.profile.updated_at > this.user.updated_at
          ? this.user.profile.updated_at
          : this.user.updated_at;
      }
    }
  }
};
</script>

<style></style>
