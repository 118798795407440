<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Headings</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="heading-large">Heading (large) *</label>
          <input
            class="form-control"
            id="heading-large"
            name="heading-large"
            placeholder="Heading (large)"
            type="text"
            v-model="values.headingLarge"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="heading-small">Heading (small)</label>
          <input
            class="form-control"
            id="heading-small"
            name="heading-small"
            placeholder="Heading (small)"
            type="text"
            v-model="values.headingSmall"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headingSmall: String,
    headingLarge: String
  },
  data() {
    return {
      values: {
        headingSmall: this.headingSmall,
        headingLarge: this.headingLarge
      }
    };
  },
  created() {},
  mounted() {},
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          return;
        }
        this.$emit("valueChange", this.values);
      }
    }
  }
};
</script>
