export default [
  {
    name: "date",
    text: "Created",
    render: item => item.created_at
  },
  {
    name: "opportunity_name",
    text: "Opp Name",
    render: item => `${item.listing?.reference} - ${item.opportunity_name}`
  },
  {
    name: "account",
    text: "Account",
    render: item => `${item.customer?.first_name} ${item.customer?.last_name}`
  },
  {
    name: "state",
    text: "State",
    render: item => `${item.customer?.state?.name || "N/A"}`
  },
  {
    name: "amount",
    text: "Amount",
    render: item => (item.opportunity_amount ? item.opportunity_amount : 0)?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  },
  {
    name: "owner",
    text: "Owner",
    render: item =>
      `${item.owner?.first_name || ""} ${item.owner?.last_name || ""}`
  },
  {
    name: "Stage",
    text: "Stage",
    className: item =>
        item.status === "Archived" ? "font-orange" : "",
    render: item => item.process_stage
  },
  {
    name: "closing_date",
    text: "Closing Date",
    render: item => item.closing_date
  }
];
