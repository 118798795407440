<template>
  <section id="main-container" class="main-container">
    <div class="text-center"><div class="loading"></div></div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    handleRedirectCognito() {
      const url = `${process.env.VUE_APP_GS_AUTH_URL}/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_GS_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_GS_REDIRECT_URL}`;
      window.location.replace(url);
    }
  },
  mounted() {
    this.handleRedirectCognito();
  }
};
</script>

<style></style>
