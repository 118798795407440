<template>
  <div class="row">
    <div class="col-md-12">
      <p v-html="text_line_1"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text_line_1: String
  }
};
</script>
