<template>
  <div class="entry-content" v-if="account">
    <div class="col-md-12">
      <div class="row" v-if="created">
        <div class="col-md-12">
          <Alert
            type="alert-success"
            main-message="Success!"
            :message="
              `${account?.contact?.last_name}'s account has been created`
            "
          />
        </div>
      </div>
      <div class="row" v-if="updated">
        <div class="col-md-12">
          <Alert
            type="alert-success"
            main-message="Success!"
            :message="
              `${account?.contact?.last_name}'s details have been updated`
            "
          />
        </div>
      </div>

      <div class="row" v-if="permissionChanged">
        <div class="col-md-12">
          <Alert
            type="alert-success"
            main-message="Success!"
            :message="
              `${account?.contact?.last_name}'s permissions have been updated`
            "
          />
        </div>
      </div>
      <div class="row" v-if="passwordUpdated">
        <div class="col-md-12">
          <Alert
            type="alert-success"
            main-message="Success!"
            :message="
              `A new password has been sent to ${account?.contact?.last_name}`
            "
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <p><strong class="font-120P">Account Definition</strong></p>
          <br />
        </div>
      </div>

      <div class="gap-20"></div>
      <div class="row" v-if="accountStages && account?.stage_id">
        <Status
          :statuses="accountStages"
          :selected="`${account.stage_id}`"
          @clicked="handleClicked"
        />
      </div>
      <div class="gap-20"></div>
      <div class="row">
        <div class="col-md-3">
          <p class="listing-label">Registered as an</p>
          <p>{{ account?.company?.name }}</p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Category</p>
          <p>{{ account?.category }}</p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Buyer</p>
          <p>{{ account?.registered_buyer }}</p>
          <br />
        </div>
        <div class="col-md-3">
          <p class="listing-label">Seller</p>
          <p>{{ account?.registered_seller }}</p>
          <br />
        </div>
      </div>

      <Single
        title="Primary Contact"
        :business="account.business"
        :contact="account.contact"
        v-if="account.contact"
        :status="account.status"
      />
      <div class="row">
        <Accordion header="Additional Contacts" icon="fa fa-user-plus" closed>
          <div class="gap-40"></div>
          <div v-for="contact in account.contacts" :key="contact.id">
            <ContactDetail :contact="contact" />
            <div class="gap-40"></div>
          </div>
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Business Details" icon="fa fa-address-card" closed>
          <div class="gap-40"></div>
          <BusinessDetail :business="account?.primaryBusiness" />
          <div class="row">
            <div class="col-md-12">
              <div class="div-border-bottom"></div>
            </div>
          </div>
          <div class="gap-40"></div>
          <div
            v-for="address in account?.primaryBusiness?.addresses"
            :key="address.id"
          >
            <BusinessAddressDetail
              :address="address"
              :primary="account?.primaryBusiness?.primary_address_id"
            />
            <div class="gap-40"></div>
          </div>
        </Accordion>
      </div>

      <div class="gap-40"></div>
      <div class="row" v-if="account.registered_buyer === 'Yes'">
        <Accordion
          header="Rent Roll / Agency Preferences (for buyers)"
          icon="fa fa-building"
          closed
        >
          <AgencyPreference :account="account" />
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row" v-if="account.registered_seller === 'Yes'">
        <Accordion header="Rent Rolls For Sale" icon="fa fa-gavel" closed>
          <ListingReference :account="account" />
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <Accordion header="Linked Projects" icon="fa fa-modx" closed>
          <Projects :account="account" />
        </Accordion>
      </div>
      <div class="gap-40"></div>
      <div class="row">
        <ModifiedSection
      :created="account.created_at"
      :updated="
        account.updated_at > account.business?.updated_at
          ? account.updated_at
          : account.business?.updated_at
      "
      model="Customer"
      :model-id="account.id"
    />
        <ConfirmationModal ref="refConfirmationModal"
                           message="Are you sure you want to archive this artifact ?"
                           @declined="$refs.refConfirmationModal.toggleModal(false)"
                           @confirmed="handleClicked(1005,false)"
                           />
      </div>
    </div>
  </div>
</template>
<script>
import Alert from "../../../components/Alert";
import AgencyPreference from "../components/Details/AgencyPreference";
import ListingReference from "../components/Details/ListingReference";
import Projects from "../components/Details/Projects";
import Accordion from "../../../components/Accordion";
import Status from "../../../components/Status";
import Single from "../../../components/Contact/Single";
import ContactDetail from "../../../components/Contact/View";
import BusinessDetail from "../../../components/Business/Detail";
import BusinessAddressDetail from "../../../components/Business/Address/Detail";
import ModifiedSection from "@/components/ModifiedSection";
import ConfirmationModal from "../../../components/ConfirmationModal";

export default {
  components: {
    Alert,
    Status,
    Single,
    ContactDetail,
    Accordion,
    BusinessDetail,
    BusinessAddressDetail,
    AgencyPreference,
    ListingReference,
    Projects,
    ModifiedSection,
    ConfirmationModal
  },
  props: {
    account: Object,
    accountStages: Object,
    created: Boolean,
    passwordUpdated: Boolean,
    permissionChanged: Boolean,
    updated: Boolean
  },
  methods: {
    async handleClicked(selected,byPass = true) {

      if(selected === "1005" && byPass){
        this.$refs.refConfirmationModal.toggleModal(true)
        return
      }

      if(!byPass){
        this.$refs.refConfirmationModal.toggleModal(false)
      }
      try {
        await this.$http.patch(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${this.account.id}`,
          {
            stage_id: selected
          }
        );
        this.$emit("save", "account");
      } catch {
        //
      }
    }
  }
};
</script>
