<template>
  <div class="col-md-12">
    <div class="panel-body">
      <div class="tab-content">
        <div id="tab-01" class="tab-pane active">
          <div class="col-md-12">
            <div class="row">
              <Permission
                  @update="handleChangePermission"
                  :default-permissions="defaultPermissions"
                  :values="values"
                  header="Permissions"
                  :create="!update"
                  @save="handleUpdatePermission"
              />
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="project_stage">Project Stage *</label>
                  <select
                      class="form-control"
                      id="status"
                      name="status"
                      @change="changeStatus($event)"
                  >
                    <option value="" disabled selected hidden>Please Select</option>
                    <option value="NEW">New Project</option>
                    <option value="IN_PROGRESS">In Progress</option>
                    <option value="COMPLETE">Complete</option>
                    <option value="ARCHIVED">Archived</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <div class="form-group">
                  <label for="project_name">Project Name *</label>
                  <input
                      class="form-control"
                      id="name"
                      name="name"
                      placeholder="Project name"
                      type="text"
                      required
                      v-model="values.name"
                      :class="
                      `form-control ${
                        errors.hasOwnProperty('name') ? 'has-error' : ''
                      }`
                    "
                  />
                  <span
                      v-if="errors.hasOwnProperty('name')"
                      class="help-block text-danger"
                  >{{ errors.name[0] }}</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <Select
                      name="owner"
                      label="Project Owner *"
                      :value="values.owner_id"
                      :class-name="
                      `${errors.hasOwnProperty('owner_id') ? 'has-error' : ''}`
                    "
                      :error="
                      errors.hasOwnProperty('owner_id')
                        ? errors.owner_id[0]
                        : ''
                    "
                      required
                      placeholder="Select a owner"
                      :url="userSearch"
                      :axios="$http"
                      @selected="
                      value =>
                        handleDropdownChange(
                          value ? value.id : null,
                          'owner_id'
                        )
                    "
                      showother
                  />
                </div>
              </div>
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <Select
                      name="listing_id"
                      label="Listing Reference"
                      :value="values.listing_id"
                      :class-name="
                      `${
                        errors.hasOwnProperty('listing_id') ? 'has-error' : ''
                      }`
                    "
                      :error="
                      errors.hasOwnProperty( 'listing_id')
                        ? ( errors.listing_id[0])
                        : ''
                    "
                      required
                      placeholder="Please Select"
                      :url="listSearch"
                      :axios="$http"
                      @selected="
                      value =>
                        handleDropdownChange(
                          value ? value.id : null,
                          'listing_id'
                        )
                    "
                      showother
                  />
                </div>
              </div>
            </div>

            <div class="gap-20"></div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="project_description">Project Description</label>
                  <textarea
                      class="form-control form-control-message"
                      name="description"
                      id="description"
                      v-model="values.description"
                      placeholder="Project description"
                      rows="3"
                      :class="
                      `form-control ${
                        errors.hasOwnProperty('description') ? 'has-error' : ''
                      }`
                    "
                  ></textarea>
                  <span
                      v-if="errors.hasOwnProperty('description')"
                      class="help-block text-danger"
                  >{{ errors.description[0] }}</span
                  >
                </div>
              </div>
            </div>

            <div class="gap-40"></div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <SubmitButton
                      v-if="updateAvailable"
                      :isProgress="isLoading"
                      :caption="buttonName"
                      @submit="handleSubmit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Permission from "../../components/Permission";
import Select from "@/components/Form/Select";
import SubmitButton from "@/components/SubmitButton";
import {mapGetters} from "vuex";
import map from "lodash-es/map";

export default {
  props: {
    formData: {
      type: Object,
      default: () => {
      }
    },
    isLoading: {
      type: Boolean,
      default: () => false
    },
    errors: {
      type: Object,
      default: () => {
      }
    },
    update: {
      type: Boolean,
      default: () => false
    },
    submitting: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    Permission,
    Select,
    SubmitButton
  },
  data() {
    return {
      values: this.formData
    };
  },
  methods: {
    handleDropdownChange(value, param) {
      this.values[param] = value;
    },
    handleChangePermission(values) {
      this.values.permissions = values;
    },
    async handleUpdatePermission(values) {
      try {
        await this.$http.post(
            `${process.env.VUE_APP_LISTINGS_API_URL}/admin/projects/${this.formData.id}`,
            {
              ...this.formData,
              permissions: values
            }
        );
        this.$emit("save", "permission");
      } catch (e) {
        console.log(e);
      }
    },
    changeStatus(e){
      this.values.status = e.target.value
    },
    handleSubmit() {
      this.$emit("submit", {
        ...this.values,
        permissions: this.values.permissions
            ? this.values.permissions
            : {
              admin_access: {
                admin_approved: false
              }
            }
      });
    }
  },
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions"
    }),
    userSearch() {
      return `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/select`;
    },
    listSearch() {
      return `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings/search`;
    },
    buttonName() {
      if (this.update) {
        if (this.isLoading) {
          return "Updating";
        } else {
          return "Update Project";
        }
      } else {
        if (this.isLoading) {
          return "Creating";
        } else {
          return "Create Project";
        }
      }
    },
    defaultPermissions() {
      let permissions = [];
      map(this.permissionMeta.module_permission.project.permissions, group => {
        map(group.permissions, permission => {
          permissions.push({
            group: group.group,
            name: permission.caption,
            permission: permission.title,
            value: permission.name
          });
        });
      });

      return permissions;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
          ? this.permissions.permissions.includes(0)
          : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
          ? this.permissions.permissions.includes(1)
          : false;
    },
  }
};
</script>

<style></style>
