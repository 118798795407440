<template>
  <div
      id="banner-area"
      class="banner-area"
      :style="{
      backgroundImage:
        'url(' + require('@/assets/images/rent_roll_broker/privacy.jpg') + ')'
    }"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="banner-heading">
              <h1 class="banner-title">PRIVACY STATEMENT</h1>
              <ol class="breadcrumb">
                <li class="font-white"><a href="/">Home</a></li>
                <li>Privacy Statement</li>
              </ol>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->

  <section id="main-container" class="main-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          This Privacy Statement applies to this website which is operated by
          J.M.L.A Holdings Pty Ltd (“JMLA”), ACN 007 885 350, under the domain
          name www.the rentrollbroker.com.au ("this Website").
          <br /><br />
          In this Privacy Statement, “we”, “us” and “our” means JMLA.
          <br /><br />
          <h3 class="column-title">Your personal information</h3>
          Personal information about you is only collected by us when you
          voluntarily and knowingly provide it to us.
          <br /><br />
          The personal data about you which this Website may collect includes:
          <br /><br />
          <ul>
            <li>
              information you submit via this Website in order to receive
              information from us;
            </li>
            <li>
              information you submit via this Website in order to utilise our
              services;
            </li>
            <li>
              information you submit via this Website to participate in online
              questionnaires, forums, surveys or competitions.
            </li>
          </ul>
          <br />
          You may browse some information-only areas of this Website without
          providing any personal data, however we may not be able to provide the
          full range of services through this Website if certain information is
          not provided to us.
          <br /><br />
          We may also collect aggregated information which tells us about
          visitors to this Website but not the identity of those visitors. For
          instance, we may collect information about the date, time and duration
          of visits and which pages of this Website are most commonly accessed.
          This type of information may be used by us for administrative purposes
          and/or to help improve this Website.
          <br /><br /><br />
          <h3 class="column-title">Cookies</h3>
          We may use cookies on this Website. A cookie is a small text file that
          this Website may place on your computer. Usually, cookies are used as
          a means for this Website to remember your preferences. As such,
          cookies are designed to improve your experience when using and
          accessing this Website. Most internet browsers are pre-set to accept
          cookies, however you can adjust your internet browser to disable
          cookies or to warn you when cookies are being used. If you disable
          cookies however, you may not be able to access certain areas of this
          Website.
          <br /><br /><br />
          <h3 class="column-title">
            Use and disclosure of information collected
          </h3>
          Personal data about you is used by us only for the purpose for which
          it is submitted unless we disclose other uses in this Privacy
          Statement or at the time of collection. We will not send, pass on or
          sell your personal data to any third parties unless you have
          authorised us to do so.
          <br /><br />
          We may also disclose your personal data to government and regulatory
          authorities and other organisations as required or authorised by law.
          <br /><br />
          We use and disclose aggregated non-personally identifying information
          collected through this Website as part of our process of constantly
          improving this Website.
          <br /><br /><br />
          <h3 class="column-title">Links to other websites</h3>
          This Website may contain links to the websites of other organisations
          which may be of interest to you (“Linked Websites”). Linked websites
          are responsible for their own privacy practices and you should always
          check those websites for their respective privacy statements.
          <br /><br /><br />
          <h3 class="column-title">Security</h3>
          We use a number of mechanisms to protect the security and integrity of
          personal data. Unfortunately, no data transmission over the internet
          can be guaranteed as completely secure. So whilst we strive to protect
          such information, we do not warrant or guarantee the security of any
          information transmitted to us and you do so at your own risk.
          <br /><br />
          Once any personal data comes into our possession, we will take
          reasonable steps to protect that information from misuse and loss and
          from unauthorised access, modification or disclosure.
          <br /><br />
          A username and password may be essential for you to use some sections
          of this Website. For your own protection, we require you to keep such
          information secure and confidential at all times.
          <br /><br /><br />
          <h3 class="column-title">Access to information</h3>
          In most circumstances, you have a right to access any personal data we
          collect and hold about you. In addition, you are generally entitled to
          seek or require amendment to such information if it is no longer
          accurate and/or correct.
          <br /><br />
          If you wish to request access to any personal data which we hold about
          you, please refer to the "How to contact us" section below.
          <br /><br /><br />
          <h3 class="column-title">How to contact us</h3>
          If you wish to:
          <br /><br />
          <ul>
            <li>ask questions about this Privacy Statement; or</li>
            <li>
              make a request for access to any personal information that we may
              hold about you;
            </li>
            <li>
              to amend or update personal information we may hold about you;
            </li>
            <li>
              make a complaint about the way we have handled your personal
              information
            </li>
          </ul>
          <br />
          please contact our Privacy Officer:
          <br /><br />
          <strong>Phone</strong>
          <br />
          <a href="call:+61882615724">+61 8 8261 5724</a>
          <br /><br />
          <strong>Email</strong>
          <br />
          <a href="mailto:admin@therentrollbroker.com.au"
          >admin@therentrollbroker.com.au</a
          >
          <br /><br />
          <strong>Post</strong>
          <br />
          P.O. Box 447
          <br />
          STEPNEY, SA 5069
          <br /><br /><br />
          <h3 class="column-title">Further information</h3>
          Further information on privacy is available at the website of the
          Office of the Federal Privacy Commissioner at:
          <br /><br />
          <a href="https://www.privacy.gov.au">www.privacy.gov.au</a>
        </div>
        <!-- Col end -->
      </div>
      <!-- Content row end -->
    </div>
    <!-- Container end -->
  </section>
  <!-- Main container end -->
</template>

<script>
export default {};
</script>

<style></style>
