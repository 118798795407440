<template>
  <Accordion :header="header" :closed="!create">
    <form id="form_2" action="" method="post" role="form">
      <div class="error-container"></div>
      <div class="row">
        <div class="col-md-11">
          <div class="row">
            <div
                class="col-md-4"
                :key="index"
                v-for="(permission, index) in defaultPermissions"
            >
              <p class="listing-label">{{ permission.name }}</p>
              <div class="form-group font-80P">
                <label
                ><input
                    class="form-check-input"
                    :id="permission.value"
                    :name="permission.value"
                    type="checkbox"
                    :checked="
                    permissions[permission.group]
                      ? permissions[permission.group][permission.value]
                      : false
                  "
                    :value="permission.value"
                    @change="$event => handleChangeUpdate($event, permission)"
                />&nbsp;&nbsp; {{ permission.permission }}</label
                >
              </div>
            </div>
            <div
                class="col-md-4"
                v-if="onSiteLabel"
            >
              <p class="listing-label">{{onSiteLabel}}</p>
              <div class="form-group font-80P" :key="key">
                <label
                    v-for="(status, index) in showOnSiteStatus"
                    :key="index"
                    class="pr-4"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :checked="isShowOnSiteChecked(status)"
                    :value="status"
                    @click="$emit('updateShowOnSiteStatus',status)"

                />&nbsp;&nbsp; {{status}}</label
                >
              </div>
            </div>
            <div
                class="col-md-4"
                v-if="archiveLabel"
            >
              <p class="listing-label">{{archiveLabel}}</p>
              <div class="form-group font-80P" :key="key">
                <label
                    v-for="(status, index) in listingStatus"
                    :key="index"
                    class="pr-4"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :disabled="isDisabled(status)"
                    :checked="isChecked(status)"
                    :value="status"
                    @click="$emit('updateStatus',status)"

                />&nbsp;&nbsp; {{status}}</label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1">
          <div >
            <button
                v-if="!create"
                class="btn btn-xsmall"
                type="button"
                form="form_2"
                @click="$emit('save', this.permissions)"
            >
              <i class="fa fa-save"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row" v-if="onSiteLabel && isOffMarketListingChecked() && websiteLink">
          <div class="col-md-11">
              <p class="listing-label">Off Market URL</p>
              <div class="font-80P">
                  <p ref="listingURL" :class="{ 'blink': blinking }">{{ websiteLink }}</p>
              </div>
          </div>
          <div class="col-md-1">
              <button class="btn btn-xsmall"
                      type="button"
                      title="Copy to Clipboard"
                      @click="copyWebsiteLink()">
                  <i class="fa fa-copy"></i>
              </button>
          </div>
      </div>
    </form>
  </Accordion>
</template>
<script>
import Accordion from "../Accordion";
import map from "lodash-es/map";
import get from "lodash-es/get";
import {listingStatus} from "@/constants/status.js"
import {showOnSiteStatus} from "@/constants/status.js"
import {mapGetters} from "vuex";

export default {
  props: {
    header: String,
    create: Boolean,
    values: Object,
    defaultPermissions: Array,
    showArchive : Boolean,
    archiveLabel: {
      type: [String,null],
      default : null
    },
    onSiteLabel: {
      type: [String,null],
      default : null
    },
    websiteLink: {
        type: [String,null],
        default : null
    }
  },
  data() {
    return {
      permissions: {},
      listingStatus : listingStatus,
      showOnSiteStatus : showOnSiteStatus,
      key : 0,
      blinking: false
    };
  },
  computed : {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  components: {
    Accordion
  },
  watch: {
    'values.active_status' (value) {
     if(value !== "In Active") this.key++
    }
  },
  mounted() {
    const permissionsData = this.values ? this.values.permissions : {};
    const groupedPermissions = {};
    map(this.defaultPermissions, (item) => {
      if (!groupedPermissions[item.group]) {
        groupedPermissions[item.group] = {};
      }
        groupedPermissions[item.group][item.value] = get(
            permissionsData,
            `${item.group}.${item.value}`,
            false
          );
    });
    this.permissions = groupedPermissions;
  },
  methods: {
    submitAll(){
      this.$emit("save", this.permissions);
    },
    handleChangeUpdate($event, permission) {
      this.permissions[permission.group][permission.value] =
        $event.target.checked;
      this.$emit("update", this.permissions);
    },
    isDisabled (status) {
      return ['Inactive','In Active','Active'].includes(status)
    },
    isChecked(status) {
      return this.values.active_status === status.replaceAll(' ', '').toLowerCase().replace(/./, c => c.toUpperCase())
    },
    isShowOnSiteChecked(status) {
      return this.values.site_access ? this.values.site_access === status : status === 'No'
    },
    isOffMarketListingChecked() {
      return this.values.site_access === 'Off Market Listing'
    },
    copyWebsiteLink() {
      const Url = this.$refs.listingURL;
      navigator.clipboard.writeText(Url.innerHTML);
      this.blinking = true;
      setTimeout(() => {
          this.blinking = false;
      }, 250);
    }
  }
};
</script>
