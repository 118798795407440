<template>
<div>
  <ModalComponent :centerModal="true" v-if="isOpen" @close="$emit('declined',valueBeforeConfirmation?.current_value)">
    <template v-slot:title>
      <p>
        <strong class="font-120P"
        ><i class="fa fa-question-circle font-orange"></i
        >&nbsp;&nbsp;&nbsp;&nbsp;
          {{ title }}</strong
        >
      </p>
    </template>

    <p>
      {{message}}
    </p>
    <template v-slot:submitBtn>
      <SubmitButton
          caption="Confirm"
          @submit="$emit('confirmed',valueBeforeConfirmation?.new_value)"
      />
      <SubmitButton
          caption="Cancel"
          @submit="$emit('declined',valueBeforeConfirmation?.current_value)"
      />
    </template>
  </ModalComponent>
</div>
</template>


<script>
import ModalComponent from "./ModalComponent";
import SubmitButton from "./SubmitButton";
export default {
  name: "ConfirmationModal",
  components: {
    ModalComponent,
    SubmitButton
  },
  props : {
    title : {
      type : String,
      default : "Confirm"
    },
    message : {
      type : String,
      default : "Are you sure about this ?"
    },

  },
  data(){
    return{
      isOpen :false,
      valueBeforeConfirmation : null
    }
  },
  methods :{
    toggleModal(isOpen,valueBeforeConfirmation = null) {
    this.isOpen = isOpen;
    this.valueBeforeConfirmation = valueBeforeConfirmation;
    },
  }
}
</script>

<style scoped>

</style>