<template>
  <div class="entry-content">
    <!--Tabs start-->
    <div class="col-md-12">
      <div class="row">
        <!--tabs border start-->
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              @click="activeTap = 'detail'"
              :class="{ active: activeTap === 'detail' }"
            >
              <a
                id="view_document_tab_link"
                title="View"
                data-toggle="tab"
                href="#tab-01"
                ><h4 class="panel-title"><i class="fa fa-eye"></i></h4
              ></a>
            </li>
            <li
              id="tab-label-02"
              v-if="updateAvailable"
              @click="activeTap = 'update'"
              :class="{ active: activeTap === 'update' }"
            >
              <a
                id="update_document_tab_link"
                data-toggle="tab"
                href="#tab-02"
                title="Edit"
                ><h4 class="panel-title"><i class="fa fa-edit"></i></h4
              ></a>
            </li>
          </ul>
          <div class="panel-body">
            <div class="tab-content">
              <div
                id="tab-01"
                class="tab-pane"
                :class="{ active: activeTap === 'detail' }"
              >
                <div class="col-md-12">
                  <!-- document details start -->
                  <Details
                    v-if="!isLoading && document"
                    :document="document"
                    :updated="updated"
                    :permission-update="permissionUpdate"
                  />
                  <div v-if="isLoading" class="text-center">
                    <div class="loading"></div>
                  </div>
                  <!-- document details end -->
                </div>
              </div>
              <div
                id="tab-02"
                class="tab-pane"
                :class="{ active: activeTap === 'update' }"
              >
                <div class="col-md-12">
                  <form id="form_1" action="" v-if="document">
                    <Form
                      @submit="handleSubmit"
                      :form-data="document"
                      :is-loading="isSubmitting"
                      update
                      :key="document.id"
                      :errors="errors"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Details from "./Show/Index";
import Form from "./Form";
import get from "lodash-es/get";
import { map } from "lodash-es";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  components: {
    Details,
    Form
  },
  data() {
    return {
      document: null,
      isLoading: false,
      updateAvailable: false,
      isSubmitting: false,
      activeTap: "detail",
      updated: false,
      permissionUpdate: false,
      url: `${process.env.VUE_APP_DOCUMENTS_API_URL}/admin/documents`,
      formData: {},
      errors: {}
    };
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async loadUser() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(
          `${this.url}/${this.$route.params.id}`
        );
        this.document = data;
        this.formData = {
          ...data
        };
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
      }
    },
    async handleSubmit(values, permission) {
      this.isSubmitting = true;
      try {
        let formData = {
          ...this.formData
        };
        formData = {
          ...formData,
          ...values
        };

        let formDataValues = new FormData();
        map(
          {
            ...formData,
            active_date: formData.active_date
              ? moment(formData.active_date).format("YYYY-MM-DD")
              : null
          },
          (value, index) => {
            if (index === "permissions") {
              formDataValues.append(index, JSON.stringify(value));
            } else {
              formDataValues.append(index, value);
            }
          }
        );

        const { data } = await this.$http.post(
          `${this.url}/${this.$route.params.id}`,
          formDataValues
        );
        await this.loadUser();
        await this.loadStats();
        if (permission) {
          this.permissionUpdate = true;
        } else {
          this.updated = true;
        }
        this.activeTap = "detail";
        window.scrollTo(0,0);
        this.isSubmitting = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isSubmitting = false;
      }
    },
    handlePermissionSubmit(values) {
      this.handleSubmit(values, true);
    }
  },
  async mounted() {
    await this.loadUser();

    const breadcrumbs = [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Documents",
        url: "documents"
      },
      {
        name: "Document Details",
        url: "documents_show",
        active: true,
        params: {
          id: this.$route.params.id
        },
        data: this.document?.description
      }
    ];
    console.log(breadcrumbs);
    this.$store.commit("app/SET_BREADCRUMBS", [...breadcrumbs]);

    this.updateAvailable = this.$route.permissions
      ? this.$route.permissions.permissions.includes(1)
      : true;
    if (this.$route.query.action) {
      this.activeTap = this.$route.query.action;
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
