<template>
  <div>
    <div class="row">
      <div class="col-md-3" v-if="newAddress">
        <p><strong class="font-120P">New Address</strong></p>
      </div>
      <div class="col-md-3" v-if="newAddress">
        Primary Address&nbsp;&nbsp;
        <label class="switch">
          <input type="checkbox" v-model="values.primary" />
          <span class="slider"></span>
        </label>
      </div>
      <div class="col-md-6" v-if="active">
        Active&nbsp;&nbsp;
        <label class="switch">
          <input type="checkbox" v-model="values.is_active" />
          <span class="slider"></span>
        </label>
      </div>
      <div class="col-md-6 text-right">
        <button
          type="button"
          v-if="setPrimary && values.id"
          class="btn btn-xsmall"
          @click="handleSetPrimary"
        >
          SET TO PRIMARY&nbsp;</button
        >&nbsp;&nbsp;
        <button
          title="Save Account"
          class="btn btn-xsmall"
          :disabled="isSubmitting"
          @click="handleSave()"
        >
          <span class="font-120P"><i class="fa fa-save"></i></span>
        </button>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="description">Business address description </label>
          <input
            class="form-control"
            id="description"
            name="description"
            placeholder="The business address description"
            type="text"
            v-model="values.description"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="phone">Business land-line number </label>
          <input
            class="form-control"
            id="phone"
            name="phone"
            placeholder="The business land-line number"
            type="text"
            v-model="values.phone"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="street_address">Street address</label>
          <input
            class="form-control"
            id="street_address"
            name="street_address"
            placeholder="The registered business street address"
            type="text"
            v-model="values.street_address"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="suburb">Suburb</label>
          <input
            class="form-control"
            id="suburb"
            name="suburb"
            placeholder="Registered suburb"
            type="text"
            v-model="values.suburb"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <Select
            name="state"
            label="State *"
            :class-name="
              `${
                errors && errors.hasOwnProperty('state_id') ? 'has-error' : ''
              }`
            "
            :error="
              errors && errors.hasOwnProperty('state_id')
                ? errors.state_id[0]
                : ''
            "
            required
            placeholder="Select a state"
            selected="Australia"
            :url="stateSearch"
            :value="values.state_id"
            :axios="$http"
            @selected="value => handleDropdownChange(value, 'state_id')"
            showother
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="postcode">Postcode</label>
          <input
            class="form-control"
            id="postcode"
            name="postcode"
            placeholder="Registered postcode"
            type="text"
            v-model="values.postcode"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <Select
            name="country"
            label="Country *"
            required
            :class-name="
              `${errors.hasOwnProperty('country_id') ? 'has-error' : ''}`
            "
            :error="
              errors.hasOwnProperty('country_id') ? errors.country_id[0] : ''
            "
            placeholder="Select a Country"
            :value="values.country_id ? values.country_id : 14"
            :url="countrySearch"
            :axios="$http"
            @selected="value => handleDropdownChange(value, 'country_id')"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="note">Address Note</label>
          <textarea
            class="form-control form-control-message"
            name="note"
            id="note"
            placeholder="Address Note"
            rows="2"
            v-model="values.note"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Select from "../../Form/Select";
export default {
  components: {
    Select
  },
  props: {
    formData: {
      type: Object,
      default: () => ({
        description: "",
        phone: "",
        street_address: "",
        state_id: "",
        suburb: "",
        postcode: null,
        country_id: null,
        note: null,
        primary: false,
        is_active: 0
      })
    },
    active: {
      type: Boolean,
      default: () => false
    },
    newAddress: {
      type: Boolean,
      default: () => false
    },
    setPrimary: {
      type: Boolean,
      default: () => false
    },
    business: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      isSubmitting: false,
      countrySearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/countries/search`,
      stateSearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`,
      errors: {},
      values: {
        ...this.formData,
        is_active: !!this.formData.is_active
      }
    };
  },
  methods: {
    async handleSave() {
      this.isSubmitting = true;
      if (this.values.identifier) {
        await this.handleUpdate();
      } else {
        await this.handleCreate();
      }
      this.isSubmitting = false;
    },
    async handleUpdate() {
      try {
        await this.$http.patch(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/businesses/${this.business}/addresses/${this.values.identifier}`,
          {
            ...this.values,
            is_active: this.values.is_active ? 1 : 0
          }
        );
        this.$emit("save", "business");
      } catch {
        //
      }
    },
    async handleCreate() {
      //count 3 or less than 3 means it has only pre selected values. so at that moment we dont need to create a new address
      if(Object.values(this.values).filter(x => x !== null && x !== "").length <= 3 ) return
      try {
        await this.$http.post(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/businesses/${this.business}/addresses`,
          {
            ...this.values,
            is_active: 1
          }
        );
        this.values = {}
        this.$emit("save", "business");
      } catch {
        //
      }
    },
    async handleSetPrimary() {},
    handleDropdownChange(value, name) {
      this.values[name] = value ? value.id : null;
    }
  }
};
</script>
