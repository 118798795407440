<template>
  <div class="col-md-12">
    <div class="row mb-2">
      <Permission
        header="Permissions"
        :default-permissions="defaultPermissions"
        :create="!update"
        @update="handleUpdatePermission"
        @updateStatus="handleUpdateStatus"
        @updateShowOnSiteStatus="handleUpdateShowOnSiteStatus"
        :values="values"
        @save="handleSavePermission"
        :show-archive="true"
        archiveLabel="LISTING ACTIVE STATUS"
        onSiteLabel="Show on Site"
        :websiteLink="websiteLink"
      />
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Listing Status</strong></p>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <div class="form-group">
            <label for="reference">Reference Number *</label>
            <input
              class="form-control"
              id="reference"
              v-model="values.reference"
              name="reference"
              :class="
                `form-control ${
                  errors.hasOwnProperty('reference') ? 'has-error' : ''
                }`
              "
              placeholder="Reference Number "
              type="text"
              required
            />
            <span
              v-if="errors.hasOwnProperty('reference')"
              class="help-block text-danger"
              >{{ errors.reference[0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="active_date">Active Date</label>
          <DatePicker
            :model-value="values.active_date"
            @update="
              e => {
                values.active_date = e.target.value;
              }
            "
            name="active_date"
            id="active_date"
            :input-class="
              `form-control ${
                errors.hasOwnProperty('active_date') ? 'has-error' : ''
              }`
            "
            placeholder="The date the listing will be active from"
          />
          <span
            v-if="errors.hasOwnProperty('active_date')"
            class="help-block text-danger"
            >{{ errors.active_date[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="status">Listing Status *</label>
          <select
            class="form-control"
            id="status"
            :class="
              `form-control ${
                errors.hasOwnProperty('status') ? 'has-error' : ''
              }`
            "
            name="status"
            v-model="values.status"
          >
            <option selected disabled>Please Select</option>
            <option value="For Sale" selected>For Sale</option>
            <option value="Under Contract">Under Contract</option>
            <option value="Sold">Sold</option>
          </select>
          <span
            v-if="errors.hasOwnProperty('status')"
            class="help-block text-danger"
            >{{ errors.status[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <Select
            name="owner"
            label="Listing Owner *"
            required
            :class-name="
              `${errors.hasOwnProperty('listing_owner_id') ? 'has-error' : ''}`
            "
            :error="
              errors.hasOwnProperty('listing_owner_id')
                ? errors.listing_owner_id[0]
                : ''
            "
            placeholder="Select a Owner"
            :value="values.owner_id"
            :url="ownerSearch"
            :axios="$http"
            @selected="value => handleDropdownChange(value, 'owner_id')"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <Select
            name="seller"
            label="Seller"
            :class-name="
              `${errors.hasOwnProperty('seller_id') ? 'has-error' : ''}`
            "
            :error="
              errors.hasOwnProperty('seller_id') ? errors.seller_id[0] : ''
            "
            placeholder="Select a Seller"
            :value="values.seller_id"
            :url="customerSearch"
            :axios="$http"
            @selected="value => handleDropdownChange(value, 'seller')"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Commission</strong></p>
      </div>

      <div class="col-md-6">
        Fixed Amount&nbsp;&nbsp;
        <label class="switch">
          <input
            id="uncheck_fixed"
            name="uncheck_fixed"
            type="checkbox"
            :checked="values.commission_type === 'FixedAmount'"
            @change="$event => handleCheckboxChange($event, 'FixedAmount')"
          />
          <span class="slider"></span>
        </label>
      </div>

      <div class="col-md-6">
        Percentage&nbsp;&nbsp;
        <!-- Rounded switch -->
        <label class="switch">
          <input
            id="uncheck_percent"
            name="uncheck_percent"
            type="checkbox"
            :checked="values.commission_type === 'Percentage'"
            @change="$event => handleCheckboxChange($event, 'Percentage')"
          />
          <span class="slider"></span> </label
        >&nbsp;&nbsp;
      </div>

      <div class="gap-20"></div>

      <div class="col-md-12">
        <div class="form-group">
          <div class="form-group">
            <label for="reference"
              >Commission Amount ({{
                values.commission_type === "Percentage" ? "%" : "$"
              }})</label
            >
            <input
              class="form-control"
              id="commission_amount"
              v-model="values.commission_amount"
              name="commission_amount"
              :class="
                `form-control ${
                  errors.hasOwnProperty('commission_amount') ? 'has-error' : ''
                }`
              "
              :placeholder="
                `Commission Amount (${
                  values.commission_type === 'Percentage' ? '%' : '$'
                }) eg 123456`
              "
              type="number"
              required
            />
            <span
              v-if="errors.hasOwnProperty('commission_amount')"
              class="help-block text-danger"
              >{{ errors.reference[0] }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Listing Summary</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-group">
            <label for="price">Asking price ($)</label>
            <input
              class="form-control"
              id="price"

              v-model="values.price"
              name="price"
              :class="
                `form-control ${
                  errors.hasOwnProperty('price') ? 'has-error' : ''
                }`
              "
              placeholder="Asking price ($) eg 123456 "
              type="number"
              required
            />
            <span
              v-if="errors.hasOwnProperty('price')"
              class="help-block text-danger"
              >{{ errors.price[0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="currency">Currency *</label>
          <select
            class="form-control"
            id="currency"
            name="currency"
            :class="
              `form-control ${
                errors.hasOwnProperty('currency') ? 'has-error' : ''
              }`
            "
            v-model="values.currency"
          >
            <option selected disabled>Please Select</option>
            <option value="AUD">AUD</option>
            <option value="SGP">SGP</option>
            <option value="USD">USD</option>
          </select>
          <span
            v-if="errors.hasOwnProperty('currency')"
            class="help-block text-danger"
            >{{ errors.currency[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="form-group">
            <label for="region">Region *</label>
            <input
              class="form-control"
              id="region"
              v-model="values.region"
              name="region"
              :class="
                `form-control ${
                  errors.hasOwnProperty('region') ? 'has-error' : ''
                }`
              "
              placeholder="Region"
              type="text"
              required
            />
            <span
              v-if="errors.hasOwnProperty('region')"
              class="help-block text-danger"
              >{{ errors.region[0] }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <Select
            name="country"
            label="Country *"
            required
            :class-name="
              `${errors.hasOwnProperty('country_id') ? 'has-error' : ''}`
            "
            :error="
              errors.hasOwnProperty('country_id') ? errors.country_id[0] : ''
            "
            placeholder="Select a Country"
            :value="values.country_id ? values.country_id : 14"
            :url="countrySearch"
            :axios="getGeoLocations"
            @selected="value => handleDropdownChange(value, 'country')"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <Select
            name="state"
            label="State *"
            :value="values.state_id"
            :class-name="
              `${errors.hasOwnProperty('state_id') ? 'has-error' : ''}`
            "
            :error="errors.hasOwnProperty('state_id') ? errors.state_id[0] : ''"
            required
            placeholder="Select a state"
            selected="Australia"
            :url="stateSearch"
            :axios="getGeoLocations"
            @selected="value => handleDropdownChange(value, 'state')"
            showother
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-group">
            <label for="management_fee_income"
              >Annual management fee income ($) *</label
            >
            <input
              class="form-control"
              id="management_fee_income"
              v-model="values.management_fee_income"
              name="management_fee_income"
              placeholder="Annual management fee income ($) eg 123456 "
              type="number"
              required
              :class="
                `form-control ${
                  errors.hasOwnProperty('management_fee_income')
                    ? 'has-error'
                    : ''
                }`
              "
            />
            <span
              v-if="errors.hasOwnProperty('management_fee_income')"
              class="help-block text-danger"
              >{{ errors.management_fee_income[0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-group">
            <label for="gross_income">Gross income last 12 months ($) *</label>
            <input
              class="form-control"
              id="gross_income"
              v-model="values.gross_income"
              name="gross_income"
              placeholder="Gross income last 12 months ($) eg 123456 "
              type="number"
              required
              :class="
                `form-control ${
                  errors.hasOwnProperty('gross_income') ? 'has-error' : ''
                }`
              "
            />
            <span
              v-if="errors.hasOwnProperty('gross_income')"
              class="help-block text-danger"
              >{{ errors.gross_income[0] }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-group">
            <label for="average_rent">Average rent per week ($) *</label>
            <input
              class="form-control"
              id="average_rent"
              v-model="values.average_rent"
              name="average_rent"
              placeholder="Average rent per week ($) eg 123456 "
              type="number"
              required
              :class="
                `form-control ${
                  errors.hasOwnProperty('average_rent') ? 'has-error' : ''
                }`
              "
            />
            <span
              v-if="errors.hasOwnProperty('average_rent')"
              class="help-block text-danger"
              >{{ errors.average_rent[0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-group">
            <label for="average_management_fee"
              >Average management fee per property ($) *</label
            >
            <input
              class="form-control"
              id="average_management_fee"
              v-model="values.average_management_fee"
              name="average_management_fee"
              placeholder="Average management fee per property ($) eg 123456 "
              type="number"
              required
              :class="
                `form-control ${
                  errors.hasOwnProperty('average_management_fee')
                    ? 'has-error'
                    : ''
                }`
              "
            />
            <span
              v-if="errors.hasOwnProperty('average_management_fee')"
              class="help-block text-danger"
              >{{ errors.average_management_fee[0] }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Rent Roll Details</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="total_properties">Total properties *</label>
          <input
            class="form-control"
            id="total_properties"
            name="total_properties"
            placeholder="Total properties"
            type="text"
            required
            v-model="values.total_properties"
            :class="
              `form-control ${
                errors.hasOwnProperty('total_properties') ? 'has-error' : ''
              }`
            "
          />
          <span
            v-if="errors.hasOwnProperty('total_properties')"
            class="help-block text-danger"
            >{{ errors.total_properties[0] }}</span
          >
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <p><strong>Residential Breakdown</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="residential">Total residential properties *</label>
          <input
            class="form-control"
            id="residential"
            name="residential"
            placeholder="Total residential properties"
            type="text"
            required
            v-model="values.residential"
            :class="
              `form-control ${
                errors.hasOwnProperty('residential') ? 'has-error' : ''
              }`
            "
          />
          <span
            v-if="errors.hasOwnProperty('residential')"
            class="help-block text-danger"
            >{{ errors.residential[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="average_management_fee_residential"
            >Average management fee residential (%) *</label
          >
          <input
            class="form-control"
            id="average_management_fee_residential"
            name="average_management_fee_residential"
            placeholder="Average management fee residential (%)"
            type="number"
            required
            v-model="values.average_management_fee_residential"
            :class="
              `form-control ${
                errors.hasOwnProperty('average_management_fee_residential')
                  ? 'has-error'
                  : ''
              }`
            "
            :readonly="values.residential == 0"
          />
          <span
            v-if="errors.hasOwnProperty('average_management_fee_residential')"
            class="help-block text-danger"
            >{{ errors.average_management_fee_residential[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Commercial / Industrial Breakdown</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="commercial"
            >Total commercial / industrial properties *</label
          >
          <input
            class="form-control"
            id="commercial"
            name="commercial"
            placeholder="Total commercial properties"
            type="text"
            required
            v-model="values.commercial"
            :class="
              `form-control ${
                errors.hasOwnProperty('commercial') ? 'has-error' : ''
              }`
            "
          />
          <span
            v-if="errors.hasOwnProperty('commercial')"
            class="help-block text-danger"
            >{{ errors.commercial[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="average_management_fee_commercial"
            >Average management fee commercial (%) *</label
          >
          <input
            class="form-control"
            id="average_management_fee_commercial"
            name="average_management_fee_commercial"
            placeholder="Average management fee commercial (%)"
            type="number"
            required
            v-model="values.average_management_fee_commercial"
            :class="
              `form-control ${
                errors.hasOwnProperty('average_management_fee_commercial')
                  ? 'has-error'
                  : ''
              }`
            "
            :readonly="values.commercial == 0"
          />

          <span
            v-if="errors.hasOwnProperty('average_management_fee_commercial')"
            class="help-block text-danger"
            >{{ errors.average_management_fee_commercial[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Holiday Breakdown</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="holiday">Total holiday properties *</label>
          <input
            class="form-control"
            id="holiday"
            name="holiday"
            placeholder="Total holiday properties"
            type="text"
            required
            v-model="values.holiday"
            :class="
              `form-control ${
                errors.hasOwnProperty('holiday') ? 'has-error' : ''
              }`
            "
          />
          <span
            v-if="errors.hasOwnProperty('holiday')"
            class="help-block text-danger"
            >{{ errors.holiday[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="average_management_fee_holiday"
            >Average management fee holiday (%) *</label
          >
          <input
            class="form-control"
            id="average_management_fee_holiday"
            name="average_management_fee_holiday"
            placeholder="Average management fee holiday (%)"
            type="number"
            required
            v-model="values.average_management_fee_holiday"
            :class="
              `form-control ${
                errors.hasOwnProperty('average_management_fee_holiday')
                  ? 'has-error'
                  : ''
              }`
            "
            :readonly="values.holiday == 0"
          />
          <span
            v-if="errors.hasOwnProperty('average_management_fee_holiday')"
            class="help-block text-danger"
            >{{ errors.average_management_fee_holiday[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Strata / Community</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="strata">Total strata / community properties *</label>
          <input
            class="form-control"
            id="strata"
            name="strata"
            placeholder="Total strata properties"
            type="text"
            required
            v-model="values.strata"
            :class="
              `form-control ${
                errors.hasOwnProperty('strata') ? 'has-error' : ''
              }`
            "
          />
          <span
            v-if="errors.hasOwnProperty('strata')"
            class="help-block text-danger"
            >{{ errors.strata[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="average_management_fee_strata"
            >Average management fee strata (%) *</label
          >
          <input
            class="form-control"
            id="average_management_fee_strata"
            name="average_management_fee_strata"
            placeholder="Average management fee strata (%)"
            type="number"
            required
            v-model="values.average_management_fee_strata"
            :class="
              `form-control ${
                errors.hasOwnProperty('average_management_fee_strata')
                  ? 'has-error'
                  : ''
              }`
            "
            :readonly="values.strata == 0"
          />
          <span
            v-if="errors.hasOwnProperty('average_management_fee_strata')"
            class="help-block text-danger"
            >{{ errors.average_management_fee_strata[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Storage Sheds Breakdown</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="sheds">Total storage shed properties *</label>
          <input
              class="form-control"
              id="storage_sheds"
              name="storage_sheds"
              placeholder="Total storage sheds properties"
              type="text"
              required
              v-model="values.storage_sheds"
              :class="
              `form-control ${
                errors.hasOwnProperty('storage_sheds') ? 'has-error' : ''
              }`
            "
          />
          <span
              v-if="errors.hasOwnProperty('storage_sheds')"
              class="help-block text-danger"
          >{{ errors.storage_sheds[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="average_management_fee_storage_sheds"
          >Average management fee storage sheds (%) *</label
          >
          <input
              class="form-control"
              id="average_management_fee_storage_sheds"
              name="average_management_fee_storage_sheds"
              placeholder="Average management fee storage sheds (%)"
              type="number"
              required
              v-model="values.average_management_fee_storage_sheds"
              :class="
              `form-control ${
                errors.hasOwnProperty('average_management_fee_storage_sheds')
                  ? 'has-error'
                  : ''
              }`
            "
              :readonly="values.storage_sheds == 0"
          />
          <span
              v-if="errors.hasOwnProperty('average_management_fee_storage_sheds')"
              class="help-block text-danger"
          >{{ errors.average_management_fee_storage_sheds[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>AirBnB Breakdown</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="airbnb">Total airbnb properties *</label>
          <input
              class="form-control"
              id="airbnb"
              name="airbnb"
              placeholder="Total airbnb properties"
              type="text"
              required
              v-model="values.airbnb"
              :class="
              `form-control ${
                errors.hasOwnProperty('airbnb') ? 'has-error' : ''
              }`
            "
          />
          <span
              v-if="errors.hasOwnProperty('airbnb')"
              class="help-block text-danger"
          >{{ errors.airbnb[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="average_management_fee_airbnb"
          >Average management fee airbnb (%) *</label
          >
          <input
              class="form-control"
              id="average_management_fee_airbnb"
              name="average_management_fee_airbnb"
              placeholder="Average management fee airbnb (%)"
              type="number"
              required
              v-model="values.average_management_fee_airbnb"
              :class="
              `form-control ${
                errors.hasOwnProperty('average_management_fee_airbnb')
                  ? 'has-error'
                  : ''
              }`
            "
              :readonly="values.airbnb == 0"
          />
          <span
              v-if="errors.hasOwnProperty('average_management_fee_airbnb')"
              class="help-block text-danger"
          >{{ errors.average_management_fee_airbnb[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Write Up</strong></p>
      </div>
      <div class="col-md-12">
        <select
            class="form-control"
            id="agency_detail_title"
            name="agency_detail_title"
            :class="
              `form-control ${
                errors.hasOwnProperty('agency_detail_title') ? 'has-error' : ''
              }`
            "
            v-model="values.agency_detail_title"
          >
            <option value="Agency Details">Agency Details</option>
            <option value="Strata Details">Strata Details</option>
            <option value="Holiday Letting Details">Holiday Letting Details</option>
          </select>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <Editor
            class="form-control form-control-message"
            name="agency_details"
            :plugins="plugins"
            :toolbar="toolbar"
            id="agency_details"
            placeholder="Agency Details"
            :class="{ 'has-error': errors.hasOwnProperty('agency_details') }"
            rows="10"
            required
            v-model="values.agency_details"
          ></Editor>
        </div>
      </div>
      <div class="gap-20"></div>
      <div class="col-md-12">
        <p><strong>Rent Roll Details</strong></p>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <Editor
            class="form-control form-control-message"
            name="rent_roll_details"
            :plugins="plugins"
            :toolbar="toolbar"
            id="rent_roll_details"
            :class="{ 'has-error': errors.hasOwnProperty('rent_roll_details') }"
            placeholder="Rent Roll Details"
            rows="10"
            required
            v-model="values.rent_roll_details"
          ></Editor>
        </div>
      </div>
      <div class="gap-20"></div>
      <div class="col-md-12">
        <p><strong>Optional Details</strong></p>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="optional_details_label">Optional details label</label>
          <input
            class="form-control"
            id="optional_details_label"
            name="optional_details_label"
            :class="{
              'has-error': errors.hasOwnProperty('optional_details_label')
            }"
            placeholder="Optional details label "
            type="text"
            v-model="values.optional_details_label"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <Editor
            class="form-control form-control-message"
            name="optional_details_data"
            id="optional_details"
            :plugins="plugins"
            :toolbar="toolbar"
            placeholder="Optional Details"
            :class="{
              'has-error': errors.hasOwnProperty('optional_details_data')
            }"
            rows="10"
            required
            v-model="values.optional_details_data"
          ></Editor>
        </div>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row" v-if="!update && isCreatable">
      <div class="col-md-12">
        <div class="form-group">
          <SubmitButton
            :isProgress="isLoading"
            :caption="isLoading ? 'Creating' : 'Create Listing'"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
    <div class="row" v-if="update && updateAvailable">
      <div class="col-md-12">
        <div class="form-group">
          <SubmitButton
            :isProgress="isLoading"
            :caption="isLoading ? 'Updating' : 'Update Listing'"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
    <ConfirmationModal ref="refConfirmationModal"
                       message="Are you sure you want to archive this artifact ?"
                       @declined="archiveDeclined"
                       @confirmed="archiveConfirmed"
    />
  </div>
</template>

<script>
import DatePicker from "@/components/Form/DatePicker";
import Select from "@/components/Form/Select";
import Editor from "@tinymce/tinymce-vue";
import SubmitButton from "@/components/SubmitButton";
import Permission from "../../components/Permission";
import { mapGetters } from "vuex";
import ConfirmationModal from "../../components/ConfirmationModal.vue";
import map from "lodash-es/map";

export default {
  props: {
    stateData: {
      type: Object,
      default: () => {}
    },
    countryData: {
      type: Object,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Object,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: () => false
    },
    isLoading: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    DatePicker,
    Select,
    Editor,
    SubmitButton,
    Permission,
    ConfirmationModal
  },
  data() {
    return {
      values: {
        commission_type: "FixedAmount",
        currency: "AUD",
        owner_id: this.formData?.owner?.id,
        state_id: this.formData?.state?.id,
        country_id: this.formData?.country?.id,
        seller_id: this.formData?.seller?.id,
        ...this.formData
      },
      countrySearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/countries/search`,
      ownerSearch: `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/select`,
      customerSearch: `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/search`,
      stateSearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`,
      plugins: "lists advlist code link table",
      toolbar: "numlist bullist"
    };
  },
  watch: {
    'values.residential'(newVal){
      if(newVal == 0){
        this.values.average_management_fee_residential = 0;
      }
    },
    'values.commercial'(newVal){
      if(newVal == 0){
        this.values.average_management_fee_commercial = 0;
      }
    },
    'values.holiday'(newVal){
      if(newVal == 0){
        this.values.average_management_fee_holiday = 0;
      }
    },
    'values.strata'(newVal){
      if(newVal == 0){
        this.values.average_management_fee_strata = 0;
      }
    },
    'values.storage_sheds'(newVal) {
      if (newVal == 0) {
        this.values.average_management_fee_storage_sheds = 0;
      }
    },
    'values.airbnb'(newVal){
      if(newVal == 0){
        this.values.average_management_fee_airbnb = 0;
      }
    }
  },
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions"
    }),
    states() {
      return this.stateData;
    },
    countries() {
      return this.countryData;
    },
    getGeoLocations() {
      return this.$http;
    },
    defaultPermissions() {
      let permissions = [];
      map(this.permissionMeta.module_permission.listing.permissions, group => {
        map(group.permissions, permission => {
          permissions.push({
            group: group.group,
            name: permission.caption,
            permission: permission.title,
            value: permission.name
          });
        });
      });
      return permissions;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    },
    websiteLink() {
        return this.values.id ? `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings/${this.values.id}` : null;
    },
  },
  methods: {
    handleSetPermission() {
      this.$emit("submit", this.values);
    },
    archiveDeclined (currentValue){
      this.values.active_status = null;
      setTimeout(() => {
        this.values.active_status = currentValue
        this.$refs.refConfirmationModal.toggleModal(false)
      },200)
    },
    archiveConfirmed (newValue){
      this.values.active_status = newValue
      this.$refs.refConfirmationModal.toggleModal(false)
    },
    handleUpdateStatus(newStatus) {
      if( this.values.active_status === newStatus) return
      if(newStatus === "Archived"){
          this.$refs.refConfirmationModal.toggleModal(true,
              {current_value : this.values.active_status,new_value :newStatus}
          );
      } else {
        this.values.active_status = newStatus;
      }
    },
    handleUpdateShowOnSiteStatus(newStatus) {
      if(this.values.site_access === newStatus) return
      this.values.off_market_listing = (newStatus === 'Off Market Listing' ? 'Yes' : null);
      this.values.site_access = newStatus;
    },
    handleUpdatePermission(permissions) {
      this.values.permissions = permissions;
    },
    handleSubmit() {
      this.$emit("submit", this.values);
    },
    handleDropdownChange(value, param) {
      this.values[param] = value;
    },
    handleCheckboxChange($event, model) {
      this.values.commission_type = $event.target.checked ? model : null;
    },
    handleSavePermission(values) {
      this.$emit(
        "submit",
        {
          ...this.values,
          permissions: values
            ? values
            : {
                admin_access: {
                  admin_approved: false,
                  site_access: false
                }
              }
        },
        true
      );
    }
  }
};
</script>

<style>
.v3dp__divider {
  display: none !important;
}
</style>
