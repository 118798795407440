<template>
  <div class="entry-content">
    <div class="gap-20"></div>
    <ResourceTable
      remove-actions
      v-if="item"
      :columns="columns"
      :url="logUrl ? logUrl : url"
      :extra-params="{
        model: model,
        subject: subject,
        causer: subjectId ? null : item.id,
        subject_id: subjectId
      }"
    />
  </div>
</template>

<script>
import ResourceTable from "@/components/ResourceTable";
export default {
  props: {
    item: Object,
    model: String,
    subject: String,
    subjectId: Number,
    userAvailable: Boolean,
    logUrl: String
  },
  components: {
    ResourceTable
  },
  data() {
    return {
      url: `${process.env.VUE_APP_ACTIVITY_API_URL}/admin/customerlogs`,
      columns: [
        {
          name: "date",
          text: "Date",
          timestamp: item => item.created
        },
        {
          name: "activity",
          text: "Activity",
          render: item => `${item.activity}`,
          sort_disable: true
        }
      ]
    };
  },
  created() {},
  mounted() {
    if (this.userAvailable) {
      this.columns = [
        {
          name: "date",
          text: "Date",
          timestamp: item => item.created
        },
        {
          name: "user",
          text: "User",
          render: item => `${item.user}`,
          sort_disable: true
        },
        {
          name: "activity",
          text: "Activity",
          render: item => `${item.activity}`,
          sort_disable: true
        }
      ];
    }
  }
};
</script>
