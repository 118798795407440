<template>
  <div class="col-md-12">
    <form role="form">
      <div class="row">
        <div class="col-md-12">
          <Tab :tabs="tabs" @save="handleSave" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Tab from "../../../components/Tab";
import { mapActions, mapGetters } from "vuex";
import map from "lodash-es/map";

export default {
  components: {
    Tab
  },
  computed: {
    ...mapGetters({
      values: "masterData/projectValues"
    }),
    tabs() {
      return map(this.values, (value, key) => {
        return {
          name: key.replace("_", " "),
          index: key,
          key: key,
          component: require("./Stage/index.vue").default,
          props: {
            stage: key.replace("_", " "),
            tab: key,
            values: value ? value : {}
          }
        };
      });
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      loadProjectData: "masterData/loadProjectData"
    }),
    async handleSave({ values, tab }) {
      try {
        const data = this.$http.post(
          `${process.env.VUE_APP_CONFIG_API_URL}/admin/master-data`,
          {
            key: "PROJECT",
            values: {
              ...this.values,
              [tab]: values[tab]
            }
          }
        );
        this.$store.commit("masterData/UPDATE_PROJECT_VALUES", values);
      } catch (error) {
        console.log(error, "error");
      }
    }
  },
  mounted() {
    this.loadProjectData();
  }
};
</script>
