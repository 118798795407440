<template>
  <div class="entry-content">
    <div class="row">
      <div class="alert alert-success fade in" v-if="created">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The affiliate has been created
      </div>
    </div>

    <div class="row" v-if="updated">
      <div class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The affiliate's details have been updated
      </div>
      <!-- Alert success end -->
    </div>
    <div class="row" v-if="permissionUpdate">
      <div class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The affiliate's permissions have been
        updated
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <p>
          <strong class="font-120P">{{ affiliate.name }}</strong
          >&nbsp;&nbsp;<span
            class="badge"
            :class="{
              'badge-active': affiliate.active_status === 'Active',
              'badge-inactive': affiliate.active_status === 'Inactive'
            }"
            >{{ affiliate.active_status }}</span
          >
        </p>
        <br />
      </div>
      <div class="col-md-4">
        <p>
          <i class="fa fa-link"></i>&nbsp;&nbsp;<a
            :href="websiteLink"
            target="_blank"
            >View live page</a
          >
        </p>
        <br />
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <p class="listing-label">Industry</p>
        <p>{{ affiliate.industry_name }}</p>
        <br />
      </div>

      <div class="col-md-4">
        <p class="listing-label">Contact Person</p>
        <p>{{ affiliate.contact_person }}</p>
        <br />
      </div>

      <div class="col-md-4">
        <p class="listing-label">Contact / Business Number</p>
        <p>{{ affiliate.contact_number }}</p>
        <br />
      </div>

      <div class="col-md-4">
        <p class="listing-label">Contact / Business Email</p>
        <p>
          <a :href="`mailto:${affiliate.email}`">{{ affiliate.email }}</a>
        </p>
        <br />
      </div>

      <div class="col-md-4">
        <p class="listing-label">Business Website</p>
        <p>
          <a
            v-if="affiliate.website"
            :href="`https://www.${affiliate.website}`"
            target="_blank"
            >https://www.{{ affiliate.website }}</a
          >
        </p>
        <br />
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <p class="listing-label">Business Address</p>
        <p>
          {{ affiliate.street }}, {{ affiliate.suburb }}
          {{ affiliate.state?.name }},{{ affiliate.postcode }}
        </p>
        <br />
      </div>
    </div>
    <ModifiedSection
      :created="affiliate.created_at"
      :updated="affiliate.updated_at"
      model="Affiliate"
      :model-id="`${affiliate.id}`"
    />
  </div>
</template>

<script>
import ModifiedSection from "@/components/ModifiedSection";
export default {
  props: {
    created: Boolean,
    updated: Boolean,
    affiliate: Object,
    permissionUpdate: Boolean
  },
  components: {
    ModifiedSection
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    websiteLink() {
      return `${process.env.VUE_APP_WEB_URL}/affiliates`;
    }
  }
};
</script>

<style></style>
