<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="">
          <p class="listing-label">Reference Number</p>
          <p>
            <span class="listing-highlight-3">{{ listing.reference }}</span
            >&nbsp;&nbsp;<span
              class="badge"
              :class="{
                'badge-active': listing.active_status === 'Active',
                'badge-inactive': listing.active_status === 'Inactive'
              }"
              >{{ listing.active_status }}</span
            >
          </p>
        </div>
      </div>
      <div class="col-md-3">
        <p class="listing-label">Listing Website Link</p>
        <p>
          <i class="fa fa-link"></i>&nbsp;&nbsp;<a
            :href="websiteLink"
            target="_blank"
            >View live page</a
          >
        </p>
      </div>
      <div class="col-md-6">
        <div class="">
          <p class="listing-label">LINKED INFORMATION MEMORANDUM</p>
          <p>
            <strong>
              <a
                v-if="listing.memorandum !== null"
                :href="memorandumUrl"
                >CLICK HERE TO VIEW</a
              > </strong
            >&nbsp;<strong
              ><span v-if="listing.memorandum === null"
                >[
                <a :href="memorandunCreateUrl">ADD NEW</a>
                ]</span
              ></strong
            >&nbsp;
            <strong v-if="listing.memorandum !== null"
              >[
              <a
                v-if="listing.memorandum !== null"
                href="#"
                @click="$emit('unlink')"
                >UNLINK IM</a
              >
              ]</strong
            >
          </p>
          <br />
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-3">
        <div class="">
          <p class="listing-label">Asking price ($)</p>
          <p>{{ getCurrency(listing.price) }} {{ listing.currency }}</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="">
          <p class="listing-label">Active Date</p>
          <p>{{ listing.active_date }}</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="">
          <p class="listing-label">Listing Status</p>
          <p>{{ listing.status }}</p>
        </div>
      </div>
      <div class="col-md-3">
        <div v-if="listing.commission_type === 'FixedAmount'" class="">
          <p class="listing-label">Commission Fixed</p>
          <p>{{ getCurrency(listing.commission_amount) }}</p>
        </div>
        <div v-if="listing.commission_type === 'Percentage'" class="">
          <p class="listing-label">Commission Percentage</p>
          <p>{{ listing.commission_amount }}%</p>
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Listing Summary</strong></p>
      </div>
      <div class="gap-20"></div>

      <div class="col-md-6">
        <div class="">
          <p class="listing-label">Region</p>
          <p>
            <strong>{{ listing.region }}</strong>
          </p>
          <br />
        </div>
      </div>
      <div class="col-md-3">
        <p class="listing-label">Country</p>
        <p>{{ listing?.country?.name }}</p>
        <br />
      </div>
      <div class="col-md-3">
        <p class="listing-label">State</p>
        <p>{{ listing?.state?.name }}</p>
        <br />
      </div>
      <div class="col-md-6">
        <div class="">
          <p class="listing-label">Annual management fee income ($)</p>
          <p>{{ getCurrency(listing.management_fee_income) }}</p>
          <br />
        </div>
      </div>
      <div class="col-md-6">
        <div class="">
          <p class="listing-label">Gross income last 12 months ($)</p>
          <p>{{ getCurrency(listing.gross_income) }}</p>
          <br />
        </div>
      </div>
      <div class="col-md-6">
        <div class="">
          <p class="listing-label">Average rent per week ($)</p>
          <p>{{ getCurrency(listing.average_rent) }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="">
          <p class="listing-label">Average management fee per property ($)</p>
          <p>{{ getCurrency(listing.average_management_fee) }}</p>
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Rent Roll Details</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">Total properties</p>
        <p>{{ formatNumber(listing.total_properties) }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Residential Breakdown</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">Total residential properties</p>
        <p>{{ formatNumber(listing.residential) }}</p>
      </div>
      <div class="col-md-6">
        <p class="listing-label">Average management fee residential (%)</p>
        <p>{{ listing.average_management_fee_residential }}%</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Commercial / Industrial Breakdown</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">Total commercial / industrial properties</p>
        <p>{{ formatNumber(listing.commercial) }}</p>
      </div>
      <div class="col-md-6">
        <p class="listing-label">Average management fee commercial (%)</p>
        <p>{{ listing.average_management_fee_commercial }}%</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Holiday Breakdown</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">Total holiday properties</p>
        <p>{{ formatNumber(listing.holiday) }}</p>
      </div>
      <div class="col-md-6">
        <p class="listing-label">Average management fee holiday (%)</p>
        <p>{{ listing.average_management_fee_holiday }}%</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Strata / Community</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">Total strata / community properties</p>
        <p>{{ formatNumber(listing.strata) }}</p>
      </div>
      <div class="col-md-6">
        <p class="listing-label">Average management fee strata (%)</p>
        <p>{{ listing.average_management_fee_strata }}%</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Storage Sheds Breakdown</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">Total storage shed properties</p>
        <p>{{ formatNumber(listing.storage_sheds) }}</p>
      </div>
      <div class="col-md-6">
        <p class="listing-label">Average management fee storage sheds</p>
        <p>{{ listing.average_management_fee_storage_sheds }}%</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>AirBnB Breakdown</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">Total AirBnB properties</p>
        <p>{{ formatNumber(listing.airbnb) }}</p>
      </div>
      <div class="col-md-6">
        <p class="listing-label">Average management fee AirBnB</p>
        <p>{{ listing.average_management_fee_airbnb }}%</p>
      </div>
    </div>
    <!-- Updated Section End -->

    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Write Up</strong></p>
      </div>
      <div class="col-md-12">
        <p><strong>{{listing.agency_detail_title}}</strong></p>
      </div>
      <div class="col-md-12">
        <p v-html="listing.agency_details"></p>
      </div>
      <div class="gap-20"></div>
      <div class="col-md-12">
        <p><strong>Rent Roll Details</strong></p>
      </div>
      <div class="col-md-12">
        <p v-html="listing.rent_roll_details"></p>
      </div>
      <div class="gap-20"></div>
      <div class="col-md-12" v-if="listing.optional_details_label">
        <p>
          <strong>{{ listing.optional_details_label }}</strong>
        </p>
      </div>
      <div class="col-md-12" v-if="listing.optional_details_data">
        <p v-html="listing.optional_details_data"></p>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  props: {
    listing: Object
  },
  components: {
  },
  computed: {
    websiteLink() {
      return `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings/${this.listing?.identifier}`;
    },
    memorandumUrl() {
      return `${process.env.VUE_APP_ADMIN_URL}/information-memorandums/${this.listing?.memorandum?.identifier}`;
    },
    memorandunCreateUrl() {
      return `${process.env.VUE_APP_ADMIN_URL}/information-memorandums/create/${this.listing?.id}`;
    }
  },
  methods: {
    getCurrency(amount) {
      if (!amount) {
        amount = 0;
      }
      return amount.toLocaleString("en-AU", {
        style: "currency",
        currency: "AUD",
      });
    },
    formatNumber(number) {
      return parseFloat(number).toLocaleString("en-AU", {
        style: "decimal",
        minimumFractionDigits: 0,
      });
    },
  }
};
</script>
