import { mapActions } from "vuex";
import $store from "@/store";

/**
 * Simple mapping of the Vuex store UI module.
 * @module services/ui
 */
export default Object.assign(
  {
    $store
  },
  mapActions("app", ["setPageHeader", "setBreadcrumbs"])
);
