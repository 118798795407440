<template>
  <div class="entry-content">
    <!--Tabs start-->
    <div class="col-md-12">
      <div class="row">
        <!--tabs border start-->
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              @click="activeTap = 'detail'"
              :class="{ active: activeTap === 'detail' }"
            >
              <a id="view_im_tab_link" data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Information Memorandum</h4></a
              >
            </li>
            <li
              id="tab-label-02"
              @click="activeTap = 'expression_of_interest_tab_link'"
              :class="{
                active: activeTap === 'expression_of_interest_tab_link'
              }"
            >
              <a
                id="expression_of_interest_tab_link"
                data-toggle="tab"
                href="#tab-02"
                ><h4 class="panel-title">
                  Submit Expression of Interest&nbsp;&nbsp;
                  <span
                    class="badge badge-highlight font-70P"
                    v-if="expression"
                    >{{ expression.status }}</span
                  >
                </h4></a
              >
            </li>
          </ul>
          <div class="panel-body">
            <div class="tab-content">
              <div
                id="tab-01"
                class="tab-pane"
                v-if="activeTap === 'detail'"
                :class="{ active: activeTap === 'detail' }"
              >
                <!-- im details start -->
                <div class="col-md-12">
                  <div class="row">
                    <div class="alert alert-success fade in" v-if="submitted">
                      <a
                        href="#"
                        class="close"
                        data-dismiss="alert"
                        aria-label="close"
                        title="close"
                        >×</a
                      >
                      <span class="icon"><i class="fa fa-check"></i></span
                      ><strong>Success!</strong><br />The Expression Of Interest
                      has been submitted
                    </div>
                  </div>
                  <div

                    class="row"
                  >
                    <div class="col-md-12">
                      <div class="row pull-right">
                        <div class="col-md-12">
                          <button
                            class="btn btn-small"
                            type="button"
                            value=""
                            v-on:click="
                              downloadIM(
                                'blog-content',
                                data && data.listing
                                  ? data.listing.reference
                                  : ''
                              )
                            "
                          >
                            <i class="fa fa-cloud-download"></i
                            >&nbsp;&nbsp;Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gap-20"></div>
                  <div v-if="!isLoading && data">
                    <div id="blog-content" class="blog-content-max-width" ref="blogContent">
                      <Blog
                        :key="index"
                        v-for="(blog, index) in data.sections"
                        :listing="data.listing"
                        :blog="blog"
                        :isPreview="isPreview"
                      />
                    </div>
                    <div class="gap-60"></div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <button
                            class="btn btn-primary"
                            type="button"
                            v-if="
                              expression.status !== 'Submitted' &&
                                this.customer !== null
                            "
                            @click="
                              activeTap = 'expression_of_interest_tab_link'
                            "
                          >
                            Submit an Expression of Interest
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <hr />
                      </div>
                      <div class="col-md-6">
                        <p class="listing-label">Created</p>
                        <p>{{ data.created_at }}</p>
                        <br />
                      </div>
                      <div class="col-md-6">
                        <p class="listing-label">Last Modified</p>
                        <p>{{ data.updated_at }}</p>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div v-if="isLoading" class="text-center">
                    <div class="loading"></div>
                  </div>
                </div>
                <!-- im details end -->
              </div>
              <div
                v-if="activeTap === 'expression_of_interest_tab_link'"
                :class="{
                  active: activeTap === 'expression_of_interest_tab_link'
                }"
                id="tab-02"
                class="tab-pane"
              >
                <div class="col-md-12" v-if="data">
                  <div class="row">
                    <div class="alert alert-danger fade in" v-if="failed">
                      <a
                        href="#"
                        class="close"
                        data-dismiss="alert"
                        aria-label="close"
                        title="close"
                        >×</a
                      >
                      <span class="icon"
                        ><i class="fa fa-exclamation"></i
                      ></span>
                      <strong>Oops!</strong><br />Something went wrong
                      submitting your Expression of Interest. Please try again
                      later
                    </div>
                    <div class="alert alert-success fade in" v-if="drafted">
                      <a
                        href="#"
                        class="close"
                        data-dismiss="alert"
                        aria-label="close"
                        title="close"
                        >×</a
                      >
                      <span class="icon"><i class="fa fa-check"></i></span
                      ><strong>Success!</strong><br />Draft has been saved
                    </div>
                  </div>

                  <ExpressionOfInterestForm
                    :data="data"
                    :expression="expression"
                    :customer="customer"
                    @submitted="handleSubmitted"
                    @failed="handleFailed"
                    @drafted="handleDraft"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Blog from "./components/View/Blog/Index";
import ExpressionOfInterestForm from "./ExpressionOfInterestForm.vue";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default {
  components: {
    Blog,
    ExpressionOfInterestForm
  },
  data() {
    return {
      updateAvailable: false,
      isSubmitting: false,
      activeTap: this.$route.query.tab
        ? "expression_of_interest_tab_link"
        : "detail",
      formData: {},
      errors: {},
      submitted: false,
      failed: false,
      drafted: false,
      isPreview: false
    };
  },
  computed: {
    ...mapGetters("im", ["isLoading", "data", "expression"]),
    ...mapGetters("auth", ["customer"])
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    ...mapActions("im", ["getIm", "loadExpression"]),
    async handleSubmitted() {
      this.drafted = false;
      this.failed = false;
      this.activeTap = "detail";
      this.submitted = true;
      await this.loadExpression(this.data.interest_id);
      window.scrollTo(0, 0);
    },
    async handleFailed() {
      this.drafted = false;
      this.submitted = false;
      this.failed = true;
      window.scrollTo(0, 0);
    },
    async handleDraft() {
      this.failed = false;
      this.submitted = false;
      this.drafted = true;
      window.scrollTo(0, 0);
    },
    downloadIM(divName, reference) {
      this.isPreview = true;
      let _this = this;

      var pdf = new jsPDF("p", "pt", "a2", true, true, 2, 1.0);
      let fileName = "INFORMATION_MEMORANDUM".concat("_", reference, ".pdf");
      pdf.html(document.getElementById(divName), {
        margin: [50, 50, 50, 50],
        callback: function(pdf) {
          pdf.save(fileName);
          _this.isPreview = false;
        },

        x: 175,
        y: 50,
        useCORS: true
      });
    }
  },
  async mounted() {
    this.$ui.setPageHeader({
      title: "Customer Dashboard",
      parentTitle: "Dashboard"
    });

    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Customer Dashboard",
        url: "home"
      }
    ]);
    try {
      await this.getIm(this.$route.params.uuid);
      await this.loadExpression(this.data.interest_id);
    } catch (ex) {}
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
