<template>
  <form id="form_1" action="" method="post" role="form">
    <div class="error-container"></div>

    <div class="row">
      <div class="col-md-12">
        <p>
          <strong class="font-purple font-120P">Instructions</strong>
        </p>
      </div>
      <div class="col-md-12">
        <ol>
          <li>
            By providing the information below accurately, you will assist us to
            get you the correct information and provide you the best customer
            service / experience possible.
          </li>
          <li>
            Once you submit the online form below, you will receive an email
            notification from GetSignature to sign an
            <strong>Expression of Interest</strong>
            between you or your business entity and The Rent Roll Broker.
          </li>
          <li>
            If nothing received in your Inbox from GetSignature to sign, please
            check your Junk Mail folder for this notification. Once signed,
            someone will be in touch with you within the next 48 business hours.
          </li>
        </ol>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-10">
        <p>
          <strong class="font-120P"
            >The prepared contract is set to include the following terms and
            conditions</strong
          >
        </p>
      </div>
      <div class="col-md-2 text-right">
        <SaveBtn
          v-if="expression && expression.status !== 'Submitted'"
          @draftClicked="() => handleSubmit()"
        />
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="purchase_price">The purchase price of ($) *</label>
          <input
            class="form-control"
            id="purchase_price"
            name="purchase_price"
            :disabled="disableField"
            placeholder="The purchase price ($)"
            :class="{ 'has-error': errors?.hasOwnProperty('purchase_price') }"
            type="number"
            v-model="values.purchase_price"
            required
          />
          <span
            v-if="errors?.hasOwnProperty('purchase_price')"
            class="help-block text-danger"
            >{{ errors.purchase_price[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="settlement_date">Settlement date</label>
          <div class="input-group date" id="settlement_date_picker">
            <DatePicker
              v-model="values.settlement_date"
              @update="e => {values.settlement_date = e.target.value;}"
              name="settlement_date"
              id="settlement_date"
              :disabled="disableField"
              :input-class="
                `form-control ${
                  errors?.hasOwnProperty('settlement_date') ? 'has-error' : ''
                }`
              "
              placeholder="Settlement date"
            />
<!--            <span class="input-group-addon ">-->
<!--              <span class="glyphicon glyphicon-calendar"></span>-->
<!--            </span>-->
          </div>
          <span
            v-if="errors?.hasOwnProperty('settlement_date')"
            class="help-block text-danger"
            >{{ errors.settlement_date[0] }}</span
          >
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-10">
        <p>
          <strong class="font-120P">The contract is to be subject to</strong>
        </p>
      </div>
      <div class="col-md-2 text-right">
        <SaveBtn
          v-if="expression && expression.status !== 'Submitted'"
          @draftClicked="handleSubmit"
        />
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="bank">Finance from</label>
          <input
            class="form-control"
            id="bank"
            name="bank"
            :disabled="disableField"
            :class="{ 'has-error': errors?.hasOwnProperty('contract.bank') }"
            placeholder="Financer / Bank"
            type="text"
            v-model="values.contract.bank"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="finance_amount">Finance amount ($)</label>
          <input
            class="form-control"
            id="finance_amount"
            :disabled="disableField"
            :class="{
              'has-error': errors?.hasOwnProperty('contract.finance_amount')
            }"
            name="finance_amount"
            placeholder="Finance amount ($)"
            type="number"
            v-model="values.contract.finance_amount"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="interest_rate">Interest rate (%)</label>
          <input
            class="form-control"
            id="interest_rate"
            :disabled="disableField"
            :class="{
              'has-error': errors?.hasOwnProperty('contract.interest_rate')
            }"
            name="interest_rate"
            placeholder="Not exceeding (%)"
            type="number"
            v-model="values.contract.interest_rate"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="settlement_date">Approval date</label>
          <div class="input-group date" id="approval_date_picker">
            <DatePicker
              v-model="values.contract.approval_date"
              @update="e => {values.contract.approval_date = e.target.value;}"
              name="approval_date"
              id="approval_date"
              :disabled="disableField"
              :input-class="
                `form-control ${
                  errors?.hasOwnProperty('contract.approval_date')
                    ? 'has-error'
                    : ''
                }`
              "
              placeholder="Approval date"
            />
<!--            <span class="input-group-addon">-->
<!--              <span class="glyphicon glyphicon-calendar"></span>-->
<!--            </span>-->
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <label for="other_details">Other details</label>
        <div class="form-group">
          <textarea
            class="form-control form-control-message"
            name="other_details"
            id="other_details"
            :disabled="disableField"
            :class="{
              'has-error': errors?.hasOwnProperty('contract.other_details')
            }"
            placeholder="Other Details"
            v-model="values.contract.other_details"
            rows="5"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-10">
        <p>
          <strong class="font-120P">Contact / Representative Details</strong>
        </p>
      </div>
      <div class="col-md-2 text-right">
        <SaveBtn
          v-if="expression && expression.status !== 'Submitted'"
          @draftClicked="handleSubmit"
        />
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="first_name">First name *</label>
          <input
            class="form-control"
            id="first_name"
            :disabled="disableField"
            name="first_name"
            :class="{
              'has-error': errors?.hasOwnProperty('representative.first_name')
            }"
            placeholder="Legal first name"
            type="text"
            v-model="values.representative.first_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="surname">Surname *</label>
          <input
            class="form-control"
            id="surname"
            name="surname"
            :disabled="disableField"
            :class="{
              'has-error': errors?.hasOwnProperty('representative.last_name')
            }"
            placeholder="Legal last name / surname"
            type="text"
            v-model="values.representative.last_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="email">Email address *</label>
          <input
            class="form-control"
            id="email"
            name="email"
            :disabled="disableField"
            :class="{
              'has-error': errors?.hasOwnProperty('representative.email')
            }"
            placeholder="Best correspondence email"
            type="email"
            v-model="values.representative.email"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="contact_phone">Best contact number *</label>
          <input
            class="form-control"
            id="contact_phone"
            name="contact_phone"
            :disabled="disableField"
            :class="{
              'has-error': errors?.hasOwnProperty('representative.contact_phone')
            }"
            placeholder="Best correspondence phone number"
            type="text"
            v-model="values.representative.contact_phone"
            required
          />
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-10">
        <p>
          <strong class="font-120P">Purchaser Details</strong>
        </p>
      </div>
      <div class="col-md-2 text-right">
        <SaveBtn
          v-if="expression && expression.status !== 'Submitted'"
          @draftClicked="handleSubmit"
        />
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="same_as_contact_boolean"
            ><input
              type="checkbox"
              id="same_as_contact_boolean"
              :disabled="disableField"
              name="same_as_contact_boolean"
              v-model="values.same_as_contact"
              @change="handleSameContact"
              checked
            />&nbsp;&nbsp; Same as contact</label
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="purchaser_first_name">First name *</label>
          <input
            class="form-control"
            id="purchaser_first_name"
            name="purchaser_first_name"
            :disabled="disableField"
            placeholder="Legal first name"
            :class="{
              'has-error': errors?.hasOwnProperty('purchaser.first_name')
            }"
            type="text"
            v-model="values.purchaser.first_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="purchaser_surname">Surname *</label>
          <input
            class="form-control"
            id="purchaser_surname"
            :disabled="disableField"
            name="purchaser_surname"
            :class="{
              'has-error': errors?.hasOwnProperty('purchaser.last_name')
            }"
            placeholder="Legal last name / surname"
            type="text"
            v-model="values.purchaser.last_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="purchaser_email">Email address *</label>
          <input
            class="form-control"
            id="purchaser_email"
            :disabled="disableField"
            name="purchaser_email"
            placeholder="Best correspondence email"
            :class="{ 'has-error': errors?.hasOwnProperty('purchaser.email') }"
            type="email"
            v-model="values.purchaser.email"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="purchaser_contact_phone">Best contact number *</label>
          <input
            class="form-control"
            id="purchaser_contact_phone"
            :disabled="disableField"
            name="purchaser_contact_phone"
            placeholder="Best correspondence phone number"
            :class="{
              'has-error': errors?.hasOwnProperty('purchaser.contact_phone')
            }"
            type="text"
            v-model="values.purchaser.contact_phone"
            required
          />
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-10">
        <p>
          <strong class="font-120P">Solicitor / Conveyance Details</strong>
        </p>
      </div>
      <div class="col-md-2 text-right">
        <SaveBtn
          v-if="expression && expression.status !== 'Submitted'"
          @draftClicked="handleSubmit"
        />
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="conveyance_solicitor_first_name">Company name *</label>
          <input
            class="form-control"
            id="conveyance_solicitor_company_name"
            :disabled="disableField"
            name="conveyance_solicitor_company_name"
            :class="{
              'has-error': errors?.hasOwnProperty('solicitor.company_name')
            }"
            placeholder="Legal company name"
            type="text"
            v-model="values.solicitor.company_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="conveyance_solicitor_first_name"
            >Contact first name *</label
          >
          <input
            class="form-control"
            id="conveyance_solicitor_first_name"
            name="conveyance_solicitor_first_name"
            :disabled="disableField"
            placeholder="Legal first name"
            :class="{
              'has-error': errors?.hasOwnProperty('solicitor.first_name')
            }"
            type="text"
            v-model="values.solicitor.first_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="conveyance_solicitor_surname">Contact surname *</label>
          <input
            class="form-control"
            id="conveyance_solicitor_surname"
            name="conveyance_solicitor_surname"
            :disabled="disableField"
            placeholder="Legal last name / surname"
            :class="{
              'has-error': errors?.hasOwnProperty('solicitor.last_name')
            }"
            type="text"
            v-model="values.solicitor.last_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="conveyance_solicitor_email">Contact mail address *</label>
          <input
            class="form-control"
            id="conveyance_solicitor_email"
            :disabled="disableField"
            name="conveyance_solicitor_email"
            placeholder="Best correspondence email"
            :class="{ 'has-error': errors?.hasOwnProperty('solicitor.email') }"
            type="email"
            v-model="values.solicitor.email"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="conveyance_solicitor_contact_phone"
            >Contact best contact number *</label
          >
          <input
            class="form-control"
            id="conveyance_solicitor_contact_phone"
            name="conveyance_solicitor_contact_phone"
            :disabled="disableField"
            placeholder="Best correspondence phone number"
            type="text"
            :class="{
              'has-error': errors?.hasOwnProperty('solicitor.contact_phone')
            }"
            v-model="values.solicitor.contact_phone"
            required
          />
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-10">
        <p>
          <strong class="font-120P">Guarantor Details</strong>
        </p>
      </div>
      <div class="col-md-2 text-right">
        <SaveBtn
          v-if="expression && expression.status !== 'Submitted'"
          @draftClicked="handleSubmit"
        />
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="guarantor_first_name">First name *</label>
          <input
            class="form-control"
            id="guarantor_first_name"
            :disabled="disableField"
            name="guarantor_first_name"
            :class="{
              'has-error': errors?.hasOwnProperty('guarantor.first_name')
            }"
            placeholder="Legal first name"
            type="text"
            v-model="values.guarantor.first_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="guarantor_surname">Surname *</label>
          <input
            class="form-control"
            id="guarantor_surname"
            :disabled="disableField"
            name="guarantor_surname"
            :class="{
              'has-error': errors?.hasOwnProperty('guarantor.last_name')
            }"
            placeholder="Legal last name / surname"
            type="text"
            v-model="values.guarantor.last_name"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="guarantor_email">Email address *</label>
          <input
            class="form-control"
            id="guarantor_email"
            :disabled="disableField"
            name="guarantor_email"
            :class="{ 'has-error': errors?.hasOwnProperty('guarantor.email') }"
            placeholder="Best correspondence email"
            type="email"
            v-model="values.guarantor.email"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="guarantor_contact_phone">Best contact number *</label>
          <input
            class="form-control"
            id="guarantor_contact_phone"
            :disabled="disableField"
            name="guarantor_contact_phone"
            :class="{
              'has-error': errors?.hasOwnProperty('guarantor.contact_phone')
            }"
            placeholder="Best correspondence phone number"
            type="text"
            v-model="values.guarantor.contact_phone"
            required
          />
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-10">
        <p>
          <strong class="font-120P">Company/Business/Individual Details</strong>
        </p>
      </div>
      <div class="col-md-2 text-right">
        <SaveBtn
          v-if="expression && expression.status !== 'Submitted'"
          @draftClicked="handleSubmit"
        />
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="business_name">Legal business name</label>
          <input
            class="form-control"
            id="business_name"
            :disabled="disableField"
            name="business_name"
            :class="{ 'has-error': errors?.hasOwnProperty('business.name') }"
            placeholder="The legal business name"
            type="text"
            v-model="values.business.name"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="business_abn">ABN / ACN </label>
          <input
            class="form-control"
            id="business_abn"
            :disabled="disableField"
            name="business_abn"
            :class="{ 'has-error': errors?.hasOwnProperty('business.abn') }"
            placeholder="The business ABN / ACN"
            type="text"
            v-model="values.business.abn"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="trading_name">Trading name</label>
          <input
            class="form-control"
            id="trading_name"
            :disabled="disableField"
            name="trading_name"
            placeholder="Trading name"
            :class="{
              'has-error': errors?.hasOwnProperty('business.trading_name')
            }"
            type="text"
            v-model="values.business.trading_name"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="director_name">Director's Name(s)</label>
          <input
            class="form-control"
            id="director_name"
            :disabled="disableField"
            :class="{
              'has-error': errors?.hasOwnProperty('business.director_name')
            }"
            name="director_name"
            placeholder="Director's Name(s)"
            type="text"
            v-model="values.business.director_name"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="street_address">Street address *</label>
          <input
            class="form-control"
            id="street_address"
            :disabled="disableField"
            name="street_address"
            :class="{
              'has-error': errors?.hasOwnProperty('business.street_address')
            }"
            placeholder="The registered business street address"
            type="text"
            v-model="values.business.street_address"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="suburb">Suburb *</label>
          <input
            class="form-control"
            id="suburb"
            :disabled="disableField"
            name="suburb"
            :class="{ 'has-error': errors?.hasOwnProperty('business.suburb') }"
            placeholder="Registered suburb"
            type="text"
            v-model="values.business.suburb"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <Select
            name="state"
            label="State *"
            :value="values.business.state_id"
            :class-name="
              `${errors?.hasOwnProperty('business.state_id') ? 'has-error' : ''}`
            "
            required
            :disabled="disableField"
            placeholder="Select a state"
            :url="stateSearch"
            :axios="getGeoLocations"
            @selected="
              value =>
                handleDropdownChange(
                  value ? value.id : null,
                  'business.state_id'
                )
            "
            showother
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="postcode">Postcode *</label>
          <input
            class="form-control"
            id="postcode"
            :disabled="disableField"
            :class="{ 'has-error': errors?.hasOwnProperty('business.postcode') }"
            name="postcode"
            placeholder="Registered postcode"
            type="text"
            v-model="values.business.postcode"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <Select
            name="country"
            label="Country *"
            required
            :disabled="disableField"
            :class-name="
              `${
                errors?.hasOwnProperty('business.country_id') ? 'has-error' : ''
              }`
            "
            placeholder="Select a Country"
            :value="
              values.business.country_id ? values.business.country_id : 14
            "
            :url="countrySearch"
            :axios="getGeoLocations"
            @selected="
              value =>
                handleDropdownChange(
                  value ? value.id : null,
                  'business.country_id'
                )
            "
          />
        </div>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <SubmitButton
            v-if="expression.status !== 'Submitted' && this.customer !== null"
            :isProgress="isLoading"
            :caption="
              isLoading ? 'Submitting' : 'Submit an Expression of Interest'
            "
            @submit="handleSubmit('Submitted')"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DatePicker from "@/components/Form/DatePicker";
import Select from "@/components/Form/Select";
import SaveBtn from "@/components/SaveBtn";
import SubmitButton from "@/components/SubmitButton";

import map from "lodash-es/map";
import set from "lodash-es/set";
import get from "lodash-es/get";
import moment from "moment";
export default {
  computed: {
    getGeoLocations() {
      return this.$http;
    },
    disableField() {
      return this.expression && this.expression.status === "Submitted";
    }
  },
  components: {
    DatePicker,
    Select,
    SaveBtn,
    SubmitButton
  },
  props: {
    data: Object,
    expression: Object,
    customer: Object
  },
  data() {
    return {
      values: {
        contract: {},
        representative: {
          ...this.customer,
          contact_phone: this.customer ? this.customer.phone_number : ""
        },
        purchaser: {
          ...this.customer,
          contact_phone: this.customer ? this.customer.phone_number : ""
        },
        solicitor: {},
        guarantor: {},
        business: {
          ...this.customer?.business
        },
        interest_id: this.data ? this.data.interest_id : "",
        same_as_contact: this.expression
          ? !!this.expression.same_as_contact
          : true
      },
      errors: {},
      countrySearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/countries/search`,
      stateSearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`,
      isLoading: false
    };
  },
  methods: {
    handleDropdownChange(value, param) {
      set(this.values, param, value);
    },
    async handleSubmit(status) {
      this.isLoading = true;
      var data = null;
      try {
        data = await this.$http.post(
          `${process.env.VUE_APP_INTEREST_API_URL}/admin/expressions`,
          {
            ...this.values,
            business: {
              ...this.values.business
            },
            settlement_date: this.values.settlement_date
              ? moment(this.values.settlement_date).format("YYYY-MM-DD")
              : null,
            contract: {
              ...this.values.contract,
              approval_date:
                this.values.contract && this.values.contract.approval_date
                  ? moment(this.values.contract.approval_date).format(
                      "YYYY-MM-DD"
                    )
                  : null,
              finance_amount:
                this.values.contract && this.values.contract.finance_amount
                  ? this.values.contract.finance_amount
                  : null
            },
            status: status ? status : null,
            purchase_price: this.values.purchase_price
              ? this.values.purchase_price
              : null
          }
        );


        // If expression submitted successfully
        if (data !== null && data !== undefined) {
          if (data.status === "Submitted" || data.status === "Pending") {
            this.$emit("submitted");
          } else if (data.status === "Failed") {
            this.$emit("failed");
          } else if (data.status === "Draft") {
            this.$emit("drafted");
          }

          await this.$router.push({
            name: "email_verification",
            params: {interestID: data.interest.uuid, userUUID: data.interest.customer.user.identifier}
          });
        }

        /*
        if (this.expression) {
          await this.$router.push({
            name: "email_verification",
            params: {interestID: this.expression.interest.uuid, userUUID: this.expression.interest.customer.user.identifier}
          });
        }
        */

        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        console.log(get(e, "data.errors"));
        this.isLoading = false;
      }
    },
    handleSameContact() {
      if (this.values.same_as_contact) {
        this.values.purchaser = {
          ...this.values.representative
        };
      } else {
        this.values.purchaser.first_name = null;
        this.values.purchaser.last_name = null;
        this.values.purchaser.email = null;
        this.values.purchaser.contact_phone = null;
      }
    },
    async loadData() {
      map(this.expression, (value, index) => {
        const key = get(index.split("_"), "0");
        const valueKey = index.substring(index.indexOf("_") + 1);
        if (this.values.hasOwnProperty(key)) {
          this.values = {
            ...this.values,
            [key]: {
              ...this.values[key],
              [valueKey]:
                valueKey === "approval_date"
                  ? moment().format("YYYY-MM-DD")
                  : value
            }
          };
        }
      });
      if (this.expression) {
        this.values = {
          ...this.values,
          purchase_price: this.expression.purchase_price
            ? this.expression.purchase_price
            : null,
          business: {
            ...this.values.business,
            ...this.expression.business,
            trading_name: this.expression.trading_name
              ? this.expression.trading_name
              : this.values.business.name,
            director_name: this.expression.director_name
              ? this.expression.director_name
              : null
          }
        };
        this.values.representative.first_name = this.values.representative.contact.first_name;
        this.values.representative.last_name = this.values.representative.contact.last_name;
        this.values.representative.email = this.values.representative.contact.email;
        this.values.representative.contact_phone = this.values.representative.contact.phone_number;
        this.values.settlement_date = this.expression.settlement_date
          ? this.expression.settlement_date
          : moment().format("YYYY-MM-DD");
      }
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    expression: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.loadData();
        }
      }
    }
  }
};
</script>
