<template>
  <div class="row">
    <div class="col-md-12">
      <div class="entry-header-admin">
        <div class="gap-40"></div>
        <div class="col-md-12 entry-title-admin">
          <div class="row">
            <div class="pull-left">
              <p class="font-120P">Website Approval Tasks</p>
            </div>
            <div v-show="isSuperAdmin" class="pull-right text-right">
              <button class="btn btn-small" type="button" value="" @click="openAllTask"><i class="fa fa-close"></i>&nbsp;&nbsp;CLEAR ALL TASKS</button>
            </div>
          </div>
        </div>
      </div>
      <div class="entry-content">
        <div class="gap-20"></div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 lh-200">
              <ul class="unstyled">
                <li v-for="task in systemTasks" :key="task.id">
                  <i class="fa fa-calendar-check-o font-orange"></i>
                  <router-link
                    class="ml-4"
                    :to="{
                      name: task.url,
                      params: { id: task.id }
                    }"
                  >
                    {{ task.task }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="gap-40"></div>
      <div class="entry-header-admin">
        <div class="gap-40"></div>
        <div class="col-md-12 entry-title-admin">
          <div class="row">
            <div class="pull-left">
              <p class="font-120P">Today's Tasks</p>
            </div>
          </div>
        </div>
      </div>
      <div class="entry-content">
        <div class="gap-20"></div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 lh-200">
              <ul class="unstyled">
                <li v-for="task in todayTasks" :key="task.id">
                  <i class="fa fa-calendar-check-o font-orange"></i>
                  <a
                    href="javascript:void(0);"
                    @click="handleTaskOpen(task)"
                    class="ml-4"
                  >
                    {{ task.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationTaskModel v-model:model-value="showModal" @clear-all-tasks="clearAllTasks"/>
  </div>
</template>

<script>
import get from "lodash-es/get";
import { mapActions, mapGetters } from 'vuex'
import ConfirmationTaskModel from '@/components/ConfirmationTaskModel.vue'
import { filterByType } from '@/utils/helpers'

export default {
  components: {
    ConfirmationTaskModel
  },
  data: () => ({
    isLoading: false,
    systemTasks: [],
    todayTasks: [],
    showModal: false,
  }),
  computed: {
    ...mapGetters({
      isSuperAdmin: "auth/isSuperAdmin"
    }),
  },
  methods: {
    ...mapActions({
      setInitialValues: "task/setInitialValues"
    }),
    async getSystemTasks() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_TASKS_API_URL}/admin/tasks/system`
        );
        this.systemTasks = data;
      } catch (e) {
        this.errors = get(e, "data.errors");
      }
    },
    async getOpenTasks() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_TASKS_API_URL}/admin/tasks/today`
        );
        this.todayTasks = data;
      } catch (e) {
        this.errors = get(e, "data.errors");
      }
    },
    handleTaskOpen(task) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          task: task.identifier
        }
      });
      this.$store.commit("task/RESET_FORM");

      this.setInitialValues({
        related: this.$route.meta.model,
        id: this.$route.meta.id,
        task: task.identifier
      });
      this.$store.commit("task/SET_IS_OPEN", true);
    },
    openAllTask(){
      if (!this.isSuperAdmin) return false;
      this.showModal = true
    },
    async clearAllTasks () {
      const taskTypes = ['customers', 'affiliates', 'listings', 'news', 'documents', 'users']
      const tasksByType = taskTypes.map(type => ({
        type,
        tasks: filterByType(this.systemTasks, type),
      }))

      const promises = tasksByType.filter(({ tasks }) => tasks.length > 0).map(({ type, tasks }) => {
        if (type === 'customers') return this.clearCustomerTask(tasks);
        if (type === 'affiliates') return this.clearAffiliatesTask(tasks);
        if (type === 'listings') return this.clearListingsTask(tasks);
        if (type === 'news') return this.clearNewsTask(tasks);
        if (type === 'documents') return this.clearDocumentsTask(tasks);
        if (type === 'users') return this.clearUsersTask(tasks);
      })

      if (promises.length > 0) {
        Promise.all(promises).then(() => {
          this.showModal = false
          this.getSystemTasks()
        }).catch(err => {
          console.error(err)
        })
      }
    },
    async clearCustomerTask(customer){
      const customerIds = customer.map((task) => task.id)
      const data = {identifier: customerIds}
      await this.$http.patch(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/approval-tasks`, data,
      ).catch((err) => console.error(err))
    },
    async clearAffiliatesTask(affiliates){
      const affiliateIds = affiliates.map((task) => task.id)
      const data = {identifier: affiliateIds}
    },
    async clearListingsTask(listings){
      const listingIds = listings.map((task) => task.id)
      const data = {identifier: listingIds}
      await this.$http.patch(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings/approval-tasks`, data,
      ).catch((err) => console.error(err))
    },
    async clearNewsTask(news){
      const newsIds = news.map((task) => task.id)
      const data = {identifier: newsIds}
    },
    async clearDocumentsTask(documents){
      const documentIds = documents.map((task) => task.id)
      const data = {identifier: documentIds}
    },
    async clearUsersTask(users){
      const userIds = users.map((task) => task.id)
      const data = {identifier: userIds}
    }
  },
  async created() {
    this.isLoading = true;
    await this.getSystemTasks();
    await this.getOpenTasks();
    this.isLoading = false;

    this.$emitter.on("task_created", () => {
      this.getOpenTasks();
    });
  }
};
</script>
