import { configs } from "@/lib/axios";
import map from "lodash-es/map";

export const CONFIGS = "CONFIGS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const PAGE_TITLE = "PAGE_TITLE";
export const PAGE_SHOW_TASK = "PAGE_SHOW_TASK";
export const PAGE_PARENT_TITLE = "PAGE_PARENT_TITLE";
export const PAGE_ITEM_TITLE = "PAGE_ITEM_TITLE";
export const PAGE_ITEM = "PAGE_ITEM";
export const PAGE_SLIDER_PANEL = "PAGE_SLIDER_PANEL";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";

const state = {
  menus: [],
  footer: [],
  permissions: [],
  breadcrumbs: [],
  page: {
    showTask: false,
    pageItemTitle: null,
    pageItem: null
  }
};

// getters
const getters = {
  menus: state => {
    return state.menus;
  },
  footer: state => {
    return state.footer;
  },
  permissions: state => {
    return state.permissions;
  },
  page: state => {
    return state.page;
  },
  breadcrumbs: state => {
    return state.breadcrumbs;
  }
};

// actions
const actions = {
  async getMenus({ commit }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await configs.get("/proxy/configurations");
      commit(CONFIGS, data);
    } catch (error) {
      throw error;
    }
  },
  async getPermissions({ commit }) {
    try {
      const { data } = await this.$http.get(`/api/admin/permissions/meta`);
      commit(SET_PERMISSIONS, data);
    } catch (error) {
      console.log(error);
    }
  },
  async setStats({ commit }, { group, value }) {
    console.log(group, value);
  },
  async setPageHeader(
    { commit },
    { title, showTask, parentTitle, pageItemTitle, pageItem }
  ) {
    commit(PAGE_TITLE, title);
    commit(PAGE_SHOW_TASK, showTask);
    commit(PAGE_PARENT_TITLE, parentTitle);
    commit(PAGE_ITEM_TITLE, pageItemTitle);
    commit(PAGE_ITEM, pageItem);
  },
  async setBreadcrumbs({ commit }, items) {
    commit(SET_BREADCRUMBS, items);
  }
};

// mutations
const mutations = {
  [CONFIGS]: (state, configs) => {
    let footerData = {};
    map(configs.footer, (footer, key) => {
      let footerValue = {};
      if (footer.name) {
        footerValue = {
          ...footer,
          route: `${footer.base
            .replace("[WEB]", process.env.VUE_APP_WEB_URL)
            .replace("[ADMIN]", process.env.VUE_APP_ADMIN_URL)}${footer.path}`
        };
      } else {
        footerValue = map(footer, item => {
          item.route = `${item.base
            .replace("[WEB]", process.env.VUE_APP_WEB_URL)
            .replace("[ADMIN]", process.env.VUE_APP_ADMIN_URL)}${item.path}`;
          return item;
        });
      }

      footerData[key] = footerValue;
    });
    state.menus = map(configs.menus, menu => {
      if (!(menu.child && menu.child.length)) {
        menu.route = `${menu.base
          .replace("[WEB]", process.env.VUE_APP_WEB_URL)
          .replace("[ADMIN]", process.env.VUE_APP_ADMIN_URL)}${menu.path}`;
      } else {
        menu.child = menu.child.map(childMenu => {
          childMenu.route = `${childMenu.base
            .replace("[WEB]", process.env.VUE_APP_WEB_URL)
            .replace("[ADMIN]", process.env.VUE_APP_ADMIN_URL)}${
            childMenu.path
          }`;
          return childMenu;
        });
      }
      return menu;
    });
    state.footer = footerData;
  },
  [SET_PERMISSIONS]: (state, permissions) => {
    state.permissions = permissions;
  },
  [PAGE_TITLE]: (state, title) => {
    state.page.title = title;
  },
  [PAGE_SHOW_TASK]: (state, showTask) => {
    state.page.showTask = showTask === undefined ? false : showTask;
  },
  [PAGE_PARENT_TITLE]: (state, parentTitle) => {
    state.page.parentTitle = parentTitle;
  },
  [PAGE_ITEM_TITLE]: (state, pageItemTitle) => {
    state.page.pageItemTitle =
      pageItemTitle === undefined ? null : pageItemTitle;
  },
  [PAGE_ITEM]: (state, pageItem) => {
    state.page.pageItem = pageItem === undefined ? null : pageItem;
  },
  [SET_BREADCRUMBS]: (state, items) => {
    state.breadcrumbs = items;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
