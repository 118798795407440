<template>
  <div class="row">
    <div class="col-md-12">
      <button
        class="admin-accordion admin-accordion-open"
        type="button"
        @click="toggleAccordion"
      >
        <i class="fa fa-lock"></i>&nbsp;&nbsp;Permissions
      </button>
      <div :class="accordionClasses">
        <div class="gap-20"></div>

        <div class="entry-content">
          <div class="error-container"></div>

          <div class="col-md-11">
            <div class="row">

            <div
              :key="section.group"
              v-for="section in permissionMeta.permissions"

            >
              <div
                :key="item.name"
                v-for="item in section.permissions"
                class="col-md-4"
              >
                <p class="listing-label">{{ item.caption }}</p>
                <div class="form-group font-80P mb-0">
                  <label
                    ><input
                      class="form-check-input"
                      :id="item.name"
                      :name="item.name"
                      :value="item.name"
                      v-model="permissions[section.group][item.name]"
                      :checked="permissions[section.group][item.name]"
                      @change="$emit('permissions', permissions)"
                      type="checkbox"
                    />&nbsp;&nbsp;{{ item.title }}</label
                  >
                </div>
              </div>
            </div>
              <div
                  v-if="currentStatus"
                  class="col-md-4"
              >
                <p class="listing-label">BUSINESS ACTIVE STATUS</p>
                <div class="form-group font-80P">
                  <label
                      v-for="(status, index) in availableStatuses"
                      :key="index"
                      class="pr-4"
                  ><input
                      class="form-check-input"
                      type="radio"
                      :disabled="status !== 'Archived'"
                      :checked="currentStatus === status"
                      :value="status"
                      @click="updateStatus(status)"

                  />&nbsp;&nbsp; {{status}}</label
                  >
                </div>
              </div>
            </div>
            <div class="row" v-if="showDelegate">
              <div class="col-md-12">
                <p class="listing-label">Delegate all artefact ownership to: *</p>
                <div class="form-group">
                  <select class="form-control" id="admin_user" v-model="delegateTo" name="admin_user" required @change="updateDelegateTo">
                    <option selected :value="null">Please Select</option>
                    <option v-for="(admin,index) in dropDownUsers" :key="index" :value="admin.identifier">{{admin.name}} - {{admin.business_name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-1 text-right">
            <div v-if="update" class="col-md-4">
              <button
                class="btn btn-xsmall"
                type="submit"
                form="form_2"
                value="Submit"
                @click="submit"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
          <div class="gap-20"></div>
        </div>
      </div>
      <div class="gap-20"></div>
    </div>
    <ConfirmationModal ref="refConfirmationModal"
                       message="Are you sure you want to archive this artifact ?"
                       @declined="archiveDeclined"
                       @confirmed="archiveConfirmed"
    />
  </div>
</template>
<script>
import ConfirmationModal from "../../components/ConfirmationModal.vue";
export default {
  emits: ["permissions",'updateStatus','delegateTo'],
  props: {
    update: {
      type: Boolean,
      default: () => false
    },
    permissionMeta: {
      type: Object,
      default: () => {}
    },
    permissionData: {
      type: Object,
      default: () => {}
    },
    currentStatus: {
      type: [String,null],
      default: null
    },
    availableStatuses : {
      type: Array,
      default: () => []
    }
  },
  components : {
    ConfirmationModal
  },
  data() {
    return {
      isOpen: true,
      permissions: {
        ...this.permissionData
      },
      showDelegate : false,
      dropDownUsers : [],
      delegateTo : null
    };
  },
  computed: {
    accordionClasses() {
      return {
        "admin-panel-closed": !this.isOpen,
        "admin-panel": this.isOpen
      };
    }
  },
  methods: {
    submit() {
      if(this.showDelegate && this.currentStatus === "Archived" && !this.delegateTo) return
      this.$emit('submit')
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    async updateDelegateTo(){
      this.$emit('delegateTo',this.delegateTo)
    },
    archiveDeclined (currentValue){
      this.$emit('updateStatus',null)
      setTimeout(() => {
        this.$emit('updateStatus',currentValue)
        this.$refs.refConfirmationModal.toggleModal(false)
      },200)
    },
    archiveConfirmed (newValue){
      this.$emit('updateStatus',newValue)
      this.$refs.refConfirmationModal.toggleModal(false)
    },
    async updateStatus(status) {
      if( this.currentStatus === status) return
      if(status === "Archived"){
        this.$refs.refConfirmationModal.toggleModal(true,
            {current_value : this.currentStatus,new_value :status}
        );
      } else {
        this.$emit('updateStatus',status)
        this.showDelegate = status === "Archived"
      }

      if(this.showDelegate) {
        await this.getAdminForDelegate()
      }
    },
    async getAdminForDelegate (){
      const { data } = await this.$http.get(
          `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/dropdown?except_business_identifier=${this.$route?.params.id}`
      );
      this.dropDownUsers = data
    },
  }
};
</script>

<style scoped>
.mb-0 {
  margin-bottom: 0 !important;
}
</style>
