<template>
  <div class="col-md-12">
    <div class="row" v-if="!fromCustomer">
      <Permission
          ref="refPermission"
        @update="handleChangePermission"
        :default-permissions="defaultPermissions"
        header="Permissions"
        :values="values"
        @save="handleUpdatePermission"
      />
    </div>
    <div class="gap-20"></div>
    <Form
        ref="refPrimaryContact"
      :form-data="values.contact"
      :new-password="!fromCustomer"
      @newPassword="newPassword = true"
      :account="values"
      @save="$emit('save', 'contact')"
      title="Primary Contact"
      :fromCustomer="fromCustomer"
    />
<!--    @updateAll="updateAll"-->
    <div class="gap-40"></div>

    <AdditionalContact
        ref="refAdditionalContact"
      :account="values"
        @save="values => $emit('save', values)"
    />

    <div class="gap-40"></div>
    <BusinessDetails
        ref="refBusinessDetails"
      :account="values"
        @save="values => $emit('save', values)"
    />

    <div class="gap-40"></div>
    <AccountDefinition
        ref="refAccountDefinition"
      v-if="!fromCustomer"
      @save="values => $emit('save', values)"
      :form-data="values"
    />

    <div class="row">
      <Accordion v-if="values.registered_buyer === 'Yes' && fromCustomer"
        header="Rent Roll / Agency Preferences"
        icon="fa fa-building"
        closed
      >
        <AgencyPreference
            ref="refAgencyPreference"
          :form-data="values"
          @save="values => $emit('save', values)"
        />
      </Accordion>
    </div>
    <Password
      v-if="!fromCustomer"
      :visible="newPassword"
      @close="newPassword = false"
      :account="formData"
      @password="handlePasswordSave"
      :submitting="passwordSubmitting"
    />
  </div>
</template>
<script>
import Accordion from "../../../components/Accordion";
import Permission from "../../../components/Permission";
import Form from "../../../components/Contact/Form";
import AdditionalContact from "../components/AdditionalContact";
import BusinessDetails from "../components/BusinessDetails";
import AccountDefinition from "../components/AccountDefinition";
import AgencyPreference from "../components/AgencyPreference";
import Password from "./Password";
import map from "lodash-es/map";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    formData: Object,
    isLoading: Boolean,
    errors: Object,
    update: Boolean,
    submitting: Boolean,
    fromCustomer: Boolean
  },
  components: {
    Permission,
    Password,
    Form,
    AdditionalContact,
    BusinessDetails,
    AccountDefinition,
    AgencyPreference,
    Accordion
  },
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions"
    }),
    defaultPermissions() {
      let permissions = [];
      map(this.permissionMeta.module_permission?.account.permissions ?? [], group => {
        map(group.permissions, permission => {
          permissions.push({
            group: group.group,
            name: permission.caption,
            permission: permission.title,
            value: permission.name
          });
        });
      });
      return permissions;
    }
  },
  data() {
    return {
      values: {
        ...this.formData,
        registered_buyer: this.formData.registered_buyer
          ? this.formData.registered_buyer
          : "No",
        registered_seller: this.formData.registered_seller
          ? this.formData.registered_seller
          : "No"
      },
      newPassword: false,
      passwordSubmitting: false,
      isSubmitAll : false,
    };
  },
  methods: {
    ...mapActions({
      loadDropDownListData: "masterData/loadDropDownListData"
    }),
    async updateAll() {
      this.isSubmitAll = true
     await this.$refs.refPermission?.submitAll(true)
     await this.$refs.refPrimaryContact?.handleSave(true)
     await this.$refs.refAdditionalContact?.submitAll()
     await this.$refs.refAccountDefinition?.submitAll()
     await this.$refs.refBusinessDetails?.submitAll()
      this.isSubmitAll = false
    },
    handleChangePermission(values) {
      this.values.permissions = values;
    },
    async handleUpdatePermission(values) {
      try {
        await this.$http.patch(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${this.formData.id}`,
          {
            permissions: values
          }
        );
        if(!this.isSubmitAll)this.$emit("save", "permission");
      } catch {
        //
      }
    },
    async handlePasswordSave(values) {
      this.passwordSubmitting = true;
      console.log(this.formData);
      if (!this.formData.user_id) {
        console.log('if log');
        return;
      }
      try {
        await this.$http.patch(
          `/api/admin/users/${this.formData.user_id}/reset-password`,
          values
        );
        this.$emit("save", "password");
      } catch (error) {
      console.error("Error resetting password:", error);
    }
      this.passwordSubmitting = false;
    }
  },
  mounted() {
    this.loadDropDownListData();
    console.log(this.formData, "form data in the edit screen when mounted")
  }
};
</script>
