<template>
  <div class="error-container"></div>
  <div class="row">
    <div class="col-md-6">
      <div>
        <div class="form-group font-80P">
          <label>
            <input
              class="form-check-input"
              id="show_profile"
              name="show_profile"
              type="checkbox"
              v-model="showProfile"
            />&nbsp;&nbsp; Show Profile on Website as Agent</label
          >
        </div>
      </div>
      <div>
        <p>
          <strong class="font-120P"
            >{{ values.first_name }} {{ values.last_name }}</strong
          >&nbsp;&nbsp;
          <span class="badge badge-active" v-if="values.status === 'Active'">{{
            values.status
          }}</span>
          <span class="badge badge-inactive" v-if="values.status === 'Inactive'"
            >Inactive</span
          >
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-3" v-if="values.profile.status === 'Show'">
      <div>
        <div class="form-group" style="margin-bottom: 0;">
          <label>Display Order Number *</label>
        </div>
      </div>
      <div style="padding-top: 5px;">
        <select
            v-model="values.order_number"
            id="order_number"
            name="order_number"
            class="form-control"
            required
          >
          <option v-for="i in values.last_order_number" :value="i" :selected="values.order_number">{{ i }}</option>
          </select>
          <br />
      </div>
    </div>
    <div class="col-md-3" v-if="values.profile.status === 'Show'">
      <p></p>
      <br />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <p class="listing-label">Legal Business Name</p>
      <p>{{ values.business?.name }}</p>
      <br />
    </div>
    <div class="col-md-6">
      <p class="listing-label">Role Within Business</p>
      <p>{{ values.business_role }}</p>
      <br />
    </div>
    <div class="col-md-6">
      <p class="listing-label">Phone (mobile)</p>
      <p>
        <a :href="`tel:${values.contact_phone}`">{{ values.contact_phone }}</a>
      </p>
      <br />
    </div>
    <div class="col-md-6">
      <p class="listing-label">Email</p>
      <p>
        <a :href="`mailto:${values.email}`">{{ values.email }}</a>
      </p>
      <br />
    </div>
  </div>

  <div class="gap-20"></div>

  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label for="region">Sales Region *</label>
        <input
          class="form-control"
          id="region"
          name="region"
          placeholder="Sales Region"
          :class="`${errors.hasOwnProperty('sales_region') ? 'has-error' : ''}`"
          type="text"
          v-model="this.values.profile.sales_region"
          required
        />
        <span
          v-if="errors.hasOwnProperty('sales_region')"
          class="help-block text-danger"
        >
          {{ errors.sales_region[0] }}
        </span>
      </div>
    </div>
  </div>

  <div class="gap-20"></div>

  <div class="row">
    <div class="col-md-12">
      <p><strong>Agent Write-Up (short)</strong></p>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <Editor
          class="form-control form-control-message"
          name="short_note_body"
          id="short_note_body"
          placeholder="Short Note Text"
          :class="`${errors.hasOwnProperty('short_note') ? 'has-error' : ''}`"
          rows="5"
          required
          :plugins="plugins"
          :toolbar="toolbar"
          v-model="this.values.profile.short_note"
        >
        </Editor>
        <span
          v-if="errors.hasOwnProperty('short_note')"
          class="help-block text-danger"
        >
          {{ errors.short_note[0] }}
        </span>
      </div>
    </div>
  </div>

  <div class="gap-20"></div>

  <div class="row">
    <div class="col-md-12">
      <p><strong>Agent Write-Up (long)</strong></p>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <Editor
          class="form-control form-control-message"
          name="long_note_body"
          id="long_note_body"
          placeholder="Long Note Text"
          :class="`${errors.hasOwnProperty('long_note') ? 'has-error' : ''}`"
          rows="15"
          required
          :plugins="plugins"
          :toolbar="toolbar"
          v-model="this.values.profile.long_note"
        >
        </Editor>
        <span
          v-if="errors.hasOwnProperty('long_note')"
          class="help-block text-danger"
        >
          {{ errors.long_note[0] }}
        </span>
      </div>
    </div>
  </div>

  <div class="gap-40"></div>

  <div class="row">
    <div class="col-md-12">
      <p>
        <strong>Page Image</strong> -
        <span class="font-admin-help-text">Add a Profile image</span>
      </p>
    </div>
    <div class="col-md-6">
      <p class="font-admin-help-text">Dimensions: 325px x 300px</p>
      <p class="font-admin-help-text">Image type: .jpg, .png</p>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="upload_file_1">Select image to upload *</label>
        <input
          class=""
          @change="e => handleFileUpload(e, 'profile_page_image')"
          name="upload_file_1"
          id="upload_file_1"
          placeholder="Browse image (.jpg, .png)"
          :class="
            `${errors.hasOwnProperty('profile_page_image') ? 'has-error' : ''}`
          "
          type="file"
          accept="image/*"
          required
        />
        <span
          v-if="errors.hasOwnProperty('profile_page_image')"
          class="help-block text-danger"
        >
          {{ errors.profile_page_image[0] }}
        </span>
      </div>
    </div>

    <div class="gap-40"></div>

    <div class="col-md-12">
      <p>
        <strong>YouTube Video Link</strong> -
        <span class="font-admin-help-text">Add a YouTube video</span>
      </p>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <input
          class="form-control"
          id="youtube_link"
          name="youtube_link"
          placeholder="YouTube video link URL"
          type="text"
          v-model="this.values.profile.youtube_link"
          required
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label for="youtube_link_description">Video title / description </label>
        <input
          class="form-control"
          id="youtube_link_description"
          name="youtube_link_description"
          placeholder="YouTube video title / descrition"
          type="text"
          v-model="this.values.profile.youtube_link_description"
          required
        />
      </div>
    </div>
  </div>
  <div class="gap-40"></div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <SubmitButton
          :isProgress="submitting"
          :caption="submitting ? 'Updating' : 'Update User'"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import SubmitButton from "@/components/SubmitButton";
import { fileUpload } from "@/lib/axios";

export default {
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Object,
      default: () => {}
    },
    submitting: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    Editor,
    SubmitButton
  },
  data() {
    return {
      values: {
        ...this.formData,
        profile: this.formData.profile
          ? this.formData.profile
          : { type: Object, default: () => {} }
      },
      plugins: "lists advlist code link table",
      toolbar: "numlist bullist"
    };
  },
  methods: {
    async handleFileUpload(e, name) {
      const file = e.target.files[0];

      this.isUploading = {
        ...this.isUploading,
        [name]: true
      };
      try {
        const data = await this.$http.post(
          `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/signed-storage-url`,
          {
            path: this.handleGetPath(name),
            content_type: file.type
          }
        );
        if (data.url) {
          await fileUpload.put(data.url, file, {
            headers: {
              useAuth: false,
              "Content-Type": file.type,
              "x-amz-acl": "private"
            }
          });
        }
        this.isUploading = {
          ...this.isUploading,
          [name]: false
        };
        this.values.profile[name] = data.uuid;
      } catch (e) {
        console.log(e);
      }
    },
    handleGetPath(path) {
      switch (path) {
        case "profile_page_image":
          return "profiles/profile-page-images/";
      }
      return "tmp/";
    },
    handleSubmit() {
      this.$emit("submit", this.values);
    }
  },
  computed: {
    showProfile: {
      get() {
        this.values.profile.status =
          this.values.profile.status === "Draft"
            ? "Hide"
            : this.values.profile.status;
        return this.values.profile.status === "Show" ? true : false;
      },
      set(value) {
        value ? this.values.last_order_number++ : this.values.last_order_number--;
        this.values.profile.status = value === true ? "Show" : "Hide";
        value ? this.values.order_number = (this.values.last_order_number + 1) : this.values.order_number = 0;
      }
    }
  }
};
</script>
