<template>
  <div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button
          title="Save Account"
          class="btn btn-xsmall"
          :disabled="isSubmitting"
          @click="handleSave()"
        >
          <span class="font-120P"><i class="fa fa-save"></i></span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="name">Trading name</label>
          <input
            placeholder="The trading name"
            class="form-control"
            id="trading_name"
            name="trading_name"
            type="text"
            v-model="values.trading_name"
            :class="`${errors.hasOwnProperty('trading_name') ? 'has-error' : ''}`"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="name">Legal business name</label>
          <input
            placeholder="The legal business name"
            class="form-control"
            id="name"
            name="name"
            type="text"
            v-model="values.name"
            :class="`${errors.hasOwnProperty('name') ? 'has-error' : ''}`"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="abn">ABN / ACN </label>
          <input
            class="form-control"
            id="abn"
            name="abn"
            placeholder="The business ABN / ACN"
            type="text"
            v-model="values.abn"
            :class="`${errors.hasOwnProperty('abn') ? 'has-error' : ''}`"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="website">Business website </label>
          <div class="input-group mb-3">
            <div class="input-group-addon">
                      <span class="input-group-text" id="basic-addon3"
                      >https://www.</span
                      >
            </div>
            <input
                class="form-control"
                id="website"
                name="website"
                placeholder="The business website"
                type="text"
                v-model="values.website"
                :class="`${errors.hasOwnProperty('website') ? 'has-error' : ''}`"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="creation_date">Member Since</label>
          <DatePicker
            :model-value="values.creation_date ? values.creation_date : values.created_at"
            @update="
              e => {
                values.creation_date = e.target.value;
              }
            "
            name="creation_date"
            id="creation_date"
            :input-class="`form-control `"
            placeholder="Account creation date"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="note">Business Note</label>
          <textarea
            class="form-control form-control-message"
            name="note"
            id="note"
            placeholder="Business Note"
            rows="2"
            v-model="values.note"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "../Form/DatePicker";
import moment from "moment";

export default {
  components: {
    DatePicker
  },
  props: {
    business: {
      type: Object,
      default: () => ({
        trading_name: "",
        name: "",
        abn: "",
        website: "",
        note: "",
        creation_date: ""
      })
    }
  },
   data() {
    return {
      isSubmitting: false,
      errors: {},
      values: {
        ...this.business,
        // creation_date: this.creation_date ? this.creation_date : new Date()
      }
    };
  },
  methods: {
    async handleSave() {
      this.isSubmitting = true;
      if (!this.business){
        await this.$http.post(
            `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/businesses/`,
            {
              ...this.values,
              customer_id: this.$route.params.id,
              creation_date: this.values.creation_date ? (moment(this.values.creation_date).format(
                  "YYYY-MM-DD")) : ( moment(new Date()).format(
                      "YYYY-MM-DD")
              )
            }
        );
        this.$emit("save", "business");
      }else {
        try {
          await this.$http.patch(
              `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/businesses/${this.business.identifier}`,
              {
                ...this.values,
                creation_date: this.values.creation_date ? (moment(this.values.creation_date).format(
                    "YYYY-MM-DD")) : ( moment(new Date()).format(
                        "YYYY-MM-DD")
                )
              }
          );
          this.$emit("save", "business");
        } catch (error) {
          console.log(error);
        }
      }
      this.isSubmitting = false;
    }
  }
};
</script>
