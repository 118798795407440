<template>
  <div v-if="address.id" class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        Active&nbsp;&nbsp;
        <!-- Rounded switch -->
        <label class="switch">
          <input
            type="checkbox"
            id="address_active"
            name="address_active"
            :value="address.is_active"
            v-model="address.is_active"
            :checked="address.is_active"
            @change="onActiveStateChange($event)"
          />
          <span class="slider"></span> </label
        >&nbsp;&nbsp;
      </div>
      <div class="col-md-6">
        <div class="text-right">
          <button
            title="Set Contact to Primary"
            class="btn btn-xsmall"
            type="button"
            @click="$emit('setPrimary', address)"
            value="Submit"
          >
            <span class="">&nbsp;SET TO PRIMARY&nbsp;</span></button
          >&nbsp;&nbsp;&nbsp;<button
            title="Save Account"
            class="btn btn-xsmall"
            type="button"
            @click="$emit('saveAddress', address)"
            value="Submit"
          >
            <span class="font-120P"><i class="fa fa-save"></i></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!address.id" class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <p><strong class="font-120P">New Address</strong></p>
      </div>
      <div class="col-md-4">
        Primary Address&nbsp;&nbsp;
        <!-- Rounded switch -->
        <label class="switch">
          <input
            type="checkbox"
            :value="address.is_primary"
            v-model="address.is_primary"
          />
          <span class="slider"></span> </label
        >&nbsp;&nbsp;
      </div>
      <div class="col-md-2">
        <div class="text-right">
          <button
            title="Save Account"
            class="btn btn-xsmall"
            type="button"
            @click="$emit('saveAddress', address)"
            value="Submit"
          >
            <span class="font-120P"><i class="fa fa-save"></i></span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="gap-40"></div>

  <div class="col-md-6">
    <div class="form-group">
      <label for="business_address_discription"
        >Business address description
      </label>
      <input
        class="form-control"
        id="business_address_discription"
        name="business_address_discription"
        v-model="address.description"
        placeholder="The business address description"
        type="text"
      />
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label for="business_phone">Business land-line number </label>
      <input
        class="form-control"
        id="business_phone"
        name="business_phone"
        v-model="address.phone"
        placeholder="The business land-line number"
        type="text"
      />
    </div>
  </div>

  <div class="col-md-12">
    <div class="form-group">
      <label for="street_address">Street address</label>
      <input
        class="form-control"
        id="street_address"
        name="street_address"
        v-model="address.street_address"
        placeholder="The registered business street address"
        type="text"
      />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="suburb">Suburb</label>
      <input
        class="form-control"
        id="suburb"
        name="suburb"
        v-model="address.suburb"
        placeholder="Registered suburb"
        type="text"
      />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="state">State *</label>
      <select
        class="form-control"
        id="state"
        name="state"
        v-model="address.state.id"
        required
      >
        <option value="" selected disabled>Please Select</option>
        <option :key="item.id" v-for="item in states" :value="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="postcode">Postcode</label>
      <input
        class="form-control"
        id="postcode"
        name="postcode"
        v-model="address.postcode"
        placeholder="Registered postcode"
        type="text"
      />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="country">Country *</label>
      <select
        class="form-control"
        id="country"
        name="country"
        v-model="address.country.id"
        required
      >
        <option value="" selected disabled>Please Select</option>
        <option :key="item.id" v-for="item in countries" :value="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group">
      <label for="address_note">Address Note</label>
      <textarea
        class="form-control form-control-message"
        name="address_note"
        id="address_note"
        v-model="address.note"
        placeholder="Address Note"
        rows="2"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["setPrimary", "saveAddress"],
  props: {
    stateData: {
      type: Object,
      default: () => {}
    },
    countryData: {
      type: Object,
      default: () => {}
    },
    addressData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      address: {
        ...this.addressData,
        state: {
          ...this.addressData.state
        },
        country: {
          ...this.addressData.country
        }
      }
    };
  },
  computed: {
    states() {
      return this.stateData;
    },
    countries() {
      return this.countryData;
    }
  },
  methods: {
    onActiveStateChange(event) {
      this.address_is_active = event.target.checked;
    }
  }
};
</script>

<style scoped></style>
