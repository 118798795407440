<template>
  <div class="col-md-12">
    <div v-if="!stageDetails && !isLoading">
      <div class="row">
        <div class="col-md-12">
          <p>
            <strong class="font-90P">Stage Not Found</strong>
          </p>
        </div>
      </div>
    </div>
    <div v-if="isLoading">
      <div class="row">
        <div class="col-md-12 text-center">
          <p>
            <strong class="font-90P">Loading...</strong>
          </p>
        </div>
      </div>
    </div>
    <div v-if="stageDetails && !isLoading">
      <div class="row">
        <div class="col-md-12">
          <p>
            <strong class="font-purple font-90P"
              >Project Workflow {{ stageDetails.key.replace("_", " ") }}</strong
            >
          </p>
        </div>
      </div>
      <div class="gap-10"></div>
      <div class="row">
        <div class="col-md-10">
          <div id="process_description_1" v-if="!editName">
            <p class="font-120P">
              <span class="listing-label">Process:&nbsp;&nbsp;</span
              ><strong
                >{{ stageDetails.title }}&nbsp;&nbsp;&nbsp;<span
                  class="font-80P font-green"
                  >{{ stageDetails.completed_percentage }}% complete</span
                ></strong
              >
            </p>
          </div>
          <div class="form-group" v-else>
            <input
              class="form-control"
              name="edit_process_description_1_text"
              id="edit_process_description_1_text"
              placeholder="Process description"
              :value="stageDetails.title"
              v-on:input="$event => handleChangeName($event, 'title')"
              v-on:blur="$event => handleChangeName($event, 'title')"
            />
          </div>
        </div>
        <div class="col-md-2 text-right">
          <button
            class="btn btn-xxsmall"
            type="button"
            v-if="!editName"
            title="Edit Process"
            @click="editName = true"
          >
            <i class="fa fa-edit"></i>
          </button>
          <button
            class="btn btn-xxsmall"
            type="button"
            title="Save Event"
            v-else
            @click="handleSaveName"
          >
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="entry-content">
        <div class="gap-20"></div>
        <Activity
          :key="activity.id"
          :activity="activity"
          v-for="(activity, index) in stageDetails.activities"
          @updated="title => handleActivityUpdated(index, title)"
          @eventUpdated="values => handleEventUpdate(index, values)"
          @eventCreated="() => getStageDetails(true)"
        />
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="gap-20"></div>
          <div class="bottom-border"></div>
          <div class="gap-20"></div>

          <div class="row">
            <div
              name="new_activity_description"
              id="new_activity_description"
              class="col-md-10"
            >
              <div class="form-group">
                <label for="new_activity_description_text"
                  >New Activity *</label
                >
                <input
                  class="form-control"
                  name="new_activity_description_text"
                  id="new_activity_description_text"
                  placeholder="New activity description"
                  v-model="activity.title"
                />
              </div>
            </div>
            <div class="col-md-2 text-right">
              <button
                class="btn btn-xxsmall"
                type="button"
                title="Save"
                :class="{ disabled: !activity.title }"
                :disabled="!activity.title"
                @click="handleSubmitActivity"
              >
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Activity from "./Activity";
export default {
  props: {
    stage: Object,
    project: Object
  },
  components: {
    Activity
  },
  data() {
    return {
      isLoading: false,
      stageDetails: null,
      editName: false,
      activitySubmitting: false,
      title: "",
      activity: {
        title: ""
      }
    };
  },
  methods: {
    async getStageDetails(disableLoading = false) {
      if (!disableLoading) {
        this.isLoading = true;
      }
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/stages/${this.stage?.id}`
        );
        this.stageDetails = data;
      } catch {
        //
      }
      if (!disableLoading) {
        this.isLoading = false;
      }
    },
    async handleSaveName() {
      this.nameSubmitting = true;
      try {
        await this.$http.patch(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/stages/${this.stage?.id}`,
          {
            title: this.title
          }
        );
        this.stageDetails.title = this.title;
      } catch {
        //
      }
      this.editName = false;
      this.nameSubmitting = false;
    },
    async handleActivityUpdated(index, value) {
      this.stageDetails.activities[index].title = value;
    },
    handleEventUpdate(activity, { index, values }) {
      this.stageDetails.activities[activity].events[index] = {
        ...(this.stageDetails.activities[activity].events[index]
          ? this.stageDetails.activities[activity].events[index]
          : []),
        ...values
      };
      this.getStageDetails(true);
    },
    handleChangeName($event, name) {
      this[name] = $event.target.value;
    },
    async handleSubmitActivity() {
      this.activitySubmitting = true;
      try {
        await this.$http.post(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/activities`,
          {
            ...this.activity,
            project_stage_id: this.stage.id
          }
        );
        this.activity = {
          title: ""
        };
        this.getStageDetails(true);
      } catch {
        //
      }
      this.activitySubmitting = false;
    }
  },
  mounted() {
    this.getStageDetails();
  }
};
</script>
