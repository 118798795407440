<template>
  <div class="row">
    <div class="col-md-12" v-if="account">
      <EmailListTable :queries="queries"/>
    </div>
  </div>
</template>
<script>
import {toRefs} from "vue";
import EmailListTable from "../../../components/O365/EmailListTable.vue";

export default {
  components: {
    EmailListTable,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {account} = toRefs(props);
    const queries = [];
    queries.push(account.value?.contact.email);
    queries.push(...account.value?.contacts.map(contact => contact.email));
    return {
      queries
    };
  }
}
</script>
