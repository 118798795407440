export default [
  {
    name: "Dashboards",
    href: "collapse1",
    isDefault: true,
    subs: [
      {
        name: "Account",
        path: "home",
        count: false,
        icon: "fa-address-card",
        stats: "accounts",
        show: {
          active: {
            name: "Active",
            show: true
          },
          inactive: {
            name: "Inactive",
            show: true
          }
        }
      }
    ]
  },
  {
    name: "Rent Rolls",
    href: "collapse2",
    isDefault: false,
    subs: [
      {
        name: "Listings",
        path: "listings",
        count: true,
        icon: "fa-home",
        stats: "listings",
        show: {
          active: {
            name: "Active",
            show: true
          },
          inactive: {
            name: "Inactive",
            show: false
          }
        }
      }
    ]
  }
];
