<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li class="active">
              <a data-toggle="tab" href="#tab-01"
              ><h4 class="panel-title">All Projects</h4></a
              >
            </li>
          </ul>
          <div class="panel-body">
            <div class="tab-content">
              <div id="tab-01" class="tab-pane active">
                <div class="col-md-12">
                  <div class="entry-header-admin">
                    <div class="gap-40"></div>
                    <div class="col-md-8 entry-title-admin text-left pull-left">
                      <div class="row">
                        <div class="col-md-3">
                          <p class="font-120P">All Projects</p>
                        </div>
                        <!-- Start - Available to Super Admin users only -->
                        <div class="col-md-2" v-if="user.isSuperAdmin || user.isAdmin">
                          <!-- Square switch -->
                          <label class="switch">
                            <input type="checkbox" v-model="showArchived">
                            <span class="slider"></span>
                          </label>
                        </div>
                        <div class="col-md-7" v-if="user.isSuperAdmin || user.isAdmin">
                          Show Archived Projects&nbsp;&nbsp;
                        </div>
                        <!-- End - Available to Super Admin users only -->
                      </div>
                    </div>
                    <div
                        class="col-md-4 entry-title-admin text-right pull-right"
                    >
                      <div class="row" v-if="isCreatable">
                        <router-link
                            class="btn btn-primary"
                            :to="{ name: 'projects_create' }"
                        >
                          <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Add
                          Project
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- Heading end -->

                  <!-- Task details start -->
                  <div class="entry-content">
                    <div class="gap-20"></div>
                    <ResourceTable
                        :showArchived="showArchived"
                        ref="table"
                        show-path="projects_show"
                        :columns="columns"
                        :update-available="updateAvailable"
                        :url="url"
                        :extra-params="extraParams"
                    />
                  </div>
                  <!-- Task details end -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceTable from "@/components/ResourceTable";
import columns from "./columns";
import {mapGetters} from "vuex";

export default {
  components: {
    ResourceTable
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    columns() {
      return columns;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
          ? this.permissions.permissions.includes(0)
          : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
          ? this.permissions.permissions.includes(1)
          : false;
    },
    extraParams() {
      return {
        sort_column: "created_at",
        sort_dir: "desc"
      };
    }
  },
  methods: {},
  data() {
    return {
      isLoading: false,
      url: `${process.env.VUE_APP_LISTINGS_API_URL}/admin/projects`,
      showArchived : false,
    };
  },
  mounted() {
    if (this.user.isCustomer) {
      this.$router.push({name: "unauthorized"});
    }
    this.$ui.setPageHeader({
      title: "Projects Administration",
      showTask: false
    });

    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Project Administration",
        url: "projects",
        active: true
      }
    ]);
  },
  watch : {
    showArchived() {
        setTimeout(() =>{
          this.$refs.table.pageChange(1)
        },500)
    }
  }
};
</script>
