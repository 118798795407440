<template>
  <admin-home v-if="isAdmin"></admin-home>
  <customer-home v-if="!isAdmin"></customer-home>
</template>

<script>
import AdminHome from "@/views/Home/AdminHome";
import CustomerHome from "@/views/Home/CustomerHome";
/*import Stats from "@/views/Dashboard/Stats";
import Tasks from "@/views/Dashboard/Tasks";
import PendingRequests from "@/views/Dashboard/PendingRequests";
import ActivityLog from "@/views/Dashboard/ActivityLog";
import Detail from "@/views/Dashboard/Customer/Detail";
import Update from "@/views/Dashboard/Customer/Update";
import Interests from "@/views/Dashboard/Customer/Interests";
import Listings from "@/views/Dashboard/Customer/Listings";*/
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import last from "lodash-es/last";

export default {
  components: {
    "admin-home": AdminHome,
    "customer-home": CustomerHome
    /*  Stats,
    Tasks,
    Detail,
    Update,
    Interests,
    Listings,
    PendingRequests,
    ActivityLog,*/
  },
  computed: {
    ...mapGetters("table", ["items"]),
    ...mapGetters("auth", ["isSuperAdmin", "customer", "errors"]),
    ...mapGetters({
      user: "auth/user"
    }),
    isAdmin() {
      return this.user && !this.user.isCustomer;
    }
  },
  data() {
    return {
      customerInterest: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/listings`,
      sellerListings: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/seller-listings`,
      pendingRequestsUrl: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/pending-requests`,
      siteActivityLogUrl: `${process.env.VUE_APP_ACTIVITY_API_URL}/admin/sitelogs`,
      taps: {
        admin: [
          {
            name: "Tasks",
            id: "tasks",
            count: "tasks",
            component: "Tasks",
            active: true,
            props: {
              show: false
            }
          },
          {
            name: "Statistics",
            id: "statistics",
            count: null,
            component: "Stats",
            props: {
              show: true
            }
          },
          {
            name: "Pending Sign Request",
            id: "pendingrequests",
            count: "pending-requests",
            component: "PendingRequests",
            props: {
              show: true
            }
          },
          {
            name: "Activity Log",
            id: "activitylog",
            count: null,
            component: "ActivityLog",
            props: {
              show: false
            }
          }
        ],
        customer: [
          {
            name: "View Account",
            id: "customer_accounts",
            count: null,
            component: "Detail",
            active: true,
            props: {
              updated: false,
              show: true
            }
          },
          {
            name: "Update Account",
            id: "customer_update",
            count: null,
            component: "Update",
            active: false,
            props: {
              handleSubmit: this.handleCustomerSubmit,
              show: true
            }
          },
          {
            name: "Interests",
            id: "interests",
            count: "listings",
            component: "Interests",
            active: false,
            props: {
              show: false
            }
          },
          {
            name: "Listings",
            id: "listings",
            count: "seller-listings",
            component: "Listings",
            active: false,
            props: {
              show: false
            }
          }
        ]
      },
      active: "tasks",
      activeCollection: "admin"
    };
  },
  async created() {
    // Show tasks tab if the super admin logged in
    this.taps.admin.find(
      item => item.id === "tasks"
    ).props.show = this.isSuperAdmin;
    // Show activity log tab if the super admin logged in
    this.taps.admin.find(
      item => item.id === "activitylog"
    ).props.show = this.isSuperAdmin;
    if (!this.isSuperAdmin && this.customer) {
      this.active = "customer_accounts";
      this.activeCollection = "customer";

      try {
        // await this.loadCustomer();
        // Show interestes tab if the customer is a buyer
        this.taps.customer.find(item => item.id === "interests").props.show =
          this.customer && this.customer.registered_buyer === "Yes";
        // Show listings tab if the customer is a seller
        this.taps.customer.find(item => item.id === "listings").props.show =
          this.customer && this.customer.registered_seller === "Yes";

        await this.getCustomerInterests();
      } catch (e) {}
    }
    if (!this.customer) {
      try {
        await this.getPendingRequests();
      } catch (e) {}
    }
    if (this.isSuperAdmin) {
      try {
        await this.getSiteActivityLogs();
      } catch (e) {}
    }
  },
  methods: {
    ...mapActions("auth", ["updateCustomer"]),
    ...mapActions({
      //loadUser: "auth/loadUser",
      //loadCustomer: "auth/loadCustomer",
      getData: "table/getData"
    }),
    // ...mapActions("app", ["setTitle"]),
    async handleCustomerSubmit(values) {
      try {
        await this.updateCustomer({
          values: {
            ...values,
            active_date: values.active_date
              ? moment(values.active_date).format("YYYY-MM-DD")
              : null,
            price: parseFloat(values.price),
            management_fee_income: parseFloat(values.management_fee_income),
            gross_income: parseFloat(values.gross_income),
            average_rent: parseFloat(values.average_rent),
            average_management_fee: parseFloat(values.average_management_fee),
            country_id: values.country ? values.country.id : null,
            company_id: values.company ? values.company.id : null,
            state_id: values.state ? values.state.id : null
          },
          id: this.customer.id
        });
        // If there any error (requred field errors) in submission, termonate the operation
        if (this.errors && Object.values(this.errors).length > 0) return;

        this.active = "customer_accounts";
        this.taps.customer[0].props.updated = true;
        window.scrollTo(0, 0);

        try {
          // await this.loadCustomer();
        } catch (e) {}
      } catch (e) {
        throw e;
      }
    },
    getCustomerInterests() {
      if (this.customer.registered_buyer === "Yes") {
        this.getData({
          url: this.customerInterest,
          params: { customer_id: this.customer.id },
          name: last(this.customerInterest.split("/"))
        });
      }

      if (this.customer.registered_seller === "Yes") {
        this.getData({
          url: this.sellerListings,
          params: { customer_id: this.customer.id },
          name: last(this.sellerListings.split("/"))
        });
      }
    },
    getPendingRequests() {
      this.getData({
        url: this.pendingRequestsUrl,
        params: {},
        name: last(this.pendingRequestsUrl.split("/"))
      });
    },
    getSiteActivityLogs() {
      this.getData({
        url: this.siteActivityLogUrl,
        params: {},
        name: last(this.siteActivityLogUrl.split("/"))
      });
    },
    openTaskModel(page) {
      console.log(`Task opened for page ${page}`);
    }
  }
  /*mounted() {
    this.$ui.setPageHeader({
      title: "Calendar & Tasks Dashboard",
      showTask: true,
      parentTitle: "Calendar Dashboard",
    });

    this.$emitter.on("task-clicked-event", function (page) {
      console.log(`Task Opend for page ${page}`);
    });
  },*/
};
</script>

<style></style>
