<template>
  <div class="col-md-12" v-if="business">
    <div class="entry-content">
      <div class="row" v-if="created">
        <div class="alert alert-success fade in">
          <a
            href="#"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            title="close"
            >×</a
          >
          <span class="icon"><i class="fa fa-check"></i></span
          ><strong>Success!</strong><br />{{ business.name }}'s details has been
          created
        </div>
        <!-- Alert success end -->
      </div>
      <div class="row" v-if="updated">
        <div id="alert-mail-success" class="alert alert-success fade in">
          <a
            href="#"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            title="close"
            >×</a
          >
          <span class="icon"><i class="fa fa-check"></i></span
          ><strong>Success!</strong><br />{{ business.name }}'s details have
          been updated
        </div>
        <!-- Alert success end -->
      </div>
      <div class="row" v-if="permissionUpdate">
        <div id="alert-mail-success" class="alert alert-success fade in">
          <a
            href="#"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            title="close"
            >×</a
          >
          <span class="icon"><i class="fa fa-check"></i></span
          ><strong>Success!</strong><br />{{ business.name }}'s permissions have
          been updated
        </div>
        <!-- Alert success end -->
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="listing-label">Legal Business Name</p>
          <p>
            <strong class="font-120P">{{ business.name }}</strong
            >&nbsp;&nbsp;<span
              class="badge"
              :class="{
                'badge-active': business.status === 'Active',
                'badge-inactive': business.status === 'Inactive'
              }"
              >{{ business.status }}</span
            >
          </p>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <p class="listing-label">Business ABN / ACN</p>
          <p>{{ business.abn }}</p>
          <br />
        </div>
        <div class="col-md-4">
          <p class="listing-label">Business Website</p>
          <p>
            <a
              v-if="business.website"
              :href="`https://www.${business.website}`"
              target="_blank"
              >https://www.{{ business.website }}</a
            >
          </p>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="listing-label">Business Note</p>
          <p>
            {{ business.note }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p><strong class="font-120P">Primary Address</strong></p>
        </div>
      </div>

      <div class="gap-20"></div>

      <div class="row">
        <div class="col-md-4">
          <p class="listing-label">Business Address</p>
          <p>
            {{ business?.address.street_address }} <br />{{
              business?.address.suburb
            }}, {{ business?.address?.state?.code }},
            {{ business?.address.postcode }} <br />{{
              business?.address?.country?.name
            }}
          </p>
          <br />
        </div>
        <div class="col-md-4">
          <p class="listing-label">Business Phone (land-line)</p>
          <p>
            <a :href="`tel:${business?.address.phone}`">{{
              business?.address.phone
            }}</a>
          </p>
          <br />
        </div>
        <div class="col-md-12">
          <p class="listing-label">Address Note</p>
          <p>{{ business?.address.note }}</p>
          <br />
        </div>
      </div>

      <div class="gap-20"></div>
      <div class="row">
        <Accordion
          closed
          header="Additional Addresses"
          icon="fa fa-address-card"
        >
          <div v-if="addressData.length">
            <div v-for="address in addressData" :key="address.id">
              <BusinessAddressDetail :address="address" />
              <div class="gap-40"></div>
            </div>
          </div>
          <div v-if="!addressData.length">
            No data found
          </div>
        </Accordion>
      </div>
      <div class="row">
        <ModifiedSection
          :created="business.created_at"
          :updated="business.updated_at"
          model="Business"
          :model-id="business.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ModifiedSection from "@/components/ModifiedSection";
import Accordion from "../../../components/Accordion";
import BusinessAddressDetail from "../../../components/Business/Address/Detail";

export default {
  props: {
    created: Boolean,
    updated: Boolean,
    business: Object,
    permissionUpdate: Boolean,
    addressData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ModifiedSection,
    Accordion,
    BusinessAddressDetail
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style></style>
