<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Headings</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="heading-small">Heading (small) *</label>
          <input
              class="form-control"
              id="heading-small"
              name="heading-small"
              placeholder="Heading (small)"
              type="text"
              v-model="values.headingSmall"
              required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="heading-large">Heading (large) *</label>
          <input
              class="form-control"
              id="heading-large"
              name="heading-large"
              placeholder="Heading (large)"
              type="text"
              v-model="values.headingLarge"
              required
          />
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Total Price</strong></p>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-group">
            <label for="asking_price">Asking price ($)</label>
            <input
              class="form-control"
              id="asking_price"
              name="asking_price"
              v-model="values.askingPrice"
              placeholder="Asking price ($) eg 123456 OR 0 for EOI"
              type="text"
              readonly
            />
          </div>
          <div>
            <div class="form-group">
              <label>
                <input
                    class=""
                    type="checkbox"
                    value="includeGST"
                    v-model.lazy="values.includeGST"
                >
                &nbsp;&nbsp;includes GST
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="currency">Currency</label>
          <select
              class="form-control"
              id="currency"
              name="currency"
              v-model="values.currency"
          >
            <option disabled>Please Select</option>
            <option selected value="AUD">AUD</option>
            <option value="SGP">SGP</option>
            <option value="USD">USD</option>
          </select>
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="consideration">Considerations</label>
          <textarea
           class="form-control form-control-message"
           id="consideration" 
           name="consideration" 
           placeholder="Considerations" 
           rows="1"
           v-model="values.consideration"
           required
          />
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <Editor
              class="form-control form-control-message"
              name="text_line_1"
              :plugins="plugins"
              :toolbar="toolbar"
              placeholder="Due Diligence"
              rows="10"
              v-model="values.body.text_1"
          ></Editor>
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <Editor
              class="form-control form-control-message"
              name="text_line_1"
              :plugins="plugins"
              :toolbar="toolbar"
              placeholder="Retention Period"
              rows="10"
              v-model="values.body.text_line_2"
          ></Editor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  props: {
    headingSmall: String,
    headingLarge: String,
    askingPrice: String,
    currency: String,
    body: Object,
    defaultListing: Object,
    includeGST: String,
    consideration: String
  },
  data() {
    return {
      values: {
        headingSmall: this.headingSmall,
        headingLarge: this.headingLarge,
        includeGST: this.includeGST,
        askingPrice: this.defaultListing
            ? this.defaultListing.price
            : this.askingPrice,
        currency: this.defaultListing
            ? this.defaultListing.currency
            : this.currency,
        consideration: this.consideration,
        body: {
          text_1: "",
          text_line_2: "",
          ...this.body
        },
      },
      plugins: "lists advlist code link table",
      toolbar: "numlist bullist"
    };
  },
  components: {
    Editor
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          return;
        }
        this.$emit("valueChange", this.values);
      }
    },
    defaultListing: {
      immediate: true,
      handler() {
        this.values = {
          ...this.values,
          askingPrice: this.defaultListing
              ? this.defaultListing.price
              : this.askingPrice,
          currency: this.defaultListing
              ? this.defaultListing.currency
              : this.currency
        };
      }
    }
  }
};
</script>
