<template>
  <div class="col-md-12">
    <DocumentComponent :related-id="relatedId" related-type="Project" />
  </div>
</template>
<script>
import DocumentComponent from "../../../components/Document";
export default {
  components: {
    DocumentComponent
  },
  computed: {
    relatedId() {
      return this.$route.params.id;
    }
  }
};
</script>
