export default [
  {
    name: "name",
    text: "Business Name",
    render: item => item.name
  },
  {
    name: "industry_name",
    text: "Industry",
    render: item => item.industry_name
  },
  {
    name: "state",
    text: "Location",
    render: item => item.state?.name,
    sort_disable: true
  },
  {
    name: "status",
    text: "Status",
    render: item => item.status,
    className: item =>
      item.status === "Active" ? "font-green" : "font-orange",
    sort_disable: true
  }
];
