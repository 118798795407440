export default [
  {
    name: "created_at",
    text: "Created",
    render: item => item.created_at
  },
  {
    name: "name",
    text: "Project Name",
    render: item => item.name
  },
  {
    name: "reference",
    text: "Reference",
    render: item => item.listing?.reference
  },
  {
    name: "region",
    text: "Region",
    render: item => item.listing?.region
  },
  {
    name: "state",
    text: "State",
    render: item => item.listing?.state?.name
  },
  {
    name: "owner",
    text: "Owner",
    render: item => `${item?.owner?.first_name} ${item?.owner?.last_name}`,
    sort_disable: true
  },
  {
    name: "status",
    text: "Status",
    render: item => item.status,
    className: item =>
        item.status === "Archived" ? "font-orange" : "",
    sort_disable: true
  }
];
