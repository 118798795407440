<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Rent Rolls For Sale</strong></p>
        <br />
      </div>
      <div class="col-md-12">
        <p class="listing-label">Linked Listing 1</p>
        <p>
          {{
            account?.listingOne?.reference
              ? account?.listingOne?.reference
              : "None"
          }}
        </p>
        <br />
      </div>
      <div class="col-md-12">
        <p class="listing-label">Linked Listing 2</p>
        <p>
          {{
            account?.listingTwo?.reference
              ? account?.listingTwo?.reference
              : "None"
          }}
        </p>
        <br />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    account: Object
  }
};
</script>
