<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <p>
          <strong class="font-120P">{{ title }}</strong>
        </p>
        <br />
      </div>
      <div class="col-md-9">
        <p>
          <i class="fa fa-phone"></i>&nbsp;&nbsp;<a
            :href="`tel:${contact?.phone_number}`"
            >{{ contact?.phone_number }}</a
          >&nbsp;&nbsp;<span
            class="badge"
            :class="{
              'badge-active': status === 'Active',
              'badge-inactive': status === 'Inactive'
            }"
          >
            {{status}}
          </span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <p class="listing-label">Name</p>
        <p>{{ contact?.first_name }} {{ contact?.last_name }}</p>
        <br />
      </div>

      <div class="col-md-3">
        <p class="listing-label">Role Within Business</p>
        <p>{{ contact?.business_role }}</p>
        <br />
      </div>

      <div class="col-md-6">
        <p class="listing-label">Business Email / Username</p>
        <p>
          <a :href="`mailto:${contact?.email}`">{{ contact?.email }}</a>
        </p>
        <br />
      </div>

      <div class="col-md-6">
        <p class="listing-label">Linked Address</p>
        <p>
          {{ business?.street_address }} <br />{{ business?.suburb }},
          {{ business?.state?.code }}, {{ business?.postcode }} <br />{{
            business?.country?.name
          }}
        </p>
        <br />
      </div>

      <div class="col-md-6">
        <p class="listing-label">Business Website</p>
        <p>
          <a :href="`https://www.${business?.website}`"
            >https://www.{{ business?.website }}</a
          >
        </p>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="listing-label">LinkedIn Profile</p>
        <p>
          <a :href="contact.linkedin_profile" target="_blank">{{
            contact.linkedin_profile
          }}</a>
        </p>
        <br />
      </div>
      <div class="col-md-6" v-if="!customer">
        <p class="listing-label">Receive Newsletter</p>
        <p>{{ contact.newsletter ? "Yes" : "No" }}</p>
        <br />
      </div>

      <div class="col-md-12" v-if="!customer">
        <p class="listing-label">Contact Note</p>
        <p>
          {{ contact.note ?? "-" }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    contact: Object,
    business: Object,
    customer: Boolean,
    status: String
  }
};
</script>
