<template>
  <footer id="footer" class="footer bg-overlay">
    <div class="footer-main">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-12 footer-widget footer-about">
            <h3 class="widget-title">About Us</h3>
            <a href="/"
              ><img
                class="footer-logo"
                src="@/assets/images/the_rent_roll_broker_footer_logo.png"
                alt="The Rent Roll Broker"
            /></a>
            <p>
              The Rent Roll Broker is a business broker specialising exclusively
              in the sale of rent roll portfolios and real estate agency
              practices in Australia.
            </p>
          </div>
          <!-- Col end -->
          <div class="col-md-3 col-sm-12 footer-widget">
            <h3 class="widget-title">Services</h3>
            <ul class="list-arrow">
              <li :key="service.path" v-for="service in footer.services">
                <a :href="service.route">{{ service.name }} </a>
              </li>
            </ul>
          </div>
          <!-- Col end rent_roll_broker -->
          <div class="col-md-3 col-sm-12 footer-widget">
            <h3 class="widget-title">The Rent Roll Broker</h3>
            <ul class="list-arrow">
              <li
                :key="service.path"
                v-for="service in footer.rent_roll_broker"
              >
                <a :href="service.route">{{ service.name }} </a>
              </li>
            </ul>
          </div>
          <!-- Col end -->
          <div class="col-md-3 col-sm-12 footer-widget">
            <h3 class="widget-title">Licensing</h3>
            <ul class="list-arrow">
              <li
                  :key="license.path"
                  v-for="license in footer.license"
              >
                <a :href="license.route">{{ license.name }}
                  <br>{{license.line_one}}
                  <br>{{license.line_two}}
                </a>
              </li>
            </ul>
          </div>
          <!--End Licensing-->
        </div>
        <div class="gap-40"></div>
        <div class="row">
          <div class="col-md-8 footer-widget">
            <div class="copyright-info">
              <div class="footer-social">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/The-Rent-Roll-Broker-295014647236745/?ref=tn_tnmn"
                      ><i class="fa fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="http://au.linkedin.com/pub/chris-goodway/1a/12a/337"
                      ><i class="fa fa-linkedin"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="http://www.youtube.com/user/TheRentRollBroker1?feature=mhee"
                      ><i class="fa fa-youtube"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <!-- Footer social end -->
            </div>
          </div>
          <div class="col-md-4 footer-widget">
            <div class="copyright-info">
              <span class="font-grey font-80P"
                >Copyright © {{ year }} The Rent Roll Broker |
                <a v-if="footer.privacy" :href="footer.privacy.route"
                  >Privacy</a
                >
                |
                <a v-if="footer.terms" :href="footer.terms.route"
                  >Terms</a
                ></span
              >
            </div>
          </div>
        </div>
        <!-- Row end -->
        <div
          id="back-to-top"
          data-spy="affix"
          data-offset-top="10"
          class="back-to-top affix"
        >
          <button class="btn btn-primary" title="Back to Top">
            <i class="fa fa-angle-double-up"></i>
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      footer: "app/footer"
    }),
    year() {
      return new Date().getFullYear();
    }
  }
};

var time;
window.onload = function() {
  time = setTimeout(function() {
    if (document.getElementById('main-container')) {
      clearTimeout(time);
    } else {
      document.location.reload();
    }
  }, 1000);
};

</script>

<style></style>
