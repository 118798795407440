<template>
  <form id="form_1" action="">
    <div class="col-md-12">
      <Permission
        :permission-meta="permissionMeta"
        :permission-data="values.permissions"
        @permissions="handlePermissions"
        :update="update"
        @submit="handleSetPermission"
        :currentStatus="formData?.status"
        :availableStatuses = "statuses"
        @updateStatus="handleUpdateStatus"
        @delegateTo="handleDelegateTo"

      />
      <div class="row">
        <div class="col-md-12">
          <p><strong class="font-120P">Business Details</strong></p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="business_name">Legal business name</label>
            <input
              class="form-control"
              id="business_name"
              :class="`${errors?.hasOwnProperty('name') ? 'has-error' : ''}`"
              name="business_name"
              v-model="values.name"
              placeholder="The legal business name"
              type="text"
            />
            <span
              v-if="errors?.hasOwnProperty('name')"
              class="help-block text-danger"
              >{{ errors.name[0] }}</span
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="business_abn">ABN / ACN </label>
            <input
              class="form-control"
              id="business_abn"
              :class="`${errors?.hasOwnProperty('abn') ? 'has-error' : ''}`"
              name="business_abn"
              v-model="values.abn"
              placeholder="The business ABN / ACN"
              type="text"
            />
            <span
              v-if="errors?.hasOwnProperty('abn')"
              class="help-block text-danger"
              >{{ errors.abn[0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="business_phone">Business land-line number </label>
            <input
              class="form-control"
              id="business_phone"
              :class="`${errors?.hasOwnProperty('phone') ? 'has-error' : ''}`"
              name="business_phone"
              v-model="values.phone"
              placeholder="The business land-line number"
              type="text"
            />
            <span
              v-if="errors?.hasOwnProperty('phone')"
              class="help-block text-danger"
              >{{ errors.phone[0] }}</span
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="business_website">Business website </label>
            <div class="input-group mb-3">
              <div class="input-group-addon">
                <span class="input-group-text" id="basic-addon3"
                  >https://www.</span
                >
              </div>
              <input
                class="form-control"
                id="business_website"
                v-model="values.website"
                :class="
                  `${errors?.hasOwnProperty('website') ? 'has-error' : ''}`
                "
                name="business_website"
                placeholder="The business website"
                type="text"
                aria-describedby="basic-addon3"
              />
            </div>
            <span
              v-if="errors?.hasOwnProperty('website')"
              class="help-block text-danger"
              >{{ errors.website[0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="business_note">Business Note</label>
            <textarea
              class="form-control form-control-message"
              name="business_note"
              id="business_note"
              v-model="values.note"
              placeholder="Business Note"
              rows="2"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p><strong class="font-120P">Primary Address</strong></p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="address_discription"
              >Business address description
            </label>
            <input
              class="form-control"
              id="address_discription"
              v-model="values.address.description"
              name="address_discription"
              placeholder="The business address description"
              type="text"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="street_address">Street address</label>
            <input
              class="form-control"
              id="street_address"
              :class="
                `${
                  errors?.hasOwnProperty(['address.street_address'])
                    ? 'has-error'
                    : ''
                }`
              "
              name="street_address"
              v-model="values.address.street_address"
              placeholder="The registered business street address"
              type="text"
            />
            <span
              v-if="errors?.hasOwnProperty('address.street_address')"
              class="help-block text-danger"
              >{{ errors["address.street_address"][0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="suburb">Suburb *</label>
            <input
              class="form-control"
              id="suburb"
              :class="
                `${
                  errors?.hasOwnProperty(['address.suburb']) ? 'has-error' : ''
                }`
              "
              v-model="values.address.suburb"
              name="suburb"
              placeholder="Registered suburb"
              type="text"
            />
            <span
              v-if="errors?.hasOwnProperty('address.suburb')"
              class="help-block text-danger"
              >{{ errors["address.suburb"][0] }}</span
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="state_id">State *</label>
            <select
              class="form-control"
              id="state_id"
              name="state_id"
              v-model="values.address.state_id"
              required
              :class-name="
                `${
                  errors?.hasOwnProperty(['address.state_id']) ? 'has-error' : ''
                }`
              "
              :class="
                `${
                  errors?.hasOwnProperty(['address.state_id']) ? 'has-error' : ''
                }`
              "
              :error="
                errors?.hasOwnProperty('address.state_id')
                  ? errors['address.state_id'][0]
                  : ''
              "
            >
              <option value="" selected disabled>Please Select</option>
              <option :key="item.id" v-for="item in states" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <span
                v-if="errors?.hasOwnProperty('address.state_id')"
                class="help-block text-danger"
            >{{ errors["address.state_id"][0] }}</span
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="postcode">Postcode *</label>
            <input
              class="form-control"
              id="postcode"
              :class="
                `${
                  errors?.hasOwnProperty(['address.postcode']) ? 'has-error' : ''
                }`
              "
              v-model="values.address.postcode"
              name="postcode"
              placeholder="Registered postcode"
              type="text"
            />
            <span
              v-if="errors?.hasOwnProperty('address.postcode')"
              class="help-block text-danger"
              >{{ errors["address.postcode"][0] }}</span
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="country_id">Country *</label>
            <select
              class="form-control"
              id="country_id"
              name="country_id"
              v-model="values.address.country_id"
              required
              :class-name="
                `${
                  errors?.hasOwnProperty(['address.country_id'])
                    ? 'has-error'
                    : ''
                }`
              "
              :error="
                errors?.hasOwnProperty('address.country_id')
                  ? errors['address.country_id'][0]
                  : ''
              "
            >
              <option value="" selected disabled>Please Select</option>
              <option :key="item.id" v-for="item in countries" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="address_note">Address Note</label>
            <textarea
              class="form-control form-control-message"
              name="address_note"
              id="address_note"
              v-model="values.address.note"
              placeholder="Address Note"
              rows="2"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="gap-20"></div>
      <div class="row" v-if="update">
        <AddressForm
          :state-data="stateData"
          :country-data="countryData"
          :addressData="addressData"
          @set-primary="handleSetPrimary"
          @save-address="handleSave"
          @add-address="handleAdd"
        />
      </div>
      <div class="gap-40"></div>

      <div class="row" v-if="!update && isCreatable">
        <div class="col-md-12">
          <div class="form-group">
            <SubmitButton
              :isProgress="isLoading"
              caption="Create Business"
              @submit="handleSubmit"
            />
          </div>
        </div>
      </div>
      <div class="row" v-if="update && updateAvailable">
        <div class="col-md-12">
          <div class="form-group">
            <SubmitButton
              :isProgress="isLoading"
              :caption="isLoading ? 'Updating' : 'Update Business'"
              @submit="handleSubmit"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Select from "@/components/Form/Select";
import SubmitButton from "@/components/SubmitButton";
import Permission from "@/components/Form/Permission";
import AddressForm from "@/components/AddressForm";
import {statuses} from "@/constants/status.js";

export default {
  props: {
    stateData: {
      type: Object,
      default: () => {}
    },
    countryData: {
      type: Object,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Object,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: () => false
    },
    isLoading: {
      type: Boolean,
      default: () => false
    },
    permissionMeta: {
      type: Object,
      default: () => {}
    },
    addressData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Select,
    SubmitButton,
    Permission,
    AddressForm
  },
  data() {
    return {
      statuses : statuses,
      values: {
        ...this.formData
      },
      // permissionMetaData: {
      //    ...this.permissionMeta
      //   },
      countrySearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/countries/search`,
      stateSearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`
    };
  },
  computed: {
    getGeoLocations() {
      return this.$http;
    },
    states() {
      return this.stateData;
    },
    countries() {
      return this.countryData;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  methods: {
    handlePermissions(permissions) {
      this.values.permissions = {
        ...permissions
      };
    },
    handleDelegateTo(userIdentifier) {
      this.$emit("setDelegateTo", userIdentifier);
    },
    handleUpdateStatus(newStatus) {
      this.$emit("updateStatus", newStatus);
    },
    handleSetPermission() {
      this.$emit("submit", this.values);
    },
    handleSubmit() {
      this.$emit("submit", this.values);
    },
    handleDropdownChange(value, param) {
      this.values[param] = value;
    },
    handleSave(address) {
      this.$emit("saveAddress", address);
    },
    handleSetPrimary(address) {
      this.$emit("setPrimary", address);
    },
    handleAdd(address) {
      this.$emit("addAddress", address);
    }
  }
};
</script>

<style></style>
