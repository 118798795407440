<template>
  <div>
    <button
      title="Save DRAFT"
      class="btn btn-xsmall"
      type="button"
      @click="$emit('draftClicked')"
    >
      <span class="font-120P"><i class="fa fa-save"></i></span>
    </button>
  </div>
</template>
