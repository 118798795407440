<template>
  <div class="entry-content">
    <!--Tabs start-->
    <div class="col-md-12">
      <div class="row">
        <Tab
          ref="tabs"
          :tabs="tabs"
          :defaultTab="activeTap"
          @submit="handleSubmit"
          @update-status="updateStatus"
          @save-address="handleSaveAddress"
          @add-address="handleAddAddress"
          @set-primary="handleSetPrimaryAddress"
          @setDelegateTo="handleDelegateTo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash-es/get";
import { mapGetters } from "vuex";
import Tab from "../../components/Tab";

export default {
  components: {
    Tab
  },
  data() {
    return {
      business: null,
      addresses: null,
      isLoading: false,
      isSubmitting: false,
      activeTap: "details",
      updated: false,
      permissionUpdate: false,
      formData: {},
      errors: {},
      logUrl: `${process.env.VUE_APP_ACTIVITY_API_URL}/admin/businesslogs`,
      stateData: {},
      countryData: {},
      stateUrl: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`,
      countryUrl: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/countries/search`
    };
  },
  computed: {
    ...mapGetters({
      permissions: "app/permissions"
    }),
    businessPermissions() {
      return this.permissions?.module_permission.business;
    },
    tabs() {
      return [
        {
          name: "",
          icon: "fa fa-eye",
          component: require("./Show/Index.vue").default,
          key: "details",
          props: {
            addressData: this.addresses,
            business: this.business,
            updated: this.updated,
            permissionUpdate: this.permissionUpdate,
            isLoading: this.isLoading
          }
        },
        {
          name: "",
          icon: "fa fa-edit",
          component: require("./Form.vue").default,
          key: "update",
          props: {
            permissionMeta: this.businessPermissions,
            stateData: this.stateData,
            countryData: this.countryData,
            formData: this.business,
            addressData: this.addresses,
            update: true,
            key: this.business?.id,
            errors: this.errors,
            isLoading: this.isSubmitting
          }
        },
        {
          name: "",
          icon: "fa fa-calendar-check-o",
          component: require("../../components/Task/Items").default,
          key: "tasks",
          props: {
            relatedType: "Business",
            relatedId: this.business?.id
          }
        },
        {
          name: "",
          icon: "fa fa-sticky-note",
          component: require("../../components/Note/Items").default,
          key: "notes",
          props: {
            relatedType: "Business",
            relatedId: this.business?.id
          }
        },
        {
          name: "",
          icon: "fa fa-list",
          component: require("../../components/ActivityLog").default,
          key: "activity-log",
          props: {
            subject: "Business",
            subjectId: this.business?.id,
            item: this.business,
            userAvailable: true,
            logUrl: this.logUrl
          }
        }
      ];
    }
  },
  methods: {
    handleDelegateTo(userIdentifier) {
      this.formData.delegated_user_identifier = userIdentifier
    },
    updateStatus (newStatus) {
      this.formData.status = newStatus
    },
    async loadStates() {
      try {
        const { data } = await this.$http.get(this.stateUrl);
        this.stateData = data;
      } catch (e) {
        //this.errors = get(e, "data.errors");
      }
    },
    async loadCountries() {
      try {
        const { data } = await this.$http.get(this.countryUrl);
        this.countryData = data;
      } catch (e) {
        //this.errors = get(e, "data.errors");
      }
    },
    async loadBusiness() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses/${this.$route.params.id}`
        );
        this.business = data;
        this.formData = data;
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
      }
    },
    async loadAdditionalAddresses() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses/${this.$route.params.id}/addresses`
        );
        this.addresses = data;
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
      }
    },
    async handleSubmit(values, permission) {
      values.delegated_user_identifier = this.formData.delegated_user_identifier
      values.status = this.formData.status
      this.isSubmitting = true;
      try {
        let formData = {
          ...this.formData
        };

        formData = {
          ...values
        };
        if (formData.website !== null && formData.website !== undefined) {
          formData.website = formData.website
            .replace("https://", "")
            .replace("http://", "")
            .replace("www.", "");
        }
        const { data } = await this.$http.patch(
          `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses/${this.$route.params.id}`,
          formData
        );
        this.business = data;
        this.formData = data;
        if (permission) {
          this.permissionUpdate = true;
        } else {
          this.updated = true;
        }
        this.$refs.tabs.handleActiveTab("details");
        this.isSubmitting = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isSubmitting = false;
      }
    },
    handlePermissionSubmit(values) {
      this.handleSubmit(values, true);
    },
    async handleSaveAddress(address) {
      // try {
      let addressData = {
        ...address
      };

      let formData = {
        ...address,
        state_id: addressData.state.id,
        country_id: addressData.country.id
      };
      const { data } = await this.$http.patch(
        `${process.env.VUE_APP_BUSINESS_API_URL}/admin/addresses/${addressData.id}`,
        formData
      );
      await this.loadAdditionalAddresses();
      await this.loadBusiness();
      this.$refs.tabs.handleActiveTab("details");
      ///businesses/${this.$route.params.id}
      // }catch (e) {
      //    this.errors = get(e, "data.errors");
      //  }
    },
    async handleAddAddress(address) {
      // try {
      let addressData = {
        ...address
      };

      let formData = {
        ...address,
        state_id: addressData.state.id,
        country_id: addressData.country.id,
        is_active: true
      };
      const { data } = await this.$http.post(
        `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses/${this.$route.params.id}/address`,
        formData
      );
      await this.loadAdditionalAddresses();
      await this.loadBusiness();
      this.$refs.tabs.handleActiveTab("details");
      ///businesses/${this.$route.params.id}
      // }catch (e) {
      //    this.errors = get(e, "data.errors");
      //  }
    },
    async handleSetPrimaryAddress(address) {
      // try {
      let addressData = {
        ...address
      };

      let formData = {
        identifier: addressData.id
      };
      const { data } = await this.$http.patch(
        `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses/${this.$route.params.id}/setprimaryaddress`,
        formData
      );
      await this.loadAdditionalAddresses();
      await this.loadBusiness();
      this.$refs.tabs.handleActiveTab("details");
      ///businesses/${this.$route.params.id}
      // }catch (e) {
      //    this.errors = get(e, "data.errors");
      //  }
    }
  },
  async mounted() {
    const breadcrumbs = [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Business Administration",
        url: "admin_users",
        query: {
          tap: "businesses"
        }
      },
      {
        name: "Business Details",
        url: "businesses_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ];
    this.$store.commit("app/SET_BREADCRUMBS", [...breadcrumbs]);
    await this.loadAdditionalAddresses();
    await this.loadBusiness();
    this.$store.commit("app/SET_BREADCRUMBS", [
      ...breadcrumbs,
      {
        name: this.business?.name,
        url: "businesses_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ]);

    if (this.$route.query.action) {
      this.$refs.tabs.handleActiveTab(this.$route.query.action);
    }
    this.$ui.setPageHeader({
      title: "Business Administration",
      showTask: true,
      parentTitle: "Business Administration",
      pageItemTitle: "Business Details",
      pageItem: `${this.business?.name}`
    });
  },
  async created() {
    await this.loadStates();
    await this.loadCountries();
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
