<template>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group">
        <label
          >Show&nbsp;
          <select
            v-model="params.per_page"
            @change="handlePageChange"
            name="dtDataTable2_length"
            aria-controls="dtDataTable2"
            class="custom-select custom-select-sm form-control-plaintext bg-white form-control-lg"
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option> </select
          >&nbsp;entries
        </label>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group text-right">
        <label
          >Search:
          <input
            type="search"
            class="form-control-plaintext form-control-lg"
            v-on:input="handleSearchChange"
            placeholder=""
            aria-controls="dtDataTable2"
          />
        </label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table
            ref="table"
          id="dtDataTable1"
          class="table table-striped table-hover table-sm table-bordered"
          cellspacing="0"
          width="100%"
        >
          <thead class="thead-light">
            <tr>
              <th
                @click="handleSort(column)"
                class="th-sm"
                :key="column.name"
                v-for="column in columns"
              >
                <div class="d-flex content-between">
                  <span>{{ column.text }}</span>
                  <span v-if="!column.sort_disable">
                    <i
                      class="fa fa-sort"
                      :class="{
                        'fa-sort-asc':
                          params.sort_column === column.name &&
                          params.sort_dir === 'asc',
                        'fa-sort-desc':
                          params.sort_column === column.name &&
                          params.sort_dir === 'desc'
                      }"
                    />
                  </span>
                </div>
              </th>
<!--              <th class="th-sm" v-if="!removeActions">Action</th>-->
            </tr>
          </thead>
          <tbody>
            <tr
              :key="item.id"
              v-for="item in items(name)"
              v-show="!isLoading(name) && items(name).length"
            >
              <td :key="column.name" v-for="column in columns">
                <template v-if="column.render">
                  <div
                    :class="{
                      'table-cell-nowrap-content':
                        column.badge && column.badge(item) > 0
                    }"
                  >
                    <template v-if="column.download">
                      <span
                        v-if="!downloadLoading[item.id]"
                        @click="handleDownload(item, column)"
                        :class="column.className(item)"
                      >
                        {{ column.render(item) }}
                      </span>
                      <span v-if="downloadLoading[item.id]">
                        Downloading...
                      </span>
                    </template>
                    <template v-else>
                      <router-link
                        v-if="
                          (column.name !== 'link' &&
                            !column.link &&
                            showPath) ||
                            item.url
                        "
                        :to="{
                          name: showPath ? showPath : item.url,
                          params: { id: item.id }
                        }"
                         target="_blank"
                      >
                        <span
                          :class="
                            column.className ? column.className(item) : null
                          "
                        >
                          {{ column.render(item) }}
                        </span>
                      </router-link>
                      <template v-if="column.link">
                        <template
                          v-if="
                            column.downloadable &&
                              column.downloadable(item) === true
                          "
                        >
                          <span
                            v-if="!downloadLoading[item.id]"
                            @click="handleDownload(item, column)"
                            :class="
                              column.className ? column.className(item) : null
                            "
                          >
                            {{ column.render(item) }}
                          </span>
                          <span v-if="downloadLoading[item.id]">
                            Downloading...
                          </span>
                        </template>
                        <template v-else>
                          <a
                            v-if="column.link(item) !== ''"
                            :href="column.link(item)"
                          >
                            {{ column.render(item) }}
                          </a>
                          <span v-if="column.link(item) === ''">
                            {{ column.render(item) }}
                          </span>
                        </template>
                      </template>
                      <span
                        v-if="column.name !== 'link' && !column.link && !item.url && !showPath"
                        :class="
                          column.className ? column.className(item) : null
                        "
                        @click="$emit('clicked', item)"
                      >
                        {{ column.render(item) }}
                      </span>
                      <a
                        v-if="column.name === 'link'"
                        :href="column.render(item)"
                        target="_blank"
                      >
                        Link
                      </a>
                    </template>
                    <template v-if="column.badge && column.badge(item) > 0"
                      >&nbsp;&nbsp;
                      <span class="badge badge-highlight font-70P">
                        {{ column.badge(item) }}
                      </span>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <span v-if="column.timestamp" @click="$emit('clicked', item)">
                    {{ this.timestamp(column.timestamp(item)) }}
                  </span>
                  <span v-else>
                    <a
                      :href="column.link(item)"
                      :target="column.disableNewTab ? '_self' : '_blank'"
                    >
                      Link
                    </a>
                  </span>
                </template>
              </td>
<!--              <td v-if="!removeActions">-->
<!--                <router-link-->
<!--                  v-if="showPath"-->
<!--                  :to="{-->
<!--                    name: showPath ? showPath : item.url,-->
<!--                    params: { id: item.id }-->
<!--                  }"-->
<!--                >-->
<!--                  <i class="fa fa-eye"></i> </router-link-->
<!--                >&nbsp;&nbsp;-->
<!--                <router-link-->
<!--                  v-if="edit && showPath && updateAvailable"-->
<!--                  :to="{-->
<!--                    name: showPath ? showPath : item.url,-->
<!--                    params: { id: item.id },-->
<!--                    query: { action: 'update' }-->
<!--                  }"-->
<!--                >-->
<!--                  <i class="fa fa-edit"></i>-->
<!--                </router-link>-->
<!--              </td>-->
            </tr>
            <tr v-if="isLoading(name)">
              <td :colspan="columns.length + 1" class="text-center">
                <div class="loading"></div>
              </td>
            </tr>
            <tr v-if="!isLoading(name) && !items(name).length">
              <td :colspan="columns.length + 1" class="text-center">
                No Data Found.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12" v-if="!noPaginate">
      <div class="paginate-content">
        <div>
          Showing {{ meta(name).from }} to {{ meta(name).to }} of
          {{ meta(name).total }} entries
        </div>
        <div>
          <div class="paging">
            <Paginate
              :page-count="meta(name).last_page ? meta(name).last_page : 0"
              :click-handler="pageChange"
              :force-page="meta(name).current_page"
              :prev-text="'Prev'"
              :next-text="'Next'"
              container-class="pagination"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Paginate from "@/components/Paginate";
import last from "lodash-es/last";
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash-es";
import moment from "moment-timezone";
import store from "../store";

export default {
  components: {
    Paginate
  },
  props: {
    url: String,
    noPaginate: Boolean,
    updateAvailable: Boolean,
    showClick: Function,
    columns: Array,
    extraParams: {
      type: Object,
      default: () => {
        return {};
      }
    },
    edit: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    sort: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showPath: String,
    removeActions: Boolean,
    showArchived:Boolean
  },
  data() {
    return {
      params: {
        page: 1,
        ...this.extraParams,
        per_page: 10,
        ...this.sort
      },
      name: "",
      downloadLoading: []
    };
  },
  computed: {
    ...mapGetters("table", ["items", "meta", "isLoading"]),
    ...mapGetters({
      isSuperAdmin: "auth/isSuperAdmin",
      isAdmin: "auth/isAdmin"
    }),
  },
  methods: {
    ...mapActions("table", ["getData"]),
    pageChange(page) {
      this.setArchiveParameters();
      this.params = {
        ...this.params,
        page: page
      };

      this.getData({
        url: this.url,
        params: this.params,
        name: this.name
      });
    },
    handlePageChange() {
      this.setArchiveParameters();
      this.getData({
        url: this.url,
        params: this.params,
        name: this.name
      });
    },
    handleSearchChange: debounce(function(e) {
      this.setArchiveParameters();
      this.params.search = e.target.value;
      this.getData({
        url: this.url,
        params: this.params,
        name: this.name
      });
      this.$emit('onSearch',
      {
        url: this.url,
        params: this.params,
        name: this.name
      });
      //this.$emit('save', 'update');
    }, 500),
    handleSort(column) {
      this.setArchiveParameters();
      if (!column.sort_disable) {
        var oldOrder = "";
        if (this.params.sort_column === column.name) {
          oldOrder = this.params.sort_dir;
        }

        this.params = {
          ...this.params,
          sort_column: column.name,
          sort_dir: !oldOrder
            ? "asc"
            : oldOrder === "asc"
            ? "desc"
            : oldOrder === "desc"
            ? null
            : ""
        };
        this.getData({
          url: this.url,
          params: this.params,
          name: this.name
        });
      }
    },
    async handleDownload(item, column) {
      this.setArchiveParameters();
      if (!column.downloadable(item)) return;
      this.downloadLoading = {
        [item.id]: true
      };
      try {
        const { data } = await this.$http.get(
          column.link ? column.link(item) : column.downloadUrl(item)
        );
        if (data.url) {
          window.open(data.url);
        }
      } catch (error) {}
      this.downloadLoading = {
        [item.id]: false
      };
    },
    timestamp(value) {
      if (value === "") return "";
      let time = moment.utc(value);
      if (store.getters.timezone) {
        time = time.tz(store.getters.timezone);
      } else {
        time = time.local();
      }
      return time.format("YYYY-MM-DD hh:mm:ss");
    },
    customGetDataCall(params) {
      this.setArchiveParameters();
      const name = last(this.url.split("/"));
      this.name = name;
      this.getData({
        url: this.url,
        params: {
          ...this.params,
          ...params
        },
        name: name
      });
    },
    setArchiveParameters (){
      if(this.isSuperAdmin || this.isAdmin){
        this.params.show_archived =this.showArchived
      }
    }
  },
  mounted() {
    const name = last(this.url.split("/"));
    this.name = name;
    this.setArchiveParameters();
    this.getData({
      url: this.url,
      params: this.params,
      name: name
    });

    this.$emitter.on("task_created", () => {
      this.getData({
        url: this.url,
        params: this.params,
        name: name
      });
    });
  }
};
</script>

<style></style>
