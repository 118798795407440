<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">State</p>
        <p>{{ account?.state?.name }}</p>
        <br />
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <p class="listing-label">Price Range From</p>
        <p>${{ formatPrice(account?.price_range_from) }}</p>
        <br />
      </div>

      <div class="col-md-3">
        <p class="listing-label">Price Range To</p>
        <p>${{ formatPrice(account?.price_range_to) }}</p>
        <br />
      </div>

      <div class="col-md-3">
        <p class="listing-label">Roll Size From</p>
        <p>{{ account?.roll_size_from }}</p>
        <br />
      </div>

      <div class="col-md-3">
        <p class="listing-label">Roll Size To</p>
        <p>{{ account?.roll_size_to }}</p>
        <br />
      </div>
    </div>

    <div class="row">
      <div class="form-group">
        <div class="col-md-2" style="display:inline-block">
          <label
            ><input
              class="form-check-input"
              type="checkbox"
              :checked="!!account?.agency"
              disabled
            />&nbsp;&nbsp; Agency
          </label>
        </div>
        <div class="col-md-4" style="display:inline-block">
          <label
            ><input
              class="form-check-input"
              type="checkbox"
              :checked="!!account?.commercial"
              disabled
            />&nbsp;&nbsp; Commercial/Industrial
          </label>
        </div>
        <div class="col-md-2" style="display:inline-block">
          <label
            ><input
              class="form-check-input"
              type="checkbox"
              :checked="!!account?.holiday"
              disabled
            />&nbsp;&nbsp; Holiday
          </label>
        </div>
        <div class="col-md-2" style="display:inline-block">
          <label
            ><input
              class="form-check-input"
              disabled
              type="checkbox"
              :checked="!!account?.residential"
            />&nbsp;&nbsp; Residential
          </label>
        </div>
        <div class="col-md-2" style="display:inline-block">
          <label
            ><input
              class="form-check-input"
              type="checkbox"
              disabled
              :checked="!!account?.strata"
            />&nbsp;&nbsp; Strata
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    account: Object
  },
  methods: {
    formatPrice(value) {
      return Number(value)?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
  }
};
</script>
