<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              :class="{ active: activeTap === 'news' }"
              @click="activeTap = 'news'"
            >
              <a id="user_details_tab_link" data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Add</h4></a
              >
            </li>
          </ul>
          <form id="form_1">
            <div class="error-container"></div>
            <div class="panel-body">
              <div class="tab-content">
                <div
                  id="tab-01"
                  class="tab-pane"
                  :class="{ active: activeTap === 'news' }"
                >
                  <div class="col-md-12">
                    <Form
                      :form-data="formData"
                      :errors="errors"
                      @submit="handleSubmit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./Form";
import get from "lodash-es/get";
import { map } from "lodash-es";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  components: {
    Form
  },
  data() {
    return {
      formData: {
        permissions: {
          admin_access: {
            site_access: true,
            admin_approved: false
          }
        }
      },
      activeTap: "news",
      isLoading: false,
      errors: {}
    };
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async handleSubmit(values) {
      let active_date = this.formData.hasOwnProperty("active_date")
        ? this.formData.active_date
        : values.hasOwnProperty("active_date")
        ? values.active_date
        : null;
      this.isLoading = true;
      const valuesData = {
        ...this.formData,
        ...values,
        active_date: active_date
          ? moment(active_date).format("YYYY-MM-DD")
          : ''
      };
      try {
        let formDataValues = new FormData();
        map(valuesData, (value, index) => {
          if (index === "permissions") {
            formDataValues.append(index, JSON.stringify(value));
          } else {
            formDataValues.append(index, value);
          }
        });

        const { data } = await this.$http.post(
          `${process.env.VUE_APP_NEWS_API_URL}/admin/articles`,
          formDataValues
        );
        await this.loadStats();
        this.$router.push({
          name: "news_show",
          params: { id: data.id }
        });
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
        this.activeTap = "news";
      }
    },
    handleChangeTap(values) {
      this.activeTap = "permission";
      this.formData = { ...values };
    }
  },
  mounted() {
    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "News & Advice",
        url: "news"
      },
      {
        name: "Add News & Advice",
        url: "news_create",
        active: true
      }
    ]);
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
