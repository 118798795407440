<template>
  <div
    id="banner-area"
    class="banner-area"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/rent_roll_broker/privacy.jpg') + ')'
    }"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="banner-heading">
              <h1 class="banner-title">Oops !</h1>
              <ol class="breadcrumb">
                <li class="font-white"><a href="/">Home</a></li>
                <li>403</li>
              </ol>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->

  <section id="main-container" class="main-container">
    <div class="container">
      <div class="row">
        <div class="error-page text-center">
          <div class="error-message">
            <h2>403</h2>
            <h3>Unauthorized</h3>
          </div>
          <div class="error-body">
            Please contact the administrator
          </div>
        </div>
      </div>
      <!-- Content row -->
    </div>
    <!-- Conatiner end -->
  </section>
  <!-- Main container end -->
</template>

<script>
export default {};
</script>

<style></style>
