<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row" v-if="statusCode !== 403">
        <Tab
          ref="tabs"
          :tabs="tabs"
          :defaultTab="activeTap"
          @save="handlePermissionUpdated"
        />
      </div>
      <div class="row" v-else>
        <div class="col-md-12">
          <Alert
              type="alert-danger"
              main-message="Forbidden!"
              message="Access to this resource is forbidden."
              :showCloseIcon="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import get from "lodash-es/get";
import Tab from "../../components/Tab";
import { map } from "lodash-es";
import debounce from "lodash-es/debounce";
import Alert from "@/components/Alert.vue";
export default {
  components: {
    Alert,
    Tab
  },
  data() {
    return {
      statusCode : null,
      activeTap: "details",
      account: null,
      updated: false,
      updateAvailable: false,
      isLoading: false,
      accountStages: null,
      permissionChanged: false,
      passwordUpdated: false,
      errors: {},
      opportunityCount : 0
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: "",
          icon: "fa fa-eye",
          component: require("./components/Details.vue").default,
          key: "details",
          props: {
            account: this.account,
            updated: this.updated,
            accountStages: this.accountStages,
            permissionChanged: this.permissionChanged,
            passwordUpdated: this.passwordUpdated
          }
        },
        {
          name: "",
          icon: "fa fa-edit",
          component: require("./components/Edit.vue").default,
          key: "update",
          props: {
            update: true,
            errors: this.errors,
            key: this.account?.id,
            formData: this.account,
            isLoading: this.isLoading
          }
        },
        {
          name: "",
          icon: "fa fa-cloud-upload",
          component: require("./components/Document").default,
          key: "documents",
          props: {
            account: this.account
          }
        },
        {
          name: "",
          icon: "fa fa-handshake-o",
          component: require("./components/Opportunities").default,
          key: "opportunities",
          props: {
            customerId: this.account?.id,
            showCount : true,
            count : this.opportunityCount
          }
        },
        {
          name: "",
          icon: "fa fa-calendar-check-o",
          component: require("../../components/Task/Items").default,
          key: "tasks",
          props: {
            relatedType: "Customer",
            relatedId: this.account?.id
          }
        },
        {
          name: "",
          icon: "fa fa-sticky-note",
          component: require("../../components/Note/Items").default,
          key: "notes",
          props: {
            relatedType: "Customer",
            relatedId: this.account?.id
          }
        },
        {
          name: "",
          icon: "fa fa-envelope",
          component: require("./components/Emails").default,
          key: "emails",
          props: {
            account: this.account
          }
        },
        {
          name: "",
          icon: "fa fa-list",
          component: require("../../components/ActivityLog").default,
          key: "activity-log",
          props: {
            subject: "Customer",
            subjectId: this.account?.id,
            item: this.account,
            userAvailable: true
          }
        }
      ];
    }
  },
  methods: {
    async loadAccount() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${this.$route.params.id}`
        );
        this.account = data;
        console.log(this.account, "data set to the account in the show screen");
        //TODO
        // this.$refs.tabs.handleActiveTab("update");
      } catch (e) {
        console.log(e)
        this.errors = get(e, "data.errors");
        this.statusCode = get(e, "status");
      }
      this.isLoading = false;
    },
    async loadOpportunities() {
      this.isLoading = true;
      try {
        const { meta } = await this.$http.get(
            `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/listings?page=1&customer_id=${this.$route?.params.id}&per_page=10&show_archived=false`
        );
        this.opportunityCount = meta?.total;
        //TODO
        // this.$refs.tabs.handleActiveTab("update");
      } catch (e) {
        this.errors = get(e, "data.errors");
      }
      this.isLoading = false;
    },
    async loadStages() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_CONFIG_API_URL}/accountstages`
        );
        this.accountStages = map(data, item => {
          return {
            key: `${item.id}`,
            text: item.name
          };
        });
      } catch (e) {
        this.errors = get(e, "data.errors");
      }
    },
    updateAll: debounce(async function(value) {
     await this.$refs.tabs?.updateAll()
      await this.loadAccount();
      this.$refs.tabs.handleActiveTab("details");
      window.scrollTo(0,0);
    }, 1000),
    async handlePermissionUpdated(value) {
      this.updateAll()
      switch (value) {
        case "password":
          this.passwordUpdated = true;
          break;
        case "permission":
          this.permissionChanged = true;
          break;
        case "business":
        case "contact":
        case "account":
          this.updated = true;
          break;
      }
     /* await this.loadAccount();
      this.$refs.tabs.handleActiveTab("details");
      window.scrollTo(0,0);*/
    }
  },
  async mounted() {
    const breadcrumbs = [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Account Administration",
        url: "accounts"
      },
      {
        name: "Account Details",
        url: "accounts_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ];

    this.$store.commit("app/SET_BREADCRUMBS", [...breadcrumbs]);
    await this.loadStages();
    await this.loadAccount();
    this.updateAvailable = this.$route.permissions
      ? this.$route.permissions.permissions.includes(1)
      : true;
    this.$store.commit("app/SET_BREADCRUMBS", [
      ...breadcrumbs,
      {
        name: `${this.account?.contact?.first_name} ${this.account?.contact?.last_name}`,
        url: "accounts_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ]);
    if (this.$route.query?.enableAccountEdit) {
      setTimeout(() => {
        this.$refs.tabs?.handleActiveTab("update");
      }, 1000)
    }

    await this.loadOpportunities ()
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>
