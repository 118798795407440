<template>
  <div class="entry-content">
    <!--Tabs start-->
    <div class="col-md-12">
      <div class="row">
        <!--tabs border start-->
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              @click="activeTap = 'detail'"
              :class="{ active: activeTap === 'detail' }"
            >
              <a
                title="View"
                id="view_listing_tab_link"
                data-toggle="tab"
                href="#tab-01"
                ><h4 class="panel-title"><i class="fa fa-eye"></i></h4
              ></a>
            </li>
            <li
              id="tab-label-02"
              v-if="updateAvailable"
              @click="activeTap = 'update'"
              :class="{ active: activeTap === 'update' }"
            >
              <a
                title="Edit"
                id="update_listing_tab_link"
                data-toggle="tab"
                href="#tab-02"
                ><h4 class="panel-title"><i class="fa fa-edit"></i></h4
              ></a>
            </li>
          </ul>
          <div class="panel-body">
            <div class="tab-content">
              <div
                id="tab-01"
                class="tab-pane"
                :class="{ active: activeTap === 'detail' }"
              >
                <div class="col-md-12">
                  <!-- affiliate details start -->
                  <Details
                    v-if="!isLoading && affiliate"
                    :affiliate="affiliate"
                    :updated="updated"
                    :permission-update="permissionUpdate"
                  />
                  <div v-if="isLoading" class="text-center">
                    <div class="loading"></div>
                  </div>
                  <!-- affiliate details end -->
                </div>
              </div>
              <div
                id="tab-02"
                class="tab-pane"
                :class="{ active: activeTap === 'update' }"
              >
                <div class="col-md-12">
                  <form id="form_1" action="" v-if="affiliate">
                    <Form
                      @submit="handleSubmit"
                      :form-data="affiliate"
                      :is-loading="isSubmitting"
                      update
                      :key="affiliate.id"
                      :errors="errors"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Details from "./Show/Index";
import Form from "./Form";
import get from "lodash-es/get";
import { mapActions } from "vuex";

export default {
  components: {
    Details,
    Form
  },
  data() {
    return {
      affiliate: null,
      isLoading: false,
      updateAvailable: false,
      isSubmitting: false,
      activeTap: "detail",
      updated: false,
      permissionUpdate: false,
      formData: {},
      errors: {}
    };
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async loadUser() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_AFFILIATE_API_URL}/admin/affiliates/${this.$route.params.id}`
        );
        this.affiliate = data;
        this.formData = data;
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
      }
    },
    async handleSubmit(values, permission) {
      this.isSubmitting = true;
      try {
        let formData = {
          ...this.formData
        };
        formData = {
          ...formData,
          ...values
        };
        if (formData.website !== null && formData.website !== undefined) {
          formData.website = formData.website
            .replace("https://", "")
            .replace("http://", "")
            .replace("www.", "");
        }
        const { data } = await this.$http.patch(
          `${process.env.VUE_APP_AFFILIATE_API_URL}/admin/affiliates/${this.$route.params.id}`,
          {
            ...formData,
            country_id: formData.country ? formData.country.id : null,
            state_id: formData.state ? formData.state.id : null
          }
        );
        await this.loadStats();
        await this.loadUser();
        if (permission) {
          this.permissionUpdate = true;
        } else {
          this.updated = true;
        }
        this.activeTap = "detail";
        window.scrollTo(0,0);
        this.isSubmitting = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isSubmitting = false;
      }
    },
    handlePermissionSubmit(values) {
      this.handleSubmit(values, true);
    }
  },
  async mounted() {
    const breadcrumbs = [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Affiliate Administration",
        url: "affiliates"
      },
      {
        name: "Affiliate Details",
        url: "affiliates_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ];
    this.$store.commit("app/SET_BREADCRUMBS", [...breadcrumbs]);

    await this.loadUser();

    this.$store.commit("app/SET_BREADCRUMBS", [
      ...breadcrumbs,
      {
        name: this.affiliate?.name,
        url: "affiliates_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ]);

    this.updateAvailable = this.$route.permissions
      ? this.$route.permissions.permissions.includes(1)
      : true;
    if (this.$route.query.action) {
      this.activeTap = this.$route.query.action;
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
