<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="text-right">
          <button
            title="Save Account"
            class="btn btn-xsmall"
            @click="handleSave(false)"
          >
            <span class="font-120P"><i class="fa fa-save"></i></span>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <Select
            name="state_id"
            label="State *"
            :value="values.state_id ? values.state_id : null"
            required
            placeholder="Please Select"
            :url="stateUrl"
            :axios="$http"
            @selected="
              value => {
                values.state_id = value ? value.id : null;
              }
            "
            showother
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>Price Range From</label>
          <select
            id="price_range_from"
            name="price_range_from"
            v-model="values.price_range_from"
            class="form-control"
          >
            <option
              :value="priceRange.value"
              v-for="priceRange in priceRanges"
              :key="priceRange.value"
            >
              {{ priceRange.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Price Range To</label>
          <select
            id="price_range_to"
            name="price_range_to"
            v-model="values.price_range_to"
            class="form-control"
          >
            <option
              :value="priceRange.value"
              v-for="priceRange in priceRanges"
              :key="priceRange.value"
            >
              {{ priceRange.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Roll Size From</label>
          <select
            id="roll_size_from"
            name="roll_size_from"
            v-model="values.roll_size_from"
            class="form-control"
          >
            <option
              :value="rollSize.value"
              v-for="rollSize in rollSizes"
              :key="rollSize.value"
            >
              {{ rollSize.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Roll Size To</label>
          <select
            id="roll_size_to"
            name="roll_size_to"
            v-model="values.roll_size_to"
            class="form-control"
          >
            <option
              :value="rollSize.value"
              v-for="rollSize in rollSizes"
              :key="rollSize.value"
            >
              {{ rollSize.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Listing preferences (select all that apply)</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="col-md-2" style="display: inline-block">
            <label
              ><input
                class="form-check-input"
                id="agency"
                name="agency"
                type="checkbox"
                @change="values.agency = !values.agency"
                value="true"
                :checked="values.agency"
              />&nbsp;&nbsp; Agency
            </label>
          </div>
          <div class="col-md-4" style="display: inline-block">
            <label
              ><input
                class="form-check-input"
                id="commercial"
                name="commercial"
                @change="values.commercial = !values.commercial"
                type="checkbox"
                :checked="values.commercial"
                value="1"
              />&nbsp;&nbsp; Commercial/Industrial
            </label>
          </div>
          <div class="col-md-2" style="display: inline-block">
            <label
              ><input
                class="form-check-input"
                id="holiday"
                name="holiday"
                @change="values.holiday = !values.holiday"
                type="checkbox"
                value="1"
                :checked="values.holiday"
              />&nbsp;&nbsp; Holiday
            </label>
          </div>
          <div class="col-md-2" style="display: inline-block">
            <label
              ><input
                class="form-check-input"
                id="residential"
                name="residential"
                @change="values.residential = !values.residential"
                type="checkbox"
                :checked="values.residential"
                value="1"
              />&nbsp;&nbsp; Residential
            </label>
          </div>
          <div class="col-md-2" style="display: inline-block">
            <label
              ><input
                class="form-check-input"
                id="strata"
                @change="values.strata = !values.strata"
                name="strata"
                type="checkbox"
                :checked="values.strata"
                value="1"
              />&nbsp;&nbsp; Strata
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Select from "../../../components/Form/Select";
import { price_ranges, roll_sizes } from "../../../lib/config";
export default {
  props: {
    formData: {
      type: Object,
      default: () => ({
        state_id: "",
        price_range_from: "",
        price_range_to: "",
        roll_size_from: "",
        roll_size_to: "",
        agency: false,
        commercial: false,
        holiday: false,
        residential: false,
        strata: false
      })
    }
  },
  data() {
    return {
      values: {
        ...this.formData
      },
      stateUrl: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`,
      isSubmitting: false
    };
  },
  computed: {
    priceRanges() {
      return price_ranges;
    },
    rollSizes() {
      return roll_sizes;
    }
  },
  components: {
    Select
  },
  methods: {
    async handleSave(isMassUpdate = false) {
      this.isSubmitting = true;
      try {
        await this.$http.patch(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${this.values.id}`,
          this.values
        );
        if(!isMassUpdate) this.$emit("save", "account");
      } catch (e) {
        console.log(e);
      }
      this.isSubmitting = false;
    }
  }
};
</script>
