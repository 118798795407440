<template>
  <div class="form-group">
    <label :for="name">
      <span v-if="isUploading">Uploading...</span>
      <span v-if="!isUploading && !preview">{{ placeholder }}</span>
      <a
        v-if="!isUploading && preview && (imageUrl || image)"
        style="cursor: pointer;"
      >
        <img class="img-responsive" :src="imageUrl ? imageUrl : image" alt="" />
      </a>
    </label>
    <input
      class=""
      @change="e => handleFileUpload(e)"
      :name="name"
      placeholder="Browse image (.jpg, .png)"
      type="file"
      accept="image/*"
    />
  </div>
</template>

<script>
import { fileUpload } from "@/lib/axios";
export default {
  props: {
    name: {
      type: String
    },
    preview: {
      type: Boolean
    },
    image: {
      type: String
    },
    placeholder: {
      type: String
    },
    path: {
      type: String
    }
  },
  data() {
    return {
      isUploading: false,
      imageUrl: ""
    };
  },
  methods: {
    async handleFileUpload(e) {
      const file = e.target.files[0];
      this.isUploading = true;
      try {
        const data = await this.$http.post(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/signed-storage-url`,
          {
            path: this.path ? this.path : "information-memorandums/",
            content_type: file.type
          }
        );
        if (data.url) {
          await fileUpload.put(data.url, file, {
            headers: {
              useAuth: false,
              "Content-Type": file.type
            }
          });

          const url = await this.$http.get(
            `${process.env.VUE_APP_LISTINGS_API_URL}/admin/document-url/${data.uuid}`,
            {
              params: {
                path: "information-memorandums/"
              }
            }
          );
          this.isUploading = false;
          this.imageUrl = url;
          this.$emit("uploaded", { file: data.uuid, fileUrl: url });
        }
        this.isUploading = false;
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {}
};
</script>
