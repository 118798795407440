<template>
  <div class="col-md-12">
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin text-left pull-left">
        <div class="row">
          <p class="font-120P">Listing Statistics</p>
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="entry-content">
      <div class="col-md-12" v-for="chart in charts" :key="chart.key">
        <div class="row">
          <div class="gap-40"></div>
          <div class="col-md-12">
            <div class="row">
              <div class="row border-lt">
                <canvas :id="chart.key"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import map from "lodash-es/map";
const color = Chart.helpers.color;

export default {
  data() {
    return {
      total: 0,
      isTotalLoading: false,
      isChartLoading: false,
      values: {},
      charts: [
        {
          name: "Number of Active Rent Roll Listings by State",
          key: "listing_by_state",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Number of Active Rent Roll Listings by State"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true
                    }
                  }
                ]
              }
            }
          },
          transform: values => {
            return {
              labels: map(values, "label"),
              datasets: [
                {
                  label: "Number of Listings",
                  backgroundColor: color(window.chartColors.orange)
                    .alpha(0.5)
                    .rgbString(),
                  borderColor: window.chartColors.orange,
                  borderWidth: 1,
                  data: map(values, "value")
                }
              ]
            };
          }
        },
        {
          name: "Dollar Value of Active Rent Roles Listed by State",
          key: "listing_by_state_price",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Dollar Value of Active Rent Roles Listed by State"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true
                    }
                  }
                ]
              }
            }
          },
          transform: values => {
            return {
              labels: map(values, "label"),
              datasets: [
                {
                  label: "Listings Value $",
                  backgroundColor: color(window.chartColors.purple)
                    .alpha(0.5)
                    .rgbString(),
                  borderColor: window.chartColors.purple,
                  borderWidth: 1,
                  data: map(values, "value")
                }
              ]
            };
          }
        },
        {
          name: "Active Rent Roll Listings Diversity by State",
          key: "listing_by_diversity",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Active Rent Roll Listings Diversity by State"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true
                    }
                  }
                ]
              }
            }
          },
          transform: values => {
            return {
              labels: map(values, "label"),
              datasets: [
                {
                  label: "Residential",
                  backgroundColor: window.chartColors.red,
                  stack: "Stack 0",
                  data: map(map(values, "value"), "residential")
                },
                {
                  label: "Commercial",
                  backgroundColor: window.chartColors.blue,
                  stack: "Stack 0",
                  data: map(map(values, "value"), "commercial")
                },
                {
                  label: "Holiday",
                  backgroundColor: window.chartColors.green,
                  stack: "Stack 0",
                  data: map(map(values, "value"), "holiday")
                },
                {
                  label: "Strata",
                  backgroundColor: window.chartColors.brown,
                  stack: "Stack 0",
                  data: map(map(values, "value"), "strata")
                }
              ]
            };
          }
        }
      ]
    };
  },
  methods: {
    async getListingChartsData() {
      this.isChartLoading = true;
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_STATS_API_URL}/admin/charts`
        );

        map(this.charts, (chart, index) => {
          chart.options.data = chart.transform(data[chart.key]);
          this.charts[index].options.data = chart.transform(data[chart.key]);
          this.initiateChart(chart);
        });
      } catch (e) {
        console.log(e, "error");
      }
      this.isChartLoading = false;
    },
    initiateChart(chart) {
      var ctx = document.getElementById(chart.key).getContext("2d");
      window.myBar = new Chart(ctx, chart.options);
    }
  },
  created() {
    this.getListingChartsData();
  }
};
</script>
