<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li class="active">
              <a data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Opportunities</h4></a
              >
            </li>
            <li class="">
              <a data-toggle="tab" href="#tab-02"
                ><h4 class="panel-title">Generate Export Lists</h4></a
              >
            </li>
          </ul>
          <div class="panel-body">
            <div class="tab-content">
              <div id="tab-01" class="tab-pane active">
                <div class="col-md-12">
                  <div class="entry-header-admin">
                    <div class="gap-40"></div>
                    <div class="col-md-8 entry-title-admin text-left pull-left">
                      <div class="row">
                      <div class="col-md-4">
                        <p class="font-120P">All Opportunities</p>
                      </div>
                      <!-- Start - Available to Super Admin users only -->
                      <div class="col-md-2" v-if="user.isSuperAdmin || user.isAdmin">
                        <!-- Square switch -->
                        <label class="switch">
                          <input type="checkbox" v-model="showArchived">
                          <span class="slider"></span>
                        </label>
                      </div>
                      <div class="col-md-6" v-if="user.isSuperAdmin || user.isAdmin">
                        Show Archived Opportunities &nbsp;&nbsp;
                      </div>
                      <!-- End - Available to Super Admin users only -->
                      </div>
                    </div>
                    <div
                      class="col-md-4 entry-title-admin text-right pull-right"
                    >
                      <div class="row" v-if="isCreatable">
                        <router-link
                          class="btn btn-primary"
                          :to="{ name: 'opportunities_create' }"
                        >
                          <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Add
                          Opportunity
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- Heading end -->

                  <!-- Task details start -->
                  <div class="entry-content">
                    <div class="gap-20"></div>
                    <ResourceTable
                      :showArchived="showArchived"
                      v-if="apiUrl"
                      ref="table"
                      show-path="opportunities_show"
                      :columns="columns"
                      :update-available="updateAvailable"
                      :url="url"
                      :extra-params="{
                        sort_column: 'date',
                        sort_dir: 'desc'
                      }"
                    />
                  </div>
                  <!-- Task details end -->
                </div>
              </div>

              <div id="tab-02" class="tab-pane">
                <div class="col-md-12">
                  <!-- Task details start -->
                  <div class="entry-content">
                    <div class="gap-20"></div>
                    <csv-form />
                  </div>
                  <!-- Task details end -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceTable from "@/components/ResourceTable";
import CsvForm from "./components/CsvForm.vue";
import columns from "./columns";
import {mapGetters} from "vuex";

export default {
  components: {
    ResourceTable,
    CsvForm
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    columns() {
      return columns;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  methods: {},
  data() {
    return {
      isLoading: false,
      url: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests`,
      showArchived : false,
      apiUrl : '',
    };
  },
  mounted() {
    this.apiUrl = this.url
    this.$ui.setPageHeader({
      title: "Opportunities Administration",
      showTask: false
    });

    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Opportunities Administration",
        url: "opportunities",
        active: true
      }
    ]);
  },
  watch : {
    showArchived() {
      setTimeout(() =>{
        this.$refs.table.pageChange(1)
      },500)
    }
  }
};
</script>
