<template>
  <div class="form-group">
    <Select
      skip
      :name="name"
      :label="label"
      :value="values.selected"
      :placeholder="label"
      :url="url"
      :axios="$http"
      @selected="value => handleChange(value ? value.id : null)"
      ref="selection"
    />
  </div>
</template>
<script>
import Select from "@/components/Form/Select";
export default {
  props: {
    url: String,
    name: String,
    label: String,
    key: String
  },
  components: {
    Select
  },
  data() {
    return {
      values: {
        selected: null
      }
    };
  },
  methods: {
    handleChange(value) {
      this.values.selected = value;
      this.$emit("change", {
        value,
        key: this.name
      });
    },
    reset() {
      this.values.selected = null;
      this.$refs.selection.resetSelection();
    }
  }
};
</script>
