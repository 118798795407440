<template>
  <Accordion icon="fa fa-dollar" header="Offer History" closed>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-1">
        <p class="listing-label">Offer</p>
      </div>
      <div class="col-md-3">
        <p class="listing-label">Date</p>
      </div>
      <div class="col-md-3">
        <p class="listing-label">Amount</p>
      </div>
      <div class="col-md-5">
        <p class="listing-label">Notes</p>
      </div>
    </div>
    <div class="gap-20"></div>
    <div v-for="(offer, index) in offers" :key="index">
      <div class="row">
        <div class="col-md-1">
          <p>{{ index + 1 }}</p>
        </div>
        <div class="col-md-3">
          <p>{{ offer.created_at }}</p>
        </div>
        <div class="col-md-3">
          <p>
            {{ offer.offer_amount }}&nbsp;
            <i
              class="fa fa-arrow-up font-green"
              v-if="offer.diff === 'INCREASE'"
            ></i>
            <i class="fa fa-minus font-green" v-if="offer.diff === 'EQUAL'"></i>
            <i class="fa fa-minus font-green" v-if="offer.diff === 'NEW'"></i>
            <i
              class="fa fa-arrow-down font-red"
              v-if="offer.diff === 'DECREASE'"
            ></i>
          </p>
        </div>
        <div class="col-md-5">
          <p>{{ offer.notes }}</p>
        </div>
      </div>
      <div class="row">
        <div class="bottom-border"></div>
      </div>
    </div>
  </Accordion>
</template>
<script>
import Accordion from "../../../components/Accordion";
export default {
  props: {
    offers: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Accordion
  }
};
</script>
