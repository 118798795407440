<template>
  <div class="row">
    <Accordion header="Additional Contacts" icon="fa fa-user-plus" closed>
      <div v-for="contact in account.contacts" :key="contact.id">
        <Form
          :ref="`refContact_${contact.id}`"
          :account="account"
          active
          set-primary
          :form-data="contact"
          @save="values => $emit('save', values)"
        />
        <div class="col-md-12">
          <div class="div-border-bottom"></div>
        </div>
        <div class="gap-40"></div>
      </div>
      <Form
        ref="refContact"
        :account="account"
        new-contact
        @save="values => $emit('save', values)"
      />
    </Accordion>
  </div>
</template>
<script>
import Accordion from "../../../components/Accordion";
import Form from "../../../components/Contact/Form";
export default {
  components: {
    Accordion,
    Form
  },
  props: {
    account: Object
  },
  methods : {
    submitAll() {
      //Since this can have dynamic contact refs ->   :ref="`refContact_${contact.id}`"
      Object.keys(this.$refs).forEach(key =>{
        this.$refs[key]?.handleSave(true)
      })

    }
}
};
</script>
