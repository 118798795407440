export default [
  { name: "Section Break", value: "SectionBreak" },
  { name: "Confidentiality", value: "Confidentiality" },
  { name: "The Purpose", value: "Purpose" },
  { name: "About Us", value: "AboutUs" },
  {
    name: "Acknowledgment & Understanding",
    value: "AcknowledgmentAndUnderstanding"
  },
  { name: "Vendor's Disclaimer", value: "VendorsDisclaimer" },
  { name: "Broker's Disclaimer", value: "BrokersDisclaimer" },
  { name: "Main Heading", value: "MainHeading" },
  { name: "Sub Heading", value: "SubHeading" },
  { name: "Image", value: "Image" },
  { name: "Text Box", value: "TextBox" },
  { name: "Table", value: "Table" },
  { name: "Bar Chart", value: "BarChart" },
  { name: "Proposed Sale", value: "ProposedSale" }
];
