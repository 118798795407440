<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <h2 class="section-title">{{ headingSmall }}</h2>
            <h3 class="section-sub-title">{{ headingLarge }}</h3>
            <!--/ Title row end -->
          </div>
          <div class="col-md-3 text-right" v-if="listing">
            <p class="listing-label">Reference</p>
            <p>
              <span class="listing-highlight-3">{{ listing.reference }}</span>
            </p>
          </div>
        </div>
        <div class="row" id="confidentiality">
          <div class="col-md-12">
            <div class="img-container">
              <FileViewer v-if="image" :image="image" alt="" />
              <img v-else :src="imageData" alt="" />
              <div class="container">
                <div class="box-slider-content-IM">
                  <div class="box-slider-text-IM">
                    <h3 class="box-slide-sub-title">{{ body.heading }}</h3>
                    <h2 class="box-slide-title" v-html="body.text_1" />
                    <p class="box-slide-description" v-html="body.text_2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileViewer from "@/components/FileViewer/Index";
import { toDataURL } from "@/utils/image";

export default {
  data() {
    return {
      imageData: null
    };
  },
  components: {
    FileViewer
  },
  props: {
    headingSmall: String,
    headingLarge: String,
    image: String,
    image_url: String,
    body: Object,
    listing: Object
  },
  async mounted() {
    let _this = this;
    toDataURL(this.image_url, function(img) {
      _this.imageData = img;
    });
  }
};
</script>
