<template>
  <div class="">
    <div v-if="customer">
      <Form
        :form-data="customer"
        :is-loading="isSubmitting"
        update
        :key="customer.id"
        :errors="errors"
        from-customer
        @save="handleSave"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Form from "../../CustomerNew/components/Edit";

export default {
  components: {
    Form
  },
  props: {
    created: Boolean,
    updated: Boolean,
    handleSubmit: Function,
    permissionUpdate: Boolean
  },
  computed: {
    ...mapGetters("auth", ["customer", "errors", "isSubmitting"])
  },
  methods: {
    ...mapActions("auth", ["getCustomer"]),
    handleSave() {
      this.getCustomer(this.customer.id);
      this.$emit('save', 'update');
    }
  }
};
</script>

<style></style>
