<template>
  <div>
    <div class="row">
      <div class="col-md-6" v-if="title">
        <p>
          <strong class="font-120P">{{ title }}</strong>
        </p>
      </div>
      <div class="col-md-3" v-if="newContact">
        <p><strong class="font-120P">New Contact</strong></p>
      </div>
      <div class="col-md-3" v-if="newContact">
        Primary Contact&nbsp;&nbsp;
        <label class="switch">
          <input type="checkbox" v-model="values.primary" />
          <span class="slider"></span>
        </label>
      </div>
      <div class="col-md-6" v-if="active">
        Active&nbsp;&nbsp;
        <label class="switch">
          <input type="checkbox" v-model="values.status" />
          <span class="slider"></span>
        </label>
      </div>
      <div class="col-md-6 text-right">
        <button
          type="button"
          v-if="newPassword"
          class="btn btn-small"
          @click="$emit('newPassword')"
        >
          <i class="fa fa-lock"></i>&nbsp;&nbsp;New Password</button
        >&nbsp;&nbsp;
        <button
          type="button"
          v-if="setPrimary && values.id"
          class="btn btn-xsmall"
          @click="handleSetPrimary"
        >
          SET TO PRIMARY&nbsp;</button
        >&nbsp;&nbsp;
        <button
          title="Save Detail"
          class="btn btn-xsmall"
          type="button"
          :disabled="isSubmitting"
          @click="handleSave(false)"
        >
          <span class="font-120P"><i class="fa fa-save"></i></span>
        </button>
      </div>
    </div>
    <div class="gap-10"></div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="first_name">First name *</label>
          <input
            class="form-control"
            id="first_name"
            name="first_name"
            placeholder="Legal first name"
            type="text"
            v-model="values.first_name"
            :class="`${errors?.hasOwnProperty('first_name') ? 'has-error' : ''}`"
          />
          <span
              v-if="errors?.hasOwnProperty('first_name')"
              class="help-block text-danger"
          >{{ errors.first_name[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="last_name">Surname *</label>
          <input
            class="form-control"
            id="last_name"
            name="last_name"
            placeholder="Legal surname"
            type="text"
            v-model="values.last_name"
            :class="`${errors?.hasOwnProperty('last_name') ? 'has-error' : ''}`"
          />
          <span
              v-if="errors?.hasOwnProperty('last_name')"
              class="help-block text-danger"
          >{{ errors.last_name[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="business_role">Role within business </label>
          <input
            class="form-control"
            id="business_role"
            name="business_role"
            placeholder="Role within business"
            type="text"
            v-model="values.business_role"
            :class="
              `${errors?.hasOwnProperty('business_role') ? 'has-error' : ''}`
            "
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="email">Email address *</label>
          <input
            class="form-control"
            id="email"
            name="email"
            placeholder="Best correspondence email"
            type="email"
            v-model="values.email"
            :class="`${errors?.hasOwnProperty('email') ? 'has-error' : ''}`"
          />
          <span
              v-if="errors?.hasOwnProperty('email')"
              class="help-block text-danger"
          >{{ errors.email[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="email">Best contact number *</label>
          <input
            class="form-control"
            id="phone_number"
            name="phone_number"
            placeholder="Best correspondence phone number"
            type="text"
            v-model="values.phone_number"
            :class="
              `${errors?.hasOwnProperty('phone_number') ? 'has-error' : ''}`
            "
          />
          <span
              v-if="errors?.hasOwnProperty('phone_number')"
              class="help-block text-danger"
          >{{ errors.phone_number[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <Select
            name="business_address_id"
            label="Linked Address *"
            full
            :value="values.business_address_id"
            :class-name="
              `${
                errors?.hasOwnProperty('business_address_id') ? 'has-error' : ''
              }`
            "
            :error="
              errors?.hasOwnProperty('business_address_id')
                ? errors.business_address_id[0]
                : ''
            "
            required
            placeholder="Please Select"
            :url="businessSearch"
            :axios="$http"
            :extra-query="`&business_id=${account?.business?.id}`"
            @selected="
              value =>
                handleDropdownChange(
                  value ? value.id : null,
                  'business_address_id'
                )
            "
            showother
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="linkedin_profile">LinkedIn profile</label>
          <input
            class="form-control"
            id="linkin_profile"
            name="linkedin_profile"
            placeholder="LinkedIn profile"
            type="text"
            v-model="values.linkedin_profile"
            :class="
              `${errors?.hasOwnProperty('linkedin_profile') ? 'has-error' : ''}`
            "
          />
        </div>
      </div>
    </div>
    <div class="row" v-if="!fromCustomer">
      <div class="col-md-12">
        <div class="form-group">
          <label for="notes">Contact Note</label>
          <textarea
            class="form-control form-control-message"
            name="notes"
            id="notes"
            v-model="values.note"
            placeholder="Contact Note"
            rows="2"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row" v-if="!fromCustomer">
      <div class="col-md-3">
        <div class="form-group">
          <div class="" style="">
            <label
              ><input
                class="form-check-input"
                id="newsletter"
                name="newsletter"
                type="checkbox"
                v-model="values.newsletter"
              />&nbsp;&nbsp; Receive Newsletter
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Select from "../Form/Select";
export default {
  components: {
    Select
  },
  props: {
    formData: {
      type: Object,
      default: () => ({
        first_name: "",
        last_name: "",
        business_role: "",
        email: "",
        phone_number: "",
        business_address_id: "",
        linkedin_profile: "",
        note: "",
        newsletter: "",
        status: false
      })
    },
    account: Object,
    newPassword: Boolean,
    setPrimary: Boolean,
    active: Boolean,
    newContact: Boolean,
    fromCustomer: Boolean,
    title: String
  },
  data() {
    return {
      values: {
        ...this.formData,
        newsletter: !!this.formData?.newsletter,
        status: this.formData?.status === "Active"
      },
      errors: {},
      isSubmitting: false,
      primaryUpdating: false
    };
  },
  computed: {
    businessSearch() {
      return `${process.env.VUE_APP_BUSINESS_API_URL}/admin/addresses/search`;
    }
  },
  methods: {
    handleSave(isMassUpdate = false) {
      if (this.values.identifier) {
        this.handleUpdate(this.values,isMassUpdate);
      } else {
        this.handleCreate(this.values,isMassUpdate);
      }
    },
    async handleSetPrimary() {
      this.primaryUpdating = false;
      try {
        await this.$http.patch(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${this.account.id}`,
          {
            primary_contact_id: this.values.id
          }
        );
        this.$emit("save", "contact");
      } catch {
        //
      }
      this.primaryUpdating = true;
    },
    async handleUpdate(values,isMassUpdate = false) {
      this.isSubmitting = true;
      try {
        await this.$http.patch(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${this.account.id}/contacts/${values.identifier}`,
          {
            ...values,
            status: values.status ? "Active" : "Inactive"
          }
        );
        if(!isMassUpdate) this.$emit("save", "contact");
      } catch (e){
        this.errors = e.data.errors
      }
      this.isSubmitting = false;
    },
    async handleCreate(values,isMassUpdate = false) {
      //count 2 or less than 2 means it has only pre selected values. so at that moment we dont need to create a new address
      if(Object.values(this.values).filter(x => x !== null && x !== "").length <= 2 ) return
      this.isSubmitting = true;
      try {
        await this.$http.post(
          `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${this.account.id}/contacts`,
          values
        );
        this.values = {}
        if(!isMassUpdate) this.$emit("save", "contact");
      } catch {
        //
      }
      this.isSubmitting = false;
    },
    handleDropdownChange(value, param) {
      this.values[param] = value;
    }
  }
};
</script>
