<template>
  <div class="entry-content">
    <!--Tabs start-->
    <div class="col-md-12">
      <div class="row">
        <!--tabs border start-->
        <section class="normal-tabs line-tab">
          <Tab
            ref="tabs"
            :tabs="tabs"
            :defaultTab="activeTap"
            @submit="handleSubmit"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash-es/get";
import { mapActions } from "vuex";
import Tab from "../../components/Tab";

export default {
  components: {
    Tab
  },
  computed: {
    tabs() {
      return [
        {
          name: "",
          icon: "fa fa-eye",
          component: require("./Show/Index.vue").default,
          key: "details",
          props: {
            im: this.im,
            updated: this.updated,
            permissionsUpdate: this.permissionsUpdate
          }
        },
        {
          name: "",
          icon: "fa fa-edit",
          component: require("./Form.vue").default,
          key: "update",
          props: {
            im: this.im,
            updateAvailable: this.updateAvailable,
            formData: this.im,
            submitting: this.isSubmitting,
            update: true,
            key: this.im?.id,
            errors: this.errors
          }
        },
        {
          name: "",
          icon: "fa fa-handshake-o",
          component: require("./Show/Opportunities").default,
          key: "opportunities",
          props: {
            listingId: this.im?.listing?.id
          }
        },
        {
          name: "",
          icon: "fa fa-calendar-check-o",
          component: require("../../components/Task/Items").default,
          key: "tasks",
          props: {
            relatedType: "InformationMemorandum",
            relatedId: this.im?.id
          }
        },
        {
          name: "",
          icon: "fa fa-sticky-note",
          component: require("../../components/Note/Items").default,
          key: "notes",
          props: {
            relatedType: "InformationMemorandum",
            relatedId: this.im?.id
          }
        },
        {
          name: "",
          icon: "fa fa-list",
          component: require("../../components/ActivityLog").default,
          key: "activity-log",
          props: {
            subject: "InformationMemorandum",
            subjectId: this.im?.id,
            item: this.im,
            userAvailable: true
          }
        }
      ];
    }
  },
  data() {
    return {
      im: null,
      isLoading: false,
      updateAvailable: false,
      isSubmitting: false,
      activeTap: "details",
      updated: false,
      permissionUpdate: false,
      url: `${process.env.VUE_APP_LISTINGS_API_URL}/admin/information-memorandums`,
      interest: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/customers`,
      formData: {},
      errors: {},
      interestColumns: [
        {
          name: "date",
          text: "Date Enquired",
          timestamp: item => item.created
        },
        {
          name: "customer",
          text: "Customer",
          render: item => `${item.first_name} ${item.last_name}`,
          link: item => `${process.env.VUE_APP_ADMIN_URL}/customers/${item.id}`,
          sort_disable: true
        },
        {
          name: "ca",
          text: "CA",
          render: item =>
            item.status === "Completed" ? "Download" : item.status,
          download: true,
          downloadable: item => item.status === "Completed",
          className: item =>
            item.status === "Completed" ? "cursor-pointer font-link" : "",
          downloadUrl: item =>
            `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-ca`,
          sort_disable: true
        },
        {
          name: "eoi",
          text: "EOI",
          render: item =>
            item.expressionstatus === null ? "None" : item.expressionstatus,
          link: item =>
            item.expressionstatus === null
              ? ""
              : item.expressionstatus === "Submitted"
              ? `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-eoi`
              : `${process.env.VUE_APP_ADMIN_URL}/customer/${item.uuid}/information-memorandums?tab=eoi`,
          downloadable: item => item.expressionstatus === "Submitted",
          className: item =>
            item.expressionstatus === "Submitted"
              ? "cursor-pointer font-link"
              : "",
          sort_disable: true
        }
      ]
    };
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async loadUser() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(
          `${this.url}/${this.$route.params.id}`
        );
        this.im = data;
        this.formData = {
          ...data
        };
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
      }
    },
    async handleSubmit(values, permission) {
      this.isSubmitting = true;
      try {
        const { data } = await this.$http.post(
          `${this.url}/${this.$route.params.id}`,
          {
            ...this.formData,
            ...values
          }
        );
        await this.loadUser();
        await this.loadStats();
        if (permission) {
          this.permissionUpdate = true;
        } else {
          this.updated = true;
        }
        this.$refs.tabs.handleActiveTab("details");
        window.scrollTo(0,0);
        this.isSubmitting = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isSubmitting = false;
      }
    },
    handlePermissionSubmit(values) {
      this.handleSubmit(values, true);
    }
  },
  async mounted() {
    const breadcrumbs = [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "I M Administration",
        url: "im"
      },
      {
        name: "I M Details",
        url: "im_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ];
    this.$store.commit("app/SET_BREADCRUMBS", [...breadcrumbs]);
    await this.loadUser();

    this.$store.commit("app/SET_BREADCRUMBS", [
      ...breadcrumbs,
      {
        name: this.im?.listing?.reference,
        url: "im_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ]);
    this.updateAvailable = this.$route.permissions
      ? this.$route.permissions.permissions.includes(1)
      : true;
    if (this.$route.query.action) {
      this.activeTap = this.$route.query.action;
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
