<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              :class="{ active: activeTap === 'affiliate' }"
              @click="activeTap = 'affiliate'"
            >
              <a id="user_details_tab_link" data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Add</h4></a
              >
            </li>
          </ul>
          <form id="form_1">
            <div class="error-container"></div>
            <div class="panel-body">
              <div class="tab-content">
                <div
                  id="tab-01"
                  class="tab-pane"
                  :class="{ active: activeTap === 'affiliate' }"
                >
                  <div class="col-md-12">
                    <Form
                      :form-data="formData"
                      :errors="errors"
                      :is-loading="isLoading"
                      @submit="handleSubmit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./Form";
import get from "lodash-es/get";
import { mapActions } from "vuex";

export default {
  components: {
    Form
  },
  data() {
    return {
      activeTap: "affiliate",
      isLoading: false,
      errors: {}
    };
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async handleSubmit(values) {
      this.isLoading = true;
      try {
        if (values.website !== null && values.website !== undefined) {
          values.website = values.website
            .replace("https://", "")
            .replace("http://", "")
            .replace("www.", "");
        }
        const { data } = await this.$http.post(
          `${process.env.VUE_APP_AFFILIATE_API_URL}/admin/affiliates`,
          {
            ...values,
            country_id: values.country ? values.country.id : null,
            state_id: values.state ? values.state.id : null
          }
        );
        await this.loadStats();
        this.$router.push({
          name: "affiliates_show",
          params: { id: data.id }
        });
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
        this.activeTap = "affiliate";
      }
    },
    handleChangeTap() {
      this.activeTap = "permission";
    }
  },
  mounted() {
    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Affiliate Administration",
        url: "affiliates"
      },
      {
        name: "Affiliate Details",
        url: "affiliates_create",
        active: true
      }
    ]);
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
