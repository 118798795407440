<template>
  <div class="row">
    <Accordion :header="header" :icon="icon" closed>
      <Detail :customer="customer" :seller="seller" />
    </Accordion>
  </div>
</template>
<script>
import Detail from "./Detail";
import Accordion from "../Accordion";
export default {
  components: {
    Detail,
    Accordion
  },
  props: {
    header: String,
    customer: Object,
    seller: Object,
    icon: String
  }
};
</script>
