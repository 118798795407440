<template>
  <div class="body-inner" v-if="!isLoading">
    <TopBar />
    <Header />
    <AppLayout>
      <router-view />
      <!-- permissions="check" />-->
    </AppLayout>
    <Footer />
  </div>
</template>

<script>
import TopBar from "./components/Topbar.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import { mapGetters, mapActions } from "vuex";


export default {
  name: "App",
  data() {
    return {
      isLoading: false
    };
  },
  components: {
    TopBar,
    Header,
    Footer
  },
  computed: {
    ...mapGetters({
      menus: "app/menus",
      footer: "app/footer",
      permissions: "app/permissions"
    })
  },
  methods: {
    ...mapActions({
      getMenus: "app/getMenus",
      getPermissions: "app/getPermissions"
    })
  },
  async created() {
    this.isLoading = true;
    if (!Object.values(this.menus).length || !Object.keys(this.footer).length) {
      await this.getMenus();
    }
    if (!Object.keys(this.permissions).length) {
      await this.getPermissions();
    }
    this.isLoading = false;
  }
};
</script>

<style></style>
