<template>
  <div class="col-md-12">
    <div class="panel-body">
      <div class="tab-content">
        <div id="tab-01" class="tab-pane active">
          <div class="col-md-12">
            <div class="row">
              <Permission
                @update="handleChangePermission"
                :default-permissions="defaultPermissions"
                header="Permissions"
                create
              />
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-12">
                <div>
                  <p><strong class="font-120P">Account Definition</strong></p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <Select
                    name="stage_id"
                    label="Account Stage *"
                    :value="values.stage_id"
                    :class-name="
                      `${errors.hasOwnProperty('stage_id') ? 'has-error' : ''}`
                    "
                    :error="
                      errors.hasOwnProperty('stage_id')
                        ? errors.stage_id[0]
                        : ''
                    "
                    required
                    placeholder="Please Select"
                    :url="stageUrl"
                    :axios="$http"
                    @selected="
                      value =>
                        handleDropdownChange(
                          value ? value.id : null,
                          'stage_id'
                        )
                    "
                    showother
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="category">Category *</label>
                  <select
                    class="form-control"
                    name="category"
                    id="category"
                    required
                    v-model="values.category"
                  >
                    <option selected disabled value="">Please Select</option>
                    <option
                      :value="value.name"
                      v-for="(value, index) in categories"
                      :key="index"
                      >{{ value.name }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <Select
                    name="company_id"
                    label="Registering as an *"
                    :value="values.company_id"
                    :class-name="
                      `${
                        errors.hasOwnProperty('company_id') ? 'has-error' : ''
                      }`
                    "
                    :error="
                      errors.hasOwnProperty('company_id')
                        ? errors.company_id[0]
                        : ''
                    "
                    required
                    placeholder="Please Select"
                    :url="companyUrl"
                    :axios="$http"
                    @selected="
                      value =>
                        handleDropdownChange(
                          value ? value.id : null,
                          'company_id'
                        )
                    "
                    showother
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="registered_buyer">Buyer </label>
                  <select
                    class="form-control"
                    id="registered_buyer"
                    name="registered_buyer"
                    v-model="values.registered_buyer"
                    :class="
                      `${
                        errors.hasOwnProperty('registered_buyer')
                          ? 'has-error'
                          : ''
                      }`
                    "
                  >
                    <option selected disabled value="">Please Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="registered_seller">Seller </label>
                  <select
                    class="form-control"
                    id="registered_seller"
                    name="registered_seller"
                    v-model="values.registered_seller"
                    :class="
                      `${
                        errors.hasOwnProperty('registered_seller')
                          ? 'has-error'
                          : ''
                      }`
                    "
                  >
                    <option selected disabled value="">Please Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="gap-40"></div>
            <div class="row">
              <div class="col-md-12">
                <p><strong class="font-120P">Primary Contact</strong></p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="first_name">First name *</label>
                  <input
                    class="form-control"
                    id="first_name"
                    name="first_name"
                    placeholder="Legal first name"
                    type="text"
                    v-model="values.contact.first_name"
                    :class="
                      `${
                        errors.hasOwnProperty(['contact.first_name'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                  <span
                      v-if="errors.hasOwnProperty(['contact.first_name'])"
                      class="help-block text-danger"
                  >{{errors['contact.first_name'][0]}}</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="surname">Surname *</label>
                  <input
                    class="form-control"
                    id="last_name"
                    name="last_name"
                    placeholder="Legal surname"
                    type="text"
                    v-model="values.contact.last_name"
                    :class="
                      `${
                        errors.hasOwnProperty(['contact.last_name'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                  <span
                      v-if="errors.hasOwnProperty(['contact.last_name'])"
                      class="help-block text-danger"
                  >{{errors['contact.last_name'][0]}}</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="role">Role within business </label>
                  <input
                    class="form-control"
                    id="business_role"
                    name="business_role"
                    placeholder="Role within business"
                    type="text"
                    v-model="values.contact.business_role"
                    :class="
                      `${
                        errors.hasOwnProperty(['contact.business_role'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Email address *</label>
                  <input
                    class="form-control"
                    id="email"
                    name="email"
                    placeholder="Best correspondence email"
                    type="email"
                    v-model="values.contact.email"
                    :class="
                      `${
                        errors.hasOwnProperty(['contact.email'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                  <span
                      v-if="errors.hasOwnProperty(['contact.email'])"
                      class="help-block text-danger"
                  >{{errors['contact.email'][0]}}</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Best contact number *</label>
                  <input
                    class="form-control"
                    id="phone_number"
                    name="phone_number"
                    placeholder="Best correspondence phone number"
                    type="text"
                    v-model="values.contact.phone_number"
                    :class="
                      `${
                        errors.hasOwnProperty(['contact.phone_number'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="linedin">LinkedIn profile</label>
                  <input
                    class="form-control"
                    id="linkin_profile"
                    name="linkedin_profile"
                    placeholder="LinkedIn profile"
                    type="text"
                    v-model="values.contact.linkedin_profile"
                    :class="
                      `${
                        errors.hasOwnProperty(['contact.linkedin_profile'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                </div>
              </div>
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <div class="" style="">
                    <label
                      ><input
                        class="form-check-input"
                        id="newsletter"
                        name="newsletter"
                        type="checkbox"
                        v-model="values.contact.newsletter"
                      />&nbsp;&nbsp; Receive Newsletter
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="gap-40"></div>
            <div class="row">
              <div class="col-md-12">
                <p>
                  <strong class="font-120P">Primary Business Details</strong>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="business_name">Legal business name</label>
                  <input
                    class="form-control"
                    id="business_name"
                    name="business_name"
                    placeholder="The legal business name"
                    type="text"
                    v-model="values.business.name"
                    :class="
                      `${errors.hasOwnProperty('name') ? 'has-error' : ''}`
                    "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="business_abn">ABN / ACN </label>
                  <input
                    class="form-control"
                    id="business_abn"
                    name="business_abn"
                    placeholder="The business ABN / ACN"
                    type="text"
                    v-model="values.business.abn"
                    :class="
                      `${errors.hasOwnProperty('abn') ? 'has-error' : ''}`
                    "
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="business_phone">Business land-line number </label>
                  <input
                    class="form-control"
                    id="business_phone"
                    name="business_phone"
                    placeholder="The business land-line number"
                    type="text"
                    v-model="values.business.phone"
                    :class="
                      `${errors.hasOwnProperty('phone') ? 'has-error' : ''}`
                    "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="business_website">Business website </label>
                  <div class="input-group mb-3">
                    <div class="input-group-addon">
                      <span class="input-group-text" id="basic-addon3"
                        >https://www.</span
                      >
                    </div>
                    <input
                      class="form-control"
                      id="business_website"
                      v-model="values.business.website"
                      :class="
                        `${
                          errors.hasOwnProperty(['business.website'])
                            ? 'has-error'
                            : ''
                        }`
                      "
                      name="business_website"
                      placeholder="The business website"
                      type="text"
                      aria-describedby="basic-addon3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="street_address">Street address</label>
                  <input
                    class="form-control"
                    id="street_address"
                    name="street_address"
                    placeholder="The registered business street address"
                    type="text"
                    v-model="values.business.address.street_address"
                    :class="
                      `${
                        errors.hasOwnProperty(['address.street_address'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                  <span
                    v-if="errors.hasOwnProperty('address.street_address')"
                    class="help-block text-danger"
                    >{{ errors["address.street_address"][0] }}</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="suburb">Suburb</label>
                  <input
                    class="form-control"
                    id="suburb"
                    name="suburb"
                    placeholder="Registered suburb"
                    type="text"
                    v-model="values.business.address.suburb"
                    :class="
                      `${
                        errors.hasOwnProperty(['address.subrub'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <Select
                    name="state_id"
                    label="State *"
                    :value="
                      values.business.address.state_id
                        ? values.business.address.state_id
                        : null
                    "
                    :class-name="
                      `${
                        errors.hasOwnProperty(['address.state_id'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                    :error="
                      errors.hasOwnProperty(['address.state_id'])
                        ? errors.address.state_id[0]
                        : ''
                    "
                    required
                    placeholder="Please Select"
                    :url="stateUrl"
                    :axios="$http"
                    @selected="
                      value =>
                        handleDropdownChange(
                          value ? value.id : null,
                          'business.address.state_id'
                        )
                    "
                    showother
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="postcode">Postcode</label>
                  <input
                    class="form-control"
                    id="postcode"
                    name="postcode"
                    placeholder="Registered postcode"
                    type="text"
                    v-model="values.business.address.postcode"
                    :class="
                      `${
                        errors.hasOwnProperty(['address.postcoe'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <Select
                    name="country_id"
                    label="Country *"
                    :value="values.business.address.country_id"
                    :class-name="
                      `${
                        errors.hasOwnProperty(['address.country_id'])
                          ? 'has-error'
                          : ''
                      }`
                    "
                    :error="
                      errors.hasOwnProperty(['address.country_id'])
                        ? errors.address.country_id[0]
                        : ''
                    "
                    required
                    placeholder="Please Select"
                    :url="countryUrl"
                    :axios="$http"
                    @selected="
                      value =>
                        handleDropdownChange(
                          value ? value.id : null,
                          'business.address.country_id'
                        )
                    "
                    showother
                  />
                </div>
              </div>
            </div>

            <div class="gap-40" v-if="values.registered_buyer === 'Yes'"></div>
            <div class="row" v-if="values.registered_buyer === 'Yes'">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <p>
                          <strong class="font-120P"
                            >Rent Roll / Agency Preferences</strong
                          >
                          (for buyers)
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <Select
                            name="state"
                            label="State *"
                            :value="values.state_id ? values.state_id : null"
                            :class-name="
                              `${
                                errors.hasOwnProperty('state_id')
                                  ? 'has-error'
                                  : ''
                              }`
                            "
                            :error="
                              errors.hasOwnProperty('state_id')
                                ? errors.state_id[0]
                                : ''
                            "
                            placeholder="Select a state"
                            :url="stateUrl"
                            :axios="$http"
                            @selected="
                              value =>
                                handleDropdownChange(
                                  value ? value.id : null,
                                  'state_id'
                                )
                            "
                            showother
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row"></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Price Range From</label>
                      <select
                        id="price_range_from"
                        name="price_range_from"
                        v-model="values.price_range_from"
                        :class="
                          `${
                            errors.hasOwnProperty('price_range_from')
                              ? 'has-error'
                              : ''
                          }`
                        "
                        class="form-control"
                      >
                        <option
                          :value="priceRange.value"
                          v-for="priceRange in priceRanges"
                          :key="priceRange.value"
                        >
                          {{ priceRange.name }}
                        </option>
                      </select>
                      <span
                        v-if="errors.hasOwnProperty('price_range_from')"
                        class="help-block text-danger"
                        >{{ errors.price_range_from[0] }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Price Range To</label>
                      <select
                        id="price_range_to"
                        name="price_range_to"
                        v-model="values.price_range_to"
                        class="form-control"
                        :class="
                          `${
                            errors.hasOwnProperty('price_range_to')
                              ? 'has-error'
                              : ''
                          }`
                        "
                      >
                        <option
                          :value="priceRange.value"
                          v-for="priceRange in priceRanges"
                          :key="priceRange.value"
                        >
                          {{ priceRange.name }}
                        </option>
                      </select>
                      <span
                        v-if="errors.hasOwnProperty('price_range_to')"
                        class="help-block text-danger"
                        >{{ errors.price_range_to[0] }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Roll Size From</label>
                      <select
                        id="roll_size_from"
                        name="roll_size_from"
                        v-model="values.roll_size_from"
                        :class="
                          `${
                            errors.hasOwnProperty('roll_size_from')
                              ? 'has-error'
                              : ''
                          }`
                        "
                        class="form-control"
                      >
                        <option
                          :value="rollSize.value"
                          v-for="rollSize in rollSizes"
                          :key="rollSize.value"
                        >
                          {{ rollSize.name }}
                        </option>
                      </select>
                      <span
                        v-if="errors.hasOwnProperty('roll_size_from')"
                        class="help-block text-danger"
                        >{{ errors.roll_size_from[0] }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Roll Size To</label>
                      <select
                        id="roll_size_to"
                        name="roll_size_to"
                        v-model="values.roll_size_to"
                        :class="
                          `${
                            errors.hasOwnProperty('roll_size_to')
                              ? 'has-error'
                              : ''
                          }`
                        "
                        class="form-control"
                      >
                        <option
                          :value="rollSize.value"
                          v-for="rollSize in rollSizes"
                          :key="rollSize.value"
                        >
                          {{ rollSize.name }}
                        </option>
                      </select>
                      <span
                        v-if="errors.hasOwnProperty('roll_size_to')"
                        class="help-block text-danger"
                        >{{ errors.roll_size_to[0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Listing preferences (select all that apply)</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="col-md-2" style="display: inline-block">
                        <label
                          ><input
                            class="form-check-input"
                            id="agency"
                            name="agency"
                            type="checkbox"
                            :class="
                              `${
                                errors.hasOwnProperty('agency')
                                  ? 'has-error'
                                  : ''
                              }`
                            "
                            @change="values.agency = !values.agency"
                            value="true"
                            :checked="values.agency"
                          />&nbsp;&nbsp; Agency
                        </label>
                        <span
                          v-if="errors.hasOwnProperty('agency')"
                          class="help-block text-danger"
                          >{{ errors.agency[0] }}</span
                        >
                      </div>
                      <div class="col-md-4" style="display: inline-block">
                        <label
                          ><input
                            class="form-check-input"
                            id="commercial"
                            name="commercial"
                            :class="
                              `${
                                errors.hasOwnProperty('commercial')
                                  ? 'has-error'
                                  : ''
                              }`
                            "
                            @change="values.commercial = !values.commercial"
                            type="checkbox"
                            :checked="values.commercial"
                            value="1"
                          />&nbsp;&nbsp; Commercial/Industrial
                        </label>
                        <span
                          v-if="errors.hasOwnProperty('commercial')"
                          class="help-block text-danger"
                          >{{ errors.commercial[0] }}</span
                        >
                      </div>
                      <div class="col-md-2" style="display: inline-block">
                        <label
                          ><input
                            class="form-check-input"
                            id="holiday"
                            name="holiday"
                            :class="
                              `${
                                errors.hasOwnProperty('holiday')
                                  ? 'has-error'
                                  : ''
                              }`
                            "
                            @change="values.holiday = !values.holiday"
                            type="checkbox"
                            value="1"
                            :checked="values.holiday"
                          />&nbsp;&nbsp; Holiday
                        </label>
                        <span
                          v-if="errors.hasOwnProperty('holiday')"
                          class="help-block text-danger"
                          >{{ errors.holiday[0] }}</span
                        >
                      </div>
                      <div class="col-md-2" style="display: inline-block">
                        <label
                          ><input
                            class="form-check-input"
                            id="residential"
                            name="residential"
                            :class="
                              `${
                                errors.hasOwnProperty('residential')
                                  ? 'has-error'
                                  : ''
                              }`
                            "
                            @change="values.residential = !values.residential"
                            type="checkbox"
                            :checked="values.residential"
                            value="1"
                          />&nbsp;&nbsp; Residential
                        </label>
                        <span
                          v-if="errors.hasOwnProperty('residential')"
                          class="help-block text-danger"
                          >{{ errors.residential[0] }}</span
                        >
                      </div>
                      <div class="col-md-2" style="display: inline-block">
                        <label
                          ><input
                            class="form-check-input"
                            id="strata"
                            @change="values.strata = !values.strata"
                            name="strata"
                            :class="
                              `${
                                errors.hasOwnProperty('strata')
                                  ? 'has-error'
                                  : ''
                              }`
                            "
                            type="checkbox"
                            :checked="values.strata"
                            value="1"
                          />&nbsp;&nbsp; Strata
                        </label>
                        <span
                          v-if="errors.hasOwnProperty('strata')"
                          class="help-block text-danger"
                          >{{ errors.strata[0] }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <SubmitButton v-if="isCreatable || updateAvailable"
                    :isProgress="isLoading"
                    :caption="buttonName"
                    @submit="handleSubmit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Permission from "../../components/Permission";
import SubmitButton from "@/components/SubmitButton";
import Select from "@/components/Form/Select";
import { mapActions, mapGetters } from "vuex";
import { price_ranges, roll_sizes } from "../../lib/config";
import map from "lodash-es/map";
import set from "lodash-es/set";

export default {
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: () => false
    },
    errors: {
      type: Object,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: () => false
    },
    submitting: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    Permission,
    SubmitButton,
    Select
  },
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions",
      categories: "masterData/categories"
    }),
    buttonName() {
      if (this.update) {
        if (this.isLoading) {
          return "Updating";
        } else {
          return "Update Account";
        }
      } else {
        if (this.isLoading) {
          return "Creating";
        } else {
          return "Create Account";
        }
      }
    },
    defaultPermissions() {
      let permissions = [];
      map(this.permissionMeta.module_permission.account.permissions, group => {
        map(group.permissions, permission => {
          permissions.push({
            group: group.group,
            name: permission.caption,
            permission: permission.title,
            value: permission.name
          });
        });
      });
      return permissions;
    },
    stageUrl() {
      return `${process.env.VUE_APP_CONFIG_API_URL}/accountstages`;
    },
    stateUrl() {
      return `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`;
    },
    countryUrl() {
      return `${process.env.VUE_APP_GEO_LOCATION_API_URL}/countries/search`;
    },
    categoryUrl() {
      return `${process.env.VUE_APP_CONFIG_API_URL}/accountcategories`;
    },
    companyUrl() {
      return `${process.env.VUE_APP_CONFIG_API_URL}/accountcompanies`;
    },
    priceRanges() {
      return price_ranges;
    },
    rollSizes() {
      return roll_sizes;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  data() {
    return {
      values: {
        ...this.formData,
        registered_buyer: this.formData.registered_buyer
          ? this.formData.registered_buyer
          : "No",
        registered_seller: this.formData.registered_seller
          ? this.formData.registered_seller
          : "No"
      }
    };
  },
  methods: {
    ...mapActions({
      loadDropDownListData: "masterData/loadDropDownListData"
    }),
    handleDropdownChange(value, param) {
      const values = this.values;
      set(values, param, value);
      this.values = values;
    },
    handleChangePermission(values) {
      this.values.permissions = values;
    },
    handleSubmit() {
      this.$emit("submit", {
        ...this.values,
        permissions: this.values.permissions
          ? this.values.permissions
          : {
              admin_access: {
                admin_approved: true,
                site_access: true,
                im_access: false
              }
            }
      });
    }
  },
  mounted() {
    this.loadDropDownListData();
  }
};
</script>

<style></style>
