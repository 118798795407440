import map from "lodash-es/map";

export const SET_DATA = "SET_DATA";
export const SET_LOADING = "SET_LOADING";

const state = {
  items: {},
  isLoading: {}
};

// getters
const getters = {
  items: state => {
    return state.items;
  },
  isLoading: state => {
    return state.isLoading;
  }
};

// actions
const actions = {
  async getData({ commit }) {
    commit(SET_LOADING, true);
    try {
      const { data } = await this.$http.get(
        `${process.env.VUE_APP_STATS_API_URL}/admin/charts`
      );

      commit(SET_DATA, data);
      commit(SET_LOADING, false);
    } catch (e) {
      commit(SET_LOADING, false);
    }
  }
};

// mutations
const mutations = {
  [SET_DATA]: (state, data) => {
    state.items = data;
  },
  [SET_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
