<template>
  <div class="row">
    <div class="col-md-9">
      <h2 class="section-title">{{ headingSmall }}</h2>
      <h3 class="section-sub-title">{{ headingLarge }}</h3>
      <!--/ Title row end -->
    </div>
    <div class="col-md-3 text-right" v-if="listing">
      <p class="listing-label">Reference</p>
      <p>
        <span class="listing-highlight-3">{{ listing.reference }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headingSmall: String,
    headingLarge: String,
    listing: Object
  },
  data() {
    return {
      values: {
        headingSmall: this.headingSmall,
        headingLarge: this.headingLarge
      }
    };
  },
  created() {},
  mounted() {},
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("valueChange", this.values);
      }
    }
  }
};
</script>
