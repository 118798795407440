import {admin, identities} from "@/lib/axios";
import get from "lodash-es/get";
import { setCookie } from '@/utils/helpers'
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_OTP_VERIFIED = "SET_OTP_VERIFIED";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_IS_SUBMITTING = "SET_IS_SUBMITTING";
export const SET_ERRORS = "SET_ERRORS";
export const SET_UPDATED = "SET_UPDATED";
export const SET_STATS = "SET_STATS";
export const ADD_STATS = "ADD_STATS";

const state = {
  user: null,
  token: null,
  otpVerified: null,
  customer: null,
  stats: {},
  isSubmitting: false,
  isUpdated: false,
  statsLoaded: false,
  errors: {}
};

// getters
const getters = {
  user: state => {
    return state.user;
  },
  token: state => {
    return state.token;
  },
  otpVerified: state => {
    return state.otpVerified;
  },
  statsLoaded: state => {
    return state.statsLoaded;
  },
  stats: state => {
    return state.stats;
  },
  customer: state => {
    return state.customer;
  },
  loggedIn: (state, getters) => {
    return getters.user !== null && getters.token !== null && getters.otpVerified;
  },
  authorizationToken: (state, getters) => {
    return getters.token ? getters.token : null;
  },
  permissions: state => {
    return state.user?.permissions;
  },
  errors: state => {
    return state.errors;
  },
  isUpdated: state => {
    return state.isUpdated;
  },
  isSubmitting: state => {
    return state.isSubmitting;
  },
  isAdmin: state => state.user.isAdmin,
  isSuperAdmin: state => {
    const permissions = getters.permissions(state);
    return permissions && permissions.admin?.permissions
      ? permissions.admin.permissions.includes(2)
      : false;
  }
};

// actions
const actions = {
  async loadStats({ commit }) {
    try {
      const { data } = await this.$http.get(
        `${process.env.VUE_APP_STATS_API_URL}/admin/stats`
      );
      commit(SET_STATS, data);
    } catch (error) {
      console.log(error);
    }
  },

  async getCustomer({ commit }, id) {
    try {
      const { data } = await this.$http.get(
        `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${id}`
      );
      console.log(data, 'data')
      setCookie('getAuthCustomer', data, 1)
      commit(SET_CUSTOMER, data);
    } catch (error) {
      console.log(error);
    }
  },
  async loginOnce({ commit, dispatch }, values) {
    values = {
      ...values
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await admin.post("/login/once", values);
      setCookie('getAuthUser', data.user,1)
      commit(SET_USER, data.user);
    } catch (error) {
      throw error;
    }
  },
  async login({ commit, dispatch }, values) {
    values = {
      ...values,
      device_name: "web"
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await admin.post("/sanctum/token", values);
      commit(SET_TOKEN, data.token);
    } catch (error) {
      throw error;
    }
  },
  async resetPassword({ commit }, values) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { success } = await admin.post("/api/password-reset", values);
      return success;
    } catch (error) {
      throw error;
    }
  },
  async forgotPassword({ commit }, values) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await admin.post("/api/forgot-password", values);
      return data;
    } catch (error) {
      throw error;
    }
  },
  async getOTPVerification({ commit }, values) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await identities.post("/api/authenticator-verification", values);
      commit(SET_OTP_VERIFIED, data.success === true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  async getEmailOTPVerification({ commit }, values) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await identities.post("/api/email-verification", values);
      commit(SET_OTP_VERIFIED, data.success === true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  /*async loadUser({ commit }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const user = await this.$http.get("/api/user");
    //  commit(SET_USER, user);
    } catch (error) {
      throw error;
    }
  },
  async loadCustomer({ commit, state }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await this.$http.get(
        `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/get-customer`,
        {
          params: {
            email: state.user?.email
          }
        }
      );
    //  commit(SET_CUSTOMER, data);
    } catch (error) {
      throw error;
    }
  },*/
  async updateCustomer({ commit, state }, { values: values, id: id }) {
    commit(SET_IS_SUBMITTING, true);
    commit(SET_ERRORS, {});
    try {
      await this.$http.patch(
        `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${id}`,
        values
      );

      const { business: businessData } = values;
      await this.$http.patch(
        `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses/${businessData.id}/customer`,
        businessData
      );

      commit(SET_IS_SUBMITTING, false);
      commit(SET_CUSTOMER, values);
    } catch (e) {
      commit(SET_ERRORS, get(e, "data.errors"));
      commit(SET_IS_SUBMITTING, false);
    }
  },
  async logOut({ commit }, { values: values }) {
    try {
      await this.$http.post(
        `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/logout`,
        values
      );
    } catch (e) {}
    // eslint-disable-next-line no-useless-catch
    commit(LOGOUT);
  },
  async isUpdated({ commit }) {
    // eslint-disable-next-line no-useless-catch
    commit(SET_UPDATED, false);
  },
  async setInterestSubmittedCustomer({ commit, dispatch }, values){
    values = {
      ...values,
      device_name: "web"
    };

    // eslint-disable-next-line no-useless-catc
    await admin.post("/sanctum/token", values)
    .then((res) => {
      commit(SET_USER, res.data.user);
      commit(SET_TOKEN, res.data.token);
      commit(SET_OTP_VERIFIED, true);
    }).catch((error) => {
      throw error
    });
  }
};

// mutations
const mutations = {
  [SET_TOKEN]: (state, token) => {
    state.token = token;
  },
  [SET_USER]: (state, user) => {
    state.user = user;
  },
  [SET_OTP_VERIFIED]: (state, otpVerified) => {
    state.otpVerified = otpVerified;
  },
  [SET_CUSTOMER]: (state, customer) => {
    state.customer = customer;
  },
  [LOGOUT]: state => {
    state.user = null;
    state.token = null;
    state.otpVerified = null;
    state.customer = null;
    state.statsLoaded = false;
    state.table = null;
  },
  [SET_IS_SUBMITTING]: (state, isSubmitting) => {
    state.isSubmitting = isSubmitting;
  },
  [SET_ERRORS]: (state, errors) => {
    state.errors = errors;
  },
  [SET_UPDATED]: state => {
    state.isUpdated = false;
  },
  [SET_STATS]: (state, stats) => {
    state.stats = stats;
    state.statsLoaded = true;
  },
  [ADD_STATS]: (state, stats) => {
    state.stats = stats;
    state.statsLoaded = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
