
export function filterByType(data, type) {
  return data.filter(item => item.type === type)
}

export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = `${name}=${JSON.stringify(value) || ""}${expires}; domain=${process.env.VUE_APP_COOKIE_DOMAIN}; path=/; SameSite=None; Secure`;
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}
