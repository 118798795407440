<template>
  <div class="col-md-12">
    <div
      v-for="(section, index) in formData"
      :key="index"
      class="row"
      :class="section.className ? section.className : 'template-area'"
    >
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <div class="form-group">
              <select
                class="form-control-templates"
                name="template"
                v-model="section.component"
                @change="event => handleBlogChange(index, event.target.value)"
              >
                <option disabled>Please Select</option>
                <option
                  :key="component.value"
                  :value="component.value"
                  v-for="component in components"
                  >{{ component.name }}</option
                >
              </select>
            </div>
          </div>

          <div class="col-md-3 text-right">
            <div class=" m-top-6">
              <button
                title="Save section"
                class="btn btn-xsmall"
                type="button"
                :disabled="submitting || isDisabled"
                @click="handleSave"
              >
                <span class="font-120P"
                  ><i class="fa fa-save"></i
                ></span></button
              >&nbsp;&nbsp;
              <button
                title="Add new section below this one"
                class="btn btn-xsmall"
                type="button"
                @click="() => handleAddBlog(section, index)"
              >
                <span class="font-120P"
                  ><i class="fa fa-plus-circle"></i
                ></span></button
              >&nbsp;&nbsp;<button
                title="Delete this section"
                class="btn btn-xsmall"
                type="button"
                @click="() => handleRemoveBlog(index)"
              >
                <span class="font-120P"
                  ><i class="fa fa-minus-circle"></i
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="gap-20"></div>
      <component
        v-bind="section.data"
        :is="section.component"
        @valueChange="valuesData => handleChangeBlog(index, valuesData)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import set from "lodash-es/set";
import cloneDeep from "lodash-es/cloneDeep";
import components from "../../InformationMemorandum/components";
import AboutUs from "../../InformationMemorandum/components/Form/AboutUs/Index";
import AcknowledgmentAndUnderstanding from "../../InformationMemorandum/components/Form/AcknowledgmentAndUnderstanding/Index";
import BarChart from "../../InformationMemorandum/components/Form/BarChart/Index";
import BrokersDisclaimer from "../../InformationMemorandum/components/Form/BrokersDisclaimer/Index";
import Confidentiality from "../../InformationMemorandum/components/Form/Confidentiality/Index";
import Image from "../../InformationMemorandum/components/Form/Image/Index";
import MainHeading from "../../InformationMemorandum/components/Form/MainHeading/Index";
import ProposedSale from "../../InformationMemorandum/components/Form/ProposedSale/Index";
import Purpose from "../../InformationMemorandum/components/Form/Purpose/Index";
import SectionBreak from "../../InformationMemorandum/components/Form/SectionBreak/Index";
import SubHeading from "../../InformationMemorandum/components/Form/SubHeading/Index";
import Table from "../../InformationMemorandum/components/Form/Table/Index";
import TextBox from "../../InformationMemorandum/components/Form/TextBox/Index";
import VendorsDisclaimer from "../../InformationMemorandum/components/Form/VendorsDisclaimer/Index";
import filter from "lodash-es/filter";

export default {
  components: {
    AboutUs,
    AcknowledgmentAndUnderstanding,
    BarChart,
    BrokersDisclaimer,
    Confidentiality,
    Image,
    MainHeading,
    ProposedSale,
    Purpose,
    SectionBreak,
    SubHeading,
    Table,
    TextBox,
    VendorsDisclaimer
  },
  props: {
    id: {
      type: Number,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      values: "masterData/imValues"
    }),
    components() {
      return components;
    }
  },
  data() {
    return {
      isSubmitting: [],
      formData: {},
      submitting: false,
      isDisabled: false
    };
  },
  methods: {
    ...mapActions({
      loadIMData: "masterData/loadIMData"
    }),
    handleChange(value, index, name, object) {
      let values = this[object];
      set(values, `${index}.${name}`, value);
      this[object] = values;
    },
    handleBlogChange(index, value) {
      this.formData[index].component = value;
      this.formData[index].data = {};
    },
    handleChangeBlog(index, values) {
      this.formData[index].data = values;
    },
    handleAddBlog(blog, index) {
      this.formData = this.handleInsertArray(this.formData, index + 1, {
        component: "SectionBreak",
        data: {}
      });
    },
    handleInsertArray(arr, index, newItem) {
      return [...arr.slice(0, index), newItem, ...arr.slice(index)];
    },
    async handleRemoveBlog(index) {
      this.isLoadingData = true;
      this.formData = filter(this.formData, (row, key) => index !== key);
      await this.handleSave();
      this.isLoadingData = false;
    },
    async handleSave() {
      let endPoint =  `${process.env.VUE_APP_CONFIG_API_URL}/admin/master-data`
      if(this.$route.name === "im-template" || this.$route.name === "admin_users_show"){
        endPoint += '?isIMTemplates=true'
      }
      try {
        this.$http.post(
            endPoint,
          {
            key: "IM",
            id: this.$props ? this.$props.id : null,
            values: this.formData
          }
        );
        this.$store.commit("masterData/UPDATE_IM_VALUES", this.formData);
      } catch (error) {
        console.log(error, "error");
      }
    }
  },
  async mounted() {
    const id = this.$props ? this.$props : null;
    await this.loadIMData({ isIMTemplates: this.$route.name === "im-template" || this.$route.name === "admin_users_show",id});
    this.formData = cloneDeep(this.values);
  }
};
</script>
