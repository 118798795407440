<template>
  <p v-if="amount">
    <strong class="font-black">{{ amountString }}</strong
    >&nbsp;
    <i class="fa fa-arrow-up font-green" v-if="amount > listingAmount"></i
    ><i v-if="amount === listingAmount" class="fa fa-minus font-green"></i
    ><i v-if="amount < listingAmount" class="fa fa-arrow-down font-red"></i>
  </p>
</template>
<script>
export default {
  name: "Amount",
  props: {
    amount: Number,
    listingAmount: Number,
    amountString: String
  }
};
</script>
