<template>
  <div class="row">
    <div class="col-md-12">
      <hr />
    </div>
    <div class="col-md-6">
      <p class="listing-label">Created</p>
      <span v-if="item.created !== ''"
        ><Timestamp :value="created" />&nbsp;by&nbsp;{{ item.created }}&nbsp;({{
          item.created_business
        }})</span
      >
      <span v-if="item.created === ''"
        ><Timestamp :value="created" />&nbsp;by System (The Rent Roll
        Broker)</span
      >
      <br />
    </div>
    <div class="col-md-6">
      <p class="listing-label">Last Modified</p>
      <span v-if="item.updated !== ''"
        ><Timestamp
          :value="item.update_time ? item.update_time : updated"
        />&nbsp;by&nbsp;{{ item.updated }}&nbsp;({{
          item.updated_business
        }})</span
      >
      <span v-if="item.updated === ''">Never Modified</span>
      <br />
    </div>
  </div>
</template>

<script>
import Timestamp from "@/components/Timestamp";

export default {
  components: {
    Timestamp
  },
  props: {
    created: String,
    updated: String,
    model: String,
    modelId: String
  },
  data() {
    return {
      item: {}
    };
  },
  methods: {
    async handleLoadActivity() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_ACTIVITY_API_URL}/admin/get-modify-changes`,
          {
            params: {
              model: this.model,
              modelId: this.modelId
            }
          }
        );
        this.item = data;
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.handleLoadActivity();
  }
};
</script>

<style></style>
