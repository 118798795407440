<template>
  <div id="main-container">
    <div v-if="!unAuthorized">
    <div v-if="!showSuccess">
      <div v-if="template" v-html="template"/>
    </div>
    <div v-else>
      <section id="document-body" class="main-container ">
        <div id="main-document-container" class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="" v-if="hasWitnessSignature">
                <h2><i class="fa fa-check font-green"></i>&nbsp;&nbsp;Witness Sign Request Sent</h2>
                <p><br/><strong class="font-120P font-line-height-220P">What to do next</strong><br/></p>
                <p></p>
                <ol class="font-line-height-220P" type="1">
                  <li>Contact your witness and ask them to check their Inbox or Junk Mail folder for an email titled
                    'TRRB - Your signature is required as a witness' and click on the enclosed link to sign
                  </li>
                  <li>Once your witness has signed, check your Inbox or Junk Mail folder for an email titled 'The Rent
                    Roll Broker Expression of Interest Confirmation for {{ this.template?.data?.listing_reference }}'.
                  </li>
                  <li>Someone will be in touch within the next 48 business hours.</li>
                </ol>
                <p></p>
                <p class="font-line-height-220P font-purple"><br/><strong>The Rent Roll Broker</strong></p>
              </div>
              <div class="" v-else>
                <h2><i class="fa fa-check font-green"></i>&nbsp;&nbsp;Sign Request Complete</h2>
                <p><br/><strong class="font-120P font-line-height-220P">What to do next</strong><br/></p>
                <p></p>
                <ol class="font-line-height-220P" type="1">
                  <li><a :href="loginUrl"><strong>Click here to log into the Rent Roll Broker Customer Portal</strong></a> to gain access the listing's Information Memorandum you have enquired against</li>
                  <li>If you have not accessed the Rent Roll Broker Customer Portal before, click on the 'Forgot/Reset Password' button within the log in screen to set your new password</li>
                  <li>You will find a link to the Information Memorandum under the 'Interests' tab</li>
                </ol>
                <p></p>
                <p class="font-line-height-220P font-purple"><br/><strong>The Rent Roll Broker</strong></p>
              </div>
            </div><!-- Col end -->
          </div><!-- Content row end -->
        </div><!-- Document Container end -->
      </section><!-- Document body end print -->
    </div>
      </div>
    <div v-else>
      <section id="document-body" class="main-container ">
        <div id="main-document-container" class="container">
          <div class="row">
            <div class="col-md-12">
              <h1><i class="fa fa-warning font-red"></i>&nbsp;&nbsp;You can't access this resource</h1>
              <p><br/><strong class="font-120P font-line-height-220P">What to do next</strong><br/></p>
              <p></p>
              <ol class="font-line-height-220P" type="1">
                <li><a :href="loginUrl"><strong>Click here to log into the Rent Roll Broker</strong></a> to gain access the listing's Confidentiality Agreement</li>
              </ol>
              <p></p>
              <p class="font-line-height-220P font-purple"><br/><strong>The Rent Roll Broker</strong></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>


</template>

<script>
import has from 'lodash-es/has'
import { templateCA } from '@/lib/axios'
import errorMixin from '@/mixins/errorMixin'
import { mapGetters } from 'vuex'
import get from "lodash-es/get";

export default {
  mixins: [errorMixin],
  data () {
    return {
      template: null,
      showSuccess: false,
      unAuthorized: false,
      templateData: {
        full_name: '',
        witness_full_name: '',
        witness_email: '',
        confirm_witness_email: '',
      },
      loginUrl: `${process.env.VUE_APP_ADMIN_URL}/login`,
      hasWitnessSignature: false
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
    })
  },
  async mounted () {
    try {
      this.template = (await templateCA.get(`/web/interests/ca/${this.$route.params.customerListingUUID}`,{
        headers: {
          Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
        }
      })).data
    }catch (e) {
      this.showSuccess = false;
      this.unAuthorized = true;
    }

    document.addEventListener('keyup', this.updateValues)
    document.addEventListener('click', this.clicked)
  },
  beforeUnmount () {
    window.removeEventListener('keyup', this.updateValues, true)
    window.removeEventListener('click', this.clicked, true)
  },
  methods: {
    updateValues () {
      if (has(this.templateData, event.target.id)) {
        this.templateData[event.target.id] = event.target.value
      }
    },
    async clicked () {
      //To prevent auto form fillers
      for (let key in this.templateData) {
        const element = document.getElementById(key)
        if (element) {
          this.templateData[key] = element.value
        }
      }

      let isWitnessElementsAvailable = document.getElementById('witness_full_name')
      let keysCount = isWitnessElementsAvailable ?  Object.keys(this.templateData).length : 1
      if (event.target.id === 'submit' && Object.values(this.templateData).filter(n => n).length === keysCount) {
        event.preventDefault()
        let button = document.getElementById('submit')
        if (button.disabled) return
        this.templateData.customer_listing_uuid = this.$route.params.customerListingUUID
        try {
          button.disabled = true
          this.template = (await templateCA.post(`/web/interests/ca`, this.templateData,{
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            }
          } )).data

          const imId = get(this.template, 'data.customer_listing.listing.im.identifier');
          const loginToken = get(this.template, 'login_token');
          this.hasWitnessSignature = get(this.template, 'witness_signature')

          if (this.loggedIn && !this.hasWitnessSignature) {
            window.location.href = `${process.env.VUE_APP_ADMIN_URL}/customer/${imId}/information-memorandums`
          } else if (!this.loggedIn && !this.hasWitnessSignature) {
            window.location.href = `${process.env.VUE_APP_ADMIN_URL}/customer-login?login_token=${loginToken}&im=${imId}`
          }

          this.showSuccess = true
        } catch (e) {
          button.disabled = false

          let errors = this.getServerValidationErrors(e?.response?.data?.errors)
          await this.setErrorsToHtmlElements(errors,Object.keys(this.templateData))

          if (errors.customer_listing_uuid) {
            const errorSpan = document.createElement('span')
            errorSpan.innerHTML = errors.customer_listing_uuid
            errorSpan.className = 'help-block text-danger'
            button.parentNode.insertBefore(errorSpan, button.nextSibling)
            setTimeout(() => {this.showSuccess = true}, 6000)
          }

        }
        button.disabled = false

      }
    }
  }
}
</script>

