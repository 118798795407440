<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="text_line">Text line</label
          ><textarea
            class="form-control form-control-message"
            id="text_line"
            name="text_line"
            placeholder="Text body line"
            rows="3"
            required
            v-model="values.text"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String
  },
  data() {
    return {
      values: {
        text: this.text
      }
    };
  },
  created() {},
  mounted() {},
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("valueChange", this.values);
      }
    }
  }
};
</script>
