<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group text-right">
          <!-- Rounded switch -->
          Is Agent&nbsp;&nbsp;
          <label class="switch">
            <input
              type="checkbox"
              id="show_on_website"
              name="show_on_website"
              :value="values.attributes.is_agent"
              v-model="values.attributes.is_agent"
              :checked="values.attributes.is_agent"
              @change="onAgentCheckChange($event)"
            />
            <span class="slider"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Personal Details</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="first_name">First name *</label>
          <input
            class="form-control"
            id="first_name"
            name="first_name"
            :class="`${errors.hasOwnProperty('first_name') ? 'has-error' : ''}`"
            placeholder="Legal first name"
            type="text"
            v-model="values.first_name"
          />
          <span
            v-if="errors.hasOwnProperty('first_name')"
            class="help-block text-danger"
            >{{ errors.first_name[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="surname">Surname *</label>
          <input
            class="form-control"
            id="surname"
            :class="`${errors.hasOwnProperty('last_name') ? 'has-error' : ''}`"
            name="surname"
            placeholder="Legal last name / surname"
            type="text"
            v-model="values.last_name"
          />
          <span
            v-if="errors.hasOwnProperty('last_name')"
            class="help-block text-danger"
            >{{ errors.last_name[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="email">Business email address *</label>
          <input
            class="form-control"
            id="email"
            :class="`${errors.hasOwnProperty('email') ? 'has-error' : ''}`"
            name="email"
            placeholder="Business email address"
            type="email"
            v-model="values.email"
          />
          <span
            v-if="errors.hasOwnProperty('email')"
            class="help-block text-danger"
            >{{ errors.email[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="email">Best contact number *</label>
          <input
            class="form-control"
            id="contact_phone"
            :class="
              `${errors.hasOwnProperty('contact_phone') ? 'has-error' : ''}`
            "
            name="contact_phone"
            placeholder="Best correspondence phone number"
            type="text"
            v-model="values.contact_phone"
          />
          <span
            v-if="errors.hasOwnProperty('contact_phone')"
            class="help-block text-danger"
            >{{ errors.contact_phone[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="role">Role within business *</label>
          <input
            class="form-control"
            id="role"
            name="role"
            :class="
              `${errors.hasOwnProperty('business_role') ? 'has-error' : ''}`
            "
            placeholder="Role within business"
            type="text"
            v-model="values.business_role"
          />
          <span
            v-if="errors.hasOwnProperty('business_role')"
            class="help-block text-danger"
            >{{ errors.business_role[0] }}</span
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="role">LinkedIn Profile</label>
          <input
            class="form-control"
            id="linkedin"
            name="linkedin"
            :class="
              `${errors.hasOwnProperty('linkedin_profile') ? 'has-error' : ''}`
            "
            placeholder="LinkedIn profile"
            type="text"
            v-model="values.linkedin_profile"
          />
          <span
            v-if="errors.hasOwnProperty('linkedin_profile')"
            class="help-block text-danger"
            >{{ errors.linkedin_profile[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="note">User Note</label>
          <textarea
            class="form-control form-control-message"
            name="note"
            id="note"
            v-model="values.note"
            placeholder="User note"
            rows="2"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Business Details</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="business_identifier">Business *</label>
          <select
            @change="onBusinessChange($event)"
            class="form-control"
            id="business_identifier"
            name="business_identifier"
            v-model="values.business_identifier"
            required
            :class-name="
              `${
                errors.hasOwnProperty('business_identifier') ? 'has-error' : ''
              }`
            "
            :error="
              errors.hasOwnProperty('business_identifier')
                ? errors.business_identifier[0]
                : ''
            "
          >
            <option value="" selected disabled>Select a Business</option>
            <option :key="item.id" v-for="item in businesses" :value="item.id"
            >{{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="linked_address_identifier">Linked Address *</label>
          <select
            class="form-control"
            id="linked_address_identifier"
            name="linked_address_identifier"
            v-model="values.linked_address_identifier"
            required
            :class-name="
              `${
                errors.hasOwnProperty('linked_address_identifier')
                  ? 'has-error'
                  : ''
              }`
            "
            :error="
              errors.hasOwnProperty('linked_address_identifier')
                ? errors.linked_address_identifier[0]
                : ''
            "
          >
            <option value="" selected disabled>Please Select</option>
            <option
              :key="item.id"
              v-for="item in businessAddresses"
              :value="item.id"
              >{{ item.street_address }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row" v-if="!update && isCreatable">
      <div class="col-md-12">
        <div class="form-group">
          <SubmitButton
            :isProgress="submitting"
            caption="Set Permissions"
            @submit="handleSetPermission"
          />
        </div>
      </div>
    </div>
    <div class="row" v-if="update && updateAvailable">
      <div class="col-md-12">
        <div class="form-group">
          <SubmitButton
            :isProgress="submitting"
            :caption="submitting ? 'Updating' : 'Update User'"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/Form/Select";
import SubmitButton from "@/components/SubmitButton";
import get from "lodash-es/get";

export default {
  props: {
    businessData: {
      type: Object,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Object,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: () => false
    },
    submitting: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    Select,
    SubmitButton
  },
  data() {
    return {
      values: {
        ...this.formData,
        business_identifier: this.formData.business?.identifier,
        linked_address_identifier: this.formData.business?.address_identifier
      },
      selectedBusiness: this.formData.business?.identifier
    };
  },
  computed: {
    businesses() {
      return this.businessData
    },
    businessAddresses() {
      return Object.values({ ...this.businessData })?.find(
        element => element.id === this.selectedBusiness
      )?.addresses;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  methods: {
    handleSetPermission() {
      this.$emit("submit", this.values);
    },
    handleSubmit() {
      this.$emit("submit", this.values);
    },
    handleDropdownChange(value, param) {
      this.values[param] = value;
    },
    onBusinessChange(event) {
      if (
        event.target.value === null ||
        event.target.value === undefined ||
        event.target.value === ""
      )
        return;

      this.selectedBusiness = event.target.value;

      if (this.businessAddresses && this.businessAddresses.length === 1) {
        this.values.linked_address_identifier = this.businessAddresses[0].id;
        return;
      }
      if (this.businessAddresses && this.businessAddresses.length > 1) {
        this.values.linked_address_identifier = this.businessAddresses.find(
          element => element.is_primary
        ).id;
        return;
      }
      this.values.linked_address_identifier = "";
    },
    onAgentCheckChange(event) {
      if (this.formData.attributes) {
        this.formData.attributes.is_agent = event.target.checked;
      }

      this.values.attributes.is_agent = event.target.checked;
    }
  }
};
</script>

<style></style>
