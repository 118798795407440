<template>
  <div class="col-md-12">
    <div class="panel-body">
      <div class="tab-content">
        <div id="tab-01" class="tab-pane active">
          <div class="col-md-12">
            <div class="row">
              <Permission
                @update="handleChangePermission"
                :default-permissions="defaultPermissions"
                header="Permissions"
                :create="!update"
                :values="values"
              />
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="process_stage">Opportunity Stage *</label>
                  <select
                    class="form-control"
                    id="process_stage"
                    name="process_stage"
                    v-model="values.process_stage"
                  >
                    <option disabled value="">Please Select</option>
                    <option value="Draft" selected>Draft</option>
                    <option value="Qualification"
                      >Qualification</option
                    >
                    <option value="Value Proposition">Value Proposition</option>
                    <option value="Expression of Interest"
                      >Expression of Interest</option
                    >
                    <option value="Negotiation / Review"
                      >Negotiation / Review</option
                    >
                    <option value="Closed (WON)">Closed (WON)</option>
                    <option value="Closed (LOST)">Closed (LOST)</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-9">
                <div class="form-group">
                  <label for="opportunity_name">Opportunity Name *</label>
                  <input
                    class="form-control"
                    id="opportunity_name"
                    name="opportunity_name"
                    placeholder="Opportunity name"
                    type="text"
                    required
                    v-model="values.opportunity_name"
                    :class="
                      `form-control ${
                        errors.hasOwnProperty('opportunity_name')
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                  <span
                    v-if="errors.hasOwnProperty('opportunity_name')"
                    class="help-block text-danger"
                    >{{ errors.opportunity_name[0] }}</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <Select
                    name="owner"
                    label="Opportunity Owner *"
                    :value="values.owner_id"
                    :class-name="
                      `${errors.hasOwnProperty('owner_id') ? 'has-error' : ''}`
                    "
                    :error="
                      errors.hasOwnProperty('owner_id')
                        ? errors.owner_id[0]
                        : ''
                    "
                    required
                    placeholder="Select a owner"
                    :url="userSearch"
                    :axios="$http"
                    @selected="
                      value =>
                        handleDropdownChange(
                          value ? value.id : null,
                          'owner_id'
                        )
                    "
                    showother
                  />
                </div>
              </div>
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div v-if="values.id" class="col-md-6">
                <label for="closing_date">Listing Reference</label>

                  <p class="font-120P">
                    <strong>{{ values?.listing?.reference }}</strong>
                  </p>
                <br />
              </div>

              <div v-if="!values.id" class="col-md-6">
                <Select
                  name="listing_id"
                  label="Listing Reference"
                  :value="values.listing_id"
                  placeholder="Listing Reference"
                  :url="listingUrl"
                  :axios="$http"
                  identifier="identifier"
                  @selected="value => handleListingSelect(value)"
                />
              </div>
              <div class="col-md-6">
                <Select
                  skip
                  name="customer_id"
                  label="Account"
                  :value="values.customer_id"
                  placeholder="Account"
                  identifier="identifier"
                  :url="accountUrl"
                  :axios="$http"
                  @selected="
                    value => {
                      values.customer_id = value?.identifier;
                    }
                  "
                />
              </div>
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="closing_date">Closing Date *</label>
                  <DatePicker
                    name="closing_date"
                    :model-value="values.closing_date"
                    @update="
                      e => {
                        values.closing_date = e.target.value;
                      }
                    "
                    id="closing_date"
                    :input-class="
                      `form-control ${
                        errors.hasOwnProperty('closing_date') ? 'has-error' : ''
                      }`
                    "
                    placeholder="Opportunity closing date"
                  />
                  <span
                    v-if="errors.hasOwnProperty('closing_date')"
                    class="help-block text-danger"
                    >{{ errors.closing_date[0] }}</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="opportunity_name"
                    >Opportunity Amount ({{
                      selectedListing &&
                      selectedListing.commission_type === "Percentage"
                        ? "%"
                        : "$"
                    }})</label
                  >
                  <input
                    class="form-control"
                    id="opportunity_amount"
                    name="opportunity_amount"
                    placeholder="Opportunity Amount"
                    type="number"
                    required
                    v-model="values.opportunity_amount"
                    :class="
                      `form-control ${
                        errors.hasOwnProperty('opportunity_amount')
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                  <span
                    v-if="errors.hasOwnProperty('opportunity_amount')"
                    class="help-block text-danger"
                    >{{ errors.opportunity_amount[0] }}</span
                  >
                  <p class="font-green font-80P" v-if="selectedListing">
                    <span
                      v-if="selectedListing.commission_type === 'FixedAmount'"
                      >Commission Fixed : ${{
                        selectedListing.commission_amount
                      }}</span
                    >
                    <span
                      v-if="selectedListing.commission_type === 'Percentage'"
                      >Commission Percentage :
                      {{ selectedListing.commission_amount }}%</span
                    >
                    </p>
                    <p class="font-green font-80P" v-if="values.listing">
                    <span
                      v-if="values.listing.commission_type === 'FixedAmount'"
                      >Commission Fixed : ${{
                        values.listing.commission_amount
                      }}</span
                    >
                    <span
                      v-if="values.listing.commission_type === 'Percentage'"
                      >Commission Percentage :
                      {{ values.listing.commission_amount }}%</span
                    >
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="currency">Currency *</label>
                  <select
                    class="form-control"
                    name="currency"
                    id="currency"
                    required
                    v-model="values.currency"
                  >
                    <option value="" disabled>Please Select</option>
                    <option
                      :value="value.name"
                      v-for="(value, index) in currencies"
                      :key="index"
                      >{{ value.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="opportunity_name">Probability (%)</label>
                  <input
                    class="form-control"
                    id="opportunity_probability"
                    name="opportunity_probability"
                    placeholder="Opportunity probability"
                    type="number"
                    required
                    v-model="values.opportunity_probability"
                    :class="
                      `form-control ${
                        errors.hasOwnProperty('opportunity_probability')
                          ? 'has-error'
                          : ''
                      }`
                    "
                  />
                  <span
                    v-if="errors.hasOwnProperty('opportunity_probability')"
                    class="help-block text-danger"
                    >{{ errors.opportunity_probability[0] }}</span
                  >
                </div>
              </div>
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="business_type">Business Type *</label>
                  <select
                    class="form-control"
                    name="business_type"
                    id="business_type"
                    required
                    v-model="values.business_type"
                  >
                    <option value="" disabled>Please Select</option>
                    <option
                      :value="value.name"
                      v-for="(value, index) in businessTypes"
                      :key="index"
                      >{{ value.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lead_source">Lead Source *</label>
                  <select
                    class="form-control"
                    name="lead_source"
                    id="lead_source"
                    required
                    v-model="values.lead_source"
                  >
                    <option value="" disabled>Please Select</option>
                    <option
                      :value="value.name"
                      v-for="(value, index) in leadSources"
                      :key="index"
                      >{{ value.name }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="opportunity_name">Next Step</label>
                  <input
                    class="form-control"
                    id="next_step"
                    name="next_step"
                    placeholder="Next Step"
                    type="text"
                    v-model="values.next_step"
                    :class="
                      `form-control ${
                        errors.hasOwnProperty('next_step') ? 'has-error' : ''
                      }`
                    "
                  />
                  <span
                    v-if="errors.hasOwnProperty('next_step')"
                    class="help-block text-danger"
                    >{{ errors.next_step[0] }}</span
                  >
                </div>
              </div>
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="opportunity_name">Opportunity Description</label>
                  <textarea
                    class="form-control form-control-message"
                    name="description"
                    id="description"
                    placeholder="Opportunity description"
                    rows="3"
                    v-model="values.description"
                    :class="
                      `form-control ${
                        errors.hasOwnProperty('description') ? 'has-error' : ''
                      }`
                    "
                  ></textarea>
                  <span
                    v-if="errors.hasOwnProperty('description')"
                    class="help-block text-danger"
                    >{{ errors.description[0] }}</span
                  >
                </div>
              </div>
            </div>
            <div class="gap-20"></div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="opportunity_name"
                    >How did you uncover this opportunity?</label
                  >
                  <textarea
                    class="form-control form-control-message"
                    name="uncover"
                    id="uncover"
                    placeholder="How did you uncover this opportunity?"
                    rows="3"
                    v-model="values.uncover"
                    :class="
                      `form-control ${
                        errors.hasOwnProperty('uncover') ? 'has-error' : ''
                      }`
                    "
                  ></textarea>
                  <span
                    v-if="errors.hasOwnProperty('uncover')"
                    class="help-block text-danger"
                    >{{ errors.uncover[0] }}</span
                  >
                </div>
              </div>
            </div>
            <div class="gap-40"></div>
            <History @update="handleChange" :offers="values.offers" />
            <div class="gap-40"></div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <SubmitButton v-if="isCreatable || updateAvailable"
                    :isProgress="isLoading"
                    :caption="buttonName"
                    @submit="handleSubmit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Permission from "../../components/Permission";
import History from "./components/History";
import Select from "@/components/Form/Select";
import DatePicker from "@/components/Form/DatePicker";
import SubmitButton from "@/components/SubmitButton";
import map from "lodash-es/map";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  props: {
    formData: {
      type: Object,
      default: () => ({
        process_stage: "",
        opportunity_name: "",
        closing_date: ""
      })
    },
    isLoading: {
      type: Boolean,
      default: () => false
    },
    errors: {
      type: Object,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: () => false
    },
    submitting: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    Permission,
    Select,
    SubmitButton,
    DatePicker,
    History
  },
  data() {
    return {
      values: {
        ...this.formData,
        offer_history: this.formData.offer_history
          ? this.formData.offer_history
          : {
              offer_amount: 0,
              notes: ""
            },
        listing_id:
          this.formData && this.formData.listing
            ? this.formData.listing.identifier
            : null,
        customer_id:
          this.formData && this.formData.customer
            ? this.formData.customer.identifier
            : null
      },
      selectedListing: null
    };
  },
  methods: {
    ...mapActions({
      loadDropDownListData: "masterData/loadDropDownListData"
    }),
    handleDropdownChange(value, param) {
      this.values[param] = value;
    },
    handleChangePermission(values) {
      this.values.permissions = values;
    },
    handleSubmit() {
      this.$emit("submit", {
        ...this.values,
        closing_date: this.values.closing_date
          ? moment(this.values.closing_date).format("YYYY-MM-DD")
          : null,
        permissions: this.values.permissions
          ? this.values.permissions
          : {
              admin_access: {
                admin_approved: false
              }
            }
      });
    },
    handleChange(name, value) {
      this.values.offer_history[name] = value;
    },
    handleListingSelect(value) {
      this.selectedListing = value;
      if (value && this.values.listing_id === value.id) {
        return;
      }
      this.values.listing_id = value ? value.id : null;
      if (value && value.id) {
        this.values.opportunity_amount = value.price;
        this.values.currency = value.currency;
      }
    }
  },
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions",
      currencies: "masterData/currencies",
      businessTypes: "masterData/businessTypes",
      leadSources: "masterData/leadSources"
    }),
    userSearch() {
      return `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/select`;
    },
    listingUrl() {
      return `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings/search`;
    },
    accountUrl() {
      return `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/search`;
    },
    buttonName() {
      if (this.update) {
        if (this.isLoading) {
          return "Updating";
        } else {
          return "Update Opportunity";
        }
      } else {
        if (this.isLoading) {
          return "Creating";
        } else {
          return "Create Opportunity";
        }
      }
    },
    defaultPermissions() {
      let permissions = [];
      map(this.permissionMeta.module_permission.listing.permissions, group => {
        map(group.permissions, permission => {
          permissions.push({
            group: group.group,
            name: permission.caption,
            permission: permission.title,
            value: permission.name
          });
        });
      });
      return permissions;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  mounted() {
    this.loadDropDownListData();
  }
};
</script>

<style></style>
