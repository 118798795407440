<template>
  <div class="row">
    <div class="col-md-10" v-if="active === current">
      <div class="form-group">
        <input
          class="form-control"
          name="activity"
          id="activity"
          placeholder="Activity description"
          :value="value"
          v-on:input="event => $emit('update', event)"
          v-on:blur="event => $emit('update', event)"
        />
      </div>
    </div>
    <div class="col-md-10" v-else>
      <p>
        <span class="listing-label"
          >ACTIVITY {{ current + 1 }}:&nbsp;&nbsp;</span
        ><strong>{{ text }}</strong>
      </p>
    </div>
    <div class="col-md-2">
      <div class="row">
        <div class="col-md-6 text-right">
          <button
            class="btn btn-xxsmall"
            type="button"
            v-if="active === current"
            title="Save Event"
            @click="$emit('save')"
          >
            <i class="fa fa-save"></i>
          </button>
          <button
            class="btn btn-xxsmall"
            type="button"
            v-else
            @click="$emit('active')"
            title="Edit Activity"
          >
            <i class="fa fa-edit"></i>
          </button>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-xxsmall ml-2"
            type="button"
            title="Delete Activity"
            @click="$emit('delete')"
          >
            DELETE
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: Number,
    current: Number,
    value: String,
    text: String
  }
};
</script>
