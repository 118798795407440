<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="data_table"
            ><input
              type="checkbox"
              class=""
              name="data_table"
              id="data_table"
              v-model="values.data_table"
            />&nbsp;&nbsp; Check this box if it's a data table</label
          >
        </div>
      </div>
      <div class="col-md-12" v-if="!values.data_table">
        <div class="form-group">
          <label for="first_row_header_boolean"
            ><input
              type="checkbox"
              class=""
              name="first_row_header_boolean"
              id="first_row_header_boolean"
              v-model="values.header"
            />&nbsp;&nbsp; Check this box if first row is a heading</label
          >
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-12">
        <button
          type="button"
          class="btn btn-small btn-primary font-80P"
          @click="handleAddRow"
        >
          Add Row
        </button>
        <button
          type="button"
          class="btn btn-small btn-primary ml-4 font-80P"
          @click="handleAddColumn"
        >
          Add Column
        </button>
      </div>
      <div class="gap-20"></div>
      <div class="col-md-12">
        <table class="table table-bordered table-responsive">
          <tbody>
            <tr v-if="getLastColumn.length > 1">
              <td
                :key="key"
                v-for="(column, key) in getLastColumn"
                class="text-center"
              >
                <button
                  title="Remove Row"
                  class="btn btn-xsmall btn-danger"
                  type="button"
                  @click="handleRemoveColumn(key)"
                >
                  <span class="font-120P"
                    ><i class="fa fa-minus-circle"></i
                  ></span>
                </button>
              </td>
            </tr>
            <tr :key="index" v-for="(row, index) in values.rows">
              <td :key="`${index}_${key}`" v-for="(column, key) in row.columns">
                <div class="form-group">
                  <input
                    class="form-control"
                    :name="`column-data_${index}_${key}`"
                    placeholder="Column Data"
                    type="text"
                    :value="column"
                    @change="e => handleChangeData(e, index, key)"
                  />
                </div>
              </td>
              <td class="vertical-middle" v-if="values.rows.length > 1">
                <button
                  title="Remove Column"
                  class="btn btn-xsmall btn-danger"
                  type="button"
                  @click="handleRemoveRow(index)"
                >
                  <span class="font-120P"
                    ><i class="fa fa-minus-circle"></i
                  ></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="gap-20"></div>
      <div class="col-md-12">
        <button
          type="button"
          class="btn btn-small btn-primary font-80P"
          @click="handleAddRow"
        >
          Add Row
        </button>
        <button
          type="button"
          class="btn btn-small btn-primary ml-4 font-80P"
          @click="handleAddColumn"
        >
          Add Column
        </button>
      </div>
      <div class="gap-20"></div>
    </div>
  </div>
</template>

<script>
import filter from "lodash-es/filter";
import last from "lodash-es/last";
export default {
  props: {
    data_table: Boolean,
    header: Boolean,
    columns: Array,
    rows: Array,
    data: Object
  },
  data() {
    return {
      values: {
        data_table: !!this.data_table,
        header: !!this.header,
        columns: this.columns ? this.columns : [],
        rows: this.rows ? this.rows : [{ columns: ["", ""] }],
        data: this.data ? this.data : {}
      }
    };
  },
  computed: {
    getLastColumn() {
      const lastData = last(this.values.rows);
      return lastData ? lastData.columns : [];
    }
  },
  methods: {
    handleAddRow() {
      this.values.rows.push({
        columns: this.getLastColumn.map(() => "")
      });
    },
    handleAddColumn() {
      this.values.rows = this.values.rows.map(row => {
        row.columns.push("");
        return row;
      });
    },
    handleRemoveColumn(index) {
      this.values.rows = this.values.rows.map(row => {
        row.columns = filter(row.columns, (column, key) => index !== key);
        return row;
      });
    },
    handleRemoveRow(index) {
      this.values.rows = filter(this.values.rows, (row, key) => index !== key);
    },
    handleChangeData(e, row, column) {
      this.values.rows[row].columns[column] = e.target.value;
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          return;
        }
        this.$emit("valueChange", this.values);
      }
    }
  }
};
</script>
