<template>
  <div class="alert  fade in" :class="type">
    <a
      v-if="showCloseIcon"
      href="#"
      class="close"
      data-dismiss="alert"
      aria-label="close"
      title="close"
      >×
    </a>
    <span class="icon"><i class="fa fa-check"></i></span
    ><strong v-if="mainMessage">{{ mainMessage }}</strong
    ><br v-if="mainMessage" />
    {{ message }}
  </div>
</template>
<script>
export default {
  props: {
    type: String,
    message: String,
    mainMessage: String,
    showCloseIcon :{type:Boolean,default:true}
  }
};
</script>
