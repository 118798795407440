<template>
  <section id="main-container" class="main-container">
    <div class="text-center"><div class="loading"></div></div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  methods: {
    ...mapActions({
      logout: "auth/logOut"
    }),
    async handleLogout() {
      await this.logout({ values: { id: this.user.id } });
      await this.$router.push({ name: "login" });
    }
  },
  mounted() {
    this.handleLogout();
  }
};
</script>

<style></style>
