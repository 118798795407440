<template>
  <div class="col-md-12">
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row">
          <p class="font-120P">Opportunities</p>
        </div>
      </div>
    </div>

    <div class="entry-content">
      <div class="gap-20"></div>
      <ResourceTable
        v-if="customerId"
        :columns="interestColumns"
        :url="url"
        remove-actions
        :extra-params="extraParams"
      />
    </div>
  </div>
</template>
<script>
import ResourceTable from "../../../components/ResourceTable";
export default {
  props: {
    customerId: String,
    queryParams: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    ResourceTable
  },
  computed: {
    extraParams() {
      return {
        customer_id: this.customerId,
        ...this.queryParams
      };
    },
    url() {
      return `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/listings`;
    },
    interestColumns() {
      return [
        {
          name: "date",
          text: "Date Enquired",
          timestamp: item => item.created
        },
        {
          name: "reference",
          text: "Reference",
          render: item => item.reference,
          link: item =>
            `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings/${item.listing?.identifier}`,
          sort_disable: true
        },
        {
          name: "region",
          text: "Region",
          render: item => item.region,
          link: item =>
            `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings/${item.listing?.identifier}`,
          sort_disable: true
        },
        {
          name: "ca",
          text: "CA",
          render: item =>
            item.interest_status === "Completed"
              ? "Download"
              : item.is_witness_sign_pending ? 'Witness sign pending' : item.interest_status,
          download: true,
          downloadable: item => item.interest_status === "Completed" && (!item.is_witness_sign_pending),
          className: item =>
              (item.interest_status === "Completed" && !item.is_witness_sign_pending)
              ? "cursor-pointer font-link"
              : "",
          downloadUrl: item =>
            `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.id}/download-ca`,
          sort_disable: true
        },
        {
          name: "eoi",
          text: "EOI",
          render: item =>
            item.expression_status === null ? "None" : item.expression_status,
          // link: item =>
          //   item.expression_status === null
          //     ? ""
          //     : item.expression_status === "Submitted"
          //     ? `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.id}/download-eoi`
          //     : `${process.env.VUE_APP_ADMIN_URL}/customer/${item.interest_uuid}/information-memorandums?tab=eoi`,
          download: true,
          downloadable: item => item.expression_status === "Submitted",
          className: item =>
            item.expression_status === "Submitted"
              ? "cursor-pointer font-link"
              : "",
          sort_disable: true,
          downloadUrl: item =>
              `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-eoi`,
        },
        {
          name: "opportunity",
          text: "Opportunity",
          render: item => "Link",
          link: item =>
            item.interest_uuid
              ? `${process.env.VUE_APP_ADMIN_URL}/opportunities/${item.interest_uuid}`
              : null,
          sort_disable: true
        },
        {
          name: "status",
          text: "Status",
          render: item => item.status,
          sort_disable: true,
          className: item =>
            item.status === "For Sale" ? "font-green" : "font-orange"
        }
      ];
    }
  }
};
</script>
