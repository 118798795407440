<template>
  <section id="main-container" class="main-container">
    <div class="container">
      <div class="container">
        <form action="" method="post" role="form" @submit="handleFormSubmit">
          <div class="error-container"></div>
          <div class="row">
            <div class="col-md-3">
              <div>&nbsp;</div>
            </div>
            <div class="col-md-6 listing-container">
              <div class="row p-15">
                <h2 class="font-purple">Password Reset</h2>
                <div
                  id="alert-password-reset-alert"
                  class="alert alert-success fade in"
                  v-if="forgotSuccess"
                >
                  <a
                    href="#"
                    class="close"
                    data-dismiss="alert"
                    aria-label="close"
                    title="close"
                    >×</a
                  >
                  <span class="icon"><i class="fa fa-check"></i></span
                  ><strong>Success!</strong><br />
                  A password reset request has been sent to you inbox. Please
                  check and follow the prompts to reset your password.
                </div>
                <div
                  id="alert-incorrect-credentials"
                  class="alert alert-danger fade in"
                  v-if="error"
                >
                  <a
                    href="#"
                    class="close"
                    data-dismiss="alert"
                    aria-label="close"
                    title="close"
                    >×</a
                  >
                  <span class="icon"><i class="fa fa-exclamation"></i></span
                  ><strong>Unsuccessful Password Reset!</strong><br />The email
                  address entered is not associated with an active account with
                  the Rent Roll Broker.
                </div>
              </div>
              <div class="row p-15">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="email">Email Address *</label>
                    <input
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="Your email address"
                      type="email"
                      v-model="formData.email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row p-15">
                <div class="col-md-12">
                  <div class="form-group">
                    <p class="pull-left">
                      <button
                        :disabled="isLoading"
                        class="btn btn-primary"
                        type="submit"
                        value="Submit"
                      >
                        <div
                          class="loading-btn"
                          :class="{ 'loading-btn--loading': isLoading }"
                        >
                          Request Reset
                          <span v-if="isLoading" class="loading-btn--loading">
                            <b></b>
                            <b></b>
                            <b></b>
                          </span>
                        </div>
                      </button>
                    </p>
                    <p class="pull-right">
                      <router-link
                        class="btn btn-primary"
                        :to="{
                          name: 'login'
                        }"
                      >
                        Login</router-link
                      >&nbsp;&nbsp;
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div>&nbsp;</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import get from "lodash-es/get";

export default {
  data() {
    return {
      formData: {
        email: ""
      },
      success: false,
      errors: {},
      error: null,
      isLoading: false,
      forgotSuccess: false
    };
  },
  methods: {
    ...mapActions({
      resetPassword: "auth/forgotPassword"
    }),
    async handleFormSubmit($event) {
      this.isLoading = true;
      this.errors = {};
      $event.preventDefault();
      try {
        const data = await this.resetPassword(this.formData);
        this.isLoading = false;
        if (!data) {
          this.error = "Token Mismatch!";
        } else {
          // await this.$router.push({ name: "login", query: { reset: "true" } });
          this.forgotSuccess = true;
        }
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.formData.token = this.$route.query.token;
    this.formData.email = this.$route.query.email;
  }
};
</script>

<style></style>
