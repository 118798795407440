<template>
  <div class="col-md-12">
    <div class="entry-content">
      <div class="row csvform">
        <div class="gap-40"></div>

        <div class="col-md-12">
          <div class="entry-header-admin">
            <div class="entry-title-admin text-left pull-left">
              <p class="font-120P">{{ title }}</p>
            </div>
          </div>
        </div>

        <div class="gap-20"></div>

        <div class="col-md-3">
          <div class="form-group">
            <label for="accont_owner">Account Owner</label>
            <select
              class="form-control"
              name="accont_owner"
              id="accont_owner"
              v-model="values.account_owner"
            >
              <option value="" selected>All</option>
              <option v-for="owner in owners" :key="owner.id" :value="owner.id">
                {{ owner.last_name }} {{ owner.first_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="account_stage">Account Stage</label>
            <select
              class="form-control"
              name="account_stage"
              id="account_stage"
              v-model="values.account_stage"
            >
              <option value="" selected>All</option>
              <option v-for="stage in stages" :key="stage.id" :value="stage.id">
                {{ stage.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="contacts">Contacts</label>
            <select
              class="form-control"
              name="contact"
              id="contact"
              v-model="values.contact"
            >
              <option selected value="Primary Contact Only">
                Primary Contact Only
              </option>
              <option value="All Contacts">All Contacts</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="state">State</label>
            <select
              class="form-control"
              id="state"
              name="state"
              v-model="values.state"
            >
              <option value="" selected>All</option>
              <option v-for="state in states" :key="state.id" :value="state.id">
                {{ state.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="account_category">Category</label>
            <select
              class="form-control"
              name="account_category"
              id="account_category"
              v-model="values.account_category"
            >
              <option value="" selected>All</option>
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="registered_as">Registered as an</label>
            <select
              class="form-control"
              name="registered_as"
              id="registered_as"
              v-model="values.registered_as"
            >
              <option value="" selected>All</option>
              <option
                v-for="company in companies"
                :key="company.id"
                :value="company.id"
              >
                {{ company.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="receive_newsletter">Receives Newsletter</label>
            <select
              class="form-control"
              id="receive_newsletter"
              name="receive_newsletter"
              v-model="values.receive_newsletter"
            >
              <option value="" selected>All</option>
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="lead_source">Lead Source</label>
            <select
              class="form-control"
              name="lead_source"
              id="lead_source"
              v-model="values.lead_source"
            >
              <option value="" selected>All</option>
              <option
                :value="value.name"
                v-for="(value, index) in leadSources"
                :key="index"
              >
                {{ value.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="registered_buyer">Buyer </label>
            <select
              class="form-control"
              id="registered_buyer"
              name="registered_buyer"
              v-model="values.registered_buyer"
            >
              <option value="" selected>All</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="registered_seller">Seller </label>
            <select
              class="form-control"
              id="registered_seller"
              name="registered_seller"
              v-model="values.registered_seller"
            >
              <option value="" selected>All</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="postcodes">Postcodes</label>
            <textarea
              class="form-control form-control-message"
              name="postcodes"
              id="postcodes"
              placeholder="Enter each postcode followed by a comma eg 3975, 3174"
              rows="2"
              v-model="values.postcodes"
            ></textarea>
          </div>
        </div>

        <div class="gap-40"></div>
        <div class="col-md-12">
          <div class="form-group">
            <button
              :disabled="isProgress"
              class="btn btn-primary"
              type="button"
              @click="handleSubmit"
            >
              Generate CSV
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    title: String
  },
  data() {
    return {
      owners: null,
      states: null,
      stages: null,
      categories: null,
      companies: null,
      isLoading: false,
      values: {
        account_owner: "",
        account_stage: "",
        contact: "Primary Contact Only",
        state: "",
        account_category: "",
        registered_as: "",
        receive_newsletter: "",
        lead_source: "",
        registered_buyer: "",
        registered_seller: "",
        postcodes: "",
        component: "account"
      }
    };
  },
  methods: {
    ...mapActions({
      loadDropDownListData: "masterData/loadDropDownListData"
    }),
    async handleSubmit() {
      try {
        const data = await this.$http.post(
          `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/export`,
          this.values
        );
        const blob = new Blob([data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "accounts.csv";
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        console.log(e);
      }
    },
    async loadUsers() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users`
        );
        this.owners = data;
      } catch (e) {
        console.log(e);
      }
    },
    async loadStates() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states`
        );
        this.states = data;
      } catch (e) {
        console.log(e);
      }
    },
    async loadStages() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_CONFIG_API_URL}/accountstages`
        );
        this.stages = data;
      } catch (e) {
        console.log(e);
      }
    },
    async loadCategories() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_CONFIG_API_URL}/accountcategories`
        );
        this.categories = data;
      } catch (e) {
        console.log(e);
      }
    },
    async loadCompanies() {
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_CONFIG_API_URL}/accountcompanies`
        );
        this.companies = data;
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions",
      currencies: "masterData/currencies",
      businessTypes: "masterData/businessTypes",
      leadSources: "masterData/leadSources"
    })
  },
  async mounted() {
    this.loadDropDownListData();
    await this.loadUsers();
    await this.loadStates();
    await this.loadStages();
    await this.loadCategories();
    await this.loadCompanies();
  }
};
</script>

<style scoped>
.csvform {
  background-color: #ffffff;
}
</style>
