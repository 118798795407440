<template>
  <div class="entry-content">
    <!--Tabs start-->
    <div class="col-md-12">
      <div class="row">
        <!--tabs border start-->
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              title="View"
              @click="activeTap = 'detail'"
              :class="{ active: activeTap === 'detail' }"
            >
              <!-- View User -->
              <a id="view_user_tab_link" data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title"><i class="fa fa-eye"></i></h4
              ></a>
            </li>
            <li
              id="tab-label-02"
              title="Edit"
              v-if="updateAvailable"
              @click="activeTap = 'update'"
              :class="{ active: activeTap === 'update' }"
            >
              <!-- Update User -->
              <a id="update_user_tab_link" data-toggle="tab" href="#tab-02"
                ><h4 class="panel-title"><i class="fa fa-edit"></i></h4
              ></a>
            </li>
            <li
              id="tab-label-03"
              v-if="updateAvailable"
              @click="activeTap = 'updateprofile'"
              :class="{ active: activeTap === 'updateprofile' }"
            >
              <a
                id="update_user_profile_tab_link"
                data-toggle="tab"
                href="#tab-03"
                ><h4 class="panel-title">Website Profile</h4></a
              >
            </li>
            <li
              id="tab-label-04"
              title="Permissions"
              v-if="updateAvailable"
              @click="activeTap = 'permission'"
              :class="{ active: activeTap === 'permission' }"
            >
              <!-- Permission -->
              <a id="permissions_tab_link" data-toggle="tab" href="#tab-04"
                ><h4 class="panel-title">
                  <i class="fa fa-lock font-120P"></i></h4
              ></a>
            </li>
            <li
              id="tab-label-05"
              title="Tasks"
              @click="activeTap = 'task'"
              :class="{ active: activeTap === 'task' }"
            >
              <!--Recent Activity -->
              <a id="task_tab_link" data-toggle="tab" href="#tab-05"
                ><h4 class="panel-title">
                  <i class="fa fa-calendar-check-o"></i>&nbsp;
                </h4></a
              >
            </li>
            <li
              id="tab-label-06"
              title="Notes"
              @click="
                activeTap = 'note';
                loadNotes();
              "
              :class="{ active: activeTap === 'note' }"
            >
              <!--Recent Activity -->
              <a id="note_tab_link" data-toggle="tab" href="#tab-06"
                ><h4 class="panel-title"><i class="fa fa-sticky-note"></i></h4
              ></a>
            </li>
            <li
              id="tab-label-09"
              v-if="updateAvailable"
              @click="activeTap = 'imTemplate'"
              :class="{ active: activeTap === 'imTemplate' }"
            >
              <a
                id="update_user_profile_tab_link"
                data-toggle="tab"
                href="#tab-09"
                ><h4 class="panel-title">IM Template</h4></a
              >
            </li>
            <li
              id="tab-label-07"
              title="Activity Log"
              @click="activeTap = 'activity'"
              :class="{ active: activeTap === 'activity' }"
            >
              <!--Recent Activity -->
              <a id="recent_activity_tab_link" data-toggle="tab" href="#tab-07"
                ><h4 class="panel-title"><i class="fa fa-list"></i></h4
              ></a>
            </li> <!--End activity-->
            <li
                id="tab-label-08"
                title="2FA"
                v-show="authuser.id == this.$route.params.id"
                @click="activeTap = '2fa'"
                :class="{ active: activeTap === '2fa' }"
            >
              <!--Recent Activity -->
              <a id="2fa" data-toggle="tab" href="#tab-08"
              ><h4 class="panel-title"><i class="fa fa-shield"></i></h4
              ></a>
            </li> <!--End 2fa-->
          </ul>
          <div class="panel-body">
            <div class="tab-content">
              <div
                id="tab-01"
                class="tab-pane"
                v-if="activeTap === 'detail'"
                :class="{ active: activeTap === 'detail' }"
              >
                <!-- User details start -->
                <div class="col-md-12">
                  <Details
                    v-if="!isLoading && user"
                    :user="user"
                    :updated="updated"
                    :permission-update="permissionUpdate"
                    :profile-update="profileUpdate"
                  />
                  <div v-if="isLoading" class="text-center">
                    <div class="loading"></div>
                  </div>
                </div>
                <!-- User details end -->
              </div>
              <div
                id="tab-02"
                class="tab-pane"
                v-if="activeTap === 'update'"
                :class="{ active: activeTap === 'update' }"
              >
                <div class="col-md-12">
                  <form id="form_1" action="" v-if="user">
                    <Form
                      @submit="handleSubmit"
                      :business-data="businessData"
                      :form-data="user"
                      :errors="errors"
                      :submitting="isSubmitting"
                      update
                      :key="user.id"
                    />
                  </form>
                </div>
              </div>
              <div
                id="tab-03"
                class="tab-pane"
                v-if="activeTap === 'updateprofile'"
                :class="{ active: activeTap === 'updateprofile' }"
              >
                <div class="col-md-12">
                  <form id="form_1" action="" v-if="user">
                    Website Profile
                    <ProfileForm
                      @submit="handleProfileSubmit"
                      :form-data="user"
                      :errors="errors"
                      :submitting="isSubmittingProfile"
                      :key="user.profile_id"
                    />
                  </form>
                </div>
              </div>
              <div
                id="tab-04"
                class="tab-pane"
                v-if="activeTap === 'permission'"
                :class="{ active: activeTap === 'permission' }"
              >
                <!-- User details start -->
                <div class="col-md-12">
                  <div class="entry-content">
                    <form id="form_2" action="">
                      <Permission
                          ref="refPermissions"
                        v-if="user"
                        :permission-meta="permissions"
                        :form-data="user"
                        :submitting="isSubmitting"
                        update
                        @submit="handlePermissionSubmit"
                        @updateStatus="updateStatus"
                        @delegateTo="delegateTo"
                        archiveLabel="ADMIN USER ACTIVE STATUS"
                      />
                    </form>
                  </div>
                </div>
                <!-- User details end -->
              </div>
              <div
                id="tab-05"
                class="tab-pane"
                v-if="activeTap === 'task'"
                :class="{ active: activeTap === 'task' }"
              >
                <div class="col-md-12">
                  <Task :query-params="queryParams"></Task>
                </div>
              </div>
              <div
                id="tab-06"
                class="tab-pane"
                v-if="activeTap === 'note'"
                :class="{ active: activeTap === 'note' }"
              >
                <div class="col-md-12">
                  <Note related-type="User" :related-id="user.id" />
                </div>
              </div>
              <div
                id="tab-07"
                class="tab-pane"
                v-if="activeTap === 'activity'"
                :class="{ active: activeTap === 'activity' }"
              >
                <!-- Activity details start -->
                <div class="col-md-12">
                  <ActivityLog
                    v-if="user"
                    subject="user"
                    :subject-id="user.id"
                    :item="user"
                    user-available
                    :logUrl="logUrl"
                  />
                </div>
                <!-- Activity details end -->
              </div>
              <div
                  id="tab-08"
                  class="tab-pane"
                  v-if="activeTap === '2fa'"
                  :class="{ active: activeTap === '2fa' }"
              >
                <div class="col-md-12">
                  <TwoFactorAuthentication

                  />
                </div>
                <!-- 2fa end -->
              </div>
              <div
                id="tab-09"
                class="tab-pane"
                v-if="activeTap === 'imTemplate'"
                :class="{ active: activeTap === 'imTemplate' }"
              >
                <div class="col-md-12">
                  <Edits 
                  :id = formData.id_
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <ConfirmationModal ref="refConfirmationModal"
                       message="Are you sure you want to archive this artifact ?"
                       @declined="archiveDeclined"
                       @confirmed="archiveConfirmed"
    />
  </div>
</template>

<script>
import Details from "./Show/Index";
import Form from "./Form";
import ProfileForm from "./ProfileForm";
import Permission from "./Permission";
import ActivityLog from "@/components/ActivityLog";
import Note from "../../components/Note/Items";
import Task from "../../components/Task/Items";
import get from "lodash-es/get";
import { mapGetters, mapActions } from "vuex";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
import ConfirmationModal from "../../components/ConfirmationModal.vue";
import Edits from "../../../src/views/MasterData/InformationMemorandum/index.vue"

export default {
  components: {
    Details,
    Form,
    ProfileForm,
    Permission,
    ActivityLog,
    Note,
    Task,
    TwoFactorAuthentication,
    ConfirmationModal,
    Edits
  },
  data() {
    return {
      user: null,
      isLoading: false,
      isSubmitting: false,
      isSubmittingProfile: false,
      updateAvailable: false,
      activeTap: "detail",
      updated: false,
      permissionUpdate: false,
      profileUpdate: false,
      url: `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users`,
      logUrl: `${process.env.VUE_APP_ACTIVITY_API_URL}/admin/userlogs`,
      businessesUrl: `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses`,
      businessData: {},
      formData: {},
      errors: {},
      userNotes: {},
      userTasks: {},
      isNotesLoaded: false
    };
  },
  computed: {
    ...mapGetters({
      permissions: "app/permissions",
      authuser: "auth/user"
    }),
    queryParams() {
      return {
        user_id: this.user?.id
      };
    }
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async loadUser() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(
          `/api/admin/users/${this.$route.params.id}`
        );
        this.user = data;
        this.formData = data;
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isLoading = false;
      }
    },
    async loadBusinesses() {
      try {
        const { data } = await this.$http.get(`${this.businessesUrl}/getwithaddresses`);
        this.businessData = data;
      } catch (e) {
        //this.errors = get(e, "data.errors");
      }
    },
    async handleSubmit(values, permission) {
      this.isSubmitting = true;
      try {
        let formData = {
          ...this.formData
        };
        if (permission) {
          formData = {
            ...formData,
            permissions: values,
            business_id: formData.business ? formData.business.id : null,
            business_identifier: formData.business
              ? formData.business.identifier
              : null,
            linked_address_identifier: formData.linked_address_identifier
              ? formData.linked_address_identifier
              : formData.business_address
              ? formData.business_address.identifier
              : null
          };
        } else {
          formData = {
            ...formData,
            ...values,
            business_id: values.business ? values.business.id : null,
            business_identifier:
              values && values.business_identifier
                ? values.business_identifier
                : formData.business
                ? formData.business.identifier
                : null,
            linked_address_identifier: values.linked_address_identifier
              ? values.linked_address_identifier
              : formData.business_address
              ? formData.business_address.identifier
              : null
          };
        }
        const { data } = await this.$http.patch(
          `/api/admin/users/${this.$route.params.id}`,
          formData
        );
        await this.loadUser();
        await this.loadStats();
        if (permission) {
          this.permissionUpdate = true;
        } else {
          this.updated = true;
        }
        this.activeTap = "detail";
        window.scrollTo(0,0);
        this.isSubmitting = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isSubmitting = false;
      }
    },
    handlePermissionSubmit(values) {
      this.handleSubmit(values, true);
    },
    async handleProfileSubmit(values) {
      this.isSubmittingProfile = true;
      try {
        let formData = {
          ...values.profile,
          "order_number": values.order_number
        };
        const { data } = await this.$http.patch(
          `/api/admin/users/${this.$route.params.id}/profile`,
          formData
        );

        this.activeTap = "detail";
        this.isSubmittingProfile = false;
        this.profileUpdate = true;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.isSubmittingProfile = false;
      }
    },
    async handleNodeAdd(values) {
      let formData = {
        ...values,
        artifact_identifier: this.user.id,
        artifact: "User"
      };
      // try {
      this.isLoading = true;
      const { data } = await this.$http.post(
        `${process.env.VUE_APP_TASKS_API_URL}/admin/notes/add`,
        formData
      );

      this.isLoading = false;
      //} catch (e) {
      //   this.errors = get(e, "data.errors");
      //  this.isLoading = false;
      // this.activeTap = "news";
      //}
    },
    async loadNotes() {
      if (!this.isNotesLoaded) {
        //  try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_TASKS_API_URL}/admin/notes/${this.user.id}`
        );
        this.userNotes = data;

        this.isNotesLoaded = true;
        //} catch (e) {
        //   this.errors = get(e, "data.errors");
        // }
      }
      console.log("Switched to note tab");
    },
    archiveDeclined (currentValue){
      this.formData.status = null
      setTimeout(() => {
        this.formData.status = currentValue
        this.$refs.refPermissions.updateStatusAreaKey()
        this.$refs.refPermissions.updateStatus(currentValue)
        this.$refs.refConfirmationModal.toggleModal(false)
      },200)
    },
    archiveConfirmed (newValue){
      this.formData.status = newValue
      this.$refs.refConfirmationModal.toggleModal(false)
    },

    updateStatus(newStatus){
      if( this.formData.status === newStatus) return
      if(newStatus === "Archived"){
        this.$refs.refConfirmationModal.toggleModal(true,
            {current_value : this.formData.status,new_value :newStatus}
        );
      } else {
        this.formData.status = newStatus;
      }
    },
    delegateTo(userIdentifier) {
      this.formData.delegated_user_identifier = userIdentifier
    }
  },
  async created() {
    await this.$ui.setPageHeader({
      title: "User Administration",
      showTask: true,
      parentTitle: "User Administration",
      pageItemTitle: "User Details"
    });

    const breadcrumbs = [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "User Administration",
        url: "admin_users"
      },
      {
        name: "User Details",
        url: "admin_users_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ];

    this.$store.commit("app/SET_BREADCRUMBS", [...breadcrumbs]);
    await this.loadUser();
    await this.loadBusinesses();
    this.$store.commit("app/SET_BREADCRUMBS", [
      ...breadcrumbs,
      {
        name: `${this.user?.first_name} ${this.user?.last_name}`,
        url: "admin_users_show",
        active: true,
        params: {
          id: this.$route.params.id
        }
      }
    ]);

    this.updateAvailable = this.$route.permissions
      ? this.$route.permissions.permissions.includes(1)
      : true;
    if (this.$route.query.action) {
      this.activeTap = this.$route.query.action;
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
