<template>
  <div>
    <div class="col-md-3 pull-right text-right">
      <button
        v-if="routeMeta.newTaskAvailable"
        class="btn btn-small"
        type="button"
        value=""
        data-toggle="modal"
        data-target="#sliderPanel"
        @click="toggleModal(true)"
      >
        <i class="fa fa-calendar-o"></i>
        New Task
      </button>
    </div>
    <ModalComponent v-if="isOpen" @close="toggleModal(false)">
      <template v-slot:title>
        <p>
          <strong class="font-120P"
            ><i class="fa fa-calendar-check-o font-orange"></i
            >&nbsp;&nbsp;&nbsp;&nbsp;
            {{ values.identifier ? "UPDATE" : "NEW" }} TASK</strong
          >
        </p>
      </template>
      <form
        id="form_1"
        :class="{ isLoading: 'skeleton' }"
        action=""
        method="post"
        role="form"
      >
        <div class="error-container"></div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="task_title">Task Title *</label>
              <input
                class="form-control"
                id="task_title"
                name="task_title"
                placeholder="Task title"
                type="text"
                :value="values.title"
                @input="e => handleChangeField(e, 'title')"
                required
              />
            </div>
          </div>
        </div>
      <!-- Testing -->
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <Select
                skip
                name="owner_id"
                label="Task Owner *"
                required
                :key="values.owner_id"
                :value="values.owner_id"
                placeholder="Select a Task Owner"
                :url="taskOwnerUrl"
                :axios="$http"
                @selected="
                  value =>
                    handleDropdownChange(value ? value.id : null, 'owner_id')
                "
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="due_date">Task Due Date*</label>
              <DatePicker
                :model-value="values.due_date"
                @update="e => handleChangeField(e, 'due_date')"
                name="due_date"
                id="due_date"
                :input-class="`form-control `"
                placeholder="Task due date"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="due_time">Task Due Time*</label>
              <div>
                <Timepicker :value="values.due_time" @change="handleChange" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="status">Task Status *</label>
              <select
                class="form-control"
                id="status"
                name="status"
                :value="values.status"
                @input="e => handleChangeField(e, 'status')"
              >
                <option disabled>Please Select</option>
                <option value="OPEN">Active</option>
                <option value="CLOSED">Completed</option>
                <option value="NO_ACTION">No Action Taken</option>
              </select>
            </div>
          </div>
        </div>

        <div class="gap-20"></div>

        <div class="row">
          <div class="col-md-12">
            <p><strong>Task Details</strong></p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="task_reference">Task Reference *</label>
              <select
                  :skip="false"
                class="form-control"
                id="task_reference"
                name="task_reference"
                :value="values.related_type"
                @input="e => handleSelectReference(e)"
                :disabled="disabledResourceSelected"
              >
                <option selected disabled>Please Select</option>
                <option
                  :value="reference.value"
                  v-for="reference in references"
                  :key="reference.value"
                  >{{ reference.name }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-md-8"
            v-if="selectedReference && selectedReference.url"
          >
            <div class="form-group">
              <Select
                :external-link="
                  !!(selectedReference.route && values.identifier_string)
                "
                required
                :axios="$http"
                name="related_id"
                :identifier="selectedReference.id ? null : 'identifier'"
                :value="values.related_id"
                :key="selectedReference.url"
                :url="selectedReference.url"
                :disabled="disabledResourceSelected"
                :label="`${selectedReference.name} *`"
                :placeholder="`${disabledResourceSelected ? values.opportunity_name : selectedReference.name}`"
                @externalLinkClicked="handleExternalLinkClicked"
                @selected="
                  value =>
                    handleDropdownChange(
                      value
                        ? value.identifier
                          ? value.identifier
                          : value.id
                        : null,
                      'related_id'
                    )
                "
              />
            </div>
          </div>
        </div>
        <div class="gap-20"></div>

        <Note
          :key="`key_${values.identifier_string}`"
          model="Task"
          :model_id="values.identifier_string"
          ref="note"
          style="padding-bottom : 20px"
        />

        <div class="row" v-if="showDelete">
          <div class="gap-150 MobileView"></div>
          <div class="col-md-12">
            <div class="form-group">
            <div class="form-group pull-left " style="margin-bottom: 0 !important;">
              <p class="delete-note">Type 'DELETE' below, then press the DELETE TASK button to delete this task</p>
            </div>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:submitBtn>
        <div v-if="showDelete">
          <div class="form-group pull-left mobile-width-50">
            <input v-model="deleteConfirmation" class="form-control" id="delete_task" name="delete_task" placeholder="" type="text" size="10" />
          </div>
          <div class="form-group pull-left mobile-pull-right mobile-width-50">
            &nbsp;&nbsp;&nbsp;<button :disabled="deleting || deleteConfirmation !== 'DELETE'" @click="deleteTask" class="btn btn-red mobile-width-90" type="button"><i class="fa fa-exclamation"></i>&nbsp;&nbsp;DELETE TASK</button>
          </div>
        </div>
        <SubmitButton
          :isProgress="submitting"
          :caption="
            submitting
              ? 'Submitting'
              : values.identifier
              ? 'Update Task'
              : 'Add Task'
          "
          @submit="handleSubmit"
        />
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalComponent from "../ModalComponent";
import Timepicker from "../Timepicker";
import Note from "../Note";
import Select from "../Form/Select";
import DatePicker from "../Form/DatePicker";
import moment from "moment";
import get from "lodash-es/get";
import SubmitButton from "../SubmitButton";
import { find } from "lodash-es";

export default {
  components: {
    ModalComponent,
    Select,
    DatePicker,
    SubmitButton,
    Note,
    Timepicker
  },
  data() {
    return {
      submitting: false,
      errors: {},
      deleteConfirmation: '',
      deleting : false,
      o365ApiUrl : `${process.env.VUE_APP_O365_API_URL}/events`
    };
  },
  methods: {
    ...mapActions({
      setTaskSuccess: "task/setTaskSuccess"
    }),
    async deleteTask() {
      if (!this.values.identifier || this.deleteConfirmation !== 'DELETE') {
        return
      }
      try {
        const url = `${process.env.VUE_APP_TASKS_API_URL}/admin/tasks/${this.values.identifier}`;
        this.deleting = true
        const response = await this.$http.delete(url);

        //Delete task at outlook
        await this.performOutlookEvent(response,'delete')

        this.toggleModal(false);

      //  this.setTaskSuccess();
        this.$emitter.emit("task_created", true);
        this.deleting = false
      }catch (e) {
        this.errors = get(e, "data.errors");
        this.deleting = false
      }
      this.deleting = false

    },
    updateFormData(name, value) {
      this.deleteConfirmation = ''
      this.$store.commit("task/UPDATE_FIELD", {
        name,
        value
      });
    },
    handleDropdownChange(value, param) {
      switch (param) {
        case "listing_id":
          this.updateFormData("related_type", "List");
          this.updateFormData("related_id", value);
          break;
        default:
          this.updateFormData(param, value);
      }
    },
    async handleExternalLinkClicked() {
      const route = await this.$router.resolve({
        name: this.selectedReference.route,
        params: {
          id: this.values.related_id
        }
      });
      window.open(route.href, "_blank");
    },
    async handleSubmit() {
      this.submitting = true;
      const url = `${process.env.VUE_APP_TASKS_API_URL}/admin/tasks/${
        this.values.identifier ? this.values.identifier : ""
      }`;

      const method = this.values.identifier ? "patch" : "post";

      try {
        const response = await this.$http[method](url, {
          ...this.values,
          due_date: this.values.due_date
            ? moment(this.values.due_date).format("YYYY-MM-DD HH:mm")
            : null,
          note: this.$refs.note.values.note
        });
        if (!this.values.identifier) {
          // await this.$refs.note.handleSubmit(data.identifier);
        }

        //Create/Update task at outlook
        await this.performOutlookEvent(response,method)

        this.$emitter.emit("task_created", true);
        this.submitting = false;
        this.toggleModal(false);
        this.setTaskSuccess();
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.submitting = false;
      }
    },
    async performOutlookEvent (response,method){

      const isTrrbOutlookUser = response?.owner?.is_trrb_outlook_user ?? response?.data?.owner?.is_trrb_outlook_user

      //Create/Update task at outlook
      if(isTrrbOutlookUser){
        let url =this.o365ApiUrl;
        const taskIdentifier = response?.data?.identifier || response.identifier
        if(method !== "post") url+=`/${taskIdentifier}`

        await this.$http[method](url, method === "post" ? {task_identifier : taskIdentifier} : null)
      }
    },
    toggleModal(isOpen) {
      this.$store.commit("task/RESET_FORM", isOpen);
      this.$store.commit("task/SET_IS_OPEN", isOpen);
      this.updateRelated();
    },
    handleChangeField(e, name) {
      this.updateFormData(name, e.target.value);
    },
    handleSelectReference(e) {
      const value = e.target.value;
      if (value) {
        this.$store.commit(
          "task/SET_SELECTED_REFERENCE",
          find(this.references, { value: value })
        );
      } else {
        this.$store.commit("task/SET_SELECTED_REFERENCE", null);
      }
      this.updateFormData("related_type", value ? value : null);
    },
    updateRelated() {
      if (this.routeMeta.model) {
        this.$store.commit(
          "task/SET_SELECTED_REFERENCE",
          find(this.references, {
            value: this.routeMeta.model
          })
        );
        this.updateFormData("related_type", this.routeMeta.model);
      }

      if (this.$route.params.id) {
        this.$store.commit("task/SET_DISABLE_RELATED", true);
        this.updateFormData("related_id", this.$route.params.id);
      } else {
        this.$store.commit("task/SET_DISABLE_RELATED", false);
      }
    },
    handleChange(value) {
      const { HH, mm } = value.data;
      this.updateFormData(
        "due_date",
        moment(this.values.due_date)
          .set({
            hour: HH,
            minute: mm
          })
          .format("YYYY-MM-DD HH:mm")
      );
    }
  },
  computed: {
    ...mapGetters({
      isOpen: "task/isOpen",
      values: "task/values",
      selectedReference: "task/selectedReference",
      references: "task/references",
      disabledResourceSelected: "task/disabledResourceSelected",
      isLoading: "task/isLoading"
    }),
    taskOwnerUrl() {
      return `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/select`;
    },
    listingUrl() {
      return `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings/search`;
    },
    routeMeta() {
      return this.$route.meta;
    },
    showDelete (){
      return /*moment().isSame(this.values.due_date, 'day') &&*/ this.values.identifier
    }
  },
};
</script>
