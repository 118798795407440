<template>
  <div class="row">
    <Accordion header="Business Details" icon="fa fa-address-card" closed>
      <Form
          ref="refBusinessDetailsForm"
        :business="account?.primaryBusiness"
        @save="values => $emit('save', values)"
      />
      <div class="row">
        <div
          v-for="address in account?.primaryBusiness?.addresses"
          :key="address.id"
          class="col-md-12"
        >
          <div class="col-md-12">
            <div class="div-border-bottom"></div>
          </div>
          <div class="gap-40"></div>

          <AddressForm
              :ref="`refAddressForm_${address.id}`"
            setPrimary
            active
            :form-data="address"
            @save="values => $emit('save', values)"
            :business="account?.primaryBusiness?.identifier"
          />
        </div>

        <div class="col-md-12">
          <div class="div-border-bottom"></div>
        </div>
        <div class="gap-40"></div>

        <div class="col-md-12">
          <AddressForm
              ref="refBusinessAddressForm"
            setPrimary
            newAddress
            @save="values => $emit('save', values)"
            :business="account?.primaryBusiness?.identifier"
          />
        </div>
      </div>
    </Accordion>
  </div>
</template>
<script>
import Accordion from "../../../components/Accordion";
import Form from "../../../components/Business/Form";
import AddressForm from "../../../components/Business/Address/Form";

export default {
  components: {
    Accordion,
    Form,
    AddressForm
  },
  props: {
    account: Object
  },
  methods : {
    submitAll() {
    //Since this can have dynamic contact refs ->  :ref="`refAddressForm_${address.id}`"
      Object.keys(this.$refs).forEach(key =>{
        this.$refs[key]?.handleSave(true)
      })

    }
  }
};
</script>
