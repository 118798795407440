<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              :class="{ active: activeTap === 'business' }"
              @click="activeTap = 'business'"
            >
              <a id="user_details_tab_link" data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Add</h4></a
              >
            </li>
          </ul>
          <form id="form_1">
            <div class="error-container"></div>
            <div class="panel-body">
              <div class="tab-content">
                <div
                  id="tab-01"
                  class="tab-pane"
                  :class="{ active: activeTap === 'business' }"
                >
                  <div class="col-md-12">
                    <Form
                      :permission-meta="businessPermissions"
                      :state-data="stateData"
                      :country-data="countryData"
                      :form-data="formData"
                      @submit="handleSubmit"
                      :errors="errors"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./Form";
import Permission from "./Permission";
import get from "lodash-es/get";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Form,
    Permission
  },
  data() {
    return {
      formData: {
        permissions: {
          admin_access: {
            site_access: true,
            admin_approved: true
          }
        },
        address: {
          state_id: "",
          country_id: 14
        }
      },
      activeTap: "business",
      isLoading: false,
      errors: {},
      stateData: {},
      countryData: {},
      stateUrl: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`,
      countryUrl: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/countries/search`
    };
  },
  computed: {
    ...mapGetters({
      permissions: "app/permissions"
    }),
    businessPermissions() {
      return this.permissions?.module_permission.business;
    }
  },
  methods: {
    async loadStates() {
      try {
        const { data } = await this.$http.get(this.stateUrl);
        this.stateData = data;
      } catch (e) {
        //this.errors = get(e, "data.errors");
      }
    },
    async loadCountries() {
      try {
        const { data } = await this.$http.get(this.countryUrl);
        this.countryData = data;
      } catch (e) {
        //this.errors = get(e, "data.errors");
      }
    },
    async handleSubmit(values) {
      this.isLoading = true;
      try {
        if (
          this.formData.website !== null &&
          this.formData.website !== undefined
        ) {
          this.formData.website = this.formData.website
            .replace("https://", "")
            .replace("http://", "")
            .replace("www.", "");
        }
        const { data } = await this.$http.post(
          `${process.env.VUE_APP_BUSINESS_API_URL}/admin/businesses`,
          {
            ...this.formData,
            ...values
          }
        );
        this.$router.push({
          name: "businesses_show",
          params: { id: data.id },
          query: { created: true }
        });
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        console.log(this.errors);
        //  const errors = _.flatten(error.response.data)
        this.isLoading = false;
        this.activeTap = "business";
      }
    }
  },
  async mounted() {
    this.$ui.setPageHeader({
      title: "Business Administration",
      showTask: true,
      parentTitle: "Business Administration",
      pageItemTitle: "Add Business"
    });
  },
  async created() {
    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Business Administration",
        url: "admin_users",
        query: {
          tap: "businesses"
        }
      },
      {
        name: "Business Details",
        url: "businesses_create",
        active: true
      }
    ]);

    await this.loadStates();
    await this.loadCountries();
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
