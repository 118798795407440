export default [
  {
    name: "active_date",
    text: "Active Date",
    render: item => (item.listing ? item.listing.active_date : "None")
  },
  {
    name: "reference",
    text: "Reference",
    render: item => (item.listing ? item.listing.reference : "None"),
    badge: item => item.interests
  },
  {
    name: "region",
    text: "Region",
    render: item => (item.listing ? item.listing.region : "None")
  },
  {
    name: "website",
    text: "Website",
    link: item =>
      item.listing
        ? `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings/${item.listing.id}`
        : "",
    sort_disable: true
  },
  {
    name: "listing",
    text: "Listing",
    link: item =>
      item.listing
        ? `${process.env.VUE_APP_ADMIN_URL}/listings/${item.listing.id}`
        : "",
    sort_disable: true
  },
  {
    name: "status",
    text: "Status",
    render: item => (item.listing ? item.listing.status : "None"),
    className: item =>
      item.listing.status === "For Sale" ? "font-green" : "font-orange"
  }
];
