export const roll_sizes = [
  { name: "Any (default)", value: "any" },
  { name: "50", value: "50" },
  { name: "100", value: "100" },
  { name: "200", value: "200" },
  { name: "400", value: "400" },
  { name: "600", value: "600" },
  { name: "800", value: "800" },
  { name: "1000", value: "1000" },
  { name: "2000", value: "2000" },
  { name: "3000", value: "3000" }
];

export const price_ranges = [
  { name: "Any (default)", value: "any" },
  { name: "$200,000", value: "200000" },
  { name: "$400,000", value: "400000" },
  { name: "$600,000", value: "600000" },
  { name: "$800,000", value: "800000" },
  { name: "$1,000,000", value: "1000000" },
  { name: "$3,000,000", value: "3000000" },
  { name: "$4,000,000", value: "4000000" },
  { name: "$5,000,000", value: "5000000" },
  { name: "$10,000,000", value: "10000000" }
];
