<template>
  <div>
    <div class="col-md-12">
      <div class="entry-header-admin" v-if="update">
        <div class="gap-40"></div>
        <div class="col-md-12 entry-title-admin">
          <div class="row">
            <p class="font-120P">Edit Details</p>
          </div>
        </div>
      </div>
      <div class="gap-20"></div>

      <div class="row">
        <Permission
          @update="handleChangePermission"
          :default-permissions="defaultPermissions"
          header="Permissions"
          :values="values"
          :create="!update"
          @save="handleUpdatePermission"
        />
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <p><strong class="font-120P">Business Details</strong></p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="business_name">Legal business name *</label>
            <input
              class="form-control"
              id="business_name"
              name="business_name"
              :class="
                `${errors && errors.hasOwnProperty('name') ? 'has-error' : ''}`
              "
              v-model="values.name"
              placeholder="The legal business name"
              type="text"
              required
            />
            <span
              v-if="errors && errors.hasOwnProperty('name')"
              class="help-block text-danger"
              >{{ errors.name[0] }}</span
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="industry">Industry *</label>
            <input
              class="form-control"
              id="industry"
              name="industry"
              :class="
                `${
                  errors && errors.hasOwnProperty('industry_name')
                    ? 'has-error'
                    : ''
                }`
              "
              v-model="values.industry_name"
              placeholder="Business industry (eg Accountants)"
              type="text"
              required
            />
            <span
              v-if="errors && errors.hasOwnProperty('industry_name')"
              class="help-block text-danger"
              >{{ errors.industry_name[0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="contact_name">Contact full name </label>
            <input
              class="form-control"
              id="contact_name"
              :class="
                `${
                  errors && errors.hasOwnProperty('contact_person')
                    ? 'has-error'
                    : ''
                }`
              "
              v-model="values.contact_person"
              name="contact_name"
              placeholder="The best contact person"
              type="text"
            />
            <span
              v-if="errors && errors.hasOwnProperty('contact_person')"
              class="help-block text-danger"
              >{{ errors.contact_person[0] }}</span
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="contact_number">Contact / Business number * </label>
            <input
              class="form-control"
              id="contact_number"
              :class="
                `${
                  errors && errors.hasOwnProperty('contact_number')
                    ? 'has-error'
                    : ''
                }`
              "
              v-model="values.contact_number"
              name="contact_number"
              placeholder="The best contact number"
              type="text"
              required
            />
            <span
              v-if="errors && errors.hasOwnProperty('contact_number')"
              class="help-block text-danger"
              >{{ errors.contact_number[0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="contact_email">Contact / Business email * </label>
            <input
              class="form-control"
              id="contact_email"
              name="contact_email"
              :class="
                `${errors && errors.hasOwnProperty('email') ? 'has-error' : ''}`
              "
              v-model="values.email"
              placeholder="The best contact email"
              type="email"
              required
            />
            <span
              v-if="errors && errors.hasOwnProperty('email')"
              class="help-block text-danger"
              >{{ errors.email[0] }}</span
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="business_website">Business website * </label>
            <div class="input-group mb-3">
              <div class="input-group-addon">
                <span class="input-group-text" id="basic-addon3"
                  >https://www.</span
                >
              </div>
              <input
                class="form-control"
                id="business_website"
                name="business_website"
                :class="
                  `${
                    errors && errors.hasOwnProperty('website')
                      ? 'has-error'
                      : ''
                  }`
                "
                v-model="values.website"
                placeholder="The business website"
                type="text"
                required
                aria-describedby="basic-addon3"
              />
            </div>
            <span
              v-if="errors && errors.hasOwnProperty('website')"
              class="help-block text-danger"
              >{{ errors.website[0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="street_address">Street address *</label>
            <input
              class="form-control"
              id="street_address"
              name="street_address"
              :class="
                `${
                  errors && errors.hasOwnProperty('street') ? 'has-error' : ''
                }`
              "
              v-model="values.street"
              placeholder="The registered business street address"
              type="text"
              required
            />
            <span
              v-if="errors && errors.hasOwnProperty('street')"
              class="help-block text-danger"
              >{{ errors.street[0] }}</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="suburb">Suburb *</label>
            <input
              class="form-control"
              id="suburb"
              name="suburb"
              :class="
                `${
                  errors && errors.hasOwnProperty('suburb') ? 'has-error' : ''
                }`
              "
              v-model="values.suburb"
              placeholder="Registered suburb"
              type="text"
              required
            />
            <span
              v-if="errors && errors.hasOwnProperty('suburb')"
              class="help-block text-danger"
              >{{ errors.suburb[0] }}</span
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <Select
              name="state"
              label="State *"
              :class-name="
                `${
                  errors && errors.hasOwnProperty('state_id') ? 'has-error' : ''
                }`
              "
              :error="
                errors && errors.hasOwnProperty('state_id')
                  ? errors.state_id[0]
                  : ''
              "
              required
              placeholder="Select a state"
              selected="Australia"
              :url="stateSearch"
              :value="values.state_id"
              :axios="getGeoLocations"
              @selected="value => handleDropdownChange(value, 'state')"
              showother
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="postcode">Postcode *</label>
            <input
              class="form-control"
              id="postcode"
              name="postcode"
              :class="
                `${
                  errors && errors.hasOwnProperty('postcode') ? 'has-error' : ''
                }`
              "
              v-model="values.postcode"
              placeholder="Registered postcode"
              type="text"
              required
            />
            <span
              v-if="errors && errors.hasOwnProperty('postcode')"
              class="help-block text-danger"
              >{{ errors.postcode[0] }}</span
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <Select
              name="country"
              label="Country *"
              :value="values.country_id ? values.country_id : 14"
              required
              :class-name="
                `${
                  errors && errors.hasOwnProperty('country_id')
                    ? 'has-error'
                    : ''
                }`
              "
              :error="
                errors && errors.hasOwnProperty('country_id')
                  ? errors.country_id[0]
                  : ''
              "
              placeholder="Select a Country"
              :url="countrySearch"
              :axios="getGeoLocations"
              @selected="value => handleDropdownChange(value, 'country')"
            />
          </div>
        </div>
      </div>
      <div class="gap-40"></div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <SubmitButton v-if="isCreatable || updateAvailable"
              :isProgress="isLoading"
              :caption="buttonName"
              @submit="handleSubmit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/Form/Select";
import SubmitButton from "@/components/SubmitButton";
import Permission from "../../components/Permission";
import { mapGetters } from "vuex";
import map from "lodash-es/map";

export default {
  computed: {
    ...mapGetters({
      permissionMeta: "app/permissions"
    }),
    getGeoLocations() {
      return this.$http;
    },
    buttonName() {
      if (this.update) {
        if (this.isLoading) {
          return "Updating";
        } else {
          return "Update Affiliate";
        }
      } else {
        if (this.isLoading) {
          return "Creating";
        } else {
          return "Create Affiliate";
        }
      }
    },
    defaultPermissions() {
      let permissions = [];
      map(
        this.permissionMeta.module_permission.affiliate.permissions,
        group => {
          map(group.permissions, permission => {
            permissions.push({
              group: group.group,
              name: permission.caption,
              permission: permission.title,
              value: permission.name
            });
          });
        }
      );
      return permissions;
    },
    permissions() {
      return this.$route.permissions ? this.$route.permissions : {};
    },
    isCreatable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(0)
        : false;
    },
    updateAvailable() {
      return this.permissions && this.permissions.permissions
        ? this.permissions.permissions.includes(1)
        : false;
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Object,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: () => false
    },
    isLoading: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    Select,
    SubmitButton,
    Permission
  },
  data() {
    return {
      values: {
        ...this.formData
      },
      countrySearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/countries/search`,
      stateSearch: `${process.env.VUE_APP_GEO_LOCATION_API_URL}/states/search`
    };
  },
  methods: {
    handleSetPermission() {
      this.$emit("submit", this.values);
    },
    handleSubmit() {
      this.$emit("submit", {
        ...this.values,
        permissions: this.values.permissions
          ? this.values.permissions
          : {
              admin_access: {
                admin_approved: false,
                site_access: false
              }
            }
      });
    },
    handleDropdownChange(value, param) {
      this.values[param] = value;
    },
    handleChangePermission(values) {
      this.values.permissions = values;
    },
    handleUpdatePermission(values) {
      this.$emit(
        "submit",
        {
          ...this.values,
          permissions: values
            ? values
            : {
                admin_access: {
                  admin_approved: false,
                  site_access: false
                }
              }
        },
        true
      );
    }
  }
};
</script>

<style>
.v3dp__divider {
  display: none !important;
}
</style>
