export default [
  {
    name: "active_date",
    text: "Active Date",
    render: item => item.active_date
  },
  {
    name: "user",
    text: "User",
    render: item => item.user,
    sort_disable: true
  },
  {
    name: "title",
    text: "Title",
    render: item => item.title
  },
  {
    name: "status",
    text: "Status",
    render: item => item.status,
    className: item =>
      item.status === "Active" ? "font-green" : "font-orange",
    sort_disable: true
  }
];
