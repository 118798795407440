import { filter } from "lodash-es";

export const UPDATE_VALUES = "UPDATE_VALUES";
export const UPDATE_PROJECT_VALUES = "UPDATE_PROJECT_VALUES";
export const UPDATE_DROPDOWN_VALUES = "UPDATE_DROPDOWN_VALUES";
export const UPDATE_IM_VALUES = "UPDATE_IM_VALUES";

import get from "lodash-es/get";
import toArray from "lodash-es/toArray";
import map from "lodash-es/map";
const state = {
  values: {
    project: {
      stage_1: {
        name: ""
      }
    },
    dropdown: {},
    im: []
  },
  activity: {
    name: "",
    events: {
      name: ""
    }
  }
};

// getters
const getters = {
  values: state => {
    return state.values;
  },
  projectValues: state => {
    return state.values.project;
  },
  dropdownValues: state => {
    return state.values.dropdown;
  },
  imValues: state => {
    return map(toArray(state.values.im), value => {
      return {
        ...value,
        data: {
          ...(value.data ? value.data : {}),
          image_url:
            value.data && value.data.image_url
              ? (value.data.image_url.search(`${process.env.VUE_APP_ADMIN_URL}`) == -1  ? `${process.env.VUE_APP_ADMIN_URL}/${value.data.image_url}` : `${value.data.image_url}`)
              : ""
        }
      };
    });
  },
  leadSources: state => {
    const items = state.values?.dropdown?.lead_source ?? [];
    return filter(items.values ? items.values : [], { is_active: true });
  },
  currencies: state => {
    const items = state.values?.dropdown?.currency ?? [];
    return filter(items.values ? items.values : [], { is_active: true });
  },
  categories: state => {
    const items = state.values?.dropdown?.category ?? [];
    return filter(items.values ? items.values : [], { is_active: true });
  },
  businessTypes: state => {
    const items = state.values?.dropdown?.business_type ?? [];
    return filter(items.values ? items.values : [], { is_active: true });
  }
};

// actions
const actions = {
  async loadProjectData({ commit }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await this.$http.get(
        `${process.env.VUE_APP_CONFIG_API_URL}/admin/master-data`,
        {
          params: {
            key: "PROJECT"
          }
        }
      );

      commit("UPDATE_PROJECT_VALUES", get(data, "0.data"));
    } catch (error) {
      throw error;
    }
  },
  async loadDropDownListData({ commit }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await this.$http.get(
        `${process.env.VUE_APP_CONFIG_API_URL}/admin/master-data`,
        {
          params: {
            key: "DROPDOWN"
          }
        }
      );

      commit("UPDATE_DROPDOWN_VALUES", get(data, "0.data"));
    } catch (error) {
      throw error;
    }
  },
  async loadIMData({ commit },isIMTemplates = false) {
    let params;
    if(isIMTemplates.isIMTemplates){
      params =  {key: "IM", isIMTemplates: isIMTemplates.isIMTemplates, id:isIMTemplates.id.id }
    }else{
      params =  {key: "IM"}
      // if(isIMTemplates) params.isIMTemplates = isIMTemplates
    }

    try {
      const { data } = await this.$http.get(
        `${process.env.VUE_APP_CONFIG_API_URL}/admin/master-data`,
        {
          params: {...params}
        }
      );

      commit("UPDATE_IM_VALUES", get(data, "0.data"));
    } catch (error) {
      throw error;
    }
  }
};

// mutations
const mutations = {
  [UPDATE_VALUES]: (state, values) => {
    state.values = {
      ...state.values,
      ...values
    };
  },
  [UPDATE_PROJECT_VALUES]: (state, values) => {
    state.values = {
      ...state.values,
      project: {
        ...state.values.project,
        ...values
      }
    };
  },
  [UPDATE_DROPDOWN_VALUES]: (state, values) => {
    state.values = {
      ...state.values,
      dropdown: {
        ...state.values.dropdown,
        ...values
      }
    };
  },
  [UPDATE_IM_VALUES]: (state, values) => {
    state.values = {
      ...state.values,
      im: {
        ...state.values.im,
        ...values
      }
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
