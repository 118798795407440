<template>
  <div>
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row">
          <p class="font-120P">My Tasks</p>
        </div>
      </div>
    </div>
    <!-- Heading end -->

    <!-- Task details start -->
    <div class="entry-content">
      <div class="gap-20"></div>
      <ResourceTable
        :edit="false"
        :columns="columns"
        :url="url"
        remove-actions
      />
    </div>
    <!-- Task details end -->
  </div>
</template>

<script>
import ResourceTable from "@/components/ResourceTable";

export default {
  components: {
    ResourceTable
  },
  data() {
    return {
      columns: [
        {
          name: "created",
          text: "Date",
          timestamp: item => item.created
        },
        {
          name: "creator",
          text: "Creator",
          render: item => item.creator
        },
        {
          name: "task",
          text: "Task",
          render: item => item.task,
          sort_disable: true
        }
      ],
      url: `${process.env.VUE_APP_TASKS_API_URL}/admin/tasks`
    };
  }
};
</script>

<style></style>
