import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ui from "@/services/ui";
import { admin } from "@/lib/axios";
import AppLayout from "@/layouts/AppLayout";
import emitter from "tiny-emitter/instance";

//css
import "@/assets/css/animate.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/bootstrap-datepicker.css";
import "@/assets/css/Chart.min.css";
import "@/assets/css/colorbox.css";
import "@/assets/css/datatables.min.css";
import "@/assets/css/font-awesome.min.css";
//import '@/assets/css/mdb.min.css'
import "@/assets/css/owl.carousel.min.css";
import "@/assets/css/owl.theme.default.min.css";
import "@/assets/css/pure-material-switch.css";
import "@/assets/css/responsive.css";
import "@/assets/css/style.css";

//js
import "@/assets/js/jquery.js";
import "@/assets/js/bootstrap.min.js";
import "@/assets/js/bootstrap-datepicker.js";
import "@/assets/js/Chart.bundle.min.js";
import "@/assets/js/datatables.min.js";
import "@/assets/js/gmap3.js";
import "@/assets/js/html5shiv.js";
import "@/assets/js/ini.isotope.js";
//import '@/assets/js/isotope.js'
import "@/assets/js/jquery.colorbox.js";
import "@/assets/js/owl.carousel.min.js";
import "@/assets/js/custom.js";
//import '@/assets/js/respond.min.js'
import "@/assets/js/utils.js";
import "@/assets/js/validator.js";
import "@/assets/js/wow.min.js";

//components
import ModalComponent from "./components/ModalComponent.vue";
import SliderPanel from "./components/SliderPanel.vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'

window.Vapor = require("laravel-vapor");
var CrossStorageHub = require("cross-storage").CrossStorageHub;

CrossStorageHub.init([
  {
    origin: new RegExp(process.env.VUE_APP_CORS_URL, "g"),
    allow: ["get", "set", "del", "getKeys", "clear"]
  }
]);

const app = createApp(App)
  .use(store)
  .use(router)
    .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RE_CAPTCHA_KEY })
    .component("slider-panel", SliderPanel)
  .component("modal-component", ModalComponent)
  .component("AppLayout", AppLayout);

app.config.globalProperties.$http = admin;
app.config.globalProperties.$ui = ui;
app.config.globalProperties.$emitter = emitter;

app.mount("#app");
