<template>
  <div>
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row">
          <p class="font-120P">{{ title }}</p>
        </div>
      </div>
    </div>
    <div class="entry-content">
      <div class="gap-20"></div>
      <ResourceTable
        :edit="false"
        :columns="columns"
        :url="`${url}/${route}`"
        remove-actions
        @clicked="handleClicked"
      />
    </div>
  </div>
</template>

<script>
import ResourceTable from "../../components/ResourceTable";
import { mapActions } from "vuex";
import { columns } from "../../utils/tasks";

export default {
  props: {
    title: String,
    route: String
  },
  components: {
    ResourceTable
  },
  data() {
    return {
      columns: columns,
      url: `${process.env.VUE_APP_TASKS_API_URL}`
    };
  },
  methods: {
    ...mapActions({
      setInitialValues: "task/setInitialValues"
    }),
    handleClicked(task) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          task: task.identifier
        }
      });
      this.$store.commit("task/RESET_FORM");

      this.setInitialValues({
        task: task.identifier
      });
      this.$store.commit("task/SET_IS_OPEN", true);
    }
  },
};
</script>

<style></style>
