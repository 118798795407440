<template>
  <div class="row">
    <div class="col-md-12">
      <div class="entry-header-admin">
        <div class="gap-40"></div>
        <div class="col-md-12 entry-title-admin">
          <div class="row">
            <p class="font-120P">Opportunities</p>
          </div>
        </div>
      </div>
      <div class="entry-content">
        <div class="gap-20"></div>
        <div class="col-md-12">
          <ResourceTable
            v-if="listingId"
            :columns="interestColumns"
            :url="interest"
            remove-actions
            :extra-params="{
              listing_id: listingId
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ResourceTable from "@/components/ResourceTable";
export default {
  props: {
    listingId: String
  },
  components: {
    ResourceTable
  },
  computed: {
    interestColumns() {
      return [
        {
          name: "date",
          text: "Date Enquired",
          timestamp: item => item.created
        },
        {
          name: "customer",
          text: "Customer",
          render: item => `${item.first_name} ${item.last_name}`,
          link: item => `${process.env.VUE_APP_ADMIN_URL}/accounts/${item.uuid}`,
          sort_disable: true
        },
        {
          name: "ca",
          text: "CA",
          render: item =>
            item.status === "Completed" ? "Download" : item.status,
          download: true,
          downloadable: item => item.status === "Completed",
          className: item =>
            item.status === "Completed" ? "cursor-pointer font-link" : "",
          downloadUrl: item =>
            `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-ca`,
          sort_disable: true
        },
        {
          name: "eoi",
          text: "EOI",
          render: item =>
            item.expressionstatus === null ? "None" : item.expressionstatus,
          // link: item =>
          //   item.expressionstatus === null
          //     ? ""
          //     : item.expressionstatus === "Submitted"
          //     ? `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-eoi`
          //     : `${process.env.VUE_APP_ADMIN_URL}/customer/${item.uuid}/information-memorandums?tab=eoi`,
          download: true,
          downloadable: item => item.expressionstatus === "Submitted",
          className: item =>
            item.expressionstatus === "Submitted"
              ? "cursor-pointer font-link"
              : "",
          sort_disable: true,
          downloadUrl: item =>
              `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/${item.interest_id}/download-eoi`,
        },
        {
          name: "opportunity",
          text: "Opportunity",
          render: item => "Link",
          link: item =>
            item.interest_uuid
              ? `${process.env.VUE_APP_ADMIN_URL}/opportunities/${item.interest_uuid}`
              : null,
          sort_disable: true
        }
      ];
    },
    interest() {
      return `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/customers`;
    }
  }
};
</script>
