<template>
  <div class="col-md-12">
    <button
      class="admin-accordion"
      :class="{ 'admin-active admin-accordion-open': isOpen }"
      type="button"
      @click="handleClickBtn"
    >
      <i :class="`${icon ? icon : 'fa fa-lock'}`"></i>&nbsp;&nbsp;{{ header }}
    </button>
    <div class="admin-panel" v-if="isOpen" style="max-height: 100%">
      <div class="gap-20"></div>
      <div class="entry-content">
        <slot></slot>
        <div class="gap-20"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    header: String,
    icon: String,
    closed: Boolean
  },
  data() {
    return {
      isOpen: true
    };
  },
  methods: {
    handleClickBtn() {
      this.isOpen = !this.isOpen;
    }
  },
  mounted() {
    if (this.closed) {
      this.isOpen = false;
    }
  }
};
</script>
<style>
.admin-panel {
  overflow: visible !important;
}
</style>
