<template>
  <div class="row">
    <div class="gap-40"></div>
    <div class="col-md-12 table-responsive">
      <table
        :class="
          data_table
            ? isPreview
              ? `table table-sm table-bordered`
              : `table table-striped table-hover table-sm table-bordered`
            : isPreview
            ? `table`
            : `table table-striped table-hover`
        "
        ref="dtDataTable1"
        :id="`${data_table && !isPreview ? tableId : 'tablePreview'}`"
      >
        <thead v-if="header">
          <tr>
            <th :key="index" v-for="(column, index) in headerData.columns">
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr :key="index" v-for="(row, index) in rowsData">
            <td :key="key" v-for="(column, key) in row.columns">
              {{ column }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { filter, first } from "lodash-es";
import $ from "jquery";
export default {
  data() {
    return {
      tableId: URL.createObjectURL(new Blob([])).slice(-36)
    };
  },
  props: {
    data_table: Boolean,
    header: Boolean,
    columns: Array,
    rows: Array,
    data: Object,
    isPreview: Boolean
  },
  computed: {
    rowsData() {
      if (this.header) {
        return filter(this.rows, (row, index) => index !== 0);
      }
      return this.rows;
    },
    headerData() {
      if (this.header) {
        return first(filter(this.rows, (row, index) => index === 0));
      }
      return [];
    }
  },
  mounted() {
    if (this.data_table) {
      $(`#${this.tableId}`).DataTable({
        pagingType: "first_last_numbers",
        order: [[0, "desc"]]
      });
    }
  }
};
</script>
