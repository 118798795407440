<template>
  <div>
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin">
        <div class="row">
          <p class="font-120P">Documents</p>
        </div>
      </div>
    </div>

    <div class="row" v-if="created">
      <div class="col-md-12">
        <div id="alert-mail-success" class="alert alert-success fade in">
          <a
            href="#"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            title="close"
            @click="created = false"
          >
            ×
          </a>
          <span class="icon"><i class="fa fa-check"></i></span
          ><strong>Success!</strong><br />The document has been created
        </div>
      </div>
    </div>

    <div class="gap-20"></div>
    <Accordion header="Upload a Document" closed>
      <div class="gap-20"></div>
      <div class="row">
        <form id="form_1" action="#" method="post" role="form">
          <div class="col-md-12">
            <div class="form-group">
              <label for="document_description">Document Description *</label>
              <input
                class="form-control"
                id="document_description"
                name="document_description"
                placeholder="Document description"
                type="text"
                required
                v-model="values.description"
                :class="
                  `form-control ${
                    errors && errors.hasOwnProperty('description') ? 'has-error' : ''
                  }`
                "
              />
              <span
                v-if="errors && errors.hasOwnProperty('description')"
                class="help-block text-danger"
                >{{ errors.description[0] }}</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <Select
                name="owner"
                label="Document Owner *"
                :value="values.owner_id"
                :class-name="
                  `${errors && errors.hasOwnProperty('owner_id') ? 'has-error' : ''}`
                "
                :error="
                  errors && errors.hasOwnProperty('owner_id') ? errors.owner_id[0] : ''
                "
                required
                placeholder="Select a owner"
                :url="userSearch"
                :axios="$http"
                @selected="
                  value =>
                    handleDropdownChange(value ? value.id : null, 'owner_id')
                "
                showother
              />
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group">&nbsp;</div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label for="file">
                {{
                  isUploading === true
                    ? "Uploading..."
                    : `Select Document to upload *`
                }}
              </label>
              <input
                required
                name="file"
                id="file"
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.png,.jpg,.jpeg,.mov,.mp4,.mp3,.wav"
                placeholder="Browse document"
                @change="handleFileUpload"
              />
            </div>
          </div>

          <div class="gap-20"></div>

          <div class="col-md-12 text-right">
            <SubmitButton
              :isProgress="isSubmitting"
              :caption="isSubmitting ? 'Uploading' : 'Upload Document'"
              @submit="handleSubmitForm"
              icon="fa fa-cloud-upload"
            />
          </div>
        </form>
      </div>
    </Accordion>
    <div class="gap-20"></div>

    <div class="entry-content">
      <div class="gap-20"></div>
<!--      show-path="listings_show"-->
      <ResourceTable
        ref="table"
        :columns="columns"
        :url="url"
        :extraParams="extraParams"
      />
    </div>
  </div>
</template>
<script>
import Accordion from "../../components/Accordion";
import Select from "@/components/Form/Select";
import get from "lodash-es/get";
import SubmitButton from "../SubmitButton";
import ResourceTable from "@/components/ResourceTable";

export default {
  components: {
    Accordion,
    Select,
    SubmitButton,
    ResourceTable
  },
  props: {
    relatedId: String,
    relatedType: String
  },
  data() {
    return {
      values: {
        description: "",
        owner_id: null,
        related_id: "",
        related_type: "",
        file: ""
      },
      errors: {},
      isUploading: false,
      isSubmitting: false,
      created: false
    };
  },
  computed: {
    userSearch() {
      return `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/search`;
    },
    url() {
      return `${process.env.VUE_APP_DOCUMENTS_API_URL}/admin/files`;
    },
    extraParams() {
      return {
        related_id: this.relatedId,
        related_type: this.relatedType
      };
    },
    columns() {
      return [
        {
          name: "active_date",
          text: "Uploaded Date",
          render: item => item.created_at,
          timestamp: true
        },
        {
          name: "description",
          text: "Document Description",
          render: item => item.description
        },
        {
          name: "link",
          text: "Availability",
          render: item => item.file_url,
          sort_disable: true
        },
        {
          name: "owner",
          text: "Owner",
          render: item =>
            item.owner
              ? `${item.owner?.first_name} ${item.owner?.last_name}`
              : "",
          sort_disable: true
        },
        {
          name: "uploadedBy",
          text: "Uploaded by",
          render: item =>
            item.uploadedBy
              ? `${item.uploadedBy?.first_name} ${item.uploadedBy?.last_name}`
              : "",
          sort_disable: true
        }
      ];
    }
  },
  methods: {
    handleDropdownChange(value, param) {
      this.values[param] = value;
    },
    async handleFileUpload(e) {
      const file = e.target.files[0];
      this.isUploading = true;
      try {
        const data = await this.$http.post(
          `${process.env.VUE_APP_DOCUMENTS_API_URL}/admin/signed-storage-url`,
          {
            path: "files/",
            content_type: file.type,
            visibility: "private"
          }
        );
        if (data.url) {
          await this.$http.put(data.url, file, {
            headers: {
              useAuth: false,
              "Content-Type": file.type,
              "x-amz-acl": "private"
            }
          });
        }
        this.isUploading = false;
        this.values.file = data.uuid;
      } catch (e) {
        console.log(e);
      }
    },
    async handleSubmitForm() {
      this.isSubmitting = true;
      try {
        console.log('S');
        await this.$http.post(
          `${process.env.VUE_APP_DOCUMENTS_API_URL}/admin/files`,
          {
            ...this.values,
            related_id: this.relatedId,
            related_type: this.relatedType
          }
        );

        this.handleResetForm();
        this.created = true;

        console.log('table');
        this.$refs.table.refresh();

        console.log('E');
      } catch (e) {
        this.errors = get(e, "data.errors");
      }
      this.isSubmitting = false;
    },
    handleResetForm() {
      this.values = {
        description: "",
        owner_id: null,
        related_id: "",
        related_type: "",
        file: ""
      };
    }
  },
  watch: {
    created: {
      handler(newValue, oldValue){
          if (newValue === true){
              location.reload();
          }
      },
      immediate: true
    }
  }
};
</script>
