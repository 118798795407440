<template>
  <div>
    <div class="row">
      <div class="col-md-6" v-if="editName">
        <textarea
          class="form-control form-control-message"
          id="edit_event_1_text"
          placeholder="Event description"
          rows="2"
          v-model="values.title"
        />
      </div>
      <div class="col-md-6" v-else>
        <ul
          :class="{
            'ul-tick-circled': event.status === 'COMPLETED',
            'ul-flag': event.status !== 'COMPLETED'
          }"
        >
          <li>{{ event.title }}</li>
        </ul>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <Select
            name="owner"
            :value="values.owner_id"
            required
            load-on-open
            placeholder="Select a owner"
            :url="userSearch"
            :axios="$http"
            @selected="value => (values.owner_id = value ? value.id : null)"
            showother
            class-name="form-control-sm"
            :disabled="!editName"
            :selected-name="values.owner_name"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <SearchDropdown
            name="event_owner"
            :disabled="!editName"
            class-name="form-control-sm"
            :options="statuses"
            :value="getValueObject(values.status)"
            @selected="value => (values.status = value ? value.id : null)"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="row">
          <div id="event_3_showtasks_btn" class="col-md-6 text-right">
            <button
              class="btn btn-xxsmall pull-right"
              type="button"
              title="Show Event Tasks"
              @click="showTasks = !showTasks"
            >
              <i class="fa fa-calendar-o"></i>
            </button>
          </div>
          <div id="event_3_shownotes_btn" class="col-md-3 text-right">
            <button
              class="btn btn-xxsmall pull-right"
              type="button"
              title="Show Event Notes"
              @click="showNotes = !showNotes"
            >
              <i class="fa fa-sticky-note"></i>
            </button>
          </div>
          <div
            id="event_3_edit_btn"
            class="col-md-3 text-right"
            v-if="!editName"
          >
            <button
              class="btn btn-xxsmall pull-right"
              type="button"
              title="Edit Event"
              @click="editName = true"
            >
              <i class="fa fa-edit"></i>
            </button>
          </div>
          <div id="event_3_save_btn" class="col-md-3 text-right">
            <button
              class="btn btn-xxsmall pull-right"
              type="button"
              title="Save Event"
              v-if="editName"
              @click="handleSaveName"
            >
              <i class="fa fa-save"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="gap-10"></div>

    <div
      v-if="showTasks"
      class="row"
      id="tasks_area_3"
      style="padding: 0 0 40px 20px;"
    >
      <div class="col-md-6">
        <p class="font-80P cursor-pointer">
          <strong class="font-orange font-120P">EVENT TASKS</strong
          >&nbsp;&nbsp;&nbsp;[<a @click="showTasks = false">hide</a>]
        </p>
      </div>
      <div class="col-md-6 text-right">
        <button
          class="btn btn-xxsmall"
          type="button"
          @click="handleNewTaskClicked"
        >
          <i class="fa fa-calendar-o"></i>&nbsp;&nbsp;New Task
        </button>
      </div>
      <div class="gap-20"></div>
      <div class="col-md-12">
        <ResourceTable
          :key="`${key}_resource`"
          ref="resourceTable"
          :edit="false"
          :columns="columns"
          :url="taskUrl"
          remove-actions
          :extra-params="extraParams"
          @clicked="handleClicked"
        />
      </div>
    </div>

    <div class="gap-10"></div>
    <div v-if="showNotes" class="row" style="padding: 0 0 40px 20px;">
      <div class="col-md-6">
        <p class="font-80P cursor-pointer">
          <strong class="font-orange font-120P">EVENT NOTES</strong
          >&nbsp;&nbsp;&nbsp;[<a @click="showNotes = false">hide</a>]
        </p>
      </div>
      <div class="gap-20"></div>
      <Note :key="`${key}_notes`" model="Event" :model_id="event.id" />
    </div>
  </div>
</template>
<script>
import Select from "../../../components/Form/Select";
import SearchDropdown from "../../../components/SearchDropdown";
import ResourceTable from "../../../components/ResourceTable";
import Note from "../../../components/Note";
import { columns } from "../../../utils/tasks";
import find from "lodash-es/find";
import { mapActions } from "vuex";

export default {
  props: {
    event: Object
  },
  components: {
    Select,
    SearchDropdown,
    ResourceTable,
    Note
  },
  data() {
    return {
      editName: false,
      nameSubmitting: false,
      showTasks: false,
      showNotes: false,
      values: {
        owner_id: this.event?.owner_id,
        owner_name: `${this.event?.owner?.first_name} ${this.event?.owner?.last_name}`,
        title: this.event?.title,
        status: this.event?.status
      },
      key: 1
    };
  },
  computed: {
    userSearch() {
      return `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/search`;
    },
    statuses() {
      return [
        { name: "Not Started", id: "NOT_STARTED" },
        { name: "In Progress", id: "IN_PROGRESS" },
        { name: "Paused", id: "PAUSED" },
        { name: "Completed", id: "COMPLETED" },
        { name: "No Action Taken", id: "NO_ACTION" }
      ];
    },
    taskUrl() {
      return `${process.env.VUE_APP_TASKS_API_URL}/admin/tasks`;
    },
    extraParams() {
      return {
        related_type: "Event",
        related_id: this.event.id
      };
    },
    columns() {
      return columns;
    }
  },
  methods: {
    ...mapActions({
      setInitialValues: "task/setInitialValues"
    }),
    async handleSaveName() {
      this.nameSubmitting = true;
      try {
        await this.$http.patch(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/events/${this.event?.id}`,
          this.values
        );
        this.$emit("updated", this.values);
      } catch {
        //
      }
      this.editName = false;
      this.nameSubmitting = false;
    },
    handleNewTaskClicked() {
      this.$store.commit("task/SET_IS_OPEN", true);
      this.$store.commit("task/SET_SELECTED_REFERENCE", {
        name: "Event",
        value: "Event"
      });
      this.$store.commit("task/UPDATE_FIELD", {
        name: "related_type",
        value: "Event"
      });
      this.$store.commit("task/UPDATE_FIELD", {
        name: "related_id",
        value: this.event.id
      });
    },
    getValueObject(status) {
      return find(this.statuses, { id: status });
    },
    handleClicked(task) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          task: task.identifier
        }
      });
      this.$store.commit("task/RESET_FORM");

      this.setInitialValues({
        task: task.identifier
      });
      this.$store.commit("task/SET_IS_OPEN", true);
    }
  },
  mounted() {
    this.$emitter.on("task_created", () => {
      this.key = this.key + 1;
    });
  }
};
</script>
