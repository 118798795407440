<template>
  <div class="" v-if="customer">
    <ResourceTable
      :columns="interestColumns"
      :url="interest"
      remove-actions
      :extra-params="{
        customer_id: customer.id
      }"
    />
  </div>
</template>

<script>
import ResourceTable from "@/components/ResourceTable";
import { mapGetters } from "vuex";
export default {
  components: {
    ResourceTable
  },
  computed: {
    ...mapGetters("auth", ["customer"])
  },
  data() {
    return {
      interest: `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/seller-listings`,
      interestColumns: [
        {
          name: "date",
          text: "Date Listed",
          timestamp: item => item.created
        },
        {
          name: "reference",
          text: "Reference",
          render: item => item.reference,
          link: item =>
            item && item.uuid !== null && item.active_status === "Active"
              ? `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings/${item.identifier}`
              : "",
          className: item =>
            item && item.uuid !== null && item.active_status === "Active"
              ? "cursor-pointer font-link"
              : ""
        },
        {
          name: "region",
          text: "Region",
          render: item => item.region,
          link: item =>
              item && item.uuid !== null && item.active_status === "Active"
                  ? `${process.env.VUE_APP_ADMIN_URL}/rent-roll-listings/${item.identifier}`
                  : "",
          sort_disable: true
        },
        {
          name: "im",
          text: "IM",
          render: item => item && item.im !== null ? "Link" : "N/A",
          link: item => item && item.im !== null
                ? `${process.env.VUE_APP_ADMIN_URL}/customer/${item.im_identifier}/seller-information-memorandums`
                : "",
          disableNewTab: true,
          sort_disable: true,
          className: item =>
            item && item.uuid !== null && item.active_status === "Active"
              ? "cursor-pointer font-link"
              : "",
          sort_disable: true
        },
        {
          name: "status",
          text: "Status",
          render: item => item.status,
          sort_disable: true
        }
      ]
    };
  }
};
</script>

<style></style>
