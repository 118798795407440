<template>
  <div class="entry-content">
    <div class="col-md-12">
      <div class="row">
        <section class="normal-tabs line-tab">
          <ul class="nav nav-tabs">
            <li
              id="tab-label-01"
              :class="{ active: activeTap === 'project' }"
              @click="activeTap = 'project'"
            >
              <a id="project_details_tab_link" data-toggle="tab" href="#tab-01"
                ><h4 class="panel-title">Add Project</h4></a
              >
            </li>
          </ul>
          <form id="form_1">
            <div class="error-container"></div>
            <div class="panel-body">
              <div class="tab-content">
                <div
                  id="tab-01"
                  class="tab-pane"
                  v-if="activeTap === 'project'"
                  :class="{ active: activeTap === 'project' }"
                >
                  <div class="col-md-12">
                    <Form
                      :form-data="formData"
                      @submit="handleSubmit"
                      :errors="errors"
                      :isLoading="isLoading"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./Form";
import get from "lodash-es/get";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Form
  },
  data() {
    return {
      formData: {},
      activeTap: "project",
      isLoading: false,
      errors: {}
    };
  },
  computed: {
    ...mapGetters({
      permissions: "app/permissions"
    })
  },
  methods: {
    ...mapActions("auth", ["loadStats"]),
    async getPermissionsMeta() {
      try {
        // this.formData.permissions = this.permissions?.default_admin_permissions;
      } catch (error) {
        console.log(error);
      }
    },
    async handleSubmit(values) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.post(
          `${process.env.VUE_APP_LISTINGS_API_URL}/admin/projects`,
          values
        );
        await this.loadStats();
        await this.$router.push({
          name: "projects_show",
          params: { id: data.id }
        });
        this.isLoading = false;
      } catch (e) {
        this.errors = get(e, "data.errors");
        this.activeTap = "project";
        this.isLoading = false;
      }
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    activeTap: {
      immediate: true,
      handler() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  },
  async created() {
    this.$store.commit("app/SET_BREADCRUMBS", [
      {
        name: "Dashboard",
        url: "home"
      },
      {
        name: "Project Administration",
        url: "projects"
      },
      {
        name: "Project Details",
        url: "projects_create",
        active: true
      }
    ]);

    await this.getPermissionsMeta();
  }
};
</script>

<style></style>
