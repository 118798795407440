<template>
  <div class="col-md-12">
    <div class="entry-header-admin">
      <div class="gap-40"></div>
      <div class="col-md-12 entry-title-admin text-left pull-left">
        <div class="row">
          <p class="font-120P">Opportunities</p>
        </div>
      </div>
    </div>
    <div class="entry-content">
      <div class="row">
        <Filter :filters="filters" @submit="handleSubmit" closed />
      </div>
      <div class="gap-20"></div>
    </div>
    <div class="entry-content">
      <div class="gap-20"></div>
      <ResourceTable
        ref="resourceTable"
        show-path="opportunities_show"
        :columns="columns"
        :update-available="false"
        :url="url"
        @onSearch="onSearch"
      />
    </div>
    <div class="gap-20"></div>
    <div class="entry-content">
      <div class="col-md-12">
        <div class="row">
          <div class="table-responsive">
            <table id="" class="" cellspacing="0" cellpadding="0" width="100%">
              <tbody>
                <tr>
                  <td width="58%" align="right" style="padding: 15px;">
                    <p>Total Opportunities Amount</p>
                  </td>
                  <td width="42%" style="padding: 4px;" v-if="!isTotalLoading">
                    <p class="font-120P font-orange">
                      <strong>${{ total }}</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="entry-content">
      <div class="col-md-12" v-for="chart in charts" :key="chart.key">
        <div class="row">
          <div class="gap-40"></div>
          <div class="col-md-12">
            <div class="row">
              <div class="row border-lt">
                <canvas :id="chart.key"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Filter from "../../../components/Filter";
import ResourceTable from "../../../components/ResourceTable";
import columns from "../../Opportunities/columns";
import moment from "moment";
import map from "lodash-es/map";

export default {
  components: {
    Filter,
    ResourceTable
  },
  computed: {
    filters() {
      return [
        {
          key: "group_1",
          filters: [
            {
              key: "created_from",
              name: "created_from",
              component: "DateFilter",
              label: "Created Date From",
              grid: "col-md-3"
            },
            {
              key: "created_to",
              name: "created_to",
              component: "DateFilter",
              label: "Created Date To",
              grid: "col-md-3"
            },
            {
              key: "closed_from",
              name: "closed_from",
              component: "DateFilter",
              label: "Close Date From",
              grid: "col-md-3"
            },
            {
              key: "closed_to",
              name: "closed_to",
              component: "DateFilter",
              label: "Close Date To",
              grid: "col-md-3"
            }
          ]
        },
        {
          key: "group_2",
          filters: [
            {
              key: "owner",
              name: "owner",
              component: "SearchDropdown",
              label: "Opportunity Owner",
              grid: "col-md-3",
              url: `${process.env.VUE_APP_IDENTITY_API_URL}/api/admin/users/search`
            },
            {
              key: "stage",
              name: "stage",
              component: "SelectDropdown",
              label: "Opportunity Stage",
              grid: "col-md-3",
              values: [
                { name: "All", value: "" },
                { name: "Qualification", value: "Qualification" },
                { name: "Value Proposition", value: "Value Proposition" },
                {
                  name: "Expression of Interest",
                  value: "Expression of Interest"
                },
                { name: "Negotiation / Review", value: "Negotiation / Review" },
                { name: "Closed (WON)", value: "Closed (WON)" },
                { name: "Closed (LOST)", value: "Closed (LOST)" }
              ]
            }
          ]
        }
      ];
    },
    columns() {
      return columns;
    },
    url() {
      return `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests`;
    }
  },
  data() {
    return {
      total: 0,
      isTotalLoading: false,
      isChartLoading: false,
      values: {},
      charts: [
        {
          name: "Opportunities by State",
          url: `admin/charts/by-state`,
          key: "opportunities_by_state",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Opportunities by State"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        },
        {
          name: "Opportunities by Agent",
          url: `admin/charts/by-agent`,
          key: "opportunities_by_agent",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Opportunities by Agent"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        },
        {
          name: "Opportunities by Stage by Agent",
          url: `admin/charts/by-stage-agent`,
          key: "opportunities_by_stage_by_agent",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Opportunities by Stage by Agent"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        },
        {
          name: "Opportunities by Stage",
          url: `admin/charts/by-stage`,
          key: "opportunities_by_stage",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Opportunities by Stage"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        },
        {
          name: "Opportunities by Lead Source by Business Type",
          url: `admin/charts/by-lead-source-business-type`,
          key: "opportunities_by_lead_source_by_business_type",
          options: {
            type: "bar",
            data: [],
            options: {
              title: {
                display: true,
                text: "Opportunities by Lead Source by Business Type"
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                }
              }
            }
          }
        }
      ]
    };
  },
  methods: {
    handleSubmit(values) {
      this.$refs.resourceTable.customGetDataCall(values);
      this.getOpportunityChartsData(values);
      this.getOpportunityTotal(values);
    },
    async getOpportunityTotal(values = {}) {
      this.isTotalLoading = true;
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_INTEREST_API_URL}/admin/interests/total`,
          {
            params: values
          }
        );
        this.total = data;
      } catch (e) {
        console.log(e);
      }
      this.isTotalLoading = false;
    },
    async getOpportunityChartsData(values = {}) {
      this.isChartLoading = true;
      try {
        const data = await Promise.all(
          map(this.charts, chart => {
            return this.$http.get(
              `${process.env.VUE_APP_INTEREST_API_URL}/${chart.url}`,
              {
                params: values
              }
            );
          })
        );
        map(this.charts, (chart, index) => {
          chart.options.data = data[index].data;
          this.charts[index].options.data = data[index].data;
          this.initiateChart(chart);
        });
      } catch (e) {
        console.log(e, "error");
      }
      this.isChartLoading = false;
    },
    initiateChart(chart) {
      var ctx = document.getElementById(chart.key).getContext("2d");
      window.myBar = new Chart(ctx, chart.options);
    },
    onSearch(searchobject) {
      console.log("Search done");
      console.log(searchobject?.params?.search);
      this.getOpportunityTotal({search: searchobject?.params?.search});
    }
  },
  created() {
    this.getOpportunityTotal();
    this.getOpportunityChartsData();
  }
};
</script>
