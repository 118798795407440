<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Offer History</strong></p>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-1">
        <p class="listing-label">Offer</p>
      </div>
      <div class="col-md-3">
        <p class="listing-label">Date</p>
      </div>
      <div class="col-md-3">
        <p class="listing-label">Amount</p>
      </div>
      <div class="col-md-5">
        <p class="listing-label">Notes</p>
      </div>
    </div>
    <div class="gap-20"></div>
    <div v-for="(offer, index) in offers" :key="index">
      <div class="row">
        <div class="col-md-1">
          <p>{{ index + 1 }}</p>
        </div>
        <div class="col-md-3">
          <p>{{ offer.created_at }}</p>
        </div>
        <div class="col-md-3">
          <p>
            {{ offer.offer_amount }}&nbsp;
            <i
              class="fa fa-arrow-up font-green"
              v-if="offer.diff === 'INCREASE'"
            ></i>
            <i class="fa fa-minus font-green" v-if="offer.diff === 'EQUAL'"></i>
            <i class="fa fa-minus font-green" v-if="offer.diff === 'NEW'"></i>
            <i
              class="fa fa-arrow-down font-red"
              v-if="offer.diff === 'DECREASE'"
            ></i>
          </p>
        </div>
        <div class="col-md-5">
          <p>{{ offer.notes }}</p>
        </div>
      </div>
      <div class="row">
        <div class="bottom-border"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-1">
        <p>{{ offers.length + 1 }}</p>
      </div>
      <div class="col-md-3">
        <p>-</p>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <input
            class="form-control"
            style="margin-top: 15px;"
            id="offer_amount"
            name="offer_amount"
            placeholder="New offer ($)"
            type="number"
            @blur="handleChange"
            @change="handleChange"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <textarea
            class="form-control form-control-message"
            style="margin-top: 15px;"
            id="notes"
            name="notes"
            placeholder="Offer note"
            rows="2"
            @blur="handleChange"
            @change="handleChange"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="bottom-border"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    offers: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleChange($event) {
      this.$emit("update", $event.target.name, $event.target.value);
    }
  }
};
</script>
