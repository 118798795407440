<template>
  <div>
    <ResourceTable
      :show-path="showPath"
      :columns="columns"
      :update-available="false"
      :url="url"
      :extra-params="extraParams"
    />
  </div>
</template>
<script>
import ResourceTable from "@/components/ResourceTable";
export default {
  components: {
    ResourceTable
  },
  props: {
    account: Object,
    listing: Object
  },
  data() {
    return {
      showPath: "projects_show",
      url: `${process.env.VUE_APP_LISTINGS_API_URL}/admin/projects`,
      extraParams: {
        listing: [
          this.account.linked_listing_id_one,
          this.account.linked_listing_id_two,
          this.listing.id
        ]
      },
      columns: [
        {
          name: "created_at",
          text: "Created",
          render: item => item.created_at
        },
        {
          name: "name",
          text: "Project Name",
          render: item => item.name
        },
        {
          name: "reference",
          text: "Reference",
          render: item => item.listing?.reference
        },
        {
          name: "region",
          text: "Region",
          render: item => item.listing?.region
        },
        {
          name: "state",
          text: "State",
          render: item => item.listing?.state?.name
        },
        {
          name: "owner",
          text: "Owner",
          render: item =>
            `${item?.owner?.first_name} ${item?.owner?.last_name}`,
          sort_disable: true
        },
        {
          name: "status",
          text: "Status",
          render: item => item.status,
          sort_disable: true
        }
      ]
    };
  }
};
</script>
