<template>
  <div class="entry-content">
    <div class="row">
      <div class="alert alert-success fade in" v-if="created">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The article has been created
      </div>
    </div>
    <div class="row" v-if="updated">
      <div class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The article's details have been updated
      </div>
      <!-- Alert success end -->
    </div>
    <div class="row" v-if="permissionUpdate">
      <div class="alert alert-success fade in">
        <a
          href="#"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          title="close"
          >×</a
        >
        <span class="icon"><i class="fa fa-check"></i></span
        ><strong>Success!</strong><br />The article's permissions have been
        updated
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Article Title</p>
        <p>
          <strong class="font-120P text-uppercase">{{ article.title }}</strong
          >&nbsp;&nbsp;<span
            class="badge"
            :class="{
              'badge-active': article.active_status === 'Active',
              'badge-inactive': article.active_status === 'Inactive'
            }"
            >{{ article.active_status }}</span
          >
        </p>
        <!-- <span class="badge badge-inactive" v-if="article.status === 'Inactive'">Inactive</span> -->
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Active Date</p>
        <p>{{ article.active_date }}</p>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Home Page Image</p>
      </div>
      <div class="col-md-6">
        <img
          class="img-responsive"
          :src="article.home_page_image_url"
          :alt="article.title"
        />
      </div>
    </div>
    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Industry News & Advice Page Image</p>
      </div>
      <div class="col-md-6">
        <img
          :src="article.article_page_image_url"
          :alt="article.title"
          class="img-responsive"
        />
      </div>
    </div>
    <div class="gap-40"></div>

    <div class="row">
      <div class="col-md-12">
        <p><strong class="font-120P">Full Article Page</strong></p>
      </div>
    </div>
    <div class="row" v-if="article.full_article_page_image_url">
      <div class="gap-20"></div>
      <div class="col-md-12">
        <p class="listing-label">Full Article Image</p>
      </div>
      <div class="col-md-12">
        <img
          class="img-responsive"
          :src="article.full_article_page_image_url"
          :alt="article.title"
        />
      </div>
    </div>

    <div class="row" v-if="article.youtube_link">
      <div class="gap-20"></div>
      <div class="col-md-12">
        <p class="listing-label">Full Article Video</p>
      </div>
      <div class="gap-10"></div>
      <div class="video-wrapper-grey">
        <div class="full-width-video-container">
          <iframe
            :src="article.youtube_link.replace('youtu.be', 'youtube.com/embed')"
            frameborder="0"
            allowfullscreen="true"
          ></iframe>
        </div>
        <p class="font-white">{{ article.youtube_link_description }}</p>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <p class="listing-label">Full Article Body</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p v-html="article.article_body"></p>
      </div>
    </div>
    <ModifiedSection
      :created="article.created_at"
      :updated="article.updated_at"
      model="Article"
      :model-id="article.id"
    />
  </div>
</template>

<script>
import ModifiedSection from "@/components/ModifiedSection";
export default {
  props: {
    created: Boolean,
    updated: Boolean,
    article: Object,
    permissionUpdate: Boolean
  },
  components: {
    ModifiedSection
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style></style>
