
<template>
  <section id="main-container" class="main-container">
    <div id="main-document-container" class="container">
      <div class="row">
        <div class="col-md-12 loader-container">
          <span class="loader"></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    login_token: String,
    im: String
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      loggedIn: "auth/loggedIn"
    })
  },
  methods: {
    ...mapActions({
      loginCustomer: "auth/setInterestSubmittedCustomer",
      getCustomer: "auth/getCustomer",
    }),
  },
  async mounted() {
    setTimeout(async () => {
      await this.getCustomer(this.user.customer.id);
      await this.$router.push({ name: 'im_customer_show', params: {uuid: this.im} })
    },5000)
  },
  async created() {
    await this.loginCustomer({ login_token: this.login_token })
  }
}
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  text-align: center;
}

.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  border: 5px solid #FFF;
  border-bottom-color: #7b00ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
