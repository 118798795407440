<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Headings</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="heading-small">Heading (small) *</label>
          <input
            class="form-control"
            id="heading-small"
            name="heading-small"
            placeholder="Heading (small)"
            type="text"
            :value="values.headingSmall"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="heading-large">Heading (large) *</label>
          <input
            class="form-control"
            id="heading-large"
            name="heading-large"
            placeholder="Heading (large)"
            type="text"
            :value="values.headingLarge"
            required
          />
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-6">
        <div>
          <p><strong>Current Top Image</strong></p>
          <p class="font-orange font-90P">
            Replacement image size must be <strong>W 1024px x H 350px</strong>
          </p>
        </div>
        <div class="gap-20"></div>
        <div class="form-group">
          <FileUpload
            @uploaded="handleFileUploaded"
            name="profile_image"
            :image="values.image_url"
            preview
          />
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-12">
        <p><strong>Body Text</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <Editor
            class="form-control form-control-message"
            name="text_line_1"
            :plugins="plugins"
            :toolbar="toolbar"
            placeholder="Text line 1 *"
            rows="10"
            v-model="values.body.text_1"
          ></Editor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import FileUpload from "@/components/FileUpload/Index";
export default {
  components: {
    Editor,
    FileUpload
  },
  props: {
    headingSmall: String,
    headingLarge: String,
    image: String,
    image_url: String,
    path: String,
    body: Object
  },
  data() {
    return {
      values: {
        headingSmall: this.headingSmall,
        image: this.image,
        image_url: this.image_url,
        headingLarge: this.headingLarge,
        body: {
          text_1: "",
          ...this.body
        }
      },
      plugins: "lists advlist code link table",
      toolbar: "numlist bullist"
    };
  },
  created() {},
  methods: {
    handleFileUploaded({ file, fileUrl }) {
      this.values.image = file;
      this.values.image_url = fileUrl;
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    values: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          return;
        }
        this.$emit("valueChange", this.values);
      }
    }
  },
  async mounted() {
    if (!this.image) {
      return;
    }
    try {
      const url = await this.$http.get(
        `${process.env.VUE_APP_LISTINGS_API_URL}/admin/document-url/${this.image}`,
        {
          params: {
            path: this.path ? this.path : "information-memorandums/"
          }
        }
      );
      this.values.image_url = url;
    } catch (e) {
      console.log(e);
    }
  }
};
</script>
