<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="text-right">
          <button
              title="Save Account"
              class="btn btn-xsmall"
              @click="handleSave(false)"
          >
            <span class="font-120P"><i class="fa fa-save"></i></span>
          </button>
        </div>
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-12">
        <!-- We are only loading listings with an IM -->
        <Select
            :skip="false"
            name="linked_listing_id_one"
            label="Linked Listing 1"
            :value="values.linked_listing_id_one"
            placeholder="Linked Listing 1"
            :url="listingUrl"
            :axios="$http"
            identifier="id"
            @selected="
            value => {
              values.linked_listing_id_one = value?.id;
            }
          "
        />
      </div>
    </div>
    <div class="gap-20"></div>
    <div class="row">
      <div class="col-md-12">
        <!-- We are only loading listings with an IM -->
        <Select
            :skip="false"
            label="Linked Listing 2"
            name="linked_listing_id_two"
            :value="values.linked_listing_id_two"
            placeholder="Linked Listing 2"
            :url="listingUrl"
            :axios="$http"
            identifier="id"
            @selected="
            value => {
              values.linked_listing_id_two = value?.id;
            }
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import Select from "../../../components/Form/Select";

export default {
  props: {
    formData: {
      type: Object,
      default: () => ({
        linked_listing_id_one: "",
        linked_listing_id_two: ""
      })
    }
  },
  data() {
    return {
      values: {
        ...this.formData
      },
      isSubmitting: false,
      listingUrl: `${process.env.VUE_APP_LISTINGS_API_URL}/admin/listings/search`
    };
  },
  components: {
    Select
  },
  methods: {
    async handleSave(isMassUpdate = false) {
      this.isSubmitting = true;
      try {
        await this.$http.patch(
            `${process.env.VUE_APP_CUSTOMER_API_URL}/admin/customers/${this.values.id}`,
            this.values
        );
        if(!isMassUpdate) this.$emit("save", "account");
      } catch (e) {
        console.log(e);
      }
      this.isSubmitting = false;
    }
  }
};
</script>
