<template>
  <section id="main-container" class="main-container">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" v-if="$router.currentRoute.value.name !== 'email_verification' && $router.currentRoute.value.name !== 'eoi_submit'">
          <div class="sidebar sidebar-left">
            <SideBar />
          </div>
        </div>
        <div :class="$router.currentRoute.value.name !== 'eoi_submit' ? 'col-lg-9 col-md-9 col-sm-12 col-xs-12' : 'm-top-minus-15' ">
          <div class="post-admin">
            <div class="post-body">
              <div class="entry-header-admin">
                <div class="col-md-12 entry-title-admin">
                  <div class="row" v-if="$router.currentRoute.value.name !== 'email_verification' && $router.currentRoute.value.name !== 'eoi_submit'">
                    <div class="col-md-9 pull-left">
                      <p class="font-160P text-uppercase">{{ page.title }}</p>
                      <p class="font-90P">
                        //
                        <span
                          v-for="(breadcrumb, index) in breadcrumbs"
                          :key="breadcrumb.name"
                        >
                          <router-link
                            v-if="breadcrumb.url && breadcrumb.name !== 'undefined undefined'"
                            :class="breadcrumb.active ? 'font-orange' : ''"
                            :to="{
                              name: breadcrumb.url,
                              params: breadcrumb.params,
                              query: breadcrumb.query ? breadcrumb.query : {}
                            }"
                            >{{ breadcrumb.name }}
                            <span v-if="breadcrumb.data"> / {{ breadcrumb.data }}</span>
                          </router-link>
                          <span
                            :class="breadcrumb.active ? 'font-orange' : ''"
                            v-if="!breadcrumb.url"
                            >{{ breadcrumb.name }}
                            <span v-if="breadcrumb.data"> / {{ breadcrumb.data }}</span>
                          </span>

                          <span v-if="index < breadcrumbs.length - 1"> / </span>
                        </span>
                      </p>
                    </div>
                    <AddTaskComponent />
                  </div>
                </div>
              </div>
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "@/components/SideBar";
import AddTaskComponent from "@/components/Task/AddTaskComponent";
import { mapGetters } from "vuex";

export default {
  name: "AppPortalLayout",
  components: {
    SideBar,
    AddTaskComponent
  },
  data() {
    return {
      isSliderOpen: false
    };
  },
  computed: {
    ...mapGetters({
      page: "app/page",
      breadcrumbs: "app/breadcrumbs"
    }),
    parentRoute() {
      return this.$route.meta.parentRoute;
    }
  }
};
</script>

<style scoped></style>
