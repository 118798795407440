<template>
  <div>
    <div class="modal-backdrop fade in"></div>
    <div
      class="modal fade in"
      :id="id"
      tabindex="-1"
      role="dialog"
      style="display: block"
    >
      <div
        :class="{'center-modal':centerModal ,'modal-lg' : !centerModal}"
        class="modal-dialog modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              @click="$emit('close')"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <slot name="title"></slot>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer text-left">
            <slot name="submitBtn"></slot>
            <!--            <button-->
            <!--              type="button"-->
            <!--              class="btn btn-default text-black"-->
            <!--              data-dismiss="modal"-->
            <!--              @click="$emit('close')"-->
            <!--            >-->
            <!--              Cancel-->
            <!--            </button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String
    },
    title: {
      type: String
    },
    values: {
      type: Object,
      default: () => {}
    },
    centerModal : Boolean
  }
};
</script>
<style scoped>
.modal-body {
  max-height: calc(100vh - 212px);
  overflow-y: auto;
  font-weight: lighter;
}
.center-modal {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}
</style>
