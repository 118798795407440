<template>
  <section class="normal-tabs line-tab">
    <ul class="nav nav-tabs">
      <li
        :class="{ active: tab.key === activeTab }"
        :key="`header_${tab.key}`"
        v-for="tab in tabs"
        class="cursor-pointer"
        :title="tab.key"
      >
        <a :id="tab.key" data-toggle="tab" @click="handleActiveTab(tab.key)"
          ><h4 class="panel-title" v-if="tab.icon">
            <i :class="tab.icon"></i>
          <span
              v-if="tab.props.showCount"
              class="ml-4 badge badge-highlight font-70P"
          >
                   {{tab.props.count}}
                  </span>
          </h4>
          <h4 class="panel-title" v-if="!tab.icon">
            {{ tab.name }}
          </h4>
        </a>
      </li>
    </ul>
    <div class="panel-body">
      <div class="tab-content">
        <template v-for="tab in tabs" :key="`pane_${tab.key}`">
          <div
            class="tab-pane"
            :class="{ active: tab.key === activeTab }"
            v-if="tab.key === activeTab"
          >
            <component
                ref="component"
              v-if="!tab.isLoading"
              :is="tab.component"
              v-bind="tab.props ? tab.props : {}"
              @save="value => $emit('save', value)"
              @submit="values => $emit('submit', values)"
              @save-address="values => $emit('save-address', values)"
              @add-address="values => $emit('add-address', values)"
              @set-primary="values => $emit('set-primary', values)"
              @unlink="values => $emit('unlink', values)"
              @updateStatus="(value) => $emit('updateStatus',value)"
              @setDelegateTo="(value) => $emit('setDelegateTo',value)"
            />
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      activeTab: null
    };
  },
  props: {
    tabs: Array,
    defaultTab: String
  },
  methods: {
    handleActiveTab(tab) {
      this.activeTab = tab;
    },
    async updateAll(){
     if(this.$refs.component && this.$refs.component.updateAll)await this.$refs.component?.updateAll()
    }
  },
  mounted() {
    this.activeTab = this.defaultTab ? this.defaultTab : this.tabs[0]?.key;
  }
};
</script>
